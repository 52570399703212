import React, {useRef, useState} from 'react';
import './style.scss';
import Languages from "../../../translation/Languages";

function CopyTextComponent({textForCopy}) {
  const [showMessage, setShowMessage] = useState(false);
  const spanRef = useRef();

  const copyToClipboard = async (e) => {
    try {
      const text = e.target.textContent;
      await navigator.clipboard.writeText(text)
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 1000); // Сообщение исчезает после 2 секунд
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  }

  return (
    <>
      <div className='copyTextComponent'>
            <span ref={spanRef}  className='pointer' onClick={(e) => copyToClipboard(e)}>
                {textForCopy}
            </span>
        {showMessage && (
          <div
            className='copyTextComponent__container'
            style={{
              position: "absolute",
              top: `${spanRef.current.offsetTop + 30}px`,
              left: `${spanRef.current.offsetLeft}px`,
            }}
          >
            {Languages.getTranslation('copied-to-clipboard', true)}
          </div>
        )}
      </div>
    </>

  );
}

export default CopyTextComponent;