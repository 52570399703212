import './style.scss';
import img from '../../media/Component 10.svg'
import Languages from "../../translation/Languages";
import {useEffect} from "react";

const QuitApp = () => {

  history.replaceState(null, null, window.location.href);
  return (
    <div className='quitApp'>
      <div className='quitApp__modal'>
        <div className="quitApp__header">
          <img src={img} alt="img" className='quitApp__modal-img'/>
          <h3 className="quitApp__modal-title">{Languages.getTranslation('warning', true)}</h3>
        </div>
        <div className="quitApp__body">
          <div className="quitApp__body-block1">
            <p className='quitApp__text'>{Languages.getTranslation('quitApp_text4', true)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuitApp;