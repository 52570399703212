import * as THREE from "three";
import {Raycaster, Vector2} from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls.js";
import store from "../../redux/store";

// import Stats from 'three/examples/jsm/libs/stats.module'
import gsap from 'gsap'
import {empty} from "../../helpers/helper";
import {projectOperations} from "redux/project";
import {panelOperations} from "redux/panel";
import {contourEdgeHelperOperations} from "redux/contourEdgeHelper";
import Helpers from "./Helpers";
import {hoveredObjectOn3DOperations} from "redux/hoveredObjectOn3D";

export default class sceneModel {
  rendered = 0;
  shouldRender = false;
  clickMouse;
  hoveredObject = null;
  hoveredTreatment = null;
  hoveredCounterEdge = null;
  constructor(dom) {
    this.dom = dom;
    this.init();
  }

  init() {
    const width = this.dom.clientWidth;
    const height = this.dom.clientHeight;
    this.scene = new THREE.Scene();
    //FPS gui
    // this.stats = new Stats()
    // document.body.appendChild(this.stats.dom)
    //Add Renderer
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      powerPreference: "high-performance", //"high-performance", "low-power" or "default"
      precision: "mediump", //highp", "mediump" or "lowp"
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);
    this.renderer.setClearColor(0x000000, 0); // the default
    this.dom.appendChild(this.renderer.domElement);
    //add Camera
    this.camera = new THREE.PerspectiveCamera(40, width / height, 0.1, 100000);
    this.camera.position.z = 800;
    this.camera.position.y = 0;
    //Intersection
    this.mouse = new Vector2();
    this.clickMouse = new Vector2();
    this.raycaster = new Raycaster();
    //Camera Controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.addEventListener('change', this.renderScene)
    this.controls.mouseButtons.LEFT = THREE.MOUSE.ROTATE;
    this.scene.add(new THREE.AmbientLight("#f5f5f5", 1));

    this.controls.addEventListener('start', () => {
      this.disableVisibleControlsAndMesh(false)
    });

    this.controls.addEventListener('end', () => {
      this.disableVisibleControlsAndMesh(true)
    });
    this.setListeners();
    this.renderScene();
    this.start();
  }

  setListeners() {
    window.addEventListener(
      "resize",
      () => {
        this.updateRenderSize();
      },
      false
    );

    this.dom.addEventListener('mousemove', (event) => {
      let rect = this.renderer.domElement.getBoundingClientRect();
      this.clickMouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      this.clickMouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      this.clickMouse.clientX = event.clientX;
      this.clickMouse.clientY = event.clientY;
      this.hoverObject();
    });

    this.dom.addEventListener('dblclick', (event) => {
      let rect = this.renderer.domElement.getBoundingClientRect();
      this.clickMouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      this.clickMouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      this.clickMouse.clientX = event.clientX;
      this.clickMouse.clientY = event.clientY;
      this.editProc();
    });

    this.dom.addEventListener('click', (event) => {
      let rect = this.renderer.domElement.getBoundingClientRect();
      this.clickMouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      this.clickMouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      this.clickMouse.clientX = event.clientX;
      this.clickMouse.clientY = event.clientY;
      this.listenersOnClick();
    });

    this.renderer.domElement.addEventListener('mouseleave', this.cursorLeave3DScene.bind(this));
  }

  cursorLeave3DScene() {
    this.disableAndRender()
  }

  listenersOnClick() {
    this.scrollToProcInChosProcesses()
  }

  scrollToProcInChosProcesses() {
    this.raycaster.setFromCamera(this.clickMouse, this.camera)
    const found = this.raycaster.intersectObjects(this.scene?.children[1]?.children)
    // console.log(found)
    if (!empty(found)) {
      const needMesh = found.find(el => this.getActiveProcesses().includes(el?.object?.userData?.type))
      const proc = needMesh?.object?.userData?.proc
      if (empty(proc)) {
        return;
      }
      this.actionHoveredObjectOn3D(proc)
    }
  }

  hoverObject() {
    if (empty(this.scene?.children[1]?.children)) {
      return;
    }
    this.raycaster.setFromCamera(this.clickMouse, this.camera);
    const found = this.raycaster.intersectObjects(this.scene?.children[1]?.children);

    if (!empty(found)) {
      const needMesh = found.find(el => this.getActiveProcesses().includes(el?.object?.userData?.type) || el.object?.userData?.meshType === 'side');
      if (empty(needMesh)) {
        this.disableAndRender();
        return;
      }
      this.dom.style.cursor = 'pointer';
      if (needMesh.object.userData.meshType === 'side') {
        this.handleSideObject(needMesh);
        this.renderScene();
        return;
      }
      this.handleProcObject(needMesh);
    } else {
      this.disableAndRender();
    }
    this.renderScene();
  };

  disableAndRender() {
    this.disableLastProc();
    this.disableHoveredCounterEdge();
    this.disableActionHoveredObjectOn3D()
    this.dom.style.cursor = 'default';
    this.renderScene()
  };

  handleSideObject(needMesh) {
    this.disableLastProc();
    this.disableHoveredCounterEdge();
    needMesh.object.material.color = Helpers.getHighColorByThickEdge(needMesh.object.userData.edge?.thickness);
    this.hoveredCounterEdge = needMesh.object;
    if (!empty(needMesh.object.userData.edge)) {
      this.showOurContourEdgeHelper(needMesh.object.userData.edge)
    }
  };

  handleProcObject(needMesh) {
    const proc = needMesh?.object?.userData?.proc;
    if (empty(proc)) {
      return;
    }
    if (!empty(proc) && !proc.isActive) {
      this.showContourEdgeHelperForMills(proc)
      this.disableLastProc();
      proc.isActive = true;
      proc.showArrow = true;
      this.hoveredObject = proc;
    }
  };

  showContourEdgeHelperForMills(proc) {
    if(['mill', 'corner', 'tableProc'].includes(proc.subType)) {
      if (!empty(proc.edge)) {
        const edge = store.getState().project.project.construction.edges.find(el => el.index === (proc.subType === 'corner' ? proc.edge.index : proc.edge))
        this.showOurContourEdgeHelper(edge)
      }
    } else {
      this.disableHoveredCounterEdge();
    }
  }

  disableLastProc() {
    if (!empty(this.hoveredObject)) {
      this.hoveredObject.isActive = false
      this.hoveredObject.showArrow = false
      this.hoveredObject = null
    }
  }

  disableHoveredCounterEdge() {
    if (!empty(this.hoveredCounterEdge)) {
      this.hoveredCounterEdge.material.color = this.hoveredCounterEdge?.userData?.color
    }
    this.hoveredCounterEdge = null
    this.disableContourEdgeHelper()
  }

  disableContourEdgeHelper() {
    store.dispatch(contourEdgeHelperOperations.switchStateForContourEdgeHelper({
      show: false,
      edge: null,
      cursorPosition: {
        x: 0,
        y: 0,
      }
    }))
  }

  showOurContourEdgeHelper(edge) {
    store.dispatch(contourEdgeHelperOperations.switchStateForContourEdgeHelper({
      show: true,
      edge: edge,
      cursorPosition: {
        x: this.clickMouse.clientX,
        y: this.clickMouse.clientY,
      }
    }))
  }

  actionHoveredObjectOn3D(object) {
    store.dispatch(hoveredObjectOn3DOperations.switchStateForHoveredObjectOn3D({
     object: object,
    }))
  }

  disableActionHoveredObjectOn3D() {
    store.dispatch(hoveredObjectOn3DOperations.switchStateForHoveredObjectOn3D({
     object: null,
    }))
  }

  getActiveProcesses() {
    return ["hole", "cutout", "circle", "rectangle", 'compactLock', "groove", "rabbet", "Sampling", "bevel",
      "mill-gEdge", "mill-uShape", "mill-smile", "mill-radiusEdge", "corner-radius", "corner-line",
      "mill-tableCorner", "mill-tableTop", "mill-partial", "mill-closed", "tableProc-tableTop"]
  }

  updateLastProcAfterChange() {
    if (!empty(this.hoveredTreatment) && this.getActiveProcesses().includes(this.hoveredTreatment.subType)) {
      this.hoveredTreatment.updateDataFromDb()
    }
  }

  editProc() {
    this.updateLastProcAfterChange()
    this.raycaster.setFromCamera(this.clickMouse, this.camera)
    const found = this.raycaster.intersectObjects(this.scene?.children[1]?.children)
    // console.log(found)
    if (!empty(found)) {
      const needMesh = found.find(el => this.getActiveProcesses().includes(el?.object?.userData?.type) && !["mill-partial", "mill-closed", ].includes(el?.object?.userData?.type))
      const proc = needMesh?.object?.userData?.proc

      if (!empty(proc)) {
        proc.isEdit = true;
        this.hoveredTreatment = proc
        store.dispatch(
          projectOperations.setTreatment({
            Treatment: proc,
          })
        );
        store.dispatch(
          panelOperations.switchStateForPanel({
            show: 'procPanel',
            isEditPanel: true
          })
        )
      }
    }
  }

  dispose() {
    this.stop();

    window.removeEventListener('resize', this.updateRenderSize);

    this.renderer.dispose();

    this.controls.dispose();


    if(!empty(this.renderer?.domElement?.parentNode)) {
      this.renderer?.domElement.parentNode.removeChild(this.renderer.domElement);
    }

  }

  clear() {
    while(this.scene.children.length > 0){
      this.scene.remove(this.scene.children[0]);
    }
    this.renderer.dispose()
    this.stop()
  }

  updateRenderSize() {
    if (!this.renderer?.domElement?.parentElement) {
      console.error("cant calc size without parent dom element");
      return;
    }
    if (this.camera && this.renderer?.domElement?.parentElement) {
      const parentElement = this.renderer?.domElement?.parentElement;
      let width = parentElement.clientWidth;
      let height = parentElement.clientHeight;
      const aspect = width / height;

      this.camera.aspect = aspect;

      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);
    }
  }

  getCenter(zPoint) {
    this.controls.reset()
    this.camera.position.set(0.1, 9.06, zPoint);
    this.controls.update();
  }

  getCenterWithAnimation(point, type) {
    switch (type) {
      case 'front':
        gsap.to(this.controls.target, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        })
        gsap.to(this.camera.position, {
          duration: 1,
          x: 0.1,
          y: 9.06,
          z: point,
          onUpdate: () => this.controls.update(),
        })
        break
      case 'top':
        gsap.to(this.controls.target, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        })
        gsap.to(this.camera.position, {
          duration: 1,
          x: 0.000001,
          y: point,
          z: 0.0015,
          onUpdate: () => this.controls.update(),
        })
        break
      case 'bottom':
        gsap.to(this.controls.target, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        })
        gsap.to(this.camera.position, {
          duration: 1,
          x: 0.000001,
          y: -point,
          z: 0.0015,
          onUpdate: () => this.controls.update(),
        })
        break
      case 'right':
        gsap.to(this.controls.target, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        })
        gsap.to(this.camera.position, {
          duration: 1,
          x: point,
          y: 9.06,
          z: 0.0015,
          onUpdate: () => this.controls.update(),
        })
        break
      case 'left':
        gsap.to(this.controls.target, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        })
        gsap.to(this.camera.position, {
          duration: 1,
          x: -point,
          y: 9.06,
          z: 0.0015,
          onUpdate: () => this.controls.update(),
        })
        break
      case 'back':
        gsap.to(this.controls.target, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        })
        gsap.to(this.camera.position, {
          duration: 1,
          x: 0.1,
          y: 9.06,
          z: -point,
          onUpdate: () => this.controls.update(),
        })
        break

    }
  }

  start() {
    if (!this.frameId) {
      // this.animate();
      // this.renderScene();
      // this.frameId = requestAnimationFrame(this.animate);
      this.controls.update();
    }
  }

  stop() {
    cancelAnimationFrame(this.frameId);
  }

  renderScene = () => {
    if (this.renderer) this.renderer.render(this.scene, this.camera);
  };

  /**
   * Toggles the visibility of controls and meshes.
   *
   * @param {boolean} visibility - The visibility value to set for controls and meshes.
   * @returns {void}
   */
  toggleControlsAndMeshVisibility = (visibility) => {
    const nameOfControlsAndMeshes = ['stolTextDown', 'strangeArrowDown', 'stolTextUp', 'strangeArrowUp', 'lArrow', 'hArrow', 'axesHelper']
    if (this.scene.children.length > 1) {
      const arr3dControllersAndMeshes = this.scene.children[1].children.filter(el => nameOfControlsAndMeshes.includes(el.name))
      arr3dControllersAndMeshes.forEach(el => el.visible = visibility)
      this.renderScene();
    }
  }

  /**
   * Disable visible controls and mesh.
   *
   * @function
   */
  disableVisibleControlsAndMesh = (show) => {
    this.toggleControlsAndMeshVisibility(show);
  }

  /**
   * Features for controls and mesh when the camera rotates.
   *
   * @function featuresWhenCameraRotated
   * @memberof moduleName
   * @returns {void}
   */
  featuresWhenCameraRotated = () => {
    this.disableVisibleControlsAndMesh()
  }

  /**
   * Animates the models.
   *
   * @function animate
   * @memberof global
   * @instance
   * @memberOf global
   * @returns {void}
   */
  animate = () => {
    //Animate Models Here
    this.frameId = window.requestAnimationFrame(this.animate);

    //ReDraw Scene with Camera and Scene Object
    // this.controls.update();
    if(this.shouldRender) {
      this.renderScene();
    }

  };


}
