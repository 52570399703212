import {useState, useId, useEffect} from "react";
import {useDispatch} from "react-redux";
import {modalsOperations} from "redux/modals";
import { projectSelectors} from "redux/project";
import {useSelector} from "react-redux";
import Languages from "../../../../translation/Languages";
import {edgeTitle, empty, materialTitle} from "../../../../helpers/helper";
import CloseBtn from "../../Buttons/CloseBtn";
import DefaultBtn from "../../Buttons/DefaultBtn";
import RadioButtonCustom from "../../Inputs/Input/RadioButtonCustom";
import '../../../../components/CreateProject/Materials/style.scss'
import "./style.scss";
import cloneDeep from 'lodash.clonedeep'
import {tempDetailOperations} from "redux/tempDetail";

import imgNotification from "../../../../media/icon-notification.png";
import DefaultCheckbox from "../../Inputs/Input/DefaultCheckbox/DefaltCheckbox";
import {Tooltip} from "../../Tooltip";
import {useMultiplicities} from "../../../../hooks/useMultiplicity";
import {ModalFooter, ModalHeader} from "../index";

const MultiplicityModel = () => {
    const uid = useId()
    const dispatch = useDispatch();
    const construction = useSelector(projectSelectors.getConstruction);
    const {materials, edges} = construction
    const detailR = useSelector(projectSelectors.getDetail)
    const {tempDetail} = useSelector(state => state.tempDetail.property)
    let {multiplicity} = cloneDeep(tempDetail);
		const multiplicityArray = useMultiplicities();

    const {height, width} = tempDetail
    const [type, setType] = useState( () => {
        if (!window.location.pathname.split("/").includes("processes")) {
            return multiplicity !== null || multiplicity !== 'empty' ? multiplicity?.type : null
        } else {
            const {multiplicity} = detailR
            return multiplicity !== null ? multiplicity.type : 0
        }
    })

    const [isRotateTexture, setIsRotateTexture] = useState( () => {
        const data = {
            0: false,
            1: true,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
            7: false,
            8: false,
        }
        if (!window.location.pathname.split("/").includes("processes")) {
            if (multiplicity !== null || !empty(multiplicity)) {
                data[multiplicity?.type] = multiplicity?.isRotateTexture
            }
            return data
        } else {
            const {multiplicity} = detailR
            if (!empty(multiplicity)) {
                data[multiplicity.type] = multiplicity.isRotateTexture
            }
            return data
        }
    })

    const [edge, setEdge] = useState(() => {
        if (!window.location.pathname.split("/").includes("processes")) {
            return multiplicity !== null ? multiplicity.edge !== null ? edges[edges.findIndex(ed => ed.index === multiplicity.edge)] : null : null
        } else {
            const {multiplicity} = detailR
            return multiplicity !== null ? multiplicity.edge !== null ? edges[edges.findIndex(ed => ed.index === multiplicity.edge)] : null : null
        }
    });

    const [material, setMaterial] = useState(() => {
        if (!window.location.pathname.split("/").includes("processes")) {
            return multiplicity !== null ?  materials[materials.findIndex(m => m.index === multiplicity.material)]: materials[0]
        } else {
            const {multiplicity} = detailR
            return multiplicity !== null ? materials[materials.findIndex(m => m.index === multiplicity.material)] : materials[0]
        }
    });
    const [display, setDisplay] = useState([])
    const [newMaterials, setNewMaterials] = useState([])

    useEffect(() => {
        if (!window.location.pathname.split("/").includes("processes")) {
            setDisplay(() => {
                if (height < 121 && width <= 100) {
                    return [0, 1, 2]
                } else if (height < 200 && width < 200) {
                    return [0, 1, 2, 6, 8]
                } else if (height < 300 && width < 300) {
                    return [0, 1, 2, 3, 6, 7, 8]
                } else if (height < 300 && width < 500) {
                    return [0, 1, 2, 3, 5, 6, 7, 8]
                } else if (height < 500 && width < 300) {
                    return [0, 1, 2, 3, 4, 6, 7, 8]
                } else {
                    return [0, 1, 2, 3, 4, 5, 6, 7, 8]
                }
            })
        } else {
            const {_h, _l} = detailR
            setDisplay(() => {
                if(_h < 121 && _l <= 100){
                    return [0, 1, 2]
                } else if (_h < 200 && _l < 200) {
                    return [0, 1, 2, 6, 8]
                } else if (_h < 300 && _l < 300) {
                    return [0, 1, 2, 3, 6, 7, 8]
                } else if (_h < 300 && _l < 500) {
                    return [0, 1, 2, 3, 5, 6, 7, 8]
                } else if (_h < 500 && _l < 300) {
                    return [0, 1, 2, 3, 4, 6, 7, 8]
                } else {
                    return [0, 1, 2, 3, 4, 5, 6, 7, 8]
                }
            })
        }
        setNewMaterials(materials.filter(el => el.type !== 'Постформинг'))
    }, []);

    const close = () => {
        dispatch(
            modalsOperations.switchStateForModal({
                show: false,
                type: null,
            })
        );
        if (window.location.pathname === '/details') {
            dispatch(
                modalsOperations.switchStateForModal({
                    show: true,
                    type: "ADD_DETAIL",
                })
            );
        } else {
            dispatch(
                modalsOperations.showStateDetailInfo({
                    show: true,
                })
            );
        }
    };

    const checkId = (id) => {
        setType(multiplicityArray[id].number)
    }

    const checkIsRotateTexture = (item) => {
        setIsRotateTexture({
            ...isRotateTexture,
            [item]: !isRotateTexture[item]
        })
    }

    const checkEdge = (item) => {
        setEdge(item === undefined ? null : item)
    }

    const checkMaterial = (index) => {
        setMaterial(materials[Number(index)])
    }

    const submitChanges = () => {
        let {edges} = cloneDeep(tempDetail)

        const location = !window.location.pathname.split("/").includes("processes")
        if (type === 0) {
                dispatch(tempDetailOperations.addProperty('multiplicity', null))
        } else if (type === 1) {
            if (location) {
                dispatch(tempDetailOperations.addProperty('multiplicity', {edge: null, type, material: tempDetail.material.index ?? 0, isRotateTexture: isRotateTexture[type]}))
            } else {
                dispatch(tempDetailOperations.addProperty('multiplicity', {edge: null, type, material: detailR.material ?? 0, isRotateTexture: isRotateTexture[type]}))
            }
        } else if (type === 7) {
            if (location) {
                dispatch(tempDetailOperations.addProperty('edges', {...edges, bottom: null}))
                dispatch(tempDetailOperations.addProperty('multiplicity', {edge: edge?.index ?? null, type, material: material.index ?? 0, isRotateTexture: isRotateTexture[type]}))
            } else {
                dispatch(tempDetailOperations.addProperty('edges', {...edges, bottom: null}))
                dispatch(tempDetailOperations.addProperty('multiplicity', {edge: edge?.index ?? null, type, material: material.index ?? 0, isRotateTexture: isRotateTexture[type]}))
            }
        } else if (type === 6) {
            if (location) {
                dispatch(tempDetailOperations.addProperty('edges', {...edges, left: null, right: null}))
                dispatch(tempDetailOperations.addProperty('multiplicity', {edge: edge?.index ?? null, type, material: material.index ?? 0, isRotateTexture: isRotateTexture[type]}))
            } else {
                dispatch(tempDetailOperations.addProperty('edges', {...edges, left: null, right: null}))
                dispatch(tempDetailOperations.addProperty('multiplicity', {edge: edge?.index ?? null, type, material: material.index ?? 0, isRotateTexture: isRotateTexture[type]}))
            }
        } else if (type === 8) {
            if (location) {
                dispatch(tempDetailOperations.addProperty('edges', {...edges, left: null, right: null}))
                dispatch(tempDetailOperations.addProperty('multiplicity', {edge: edge?.index ?? null, type, material: material.index ?? 0, isRotateTexture: isRotateTexture[type]}))
            } else {
                dispatch(tempDetailOperations.addProperty('edges', {...edges, left: null, right: null}))
                dispatch(tempDetailOperations.addProperty('multiplicity', {edge: edge?.index ?? null, type, material: material.index ?? 0, isRotateTexture: isRotateTexture[type]}))
            }
        } else {
            if (location) {
                dispatch(tempDetailOperations.addProperty('multiplicity',
                    {edge: edge?.index ?? null, type, material: material.index ?? 0, isRotateTexture: isRotateTexture[type]}))
            } else {
                dispatch(tempDetailOperations.addProperty('multiplicity',
                    {edge: edge?.index ?? null, type, material: material.index ?? 0, isRotateTexture: isRotateTexture[type]}))
            }
        }

        dispatch(
            modalsOperations.switchStateForModal({
                show: false,
                type: null,
            })
        );
        if (window.location.pathname.includes('/details')) {
            dispatch(
                modalsOperations.switchStateForModal({
                    show: true,
                    type: "ADD_DETAIL",
                })
            );
        } else {
            dispatch(
                modalsOperations.showStateDetailInfo({
                    show: true,
                })
            );
        }
    }

    return (
        <>
					<div className="modal-multiplicity-project modal__wrapper">
						<ModalHeader className="modal-multiplicity-project__header">
							<h3 className="modal-multiplicity-project__title">{Languages.getTranslation('stitching-type', true)}</h3>
							<CloseBtn handler={close}/>
						</ModalHeader>
						<div className="modal-multiplicity-project__body">
							<div className="modal-multiplicity-project__wrapper">
								{multiplicityArray.map((item, index) => {
										if (display.includes(index)) {
											return <div
												className={item.number === type
													? "modal-multiplicity-project__wrapper_sub bg_active"
													: "modal-multiplicity-project__wrapper_sub"}
												key={`${uid}-${index}`}>
												<div className='flex' style={{width: '35%'}}>
													<RadioButtonCustom id={index} checkId={checkId} checked={item.number === type}/>
													<img className="img22" data-tooltip-id={`my-tooltip-multiplicity-${index}`}
															 src={imgNotification}
															 alt="imgNotification"/>
													<div className='modal-multiplicity-project__wrapper__item'>
														{item.name}
													</div>
												</div>
												<div className='flex'>
													{(![0].includes(item.number) ? "" : <div style={{width: "400px"}}></div>)}
													{item.material ?
														<select
															defaultValue={Languages.getTranslation('choose-materials', true)}
                                                            disabled={!(item.number === type) || item.number === 1}															onChange={(event) => checkMaterial(event.target.options[event.target.selectedIndex].id)}
															className="modal-multiplicity-project__wrapper_sub__default-select">
															{
																<option value={null}>
																	{item.number === type ?
																		`${materials.findIndex(m => m?.index === material?.index) + 1}: ${materialTitle(material)}` :
																		Languages.getTranslation('choose-materials', true)}
																</option>
															}
															{!empty(newMaterials) && newMaterials.map((el, key) => {
																return <option
																	key={key}
																	selected={key === newMaterials.findIndex(m => m?.index === newMaterials?.index) && index === type}
																	value={el?.index}
																	id={el?.index}>
																	{`${key + 1}: ${materialTitle(el)}`}
																</option>
															})}
														</select> : <div></div>
													}
													{item.hasOwnProperty("isRotateTexture-text") ? <div
														className="modal-multiplicity-project__isRotateTexture-text">{item["isRotateTexture-text"]}</div> : ""}
													{
														item.hasOwnProperty("isRotateTexture") && item.number ?
															<div className="modal-multiplicity-project__checkbox-wrapper">
																<DefaultCheckbox checked={isRotateTexture[item.number]}
																								 changeProp={() => checkIsRotateTexture(item.number)}/>
															</div> : ""
													}
													{item.edges ?
														<select
															disabled={!(item.number === type)}
															onChange={(event) => checkEdge(construction.edges[event.target.value])}
															className="modal-multiplicity-project__wrapper_sub__default-select">
															<option>
																{edge !== null && edge !== undefined && item.number === type ? `${construction.edges.findIndex(ed => ed.index === edge?.index) + 1}: ${edgeTitle(edge)}` :
																	Languages.getTranslation('choose-edges-label', true)}
															</option>
															{!empty(construction.edges) && construction.edges.map((item, key) => (
																<option key={key} value={item?.index}>
																	{`${key + 1}: ${edgeTitle(item)}`}
																</option>
															))}
															<option value="none">{Languages.getTranslation('remove-edges', true)}</option>
														</select> : ([].includes(item.number) ? "" : <div style={{width: "379px"}}></div>)
													}
												</div>
												{index > 0 && <img src={item.img} alt={item.name}/>}
												<Tooltip
													id={`my-tooltip-multiplicity-${index}`}
													place="bottom-start"
													openOnClick={true}
												>
													<p className="tooltip_first">
														{Languages.getTranslation('description-multiplicity', true)}
													</p>
													<p>{item.description}</p>
												</Tooltip>
											</div>
										}
									}
								)}
							</div>
						</div>
						<ModalFooter className="detailInfo__actions">
							<DefaultBtn
								title={Languages.getTranslation("cancel2", true)}
								handler={close}
							/>
							<DefaultBtn
								title={Languages.getTranslation("save", true)}
								handler={submitChanges}
							/>
						</ModalFooter>
					</div>
				</>
		);
};

export default MultiplicityModel;

