// const BD_API_BASE_URL = "https://bd.kronas.com.ua";
// const USER_API_BASE_URL = "https://auth.kronas.com.ua";
// const PROJECT_API = "https://auth.kronas.com.ua/api/v1";
// const VALIDATE_API_BASE_URL = "https://sv.kronas.com.ua";
const BD_API_BASE_URL = process.env.BD_API_BASE_URL;
const USER_API_BASE_URL = process.env.USER_API_BASE_URL;
const PROJECT_API = process.env.PROJECT_API;
const VALIDATE_API_BASE_URL = process.env.VALIDATE_API_BASE_URL;
const CVRT_KRONAS = process.env.CVRT_KRONAS
const TEST_STORE = process.env.TEST_STORE;
const YOUTUBE_V3_API_KEY = process.env.YOUTUBE_V3_API_KEY;
const YOUTUBE_V3_URL = "https://youtube.googleapis.com/youtube/v3"
const PB_KRONAS = process.env.PB_KRONAS
const KRONAS_COM_UA = process.env.KRONAS_COM_UA
// const VALIDATE_API_BASE_URL = "http://localhost:8000";

/* 

Запросы: 

* get_user                - Получение данных о пользователе и его токен ( POST )
* get_edges               - Получение кромки ( GET )
* get_materials           - получение материалов ( GET )

* save_project            - сохранение проекта ( POST )
* get_project_list        - получение списка проектов ( GET )
* delete_project          - удаление проекта ( DELETE ) params: id

*/

const ROUTE = {
  get_user: () => `${USER_API_BASE_URL}/api/v1/login`,
  get_user_info: () => `${USER_API_BASE_URL}/api/v1/my/info`,
  logout: () => `${USER_API_BASE_URL}/api/v1/logout`,

  get_edges: () => `${BD_API_BASE_URL}/band/get`,
  get_materials: () => `${BD_API_BASE_URL}/material/get`,
  get_current_material: () => `${BD_API_BASE_URL}/material/getbycode`,
  get_search_materials: () => `${PROJECT_API}/product-base/material-or-ages/search`,
  get_search_materials_vendor_code: () => `${PROJECT_API}/product-base/searchByVendorCode`,

  get_current_edges: () => `${BD_API_BASE_URL}/band/search`,
	
	get_orders: (params) => {
		const searchParams = new URLSearchParams(params);
		
		return `${USER_API_BASE_URL}/api/v1/baffle/get-orders?${searchParams}`
	},
	get_orders_by_phone: (params) => {
		const searchParams = new URLSearchParams(params);
		
		return `${USER_API_BASE_URL}/api/v1/baffle/get-orders-for-manager?${searchParams}`
	},

  calc_project: () => `${CVRT_KRONAS}/api/nomenclature3d`,
  info_project: () => `${CVRT_KRONAS}/api/report/pdf`,
  get_info_project: () => `${CVRT_KRONAS}/api/test/pdf`,
  get_info_detailed_report: () => `${CVRT_KRONAS}/api/report/pdf_draw`,
  save_project: () => `${PROJECT_API}/project/file`,
  update_project: () => `${PROJECT_API}/project/file`,
  get_project_list: () => `${PROJECT_API}/project/file`, 
  get_file_folder: () => `${PROJECT_API}/project/file/folder`,
  get_user_folders: () => `${PROJECT_API}/project/folder`, 
  delete_project: () => `${PROJECT_API}/project/file`, // id 
  // validate: () => `${VALIDATE_API_BASE_URL}/api/services/dsp`,
  validate: () => `${VALIDATE_API_BASE_URL}/api/services/vs`, // new endpoint for validate
  convert: () => `${CVRT_KRONAS}/convert`,
  searchUserByPhoneAndName: ({ query, page }) => `${PROJECT_API}/clients/search-by-name-or-phone/${query}?page=${page}`,
  searchUserById: () => `${PROJECT_API}/user`,
  getFilialAgency: () => `${PROJECT_API}/filial-agency`,
  setFilialAgency: () => `${PROJECT_API}/my/info/change`,
  sendSupportMessage: () => `${PROJECT_API}/message/send-to-support`,

  get_warehouse_materials: () => `${PROJECT_API}/baffle/get-client-left-overs3`,
  get_kronas_materials: () => `${USER_API_BASE_URL}/api/v1/material/search`,
  check_kronas_element: () => `${USER_API_BASE_URL}/api/v1/material/residues`,
  check_kronas_one_element: () => `${USER_API_BASE_URL}/api/v1/material`,
  reserving_materials: () => `${USER_API_BASE_URL}/api/v1/material/reserving`,
  delete_reserved_materials: () => `${USER_API_BASE_URL}/api/v1/material/change`,
  edit_user_project: () => `${PROJECT_API}/project/file/store`,
  import_project: () => `${CVRT_KRONAS}/convert`,
  get_furniture_recommend: () => `${CVRT_KRONAS}/api/report/information_report`,

  fetchYoutubeVideos: (playlistId) => `${YOUTUBE_V3_URL}/playlistItems?part=snippet&maxResults=50&playlistId=${playlistId}&key=${YOUTUBE_V3_API_KEY}`,
  search_hardware: () => `${PB_KRONAS}/api/v3/product/search`,
  search_catalog_furniture: () => `${PB_KRONAS}/api/v1/category`,
  search_catalog_id_furniture: () => `${PB_KRONAS}/api/v1/product/category`,
  search_catalog_alias_furniture: () => `${PB_KRONAS}/api/v1/category-by-alias/all`,
  get_products_by_alias: () => `${PB_KRONAS}/api/v1/product/category`,
  get_list_furniture: () => `${PB_KRONAS}/api/v1/product/vendor-codes`,
  get_current_furniture: () => `${PB_KRONAS}/api/v1/product/vendor-codes`,
  get_list_furniture_links: () => `${KRONAS_COM_UA}/api/v2/get/products/links`, // https://kronas.com.ua/api/v2/get/products/links
};

const API = {
  USER_API_BASE_URL,
  BD_API_BASE_URL,
  ROUTE,
};

export default API;
