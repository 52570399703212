import {useEffect, useRef, useState} from "react";
import axios from "axios";
import Languages from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";
import API from "../../../../api/API";
import Loader from "../../Loader";
import {empty} from "../../../../helpers/helper";
import './style.scss';
import {ModalFooter, ModalHeader} from "../index";
import Requests from "../../../../api/API";

const InputModal = ({close, getId}) => {
	
	const [projectList, setProjectList] = useState([])
	const [sortProjectList, setSortProjectList] = useState([])
	const [load, setLoad] = useState(true)
	const [selected, setSelected] = useState(null)
	
	const refId = useRef(null)
	const refName = useRef(null)
	
	const getProjectList = () => {
		axios({
			method: 'get',
			url: `${process.env.PROJECT_API}/project/file?per_page=30&constructor_type=KMaster`,
			headers: {'Authorization': `Bearer ${API.getToken()}`}
		})
			.then(function (response) {
				setProjectList(response.data.data)
				setSortProjectList(response.data.data)
				setLoad(false)
			})
			.catch(error => console.log(error))
	}
	
	useEffect(() => {
		getProjectList()
	}, [])
	
	const changeInput = (event) => {
		const {name, value} = event.target
		if (name === 'name') {
			refId.current.value = ''
			setSortProjectList(projectList.filter(item => item.name.toString().includes(value)))
		}
		if (name === 'id') {
			refName.current.value = ''
			setSortProjectList(projectList.filter(item => item.id.toString().includes(value)))
		}
	}
	
	let content;
	
	if (load) {
		content = <div className="mt-20"><Loader/></div>
	} else if (empty(sortProjectList)) {
		content = <div className="inputModal__listProject_not-found">{Languages.getTranslation('not-found', true)}</div>
	} else {
		content = sortProjectList.map(item => {
			return (
				<div
					className={`inputModal__listProject_item ${item.id === selected ? 'bgSelected' : ''}`}
					onClick={() => setSelected(item.id)}>
					<div className="inputModal__listProject_item-sub inputModal_id">{item.id}</div>
					<div className="inputModal__listProject_item-sub inputModal_name">{item.name}</div>
					<div className="inputModal__listProject_item-sub order">{item.order_number || '-'}</div>
					<div className="inputModal__listProject_item-sub date">{item.updated_at.split('T')[0]}</div>
				</div>
			)});
	}
	
	return <div className='modal'>
		<div className='inputModal modal__wrapper'>
			<ModalHeader className="inputModal__header">
				<div className="inputModal_id">
					<input
						ref={refId}
						onChange={(event) => changeInput(event)}
						className="inputModal__header_input"
						type="text"
						name='id'
						placeholder='ID'/></div>
				<div className="inputModal_name">
					<input
						ref={refName}
						onChange={(event) => changeInput(event)}
						className="inputModal__header_name"
						type="text"
						name='name'
						placeholder={Languages.getTranslation('name-project', true)}/>
				</div>
				<div className="order">{Languages.getTranslation('order-number', true)}</div>
				<div className="date">{Languages.getTranslation('Changed', true)}</div>
			</ModalHeader>
			<div className="inputModal__listProject">
				{content}
			</div>
			<ModalFooter className="inputModal__actions">
				<DefaultBtn
					title={Languages.getTranslation("cancel", true)}
					handler={close}
				/>
				<DefaultBtn
					title={Languages.getTranslation("Import", true)}
					handler={() => getId(selected)}
				/>
			</ModalFooter>
		</div>
	</div>
	
}

export default InputModal