import './style.scss';
import Languages, {LANGS} from "../../../../translation/Languages";
import React from "react";
import diametr from '../../../../media/diametr.svg'
const HardwareRecommendedTable = ({ data, onHoverItem }) => {
    return (
        <table className="hardware-table-recommended">
            <thead>
            <tr className="hardware-table-recommended-row-header">
                <th className="hardware-table-recommended-header-item no-border-left">{Languages.getTranslation("type", true)}
                </th>
                <th className="hardware-table-recommended-header-item no-border-right">{Languages.getTranslation("count", true)}</th>
            </tr>
            </thead>
            <tbody>
            {data.sort((a, b) => a.index - b.index)
                .map((item, i) => {
                    return (
                        <>
                            <tr key={i} className="hardware-table-recommended-row-item">
                                <td className="hardware-table-recommended-item"
                                    onMouseEnter={() => onHoverItem(item.items)}
                                    onMouseLeave={() => onHoverItem(null)}
                                >

                                   <span className="hardware-table-recommended-item-first">{Languages.getTranslation("hole", true)}</span>
                                    <img src={diametr} alt="diametr" className="hardware-table-recommended-item-img"/>
                                    <span>{item.diam}</span>
                                </td>
                                <td className="hardware-table-recommended-item hardware-table-recommended-item__right">{item.count}</td>
                            </tr>

                        </>
                    )
                })}

            </tbody>
        </table>
    );
};
export default HardwareRecommendedTable