import Helpers from "../3D/Helpers";
import {empty} from "../../helpers/helper";

import Languages from "../../translation/Languages";
import SizeArrow from "../3D/components/SizeArrow";

/*
* Received data
* @parent = link to the Class Detail
* @elements = array of
* */
export default class GEdge {
	_elements = []; //Array
	_detailH;
	_detailL;
  	parent;
	id;
	_touchedSides;
	constructor({
		            parent,
		            subType = 'mill',
		            detailL,
		            detailH,
								elements
	            }) {
		this.parent = parent;
		this._elements = elements;
		this._subType = subType;
		this._detailH = detailH
		this._detailL = detailL
		this.addParams()
		this.initRequiredFields();
		this.parent.edgeSide = null
	}

	initRequiredFields() {
		this.parent.requiredFields = {
			r: {
				min: this.parent.getMinRByMaterial(3),
				max: Math.min(this.parent.height, this.parent.width)
			},
			width: {
				min: 6,
				max: this.parent.detail.l - 1
			},
			height: {
				min: 6,
				max: this.parent.detail.h - 1
			}
		}
	}

	addParams() {
		let f_value = ''
		this.parent.setFormField({name: 'edgeSide', visible: false})
		this.parent.setFormField({name: 'offset', visible: false})
		this.parent.setFormField({name: 'doubleSmiles', visible: false,});
		this.parent.setFormField({name: 'typeR', value: this.parent.typeR, visible: false })
		this.parent.setFormField({
			name: 'angle', value: this.parent.angle, label: Languages.getTranslation('corner', true), type: 'select', variables: this.parent.getAnglesValue(),
			visible: true
		}) // Languages.getTranslation('thickness', true)
		this.parent.setFormField({name: 'width', value: this.parent.width, type: 'number', label: Languages.getTranslation('height', true), visible: this.parent.type === "gEdge"})
		this.parent.setFormField({name: 'height', value: this.parent.height, type: 'number', label: Languages.getTranslation('width', true), visible: this.parent.type === "gEdge"})

		f_value = this.parent._dataForConstructor.hasOwnProperty('f_r') ? this.parent._dataForConstructor['f_r'] : '';
		this.parent.setFormField({name: 'r', value: this.parent.r, type: 'number', label: `${Languages.getTranslation('radius', true)} (pr)`, visible: this.parent.type === "gEdge", additionalParam : [
				{name: 'formula', label: Languages.getTranslation('formula', true), value: f_value} ]})
	}

	get paramsSorting() {
		const sortingArr = ['type', 'angle', 'width', 'height', 'r', "comment"]

		return	this.parent.formFields.sort((a, b) => sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
	}

	generateElements() {
		const tmp_elements =  Helpers.createGedgeElements(this.parent, this.parent.detail.h, this.parent.detail.l, this.getActiveElement())
		const elements = [];
		tmp_elements.forEach(el => { //fix for "dot" line element
			if(!(el.x1 === el.x2 && el.y1 === el.y2)) {
				elements.push(el)
			}
		})
		return elements;
	}

	getActiveElement() {
		return this.parent.isActive ? Helpers.colorForActive : null
	}

	get corner() {
		return this._corner;
	}

	set corner(corner) {
		this._corner = corner;
	}

	get detailH() {
		return this._detailH;
	}

	get detailL() {
		return this._detailL;
	}

	get elements() {
		return this.generateElements()
	}

	// set elements(element) {
	// 	this._elements = element
	// }

	get cutOut() {
		this.calcHeight()
		this.calcWidth()
		return {
			x: this.parent.x,
			y: this.parent.y,
			r: this.parent.r,
			type: this.parent.type,
			subType: this._subType,
			edge: this.parent.edge,
			place: null,
			angle: this.parent.angle,
			width: this.calcHeight(),
			height: this.calcWidth(),
			sidesCount: this.touchedSides.length,
			touchedSides: this.touchedSides,
			color: this.parent.isActive ? Helpers.colorForActive : null,
			elements: this.parent.create3dDataForMills(this.elements)
		};
	}

	get dataForValidate() {
		return {
			angle: this.parent.angle,
			x: this.xCalc,
			y: this.yCalc,
			width: this.calcWidth(),
			height: this.calcHeight(),
			depth: this.parent.detail.realW,
			ext: false,
			fullDepth: true,
			subside: this.parent.angle.split("_")[0],
			r: this.parent.r,
			side: 'front',
			edge: this.parent.getEdgeArrayIndex(this.parent.edge),
			subtype: "gEdge",
			type: 'gEdge',
			z: 0,
			id: this.parent.id
		};
	}

	get realData() {
		return {
			depth: this.parent.detail.realW,
			x: this.parent.x,
			y: this.parent.y,
			angle: this.parent.angle,
			width: this.calcWidth(),
			height: this.calcHeight(),
			fullDepth: true,
			touchedSides: this.touchedSides,
			subside: this.parent.angle.split("_")[0],
			// elements: this.generateElements()
		}
	}

	addSizesArrows() {
		if (!this.parent.isActive) return false;
		const pos = this.parent.angle
		return SizeArrow.getElementSizeArrow(
			this.parent.width,
			this.parent.height,
			null,
			pos,
			this.parent.detail,
			true
		)
	}



	get xCalc() {
		switch (this.parent.angle) {
			case "left_bottom":
			case "left_top":
				return 0;
			case "right_bottom":
			case "right_top":
				return this.detailL - this.calcWidth();

		}
	}

	get yCalc() {
		switch (this.parent.angle) {
			case "right_bottom":
			case "left_bottom":
				return 0;
			case "left_top":
			case "right_top":
				return this.detailH - this.calcHeight();
		}
	}

	set side(elements) {
		let corner;
		if (elements.length === 1) {
			if ((elements[0].x1 === 0 || elements[0].x2 === 0) && elements[0].x2 !== elements[0].x1) {
				if (elements[0].type === 'arc') {
					if (elements[0].r >= this.detail.ch) {
						corner = (elements[0].y1 === 0 || elements[0].dir && elements[0].y1 === this.detail.ch) ? 'right-top' : 'right-bottom'
					} else {
						corner = (elements[0].y1 === 0) ? 'left-bottom' : 'left-top'
					}
				} else {
					corner = (elements[0].y1 === this.detail.ch) ? 'right-top' : 'left-top'
				}
			} else {
				if ((elements[0].x1 === this.detail.cl || elements[0].x2 === this.detail.cl) && elements[0].x2 !== elements[0].x1) {
					if (elements[0].type === 'arc') {
						if (elements[0].r === this.detail.ch) {
							corner = (elements[0].y1 === 0) ? 'left-top' : 'left-bottom'
						} else {
							corner = (elements[0].y1 === 0 || elements[0].y2 === 0) ? 'right-bottom' : 'right-top'
						}
					} else {
						corner = 'right-bottom'
					}
				}
			}
		} else {
			if (elements[0].x1 === 0 && elements[elements.length - 1].x2 !== 0 && elements[0].y1 !== elements[elements.length - 1].y2) {
				corner = elements[elements.length - 1].y2 === 0 ? 'left-bottom' : 'left-top';
			} else if (elements[0].x1 === this.detail.cl && elements[elements.length - 1].x2 !== this.detail.cl && elements[0].y1 !== elements[elements.length - 1].y2) {
				corner = elements[elements.length - 1].y2 === 0 ? 'right-bottom' : 'right-top';
			} else if (elements[0].y1 === this.detail.ch && elements[elements.length - 1].y2 !== this.detail.ch) {
				corner = elements[elements.length - 1].x2 === 0 ? 'left-top' : 'right-top';
			} else if (elements[0].y1 === 0 && elements[elements.length - 1].y2 !== 0) {
				corner = elements[elements.length - 1].x2 === 0 ? 'left-bottom' : 'right-bottom';
			}
		}
		if (!empty(corner)) {
			this.corner = true;
			this.angle = corner;
		} else {
			if (elements[0].x1 === 0 && elements[elements.length - 1].x2 === 0) {
				this._side = 'left';
			} else if (elements[0].x1 === this.detail.cl && elements[elements.length - 1].x2 === this.detail.cl) {
				this._side = 'right';
			} else if (elements[0].y1 === 0 && elements[elements.length - 1].y2 === 0) {
				this._side = 'bottom';
			} else if (elements[0].y1 === this.detail.ch && elements[elements.length - 1].y2 === this.detail.ch) {
				this._side = 'top';
			}
		}
		this.parent.x = this.xCalc;
		this.parent.y = this.yCalc;
 	}

	calcHeight() {
		const maxY = Math.max(...this.elements.map(el => el.y2), ...this.elements.map(el => el.y1));
		const minY = Math.min(...this.elements.map(el => el.y2), ...this.elements.map(el => el.y1));
		return maxY - minY;
	}

	calcWidth() {
		const maxX = Math.max(...this.elements.map(el => el.x2), ...this.elements.map(el => el.x1));
		const minX = Math.min(...this.elements.map(el => el.x2), ...this.elements.map(el => el.x1));
		return maxX - minX;
	}

	validate() {
		if(this.parent.r < 3){
			this.parent.detail.error.setError(`${Languages.getTranslation('radius-cannot-be', true)} ${this.parent.r}`, 'error', true);
			return Promise.reject('radius-cannot-be-0')
		}
		return Promise.resolve()
	}

	rotateDetail(direction, l, h) {
		let angle, x, y;
		const [height, width] = [this.parent.height, this.parent.width];
		switch (this.parent.angle) {
			case 'left_bottom':
				angle = direction ? 'left_top' : 'right_bottom';
				y = direction ? l - width : 0;
				x = direction ? 0 : h - height;
				break
			case 'left_top':
				angle = direction ? 'right_top': 'left_bottom';
				y = direction ? l - width : 0;
				x = direction ? h - height : 0;
				break
			case 'right_top':
				angle = direction ? 'right_bottom' : 'left_top';
				y = direction ? 0 : l - width;
				x = direction ? h - height : 0;
				break
			case 'right_bottom':
				angle = direction ? 'left_bottom' : 'right_top';
				y = direction ? 0 : l - width;
				x = direction ? 0 : h - height;
		}
		this.initRequiredFields();
		[this.parent.width, this.parent.height] = [this.parent.height, this.parent.width];
		this.parent.angle = angle;
		[this.parent.x, this.parent.y] = [x, y];
		return Promise.resolve()
	}

	get touchedSides() {
		return this.parent.angle.split('_')
	}

}