import {useEffect, useRef, useState} from "react";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";
import Languages from "translation/Languages";
import {useDispatch, useSelector} from "react-redux";
import {modalsOperations} from "redux/modals";
import "./style.scss";
import MaterialItem from "./MaterialItem";
import EdgeItem from "./EdgeItem";
import {projectSelectors} from "redux/project";
import {empty} from "../../../../helpers/helper";
import {toast} from "react-toastify";
import {hideAllOperations} from "redux/hideAll";
import {useNavigate} from "react-router-dom";
import { errorMessageOperations } from '../../../../redux/errors'
import Errors from "../../../../models/Errors";
import {ModalFooter} from "../index";

const _errors = new Errors([])

const KronasMaterials = () => {
	const construction = useSelector(projectSelectors.getConstruction);
	const [materialsIsOpen, materialsSetIsOpen] = useState(false);
	const [edgesIsOpen, edgesSetIsOpen] = useState(false);
	const [materials, setMaterials] = useState([]);
	const [edges, setEdges] = useState([])
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const materialTypes = [Languages.getTranslation("ЛДСП", true), Languages.getTranslation("МДФ", true), Languages.getTranslation("Compact-плита", true), Languages.getTranslation('Eurolight', true), Languages.getTranslation('HDF', true), Languages.getTranslation('material-type1', true), Languages.getTranslation('material-type2', true), Languages.getTranslation('material-type3', true), Languages.getTranslation("OSB", true), 'Фанера']
	const edgesTypes = [Languages.getTranslation("ABC"), Languages.getTranslation('plastic', true)]
	const currentMaterials = useSelector(projectSelectors.getMaterials);
	const currentEdges = useSelector(projectSelectors.getEdges)
	const materialsContainer = useRef()
	const edgesContainer = useRef()

	const createArticle = (arr, currentArr, articleType) => {
		const testValue = arr.length ? arr.sort((a, b) => a.article - b.article) : arr
		const arrOfOwner = currentArr.filter(el => el.article >= articleType).sort((a, b) => a.article - b.article)
		if (arr.some(el => el.article >= articleType)) {
			const data = arr.sort((a, b) => a.article - b.article)
			return `${+data[data.length - 1].article + 1}`
		}
		if (currentArr && currentArr.length && arrOfOwner.length) {
			return `${+arrOfOwner[arrOfOwner.length - 1].article + 1}`
		} else {
			if (arr.length) {
				return `${+testValue[testValue.length - 1].article + 1}`
			} else {
				return `${articleType + arr.length + 1}`
			}
		}
	}

	const createName = (currentArr, text, arr) => {
		const arrOfCurrentNames = currentArr.map((el) => `${el.name}`).filter(el => el !== undefined).filter(el => el.includes(text)).map(el => el.split(' ')).map(el => +el[el.length - 1]).sort((a, b) => a - b)
		const arrOfNames = arr.map((el) => `${el.name}`).filter(el => el !== undefined).filter(el => el.includes(text)).map(el => el.split(' ')).map(el => +el[el.length - 1]).sort((a, b) => a - b)
		const textName = text === `${Languages.getTranslation('material', true)} ${Languages.getTranslation('owner', false)}` ? `${Languages.getTranslation('material', true)} ${Languages.getTranslation('owner', false)}` : `${Languages.getTranslation('edge', true)} ${Languages.getTranslation('owner', false)}`
		if (arrOfNames.length) {
			return `${textName} ${arrOfNames[arrOfNames.length - 1] + 1}`
		}
		if (arrOfCurrentNames.length) {
			return `${textName} ${arrOfCurrentNames[arrOfCurrentNames.length - 1] + 1}`
		}
		return `${textName} ${arr.length + 1}`
	}

	const addMaterial = () => {
		const newMaterial = {
			article: createArticle(materials, currentMaterials, 100_018_000),
			name: createName(currentMaterials, `${Languages.getTranslation('material', true)} ${Languages.getTranslation('owner', false)}`, materials),
			thickness: '',
			height: '',
			width: '',
			type: ''
		}

		materialsSetIsOpen(true)
		setMaterials([...materials, newMaterial])
	}
	const addEdge = () => {
		const newEdge = {
			article: createArticle(edges, currentEdges, 100_115_000),
			name: createName(currentEdges, `${Languages.getTranslation('edge', true)} ${Languages.getTranslation('owner', false)}`, edges),
			thickness: '',
			width: '',
			type: '',
		}
		edgesSetIsOpen(true)
		setEdges([...edges, newEdge])
	}

	const validateInputs = (element, type) => {
		if (!empty(element.current)) {
			element.current.childNodes
				.forEach(child => {
					if (type === 'materials') {
						child.childNodes
							.forEach((el,idx) => {
								if (idx < 4) {
									if(empty(el.lastChild.value)) {
										if (el.lastChild.name === 'name') {
											el.lastChild.className = 'kronasMaterials__input kronasMaterials__input-name kronasMaterials__error'
											return
										}
										el.lastChild.className = 'kronasMaterials__default-input kronasMaterials__error'
									} else {
										if (el.lastChild.name === 'name') {
											el.lastChild.className = 'kronasMaterials__input kronasMaterials__input-name '
											return
										}
										el.lastChild.className = 'kronasMaterials__default-input'
									}
								}
							})
					} else {
						child.childNodes[0].childNodes
							.forEach((el,idx) => {
								if (idx < 3) {
									if(empty(el.lastChild.value)) {
										if (el.lastChild.name === 'name') {
											el.lastChild.className = 'kronasMaterials__input kronasMaterials__input-name kronasMaterials__error'
											return
										}
										el.lastChild.className = 'kronasMaterials__default-input kronasMaterials__error'
									} else {
										if (el.lastChild.name === 'name') {
											el.lastChild.className = 'kronasMaterials__input kronasMaterials__input-name '
											return
										}
										el.lastChild.className = 'kronasMaterials__default-input'
									}
								}
							})
					}

			})
		}
	}

	const close = () => {
		setMaterials([])
		setEdges([])
		dispatch(
			modalsOperations.switchStateForModal({
				show: false,
				type: null,
			})
		);
	};

	const deleteMaterial = (material) => {
		setMaterials([...materials.filter(el => el.article !== material.article)])
	}
	const deleteEdge = (edge) => {
		setEdges([...edges.filter(el => el.article !== edge.article)])
	}

	const quickValidate = (data) => {
		const res = data.map(el => Object.values(el))
		const val = res.map(el => el.every(item => !!item))
		return !val.includes(false);
	}

	const saveHandler = () => {

		const options = {
			progress: undefined,
			onOpen: (props) => {
				dispatch(hideAllOperations.switchStateForHideAll({show: true}));
			},
		};
		if (empty(materials) && empty(edges)) {
			dispatch(errorMessageOperations.switchStateError(
				{ message: Languages.getTranslation("add-material-or-edge", true), type: 'warn', show: true }))
			return
		}

		if (!quickValidate(materials)) {
			toast.error(Languages.getTranslation("error-dataMaterials-kronasMaterials", true), {
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "light",
				toastId: Languages.getTranslation("error-dataMaterials-kronasMaterials", true),
			})
			validateInputs(materialsContainer, 'materials')
			return
		}
		if (!quickValidate(edges, 'edges')) {
			toast.error(Languages.getTranslation("error-dataEdges-kronasMaterials", true), {
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "light",
				toastId: Languages.getTranslation("error-dataEdges-kronasMaterials", true),
			})
			validateInputs(edgesContainer, 'edges')
			return
		}
		const newMaterials = materials.map(el => {
			el.double_rounding = null
			return el
		})
		setMaterials(newMaterials)

		construction
			.setProjectMaterials([...materials])
			.then(() => {
				const addedEdges = edges.filter(
					(el) => !construction.edges.find((el1) => el1.article === el.article)
				);
				return construction.setProjectEdges(addedEdges);
			})
			.then(() => {
				if (!empty(materials) && !empty(edges)) {
					dispatch(errorMessageOperations.switchStateError(
						{ message: Languages.getTranslation("add-success-materials-and-edges", true), type: 'success', show: true }))

				} else if (empty(materials)) {
					dispatch(errorMessageOperations.switchStateError(
						{ message: Languages.getTranslation("edge-success", true), type: 'success', show: true }))
				} else {
					dispatch(errorMessageOperations.switchStateError(
						{ message: Languages.getTranslation("material-success", true), type: 'success', show: true }))
				}

				setMaterials([]);
				setEdges([]);
				navigate("/details");
				close();
			});
	};


	const materialsIconClass = materialsIsOpen
		? "btn__icon-angle-up"
		: "btn__icon-angle-down";

	const materialsListClass = materialsIsOpen ? "block" : "none";

	const edgesIconClass = edgesIsOpen
		? "btn__icon-angle-up"
		: "btn__icon-angle-down";

	const edgesListClass = edgesIsOpen ? "block" : "none";

	return (
		<div className="kronasMaterials modal__wrapper">
			<div className="kronasMaterials__main">
				{/*materials */}
				<div className="materialsKronas">
					<div className="materialsKronas__create">
              <span className="materialsKronas__title">{`${Languages.getTranslation(
	              "materials",
	              true
              )} ${Languages.getTranslation("owner", false)} 
              ${Languages.getTranslation("overall-size", false)}`}</span>
						<button className="btn" style={{marginLeft: "0"}} onClick={addMaterial}>
							<i className="btn__icon-add"></i>
						</button>
						<button
							className="btn"
							style={{marginLeft: "auto"}}
							onClick={() => materialsSetIsOpen(!materialsIsOpen)}
						>
							<i className={materialsIconClass}></i>
						</button>
					</div>
					<div
						className="kronasMaterials__table"
						style={{display: materialsListClass}}
					>
						<div className="kronasMaterials__table-header">
							<div className="kronasMaterials__table-header-item materialsKronas__height-width">
								{Languages.getTranslation("height", true)}
							</div>
							<div className="kronasMaterials__table-header-item materialsKronas__height-width">
								{Languages.getTranslation("width", true)}
							</div>
							<div className="kronasMaterials__table-header-item materialsKronas__sheet-thickness">
								{Languages.getTranslation("sheet-thickness", true)}
							</div>
							<div className="kronasMaterials__table-header-item materialsKronas__name">
								{Languages.getTranslation("name", true)}
							</div>
							<div
								className="kronasMaterials__table-header-item materialsKronas__materialType"
								style={{background: 'white'}}>
								{Languages.getTranslation("choose-material-type", true)}
							</div>
						</div>
						<div className="kronasMaterials__table-body" ref={materialsContainer}>
							{!empty(materials) ? materials.map((material, idx) => (
								<MaterialItem material={material} key={material.article} materialTypes={materialTypes}
									deleteMaterial={deleteMaterial}
									idx={idx} name={material.name}/>
							)) : <div className='kronasMaterials__no-items'>{Languages.getTranslation('add-material', true)}</div>}
						</div>
					</div>
				</div>

				{/*edges */}
				<div className="edgesKronas">
				<div className="edgesKronas__create">
              <span className="edgesKronas__title">{`${Languages.getTranslation(
	              "edges",
	              true
              )} ${Languages.getTranslation("owner", false)}`}</span>
						<button className="btn" onClick={addEdge}>
							<i className="btn__icon-add"></i>
						</button>
						<button
							className="btn"
							style={{marginLeft: "auto"}}
							onClick={() => edgesSetIsOpen(!edgesIsOpen)}
						>
							<i className={edgesIconClass}></i>
						</button>
					</div>
					<div
						className="kronasMaterials__table"
						style={{display: edgesListClass}}
					>
						<div className="edgesKronas__header">
							<div className="edgesKronas__header-content">
								<div className="kronasMaterials__table-header-item edgesKronas__height-width">
									{Languages.getTranslation("width", true)}
								</div>
								<div className="kronasMaterials__table-header-item edgesKronas__height-width">
									{Languages.getTranslation("thickness", true)}
								</div>
								<div className="kronasMaterials__table-header-item edgesKronas__name">
									{Languages.getTranslation("name", true)}
								</div>
								<div className="kronasMaterials__table-header-item edgesKronas__type"
									 style={{background: 'white'}}>
									{Languages.getTranslation("choose-edge-type", true)}
								</div>
							</div>
						</div>
						<div className="kronasMaterials__table-body" ref={edgesContainer}>
							{!empty(edges) ? edges.map((edge, idx) => (
								<EdgeItem key={edge.article} edge={edge} deleteEdge={deleteEdge} idx={idx} edgesTypes={edgesTypes} name={edge.name}/>))
								: <div className='kronasMaterials__no-items'>{Languages.getTranslation('add-edge', true)}</div>}
						</div>
					</div>
				</div>
			</div>

			<ModalFooter className="kronasMaterials__actions">
				<DefaultBtn
					title={Languages.getTranslation("cancel", true)}
					handler={close}
				/>
				<DefaultBtn title={Languages.getTranslation("save", true)} handler={saveHandler}/>
			</ModalFooter>
		</div>

	);
};

export default KronasMaterials;
