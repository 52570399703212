import {useState} from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { modalsOperations } from "redux/modals";
import Project from "models/Project";
import Loader from "../../../components/UI/Loader";
import Languages from "translation/Languages";
import { projectSelectors} from "redux/project";
import { userSelectors } from "redux/user";
import {copyText, empty} from "../../../helpers/helper";
import { errorMessageOperations } from "redux/errors";
import Errors from "../../../models/Errors";
import AddDetailsErrorModal from "../../UI/Modal/AddDetailsErrorModal";
import AddNew from "../../../media/AddNew.svg";
import MapMaterial from "../../../media/MapMaterial.svg";
import Сalculation from "../../../media/Calc.svg";
import Info from "../../../media/Info.svg";
import SaveProj from "../../../media/SaveProject.svg";
import {toastError} from "../../../helpers/toasts";
import {Tooltip} from "../../UI/Tooltip";
import CopyTextComponent from "../../UI/CopyTextComponent";

const _errors = new Errors([])
const ProjectCalc = () => {
	const dispatch = useDispatch();
	const [load, setLoad] = useState(false);
	const [isSaveProject, setIsSaveProject] = useState(false);
	const [isGetInfoProject, setIsGetInfoProject] = useState(false);
	const [showAddDetailsError, setShowAddDetailsError] = useState({open: false, list: [], typeBtn: ''})
	const construction = useSelector(projectSelectors.getConstruction);
	const user = useSelector(userSelectors.User);
	const permissions = useSelector(userSelectors.permissions);
	const project = useSelector(projectSelectors.getConstruction);
	const detailsList = useSelector(projectSelectors.getDetails);

	const edgesExist = () => {
		let find = false

		project.details.forEach(item => {
			['left', 'top', 'right', 'bottom'].forEach(i => {
				if(!empty(item.edges[i])){
					find = true
				}
			})
			if(empty(item.corners)){
				item.corners.forEach(i => {
					if(!empty(i.edge)){
						find = true
					}
				})
			}
			if(empty(item.mills)){
				item.mills.forEach(i => {
					if(!empty(i.edge)){
						find = true
					}
				})
			}
		})

		return find
	}

	const handleGlue = () => {
		if(empty(project.projectData.glue) && empty(project.edges) && !edgesExist()){
			return true
		}
		if(empty(project.projectData.glue) && !empty(project.edges) && edgesExist()){
			dispatch(errorMessageOperations.switchStateError(
				{ message: Languages.getTranslation('glue-not-selected', true), type: 'warning', show: true }))
			return false
		}else return true
	}


	const calcProject = async () => {
		if (handleGlue()) {
				dispatch(
					modalsOperations.switchStateForModal({
						show: true,
						type: "CALC_PROJECT",
					})
				);
		}
	};
	const getInfo = async () => {
		const projectId = project.projectData.id;
		if(projectId === 0){
			dispatch(errorMessageOperations.switchStateError({
				message: Languages.getTranslation('warn-info-project', true),
				type: 'warn',
				show: true
			}));

		}
		else {
			setLoad(true);
			try {
				await Project.reportPdfModel(projectId);
			} catch (error) {
			} finally {
				setLoad(false);
			}
		}

	};


	const generateSvgImages = (type) => {
		setLoad(true)
		let lastDetImg = detailsList[detailsList.length - 1]?.imgPdf
        if(empty(lastDetImg)){
         if(type === 'getDetailedReport' ){
			dispatch(errorMessageOperations.switchStateError({
				message: Languages.getTranslation('detailed-report-wait', true),
				type: 'warn',
				show: true
			}));
		}else{
			dispatch(errorMessageOperations.switchStateError({
				message: Languages.getTranslation('save-project-wait', true),
				type: 'warn',
				show: true
			}));
		}
		}

		const interval = setInterval(() => {
			let last = detailsList[detailsList.length - 1]?.imgPdf

			if(!empty(last)){
				clearInterval(interval);
				setLoad(false)
                if(type === 'getDetailedReport'){
					getDetailedReport()

				} else if(type === 'updateProject'){
					updateProject()
				}
				else if(type === 'saveProjectCopy'){
					saveProjectCopy()
				}
				else if(type === 'editClientProject'){
					editClientProject()
				}else {
					return null
				}

			}
		}, 1000)
	}

	const getDetailedReport = async () => {
		const projectId = project.projectData.id;

		try {
			if (projectId === 0) {
				dispatch(errorMessageOperations.switchStateError({
					message: Languages.getTranslation('warn-info-project', true),
					type: 'warn',
					show: true
				}));
			} else {
				setLoad(true);
				try {
						await Project.getDetailedReport(projectId);
				} catch (error) {
				} finally {
					setLoad(false);
				}

			}
		}catch (error){
				dispatch(errorMessageOperations.switchStateError(
					{ message: error.message, type: 'warn', show: true }
				));
				setLoad(false);
		}
	};

	// Сохранения проекта
	const updateProject = () => {
		let findError = false
		construction.details.forEach((item, indexArr) => {
			item._name = item.name
			if (!empty(item.error.allErrorsMessage)) {
				findError = true
				setShowAddDetailsError(prev => {
					return {typeBtn: 'save', open: true, list: [...prev.list, {...item, indexArr}]}
				})
			}
		})
		if (!findError && handleGlue()) {
			if (construction.projectData.id) {
				construction.reserveMaterials(construction.projectData.id)
					.then(() => Project.updateProject(construction.projectData.name, construction.projectData.id, dispatch).catch(e => console.log(e)))
					.then(() => window.location.reload())
					.catch(err => {
						toastError(Languages.getTranslation('defaultError', true))
						return Promise.reject({Error: err})
					})

			} else {
				saveProjectCopy()
			}
		}
	}

	const saveProjectCopy = () => {
		let findError = false
		construction.details.forEach((item, indexArr) => {
			if(!empty(item.error.allErrorsMessage)){
				findError = true
				setShowAddDetailsError(prev  => {
					return {typeBtn: 'saveCopy', open: true, list: [...prev.list, {...item, indexArr}]}
				})
			}
		})
		if(!findError && handleGlue()){
			dispatch(
				modalsOperations.switchStateForModal({
					show: true,
					type: "SAVE_PROJECT",
				})
			);
		}
	};

	const mapProject = () => {
		if(handleGlue()) {
			if (empty(construction.details)) {
				dispatch(errorMessageOperations.switchStateError({
					message: Languages.getTranslation('map-project-error', true),
					type: 'error', show: true
				}))

			}else if(empty(user.filial) || empty(user.filial?.ttid)){
				dispatch(errorMessageOperations.switchStateError({
					message: Languages.getTranslation('map-project-error-filial', true),
					type: 'error', show: true
				}))
			}else{
				dispatch(
					modalsOperations.switchStateForModal({
						show: true,
						type: "MAP_PROJECT",
					})
				);
			}

		}
	};

	const newProject = () => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "NEW_PROJECT",
			})
		);
	};

	const editClientProject = () => {
		if(handleGlue()){
			const name = construction.projectData.name
			const owner = construction.projectData.owner
			if(!name && !owner) {
				_errors.setError(`${Languages.getTranslation("not-name-or-owner", true)}`, 'error', true)
			}
				dispatch(
					modalsOperations.switchStateForModal({
						show: true,
						type: "SAVE_TO_OWNER_PROFILE",
					})
				);
		}
	}

	const closeErrorDetailsImport = () => {
		setShowAddDetailsError(prev => {
			return {...prev, open: !prev.open, list: []}
		})
		if(showAddDetailsError.typeBtn === 'save'){
			if(handleGlue()){
				if(construction.projectData.id) {
					construction.reserveMaterials(construction.projectData.id)
						.then(() => Project.updateProject(construction.projectData.name, construction.projectData.id, dispatch).catch(e => console.log(e)))
						.then(() => window.location.reload())
						.catch(err => {
							toastError(Languages.getTranslation('defaultError', true))
							return Promise.reject({Error: err})
						})
				} else {
					dispatch(
						modalsOperations.switchStateForModal({
							show: true,
							type: "SAVE_PROJECT",
						})
					);
				}
			}
		}
		if(showAddDetailsError.typeBtn === 'saveCopy'){
			if(handleGlue()){
				dispatch(
					modalsOperations.switchStateForModal({
						show: true,
						type: "SAVE_PROJECT",
					})
				);
			}
		}
	}

	const closeErrorModal = () => {
		setShowAddDetailsError({open: false, list: [], typeBtn: ''})
	}

	const getNewProjectButton = () => {
		if(!permissions.includes('KM_createProject')) return null;
		return (
			<button onClick={newProject} className="title-btn"
				title={Languages.getTranslation('create-new-project', true)}>
				<img className="title-btn_img" src={AddNew} alt={Languages.getTranslation('add', true)}/>
			</button>
		)
	}

	const getMapProjectButton = () => {
		if(!permissions.includes('KM_mapProject')) return null;
		return (
			<button onClick={mapProject} className="title-btn"
				title={Languages.getTranslation('map-material', true)}>
				<img className="title-btn_img" src={MapMaterial} alt={Languages.getTranslation('cutting', true)}/>
			</button>
		)
	}
	const getInfoProjectButton =() => {
		if(!permissions.includes('KM_infoProject')) return null;
		return(
		  <div
				data-tooltip-id="project-reports-tooltip"
				className="title-btn"
				title={Languages.getTranslation('info-about-project', true)}>
			<img className="title-btn_img" src={Info} alt="Інфо"/>
		 </div>
		)
	}

	const getCalcProjectButton = () => {
		if(!permissions.includes('KM_calcProject')) return null;
		return (
			<button onClick={calcProject} className="title-btn"
				title={Languages.getTranslation('calc-project', true)}>
				<img className="title-btn_img" src={Сalculation} alt={Languages.getTranslation('calculation', true)}/>
			</button>
		)
	}

	const getSaveProjectButton = () => {
		if(!permissions.includes('KM_saveProject')) return null;
		return (
			<div
				data-tooltip-id="save-project-tooltip"
				className="title-btn"
				title={Languages.getTranslation('save', true)}>
				<img className="title-btn_img" src={SaveProj} alt={Languages.getTranslation('save', true)}/>
			</div>
		)
	}
	
	return (
		<>
			<div className="project-calc">
				<div className="project-calc__name">
					<CopyTextComponent textForCopy={construction && construction.projectData.name ? construction.projectData.name || construction.projectData[0].name : Languages.getTranslation("new-project", true)} />
					<div className="project-calc__id" >
						<span>(ID - </span>
						<CopyTextComponent textForCopy={construction?.projectData?.id === 0 ? '' : `${construction?.projectData?.id}`}/>
						<span> )</span>
					</div>
				</div>
				<div className="project-calc__btns">
					{getNewProjectButton()}
					{getInfoProjectButton()}

					{getMapProjectButton()}
					
					<Tooltip
						className="project-calc-tooltip"
						id="project-reports-tooltip"
						place="bottom-end"
						clickable
						openOnClick
						isOpen={isGetInfoProject}
						setIsOpen={(value) => {
							setIsGetInfoProject(value);
						}}
					>
							<div
								className="project-calc__select-option"
								onClick={async () => {
									setIsGetInfoProject(false);
									await getInfo();
								}}
							>
								{Languages.getTranslation('general-report', true)}
							</div>
							{permissions.includes('KM_detailedReport') && (
								<div
									className="project-calc__select-option"
									onClick={() => {
										setIsGetInfoProject(false);
										generateSvgImages('getDetailedReport');
									}}
								>
									{Languages.getTranslation('detailed-report', true)}
								</div>
							)}
					</Tooltip>
					{getCalcProjectButton()}
					{getSaveProjectButton()}

					<Tooltip
						className="project-calc-tooltip"
						id="save-project-tooltip"
						place="bottom-end"
						clickable
						openOnClick
						isOpen={isSaveProject}
						setIsOpen={(value) => {
							setIsSaveProject(value);
						}}
					>
							{permissions.includes('KM_saveProject') && (<>
								<div className="project-calc__select-option"
									 onClick={() => {
										 setIsSaveProject(false);
										 generateSvgImages('updateProject')}
								}>{Languages.getTranslation('save', true)}</div>
								<div className="project-calc__select-option"
									 onClick={() => {
										 setIsSaveProject(false);
										 generateSvgImages('saveProjectCopy')}
									}
									>{Languages.getTranslation('save-copy', true)}</div>
							</>)}
							{permissions.includes('KM_saveProjectInUserProfile') &&
								<div className="project-calc__select-option" style={{lineHeight: "20px"}}
									 onClick={() => {
										 setIsSaveProject(false);
										 generateSvgImages('editClientProject');
									 }}
									>{Languages.getTranslation('save-project-in-userProfile', true)}</div>}
					</Tooltip>
				</div>

				<AddDetailsErrorModal isOpen={showAddDetailsError.open}
					titleDetail={Languages.getTranslation('error-details-for-save', true)}
					errorDetailsImport={showAddDetailsError.list}
					allErrorsMessage={true}
					closeErrorDetailsImport={closeErrorDetailsImport}
					close={closeErrorModal}/>
			</div>
			{load && <div className="project-calc__center"> <Loader/></div>}

		</>
	);
}


export default ProjectCalc;
