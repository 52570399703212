import Helpers from "../3D/Helpers";
import SizeArrow from "../3D/components/SizeArrow";
import {empty} from "../../helpers/helper";
import Languages from "../../translation/Languages";

export default class UShape  {
  _elements;
  parent;
  _detailH;
  _detailL;
  id;
  constructor({
    parent,
    elements,
    detailL,
    detailH,
  }) {
    this.parent = parent;
    this._detailH = detailH
    this._detailL = detailL
    this._elements = elements;

      const isLeftBottom = this.parent.x <= 0 && this.parent.y <= 0;
      const isRightBottom = this.parent.x + this.parent.width >= this.detailL && this.parent.y <= 0;
      const isRightTop =
        this.parent.x + this.parent.width >= this.detailL &&
        this.parent.y + this.parent.height >= this.detailH;
      const isLeftTop = this.parent.x <= 0 && this.parent.y + this.parent.height >= this.detailH;
      if (isLeftBottom || isRightBottom || isRightTop || isLeftTop) {
        if (isLeftBottom) {
          if (this.parent.width >= this.parent.height) {
            this.parent._edgeSide = "bottom";
            this.parent.offset = this.parent.x;
          } else {
            this.parent._edgeSide = "left";
            this.parent.offset = this.parent.y;
          }
        }
        if (isRightBottom) {
          if (this.parent.width >= this.parent.height) {
            this.parent._edgeSide = "bottom";
            this.parent.offset = this.parent.x;
          } else {
            this.parent._edgeSide = "right";
            this.parent.offset = this.parent.y;
          }
        }
        if (isRightTop) {
          if (this.parent.width >= this.parent.height) {
            this.parent._edgeSide = "top";
            this.parent.offset = this.parent.x;
          } else {
            this.parent._edgeSide = "right";
            this.parent.offset = this.parent.y;
          }
        }
        if (isLeftTop) {
          if (this.parent.width >= this.parent.height) {
            this.parent._edgeSide = "top";
            this.parent.offset = this.parent.x;
          } else {
            this.parent._edgeSide = "left";
            this.parent.offset = this.parent.y;
          }
        }
      } else {
        if (this.parent.y <= 0) {
          this.parent._edgeSide = "bottom";
          this.parent._offset = this.parent.x;
        }

        if (this.parent.x <= 0) {
          this.parent._edgeSide = "left";
          this.parent._offset = this.parent.y;
        }

        if (this.parent.y + this.parent.height >= this.detailH) {
          this.parent._edgeSide = "top";
          this.parent._offset = this.parent.x;
        }

        if (this.parent.x + this.parent.width >= this.detailL) {
          this.parent._edgeSide = "right";
          this.parent._offset = this.parent.y;
        }
      }

      if (["left", "right"].includes(this.parent.edgeSide)) {
        [this.parent._width, this.parent._height] = [this.parent.height, this.parent.width];
      }

    if(!empty(this.parent._dataForConstructor.edgeSide)) {
      this.parent._edgeSide = this.parent._dataForConstructor.edgeSide;
    }

    if(!empty(this.parent._dataForConstructor.offset)) {
      this.parent._offset = this.parent._dataForConstructor.offset;
    }

    if(!empty(this.parent._dataForConstructor.height)) {
      this.parent._height = this.parent._dataForConstructor.height;
    }

    if(!empty(this.parent._dataForConstructor.width)) {
      this.parent._width = this.parent._dataForConstructor.width;
    }
    this.parent.touchedSides = [this.parent.edgeSide];
    this.addParams();
    this.generateElements()
    this.initRequiredFields();
  }

  initRequiredFields() {
    this.parent.requiredFields = {
      r: {
        min: this.parent.getMinRByMaterial(3),
        max: this.parent.width / 2
      },
      width: {
        min: 15,
        max: ['left', 'right'].includes(this.parent.edgeSide) ? this.parent.detail.h - this.parent.offset : this.parent.detail.l - this.parent.offset
      },
      height: {
        min: 5,
        max: ['left', 'right'].includes(this.parent.edgeSide) ? this.parent.detail.l : this.parent.detail.h
      },
      offset: {
        min: 1,
        max: ['left', 'right'].includes(this.parent.edgeSide) ? this.parent.detail.h - this.parent.width : this.parent.detail.l - this.parent.width
      }
    }
  }
  addParams() {
    let f_value = ''
    const setCenter = this.setCenter.bind(this);
    f_value = this.parent._dataForConstructor.hasOwnProperty('f_width') ? this.parent._dataForConstructor['f_width'] : '';
    this.parent.setFormField({name: 'width', label: `${Languages.getTranslation('height', true)} (pw)`, value: this.parent.width, type: 'number', visible: true, additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value} ]});

    this.parent.setFormField({name: 'height', value: this.parent.height, type: 'number', label: Languages.getTranslation('width', true), visible: true,});
    this.parent.setFormField({name:'edgeSide', type: 'select', label: Languages.getTranslation('tip', true), value: this.parent.edgeSide, visible: true, variables: [
        ...this.parent.getSidesValues()
      ]})
    f_value = this.parent._dataForConstructor.hasOwnProperty('f_offset') ? this.parent._dataForConstructor['f_offset'] : '';
    this.parent.setFormField({name: 'offset', label: `${Languages.getTranslation('displacement', true)} (po)`, value: this.parent.offset, type: 'number', visible: true, additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value},
        {name: 'center', label: Languages.getTranslation('center-detail', true), callback: () => {
            setCenter();
          }}
      ]})
    this.parent.setFormField({name: 'x', visible: false})
    this.parent.setFormField({name: 'y', visible: false})
    this.parent.setFormField({name: 'angle', visible: false})
    this.parent.setFormField({name: 'r', value: this.parent.r, visible: true, type: 'number', label: Languages.getTranslation('radius', true), additionalProcessing: {
        name: 'removeRadius', label: '', callback: () => {
          this.removeRadius()
        }
      }})
    this.parent.setFormField({name: 'doubleSmiles', visible: false,});
    this.parent.setFormField({name: 'typeR', value: this.parent.typeR, visible: false })

  }

  get paramsSorting() {
    const sortingArrForUshape = ['type', 'side', 'edgeSide', 'offset', 'width', 'height', 'depth', 'r', 'comment']

    return 	this.parent.formFields.sort((a, b)=> sortingArrForUshape.indexOf(a.name) - sortingArrForUshape.indexOf(b.name))
  }

  removeRadius() {
    this.parent.r = 0;
    this.parent.setFormField({name: 'r', value: 0})
    this.parent.buildDetail()
      .then(() => this.parent.renderDetail());
  }

  get elements() {
    return this.generateElements()
  }

  // set elements(element) {
  //   this._elements = element
  // }

  get shape() {
    return this.realData
  }

  get realData() {
    // this.generateElements()
    return {
      side: 'front',
      ext: false,
      elements: this.elements,
      touchedSides: [this.parent.edgeSide]
    };
  }

  get cutOut() {
    const width = this.parent.widthCalc;
    const height = this.parent.heightCalc;
    const x = this.xCalc;
    const y = this.yCalc;
    // this.generateElements()
    const cutout = {
      x: x,
      y: y,
      side: 'front',
      type: 'uShape',
      subType: 'mill',
      depth: this.parent.detail.w,
      edge: this.parent.edge,
      place: this.parent.edgeSide,
      touchedSides: [this.parent.edgeSide],
      sidesCount: 1,
      width: width,
      color: this.parent.isActive ? Helpers.colorForActive : null,
      height: height,
      elements: this.parent.create3dDataForMills(this.elements)
    }
    return cutout;
  }

  generateElements() {
    this.detailH = this.parent.detail.h
    this.detailL = this.parent.detail.l
    // this.elements = Helpers.createUshapeElements(this.parent);
    const elements = []
    const width = this.parent.widthCalc
    const height = this.parent.heightCalc
    switch (this.parent.edgeSide) {
      case 'left':
        elements.push(Helpers.createLine(0, 0, width - this.parent.r, 0, this.parent.edge, this.getActiveElement()))
        elements.push(Helpers.createArc(width - this.parent.r, 0, width, this.parent.r, this.parent.r, width - this.parent.r, this.parent.r, false, this.parent.edge, this.getActiveElement()));
        elements.push(Helpers.createLine(width, this.parent.r, width, height - this.parent.r, this.parent.edge, this.getActiveElement()))
        elements.push(Helpers.createArc(width, height - this.parent.r, width - this.parent.r, height, this.parent.r, width - this.parent.r, height - this.parent.r, false, this.parent.edge, this.getActiveElement()));
        elements.push(Helpers.createLine(width - this.parent.r, height, 0, height, this.parent.edge, this.getActiveElement()))
        break
      case 'top':
        elements.push(Helpers.createLine(0, height, 0, this.parent.r, this.parent.edge, this.getActiveElement()))
        elements.push(Helpers.createArc(0, this.parent.r, this.parent.r, 0, this.parent.r, this.parent.r, this.parent.r, false, this.parent.edge, this.getActiveElement()));
        elements.push(Helpers.createLine(this.parent.r, 0, width - this.parent.r, 0, this.parent.edge, this.getActiveElement()))
        elements.push(Helpers.createArc(width - this.parent.r, 0, width, this.parent.r, this.parent.r, width - this.parent.r, this.parent.r, false, this.parent.edge, this.getActiveElement()));
        elements.push(Helpers.createLine(width, this.parent.r, width, height, this.parent.edge, this.getActiveElement()))
        break
      case 'right':
        elements.push(Helpers.createLine(width, height, this.parent.r, height, this.parent.edge, this.getActiveElement()))
        elements.push(Helpers.createArc(this.parent.r, height, 0, height - this.parent.r, this.parent.r, this.parent.r, height - this.parent.r, false, this.parent.edge, this.getActiveElement()));
        elements.push(Helpers.createLine(0, height - this.parent.r, 0, this.parent.r, this.parent.edge, this.getActiveElement()))
        elements.push(Helpers.createArc(0, this.parent.r, this.parent.r, 0, this.parent.r, this.parent.r, this.parent.r, false, this.parent.edge, this.getActiveElement()));
        elements.push(Helpers.createLine(this.parent.r, 0, width, 0, this.parent.edge, this.getActiveElement()))
        break
      case 'bottom':
        elements.push(Helpers.createLine(width, 0, width, height - this.parent.r, this.parent.edge, this.getActiveElement()))
        elements.push(Helpers.createArc(width, height - this.parent.r, width - this.parent.r, height, this.parent.r, width - this.parent.r, height - this.parent.r, false, this.parent.edge, this.getActiveElement()));
        elements.push(Helpers.createLine(width - this.parent.r, height, this.parent.r, height, this.parent.edge, this.getActiveElement()))
        elements.push(Helpers.createArc(this.parent.r, height, 0, height - this.parent.r, this.parent.r, this.parent.r, height - this.parent.r, false, this.parent.edge, this.getActiveElement()));
        elements.push(Helpers.createLine(0, height - this.parent.r, 0, 0, this.parent.edge, this.getActiveElement()))
        break
    }
    return  Helpers.fixUShapePoints({
      x: this.xCalc,
      y: this.yCalc,
      edge: this.parent.edge,
      elements
    })
  }

  getActiveElement() {
    return this.parent.isActive ? Helpers.colorForActive : null
  }


  addSizesArrows() {
    if(!this.parent.isActive) return false;
    // const detail = this.parent;
    const pos = 'left_bottom'
    return SizeArrow.getElementSizeArrow(
      this.xCalc,
      this.yCalc,
      null,
      pos,
      this.parent.detail
    )
  }

  get dataForValidate() {
    return {
      ...this.realData
    };
  }

  get detailH() {
    return this._detailH;
  }

  set detailH(detailH) {
    this._detailH = detailH;
  }

  get detailL() {
    return this._detailL;
  }

  set detailL(detailL) {
    this._detailL = detailL;
  }

  async remove() {
    this.parent.detail.uShapes.splice(this.parent.detail.uShapes.indexOf(this), 1);
    return this.parent.buildDetail()
      .then(() => this.parent.updateDb())
      .then(() => this.parent.renderDetail());
  }

  get xCalc() {
    switch (this.parent.edgeSide) {
      case 'top':
      case 'bottom':
        return this.parent.offset;
      case 'left':
        return 0;
      case 'right':
        return this.detailL - this.parent.widthCalc

    }
  }

  get yCalc() {
    switch (this.parent.edgeSide) {
      case 'left':
      case 'right':
        return this.parent.offset;
      case 'top':
        return this.detailH - this.parent.heightCalc;
      case 'bottom':
        return 0;
    }
  }



  setCenter() {
    if (["left", "right"].includes(this.parent.edgeSide)) {
      this.parent.offset = this.detailH / 2 - this.parent.heightCalc / 2
    } else {
      this.parent.offset = this.detailL / 2 - this.parent.widthCalc / 2
    }
    // this.setFormField({name: 'offset', value: this.offset})
    this.parent.buildDetail()
      .then(() => this.parent.renderDetail())
    // this.renderDetail()
  }

  validate() {
    // TODO оствил для добавления валидации смещения
    // if(this.xCalc < 0 && this.yCalc === 0) {
    //   this.updateErrors('change-the-offset')
    //   return Promise.reject('change-the-offset')
    // }
    switch (this.parent.edgeSide) {
      case 'top':
      case 'bottom':
        if(this.parent.heightCalc === 0) {
          this.parent.detail.error.setError(`${Languages.getTranslation('change-width', true)}`, 'error', true);
          return Promise.reject('change-width')
        }
        if(this.parent.width === 0) {
          this.parent.detail.error.setError(`${Languages.getTranslation('_change-height', true)}`, 'error', true);
          return Promise.reject('_change-height')
        }
        break
      case 'left':
      case 'right':
        if(this.parent.widthCalc === 0) {
          this.parent.detail.error.setError(`${Languages.getTranslation('change-width', true)}`, 'error', true);
          return Promise.reject('change-width')
        }
        if(this.parent.width === 0) {
          this.parent.detail.error.setError(`${Languages.getTranslation('_change-height', true)}`, 'error', true);
          return Promise.reject('_change-height')
        }
        break
    }
      if(this.parent.r < 3){
        this.parent.detail.error.setError(`${Languages.getTranslation('radius-cannot-be', true)} ${this.parent.r}`, 'error', true);
        return Promise.reject('radius-cannot-be-0')
      }

    return Promise.resolve()
  }

  rotateDetail(direction) {
    let offset, currentOffset = this.parent.offset;
    const oldEdgeSide = this.parent.edgeSide;
    this.parent.edgeSide = this.parent.getNextEdgeSideForRotate(direction);
    switch (oldEdgeSide) {
      case 'top':
        offset = direction ? this.parent.detail.h - currentOffset - this.parent.width : currentOffset;
        break;
      case 'right':
        offset = direction ? currentOffset : this.parent.detail.l - currentOffset - this.parent.width;
        break;
      case 'left':
        offset = direction ? currentOffset : this.parent.detail.l - currentOffset - this.parent.width;
        break;
      case 'bottom':
        offset = direction ? this.parent.detail.h - currentOffset - this.parent.width : currentOffset;
        break;
    }
    this.initRequiredFields();
    this.parent.offset = offset;
    return Promise.resolve()
  }
}
