import './style.scss';
import Languages from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";
import {Modal, ModalFooter, ModalHeader} from "../index";

const SubmitFurnitureModal = ({isOpen, submitModalText, close, deleteOneHardware, index }) => {

	const deleteOne = () => {
		deleteOneHardware(index)
	}

	return <Modal isOpen={isOpen}>
		<div className="modal">
		<div className='submitModalFurniture modal__wrapper'>
			<ModalHeader>
				<h3 className="submitModalFurniture__title">{Languages.getTranslation('delete-furniture-accept', true)}</h3>
			</ModalHeader>
			<div className="submitModalFurniture__body">
				{submitModalText} ?
			</div>
			<ModalFooter className="submitModalFurniture__actions">
				<DefaultBtn
					title={Languages.getTranslation("cancel", true)}
					handler={close}
				/>
				<DefaultBtn
					title={Languages.getTranslation("submit", true)}
					handler={deleteOne}
				/>
			</ModalFooter>
		</div>
		</div>
	</Modal>

}

export default SubmitFurnitureModal