import React, { useState } from "react";
import "./style.scss";
import Languages from "../../../../translation/Languages";
import upArrow from "../../../../media/grey-arrow-up.svg"; // Подключение icons
import downArrow from "../../../../media/grey-arrow-down.svg";
import Project from "../../../../models/Project";
import {empty} from "../../../../helpers/helper";
import AddCatalogProductModal from "../../Modal/AddCatalogProductModal";
import Loader from "../../Loader";

const TreeNode = ({ node, depth, loadFurniture, highlightedItems }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [productId, setProductId] = useState(null);
    const [isHovered, setIsHovered] = useState(false);

    const highlightedItems3 = highlightedItems || [];
    const handleToggle = () => {
        if (node.count_children > 0) {
            setIsOpen(!isOpen);
        }
    };
    const close = () => {
        setIsModalOpen(false)
    }

    const getProducts = async(id) => {
        setIsLoading(true)
        try{
            const resProducts =  await Project.getProductsByAlias(id);
            if (!empty(resProducts)){
                setProductId(id)
                setProducts(resProducts)
                setIsModalOpen(true)
            }
        } catch (err){
            console.error(err);

        }finally {
            setIsLoading(false)
        }
    };

    return (
        <div className="tree-node"
             style={{
                 '--depth': depth, // Передаём текущую глубину как CSS-переменную
             }}>
            <div
                onClick={node.count_children > 0 ? handleToggle : () => getProducts(node.id)} // Только активируем клики для папок с детьми
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                    color: (isHovered && node.count_children === 0) || highlightedItems3.includes(node.alias) ? "#A14389" : "#414141", //
                    marginLeft: `${depth * 20}px`,
                    fontWeight:  highlightedItems3.includes(node.alias) ? 700 : 'normal',
                    fontSize:  highlightedItems3.includes(node.alias) ? 16 : 14,
                }}
                className={`hardware-category-item ${isOpen ? "open" : ""}`} // Добавляем класс open для стилей
                       >
                {/* Используем иконки вместо стрелок */}
                {node.count_children === 0 ? (
                    "" // Для файлов
                ) : isOpen ? (
                    <img src={upArrow} alt="Collapse" className="tree-node-arrow" /> // Открытая папка
                ) : (
                    <img src={downArrow} alt="Expand" className="tree-node-arrow"
                         style={{ transform: "rotate(180deg)" }} /> // Закрытая папка
                )}{" "}
                {Languages.getAvaliableTranslationBaseOnUk(node.translations)}
            </div>

            {isOpen && node.children && node.children.length > 0 && (
                <div className="children-container">
                    {node.children.map((child) => (
                        <TreeNode
                            key={child.id}
                            node={child}
                            depth={depth + 1} // Увеличиваем уровень вложенности
                        />
                    ))}
                </div>
            )}
            {(!empty(products) && isModalOpen ) && <AddCatalogProductModal close={close} catalogList={products} productId={productId} loadFurniture={loadFurniture}/> }
            {isLoading && <div className="loaderFurniture"> <Loader/></div> }

        </div>
    );
};

// Основной компонент отображения дерева
const HardwareRecommendedCategory = ({ data, loadFurniture, highlightedItems }) => {
    return (
        <div className="hardware-category">
            {data.map((node) => (
                <TreeNode key={node.id} node={node} depth={0} loadFurniture={loadFurniture} highlightedItems={highlightedItems} />
            ))}
        </div>
    );
};

export default HardwareRecommendedCategory;