import { configureStore, combineReducers } from "@reduxjs/toolkit";

import { modalsReducer } from "./modals";
import { inputsReducer } from "./inputs";
import { projectReducer } from "./project";
import { loaderReducer } from "./loader";
import { userReducer } from "./user";
import { errorMessageReducer } from './errors';
import { tempDetailReducer } from './tempDetail';
import { panelReducer } from "./panel";
import { hideAllReducer } from "./hideAll";
import { langReducer } from "./lang";
import { contourEdgeHelperReducer } from "./contourEdgeHelper";
import { hoveredObjectOn3DReducer } from "./hoveredObjectOn3D";


import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  modals: modalsReducer,
  project: projectReducer,
  inputs: inputsReducer,
  user: userReducer,
  loader: loaderReducer,
  hideAll: hideAllReducer,
  panel: panelReducer,
  errorMessage: errorMessageReducer,
  tempDetail: tempDetailReducer,
  lang: langReducer,
  contourEdgeHelper: contourEdgeHelperReducer,
  hoveredObjectOn3D: hoveredObjectOn3DReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: {
    modals: modalsReducer,
    project: projectReducer,
    inputs: inputsReducer,
    user: userReducer,
    loader: loaderReducer,
    hideAll: hideAllReducer,
    panel: panelReducer,
    errorMessage: errorMessageReducer,
    tempDetail: tempDetailReducer,
    lang: langReducer,
    contourEdgeHelper: contourEdgeHelperReducer,
    hoveredObjectOn3D: hoveredObjectOn3DReducer
  },
  // middleware: [thunk, logger],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export default store;
