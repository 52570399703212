import Helpers from "../3D/Helpers";
import {empty} from "../../helpers/helper";

import Languages from "../../translation/Languages";
import SizeArrow from "../3D/components/SizeArrow";

/*
* Received data
* @parent = link to the Class Detail
* @elements = array of
* */
export default class RadiusEdge {
	_elements = []; //Array
  	parent;
	id;
	constructor({
		            parent,
		            subType = 'mill',
								elements
	            }) {
		this.parent = parent;
		this._elements = elements;
		this._subType = subType;
		this.addParams()
		this.initRequiredFields();
		// this.generateElements()
	}
	calcMinR() {
		let val;
		if(this.parent.typeR === 'internal') {
			const minVal = Math.min(this.parent.y, this.parent.x);
			const maxVal = Math.max(this.parent.y, this.parent.x);
			// if(minVal / 01maxVal < 0.3) {
				val = Helpers.calculateDistance(0, this.parent.y, this.parent.x, 0) / 2
			// } else {
				// const calcminR = Helpers.calculateDistance(0, this.parent.y, this.parent.x, 0) / 2;
				// val = Math.max(this.parent.y, this.parent.x)
			// }
		} else {
			val = Math.max(this.parent.y, this.parent.x)
		}
		return Math.round(val)
	}
	initRequiredFields() {
		this.parent.requiredFields = {
			r: {
				min: this.calcMinR(),
				max: Math.max(this.parent.detail.h, this.parent.detail.l) * 20
			},
			x: {
				min: 0,
				max: this.parent.detail.l
			},
			y: {
				min: 0,
				max: this.parent.detail.h
			}
		}
	}
	addParams() {
		let f_value = ''
		this.parent.setFormField({name: 'edgeSide', visible: false})
		this.parent.setFormField({name: 'offset', visible: false})
		this.parent.setFormField({name: 'width', visible: false})
		this.parent.setFormField({name: 'height', visible: false})
		this.parent.setFormField({name: 'doubleSmiles', visible: false,});
		this.parent.setFormField({
			name: 'angle', value: this.parent.angle, label: Languages.getTranslation('corner', true), type: 'select',
			variables: this.parent.getAnglesValue(), visible: true
		})
		this.parent.setFormField({name: 'x', value: this.parent.x, type: 'number', label: 'x', visible: this.parent.type === "radiusEdge"})
		this.parent.setFormField({name: 'y', value: this.parent.y, type: 'number', label: 'y', visible: this.parent.type === "radiusEdge"})
		this.parent.setFormField({
			name: 'typeR', value: this.parent.typeR, label: 'Тип Радіусу', type: 'select', variables: [
				{key: "internal", value: Languages.getTranslation('internal', true)},
				{key: "external", value: Languages.getTranslation('external', true)},
			], visible: true
		})

		f_value = this.parent._dataForConstructor.hasOwnProperty('f_r') ? this.parent._dataForConstructor['f_r'] : '';
		this.parent.setFormField({name: 'r', value: this.parent.r, type: 'number', label: `${Languages.getTranslation('radius', true)} (pr)`, visible: this.parent.type === "radiusEdge", additionalParam : [
				{name: 'formula', label: Languages.getTranslation('formula', true), value: f_value} ]})
	}

	get paramsSorting() {
		const sortingArr = ['type', 'angle', 'x', 'y', 'r', 'typeR', "comment"]

		return	this.parent.formFields.sort((a, b) => sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
	}

	generateElements() {
		const detailH = Number(this.parent.detail.h)
		const detailL = Number(this.parent.detail.l)
		const elements = []
		const x = Number(this.parent.x)
		const y = Number(this.parent.y)
		const r = Number(this.parent.r)
		const dir = this.parent.typeR === 'internal'
		let pointArcCenterX, pointArcCenterY
		switch (this.parent.angle) {
			case 'left_bottom':
				pointArcCenterX = dir ? Helpers.calculateArcCenter(x, 0 , 0, y, r,).xC1 : Helpers.calculateArcCenter(x, 0 , 0, y, r,).xC2
				pointArcCenterY = dir ? Helpers.calculateArcCenter(x, 0 , 0, y, r,).yC1 : Helpers.calculateArcCenter(x, 0 , 0, y, r,).yC2
				elements.push(Helpers.createArc(x, 0 , 0, y, r, pointArcCenterX, pointArcCenterY, dir, this.parent.edge, this.getActiveElement()));
				break
			case 'left_top':
				pointArcCenterX = dir ? Helpers.calculateArcCenter(0, detailH - y, x, detailH, r,).xC1 : Helpers.calculateArcCenter(0, detailH - y, x, detailH, r,).xC2
				pointArcCenterY = dir ? Helpers.calculateArcCenter(0, detailH - y, x, detailH, r,).yC1 : Helpers.calculateArcCenter(0, detailH - y, x, detailH, r,).yC2
				elements.push(Helpers.createArc(0, detailH - y, x, detailH, r, pointArcCenterX, pointArcCenterY, dir, this.parent.edge, this.getActiveElement()));
				break
			case 'right_top':
				pointArcCenterX = dir ? Helpers.calculateArcCenter(detailL - x, detailH , detailL, detailH - y, r,).xC1 : Helpers.calculateArcCenter(detailL - x, detailH, detailL, detailH - y, r,).xC2
				pointArcCenterY = dir ? Helpers.calculateArcCenter(detailL - x, detailH , detailL, detailH - y, r,).yC1 : Helpers.calculateArcCenter(detailL - x, detailH , detailL, detailH - y, r,).yC2
				elements.push(Helpers.createArc(detailL - x, detailH, detailL, detailH - y, r, pointArcCenterX, pointArcCenterY, dir, this.parent.edge, this.getActiveElement()));
				break
			case 'right_bottom':
				pointArcCenterX = dir ? Helpers.calculateArcCenter(detailL, y , detailL - x, 0, r,).xC1 : Helpers.calculateArcCenter(detailL, y , detailL - x, 0, r,).xC2
				pointArcCenterY = dir ? Helpers.calculateArcCenter(detailL, y , detailL - x, 0, r,).yC1 : Helpers.calculateArcCenter(detailL, y , detailL - x, 0, r,).yC2
				elements.push(Helpers.createArc(detailL, y , detailL - x, 0, r, pointArcCenterX, pointArcCenterY, dir, this.parent.edge, this.getActiveElement()));
				break
		}
		return elements
	}

	getActiveElement() {
		return this.parent.isActive ? Helpers.colorForActive : null
	}

	get corner() {
		return this._corner;
	}

	set corner(corner) {
		this._corner = corner;
	}

	get elements() {
		return this.generateElements()
	}

	// set elements(element) {
	// 	this._elements = element
	// }

	get realData() {
		return {
			// id: this.parent.id,
			// x: this.parent.x,
			// y: this.parent.y,
			type: "radiusEdge",
			// r: this.parent.r,
			ext: false,
			angle: this.parent.angle,
			touchedSides: this.touchedSides,
			// edge: this.parent.edge,
			typeR: this.parent.typeR,
			// isErrorText: this.parent.isErrorText,
		};
	}

	get cutOut() {
		// this.generateElements()
		const cutout = {
			x: this.parent.x,
			y: this.parent.y,
			r: this.parent.r,
			type: this.parent.type,
			subType: this._subType,
			edge: this.parent.edge,
			place: null,
			sidesCount: 2,
			touchedSides: this.touchedSides,
			angle: this.parent.angle,
			color: this.parent.isActive ? Helpers.colorForActive : null,
			elements: this.parent.create3dDataForMills(this.elements)
		}
		return cutout;
	}

	get dataForValidate() {
		// this.generateElements()
		return {
			angle: this.parent.angle,
			x: this.parent.x,
			y: this.parent.y,
			depth: this.parent.detail.realW,
			ext: false,
			fullDepth: true,
			subside: this.parent.angle.split("_")[0],
			r: this.parent.r,
			side: 'front',
			edge: this.parent.getEdgeArrayIndex(this.parent.edge),
			subtype: "radiusEdge",
			type: 'radiusEdge',
			z: 0,
			id: this.parent.id
		};
	}

	addSizesArrows() {
		if (!this.parent.isActive) return false;
		const pos = this.parent.angle
		return SizeArrow.getElementSizeArrow(
			this.parent.x,
			this.parent.y,
			null,
			pos,
			this.parent.detail,
			true
		)
	}

	validate() {
		// if(this.parent.r < 3){
		// 	this.parent.detail.error.setError(`${Languages.getTranslation('radius-cannot-be', true)} ${this.parent.r}`, 'error', true);
		// 	return Promise.reject('radius-cannot-be-0')
		// }
		// if (this.parent.typeR === 'internal') {
		// 	if(Helpers.isArcOutsideDetail(this.parent.detail.l, this.parent.detail.h, this.elements[0])){
		// 		this.parent.detail.error.setError(`${Languages.getTranslation('radius-cannot-be', true)} ${this.parent.r}`, 'error', true);
		// 		return Promise.reject('radius-cannot-be-0')
		// 	}
		// }
		return Promise.resolve()
	}

	rotateDetail(direction) {
		let angle, x, y;
		switch (this.parent.angle) {
			case 'left_bottom':
				angle = direction ? 'left_top' : 'right_bottom';
				break
			case 'left_top':
				angle = direction ? 'right_top': 'left_bottom';
				break
			case 'right_top':
				angle = direction ? 'right_bottom' : 'left_top';
				break
			case 'right_bottom':
				angle = direction ? 'left_bottom' : 'right_top';
		}
		[x, y] = [this.parent.y, this.parent.x];
		this.parent.angle = angle;
		[this.parent.x, this.parent.y] = [x, y];
		this.initRequiredFields();
		return Promise.resolve()
	}

	get touchedSides() {
		return this.parent.angle.split('_')
	}
}