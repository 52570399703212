import  { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { modalsOperations } from "redux/modals";
import AppHeader from "components/AppHeader";
import ReduxModal from "components/UI/Modal";
import DetailProps from "components/UI/Modal/DetailProps";
import DetailInfo from "components/UI/Modal/DetailInfo";
import InterFace3D from "../components/3DInterface";

const PrivateTemplate = ({ children }) => {
  const dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    if (location.pathname.indexOf("processes") == -1) {
      dispatch(
        modalsOperations.showStateDetailInfo({
          show: false,
        })
      );
    }
  }, [location]);

  return (
    <>
       <ReduxModal />
       <DetailProps />
       <DetailInfo />
       <AppHeader />
      <InterFace3D />
       {children}
    </>
  );
};
export default PrivateTemplate;
