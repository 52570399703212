import "./styles.scss";

const SingleArrowIcon = () => {
	return (<svg viewBox="0 0 24 24" focusable="false">
		<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
	</svg>);
}
const DoubleArrowIcon = () => {
	return (<svg viewBox="0 0 24 24" focusable="false">
		<path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
	</svg>);
}


export const Pagination = ({
														 meta,
														 startPage,
														 onPageSelect,
														 canGo,
														 onPrevPage,
														 onNextPage
													 }) => {
	return (
		<div className="pagination-container">
			<div className="pagination-range-actions">
				<div className="pagination-label">
					{meta.from}-{meta.to} c {meta.total}
				</div>
				<div className="pagination-btns">
					<button
						className="pagination-btn pagination-btn--prev"
						onClick={() => onPageSelect(startPage)}
						disabled={!canGo.back}
					><DoubleArrowIcon/></button>
					<button
						className="pagination-btn pagination-btn--prev"
						onClick={onPrevPage}
						disabled={!canGo.back}
					><SingleArrowIcon/></button>
					<button
						className="pagination-btn pagination-btn--next"
						onClick={() => onNextPage()}
						disabled={!canGo.next}
					><SingleArrowIcon/></button>
					<button
						className="pagination-btn pagination-btn--next"
						onClick={() => onPageSelect(meta.last_page)}
						disabled={!canGo.next}
					><DoubleArrowIcon/></button>
				</div>
			</div>
		</div>
	);
};
