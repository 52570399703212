const getConstruction = ({ project }) => project.project.construction;

const getDetailsTableConfig = ({ project }) => project.project.detailsTableConfig;

const getsceneModel = ({ project }) => {
  return project.project.sceneModel
    ? project.project.sceneModel.payload.sceneModel
    : project.project.sceneModel;
};

const getDetails = ({ project }) => {
  return project.project.construction
    ? project.project.construction._details
    : [];
};

const getMaterials = ({ project }) => {
  return project.project.construction
    ? project.project.construction._materials
    : [];
};

const getDetail = ({ project }) => {
  return project.project.detail
    ? project.project.detail.payload.detail
    : project.project.detail;
};
const getIsArrowMaterial = ({ project }) => project.project.IsArrowMaterial;
const getIsArrowEdge = ({ project }) => project.project.IsArrowEdge;

const getDetailName = ({ project }) => {
  return project.project.detail
    ? project.project.detail.payload.detail.name
    : '';
};

const getTreatment = ({ project }) => {
  return project.project.Treatment
    ? project.project.Treatment.payload.Treatment
    : project.project.Treatment;
};

const getEdges = ({ project }) => {
  return project.project.construction
      ? project.project.construction._edges
      : [];
}
const getFurniture = ({ project }) => {
  return project.project.construction
      ? project.project.construction._furnitures
      : [];
}
const getIdDetailFromScene = ({ project }) => project.project.idDetailFromScene;

export default {
  getConstruction,
  getDetails,
  getDetail,
  getIsArrowMaterial,
  getIsArrowEdge,
  getMaterials,
  getEdges,
  getsceneModel,
  getTreatment,
  getDetailName,
  getDetailsTableConfig,
  getIdDetailFromScene,
  getFurniture
};
