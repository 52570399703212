import {combineReducers} from "redux";
import {createReducer} from "@reduxjs/toolkit";
import ACTIONS from "./actions";
import CONSTANTS from "../../config/constants";

const initialProjectState = {
  construction: null,
  detail: null,
  IsArrowMaterial: false,
  IsArrowEdge: false,
  sceneModel: null,
  errors: { message: '', isShow: false },
  detailsTableConfig: {
    mode: CONSTANTS.detailsTableModes.list,
    limits: {onlyFirstGroup: false, onlyEdge: false},
    focusedRow: CONSTANTS.defaultFocusedRow()
  },
  idDetailFromScene: null
};

const project = createReducer(initialProjectState, {
  [ACTIONS.updateDetailsTableMode.type]: (state, {payload: mode}) => {
    state.detailsTableConfig = {...state.detailsTableConfig, mode};
  },
  [ACTIONS.updateProductsInConstruction.type]: (state, {payload: products}) => {
    state.construction._products = products;
  },
  [ACTIONS.updateMaterialsInConstruction.type]: (state, {payload: materials}) => {
    state.construction._materials = materials;
  },
  [ACTIONS.updateFurnitureInConstruction.type]: (state, {payload: furniture}) => {
    state.construction._furnitures = furniture;
  },
  [ACTIONS.updateDetailInConstruction.type]: (state, {payload: detail}) => {
    const detailIndex = state.construction._details.findIndex((d) => d.id === detail.id);

    if (detailIndex !== -1) {
      state.construction._details = [
        ...state.construction._details.slice(0, detailIndex),
        detail,
        ...state.construction._details.slice(detailIndex + 1),
      ];
    }
  },
  [ACTIONS.updateDetailsTableLimits.type]: (state, {payload: limits}) => {
    state.detailsTableConfig = {...state.detailsTableConfig, limits};
  },
  [ACTIONS.updateDetailsTableFocusedRow.type]: (state, {payload: focusedRow}) => {
    state.detailsTableConfig = {...state.detailsTableConfig, focusedRow};
  },
  [ACTIONS.setDefaultDetailsTableFocusedRow.type]: (state) => {
    state.detailsTableConfig = {...state.detailsTableConfig, focusedRow: CONSTANTS.defaultFocusedRow()};
  },
	[ACTIONS.setDefaultDetailsTableLimits.type]: (state) => {
		state.detailsTableConfig = {...state.detailsTableConfig, limits: {onlyFirstGroup: false, onlyEdge: false}};
	},
  [ACTIONS.setIdDetailFromScene.type]: (state, {payload}) => {
		state.idDetailFromScene = payload;
	},
  [ACTIONS.ConstructionProject.type]: (state, {payload: construction}) => {
    return {
      ...state,
      construction,
    };
  },
  [ACTIONS.setDetail.type]: (state, detail) => {
    return {
      ...state,
      detail: detail,
    }
  },
  [ACTIONS.setIsArrowMaterial.type]: (state, {payload: IsArrowMaterial}) => {
		state.IsArrowMaterial = IsArrowMaterial;
  },
  [ACTIONS.setIsArrowEdge.type]: (state, {payload: IsArrowEdge}) => {
		state.IsArrowEdge = IsArrowEdge;
  },
  [ACTIONS.setTreatment.type]: (state, Treatment) => {
    return {
      ...state,
      Treatment: Treatment,
    };
  },
  [ACTIONS.setsceneModel.type]: (state, sceneModel) => {
    return {
      ...state,
      sceneModel,
    };
  },
  [ACTIONS.errorProject.type]: (state, val) => {
    const { message, isShow } = val.payload

    return { ...state, errors: { message, isShow }};
  },
});

export default combineReducers({
  project,
});
