import './style.scss';
import Languages, {LANGS} from "../../../../translation/Languages";
import React from "react";


const HardwareTable = ({ data, handleCountChange, curLang, setSubmitDelete, setSubmitEdit }) => {
    return (
        <table className="hardware-table">
            <thead>
            <tr className="hardware-table-row-header">
                <th className="hardware-table-header-item no-border-left">№</th>
                <th className="hardware-table-header-item"></th>
                <th className="hardware-table-header-name">{Languages.getTranslation('name', true)}</th>
                <th className="hardware-table-header-item">{Languages.getTranslation('article', true)}</th>
                <th className="hardware-table-header-item">{Languages.getTranslation('amount', true)}</th>
                <th className="hardware-table-header-item">{Languages.getTranslation('price', true)}</th>
                <th className="hardware-table-header-item">{Languages.getTranslation('total', true)}</th>
                <th className="hardware-table-header-item no-border-right"></th>
            </tr>
            </thead>
            <tbody>
            {data.map((item, i) => {
                return (
                    <tr key={i} className="hardware-table-row-item">
                        <td className="hardware-table-item hardware-table-item-first-item">{i + 1}</td>
                        <td className="hardware-table-item">
                            <img
                                className="hardware-table-item_img"
                                src={item?.media?.images[0]?.small}
                                alt={item.article}
                            />
                        </td>
                        <td className="hardware-table-item__name">
                            <div className="hardware-table-item__subText">
                                <a
                                    className="hardware-table-item__subText_link"
                                    href={
                                        curLang === LANGS.UA
                                            ? `https://kronas.com.ua/ua/api/v2/product/link/${item.vendor_code}`
                                            : `https://kronas.com.ua/api/v2/product/link/${item.vendor_code}`
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {Languages.getAvaliableTranslation(item.translations) ||
                                        item.name}
                                </a>
                            </div>
                        </td>
                        <td className="hardware-table-item">{item?.vendor_code}</td>
                        <td className="hardware-table-item">
                            <div>
                                {/* Кнопка уменьшения значения */}
                                <button
                                    className="hardware-table-item__button"
                                    onClick={() => handleCountChange(i, Math.max(1, item.count - 1), item)}
                                    style={{marginRight: '8px'}}
                                >
                                    -
                                </button>
                                {/* Поле ввода */}
                                <input
                                    className="hardware-table-item__default-input-small"
                                    type="text"
                                    value={item.count}
                                    pattern="^\d*$"
                                    maxLength="5"
                                    onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const numericValue = rawValue.replace(/\D/g, '');
                                        const validatedValue = numericValue === '0' ? '1' : numericValue;

                                        handleCountChange(i, parseInt(validatedValue || '1', 10), item);
                                    }}
                                />
                                {/* Кнопка увеличения значения */}
                                <button
                                    className="hardware-table-item__button"
                                    onClick={() => handleCountChange(i, Math.min(99999, item.count + 1), item)}
                                    style={{marginLeft: '8px'}}
                                >
                                    +
                                </button>
                            </div>
                        </td>
                        <td className="hardware-table-item">{item?.info?.price.toFixed(2)}</td>
                        <td className="hardware-table-item">{(item?.count*item?.info?.price.toFixed(2)).toFixed(2)}</td>

                        <td style={{width: '8%'}} className="hardware-table-item">
                            <div className="hardware-table-item__btns">
                                <button
                                    className={`btn`}
                                    style={{marginRight: '10px'}}
                                    onClick={() =>
                                        setSubmitEdit({
                                            is: true,
                                            name:
                                                Languages.getAvaliableTranslation(
                                                    item.translations
                                                ) || item.name,
                                            item: item,
                                        })
                                    }
                                >
                                    <i className="btn__icon-edit"></i>
                                </button>
                                <button
                                    className={`btn`}
                                    onClick={() =>
                                        setSubmitDelete({
                                            is: true,
                                            name:
                                                Languages.getAvaliableTranslation(
                                                    item.translations
                                                ) || item.name,
                                            index: item.index,
                                        })
                                    }
                                >
                                    <i className="btn__icon-delete"></i>
                                </button>
                            </div>
                        </td>

                    </tr>
                )
            })}
            </tbody>
        </table>
    );
};
export default HardwareTable