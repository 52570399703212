import ACTIONS from "./actions";

const switchStateForContourEdgeHelper = ({ show, edge, cursorPosition}) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.ContourEdgeHelperStateSwitch({ show: show, edge: edge, cursorPosition: cursorPosition }));
    } catch (e) {
      console.log(e);
    }
  };
};

export default {
  switchStateForContourEdgeHelper,
};
