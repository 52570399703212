import React, {useState, useEffect} from "react";

const Checkbox = ({param, handleChange}) => {
  const [checked, setChecked] = useState(!!param.value)

  const update = (e) => {
    setChecked(e.target.checked)
    param.onChange(e.target.checked)
    handleChange()
  }

  useEffect(() => {
    setChecked(!!param.value)
  }, [param.value]);

  return (
  <>
    <input
        className={`paramIcon__ext`}
      checked={checked}
        onChange={ (e) => {
          update(e);
          handleChange(e)
        }}
      disabled={param.disabled ?? false}
        type='checkbox'
        name={param.name}
        id={`paramIcon__${param.name}`}
    />
      <label htmlFor={`paramIcon__${param.name}`}></label>
  </>

  )
}

export default Checkbox