import Processing from "./3D/Processing";
import templates from './templatesConfig.json';
import templatesTableTop from './templatesTableTopConfig.json';
import templatesHandle from './templatesHandleConfig.json';
import {empty, isNumber, isset} from "../helpers/helper";
import isObject from "lodash.isobject";
import Languages from "../translation/Languages";

/**
 * A class representing templates for processing items.
 * @extends Processing
 */


export default class Templates extends Processing {
	_additionalTreats = {}
	_templateId
	_subType = "template"
	_isActive = false;
	_additionalSide = 'left'
	_edgeSide = 'left';
	_template = null;
	direction = 'ver';
	fields = ['template'];
	fieldsTree = {};
	_name = '';
	_templateData = {};
	generateTimeOut = null;
	isLoad = false;
	_detailW = 18;
	isTableTop = false;
	templateType = 'holes';
	shouldUpdateProcessing = true;

	constructor({
								detail,
								comment,
								isErrorText = '',
								id,
		            templateId = null,
		            templateType = null,
		            templateData = {},
		            additionalTreats = {},
								name = '',
								isLoad = false,
		            isTableTop = false,
		            isInit = false
	            }) {
		super({detail, id, comment, isErrorText, isInit});
		this._additionalTreats = additionalTreats;
		this._name = name;
		this.isLoad = isLoad;
		Object.defineProperty(this, 'detailW', {
			get () { return this._detailW},
			set (val) { this._detailW = val },
			configurable: true
		})
		this.detailW = this.detail.w;
		this.isTableTop = isTableTop || templateType === "tableTop"
		this.templateType = templateType;

		if(empty(this.templateType)) {
			if(templateId === "tableTop") {
				this.templateType = 'tableTop';
			}
		}

		if(!empty(templateId)) {
			const template = this.getTemplates().find(el => el.templateId === templateId);
			if(!empty(template?.templateId)) {
				this.isNew = false;
				this.templateData = templateData;
				this.template = template.templateId;
			} else {
				this.detail.setError(`${Languages.getTranslation('template', true)} ${templateId} ${Languages.getTranslation('not-found2', true)}!`)
			}
		} else {
			this.isEdit = true;
			this.addParams();
		}
		this.initRequiredFields()
	}


	/**
	 * Initializes the required fields for the object.
	 *
	 * @function initRequiredFields
	 * @memberof Object
	 *
	 * @description
	 * This function initializes the requiredFields object of the parent object.
	 *
	 * @returns {undefined} - This method does not return a value.
	 */
	initRequiredFields () {
		this.requiredFields = {template: true}
		// if (this.templateId === 'tableCorner') {
		// 	this.requiredFields.r = {
		// 		min: 80,
		// 		max: Math.min(this.detail.h, this.detail.l) - 15
		// 	}
		// }
	}

	getTemplates() {
		let usedTemplates;
		switch (this.templateType) {
			case 'tableTop':
				usedTemplates = templatesTableTop;
				break;
			case 'handles':
				usedTemplates = templatesHandle;
				break;
			default:
				if(this.isTableTop || ['tableTop', 'tableCorner'].includes(this.templateId)) {
					usedTemplates = templatesTableTop;
				} else {
					usedTemplates = templates;
				}
				break;
		}
		return usedTemplates;
	}

	/**
	 * A method to add parameters to a form field.
	 *
	 * @function addParams
	 *
	 * @param {Array} templates - An array of templates.
	 *
	 * @returns {void}
	 */
	addParams() {
		// if(this.isInit) return;
		const types = this.getTemplates().filter(el => el.usedFor.includes(this.detail.materialType)).map(el => {return {key: el.templateId, value: el.name}});
		let variables = []
		if (this.template) {
			variables = [...types]
		} else {
			variables = [
				{key: "", value: Languages.getTranslation('choose-proc', true)},
				...types
			]
		}
		this.setFormField({
			name: 'template',
			value: this.template?.templateId ?? '',
			label: Languages.getTranslation('type-of-proces', true),
			type: 'select',
			variables,
		})
	}

	get template() {
		return this._template
	}

	/**
	 * Sets the template for the current object.
	 *
	 * @param {string} template - The type of template to set.
	 */
	set template(template) {
		if(!this.isLoad) {
			this.clearTemplate();
			this.templateData = {};
		}
		this._template = this.getTemplates().find(el => el.templateId === template);
		this.templateId = this.template?.templateId;
		this.name = this.template?.name;
		this.addParams();
		this.prepareFieldsList();
		if(!empty(this.templateId)) {
			this.genFields({...this.template.fields}, 1)
		}
		this.initRequiredFields()
	}

	/**
	 * Clears the template by removing all fields and resetting the fields array and fieldsTree object.
	 *
	 * @return {void}
	 */
	clearTemplate() {
		// this.dropFields();
		if(!empty(this.fields)) {
			this.fields.forEach(el => {
				this.removeField(el)
			})
		}
		this.fields = [];
		this.fieldsTree = {};
	}

	/**
	 * Prepares the fields list based on the own generated values.
	 *
	 * It iterates through the template fields and constructs a fields tree based on the own generated values.
	 * The fields tree structure is defined as an object, where each key represents a field and its value is an array of fields that depend on it.
	 * If a field has no dependencies, the value will be an empty array.
	 * Fields that are not included in the fields tree will be removed from the final fields list using the `removeField()` method.
	 *
	 * @returns {void}
	 */
	prepareFieldsList() {
		this.fieldsTree = {};
		// console.log(this.ownGeneratedValues);
		for(const [field, data] of Object.entries(this.template.fields)) {
			if(this.ownGeneratedValues.includes(field)) {
				this.fieldsTree[field] = []
				if(!empty(data?.values?.[this[field]]?.fields)) {
					for(const [field1, data1] of Object.entries(data.values[this[field]].fields)) {
						if(this.hasOwnProperty(field1)) {
							this.fieldsTree[field].push(field1)
							if(!empty(data1?.values?.[this[field1]]?.fields)) {
								for(const [field2, data2] of Object.entries(data1.values[this[field1]].fields)) {
									if(this.hasOwnProperty(field2)) {
										this.fieldsTree[field].push(field2)
									}
								}
							}
						}
					}
				}
				const arr = [...this.fields.filter(el => !this.fieldsTree[field].includes(el))];
				const topLevelFields = Object.keys(this.template.fields);
				if(!empty(arr)) {
					arr.forEach(el => {
						if(el !== field && el !== 'template' && !topLevelFields.includes(el)) this.removeField(el)
					})
				}
			}
		}
	}

	/**
	 * Finds fields in the template based on the provided key.
	 * If the key is empty, returns all fields in the template.
	 *
	 * @param {string} key - The key to search for in the template fields.
	 * @returns {object|null} - The fields that match the provided key, or null if not found.
	 */
	findFields(key) {
		if(empty(this.template)) return null;
		if(empty(key)) {
			return this.template.fields;
		}
		const find = (key) => {
			for (const field in this.fieldsTree) {
				if(empty(this.fieldsTree[field]) || this.fieldsTree[field].includes(key)) {
					return field
				}
			}
		}

		// let i = 0;
		const topKey = find(key);
		const fieldsArr = this.fieldsTree[topKey];

		const checkNext = (__fields, i) => {
			return!empty(__fields?.[fieldsArr[i]]?.values?.[this[fieldsArr[i]]]?.fields);
		}


		const reqFind = (fields, i) => {
			if(empty(fieldsArr)) {
				if(this.hasOwnProperty(key) && !empty(fields?.[key]?.values?.[this[key]]?.fields)) {
					return fields[key].values[this[key]].fields
				}
				else {
					return null
				}
			} else {
				const _fields = i === 0
					? fields?.[topKey]?.values?.[this[topKey]]?.fields?.[key]?.values?.[this[key]]?.fields
					: fields?.[fieldsArr[i]]?.values?.[this[fieldsArr[i]]]?.fields;
				if (this.hasOwnProperty(fieldsArr[i])) {
					const nextLevel = checkNext(_fields, i + 1);
					// // i++;
					if(nextLevel) {
						return reqFind(_fields, i + 1);
					} else
					if(!empty(_fields)) {
						return _fields
					} else {
						return null
					}
				} else {
					return null;
				}
			}
		}
		return reqFind(this.template.fields, 0);
	}

	/**
	 * Removes a field from the object and drops it from the fields list.
	 *
	 * @param {string} key - The key of the field to be removed.
	 * @return {undefined}
	 */
	removeField(key) {
		delete this[key];
		this.dropField(key)
	}

	toggleField(name, show = true) {
		if(this.hasOwnProperty(name) && !empty(this._formFields?.[name])) {
			this.setFormField({name: name, visible: show})
		}
 	}

 toggleAdditionalField(name, show) {
		switch (name) {
			case 'offset':
			case 'offsetEdge':
				if(show) {
					this.setFormField({name: name, additionalParam : [
							{name: 'center', label: Languages.getTranslation('center-detail', true), callback: () => {
									this.setCenterOffset();
								}}
						]})
				} else {
					this.setFormField({name: name, additionalParam: []})
				}
				break;
			default:
				return
		}

 }

	setCenterOffset() {
		let width = 0, height = 0;
		this.detectDirection();
		if(this.count > 1) return;
		for (const [key, value] of Object.entries(this.template.processing)) {
			value.forEach(el => {
				if(!empty(el?.width)) {
					width = Math.max(width, el.width)
				}
				if(!empty(el?.height)) {
					height = Math.max(height, el.height)
				}
			})
		}
		if(this.direction === 'hor') {
			this.offset = this.detail.l / 2 - height / 2
		} else {
			this.offset = this.detail.h / 2 - height / 2
		}
	}

	setCenterOffsetEdge() {
		let width = 0, height = 0;
		this.detectDirection();
		for (const [key, value] of Object.entries(this.template.processing)) {
			value.forEach(el => {
				if(!empty(el?.width)) {
					width = Math.max(width, el.width)
				}
				if(!empty(el?.height)) {
					height = Math.max(height, el.height)
				}
			})
		}
		if(this.direction === 'hor') {
			this.offsetEdge = this.detail.h / 2 - height / 2
		} else {
			this.offsetEdge = this.detail.l / 2 - height / 2
		}
	}

	/**
	 * Adds a field to the object with the specified key and data.
	 * If the field already exists, it updates its value and performs necessary actions.
	 * If not, it creates the field, sets its initial value, and performs necessary actions.
	 *
	 * @param {Object} field - The field to add or update.
	 * @param {string} field.key - The key/name of the field.
	 * @param {Object} field.data - The data related to the field.
	 * @param {string} field.data.label - The label of the field.
	 * @param {Object} field.data.values - The values of the field (for select type).
	 * @param {string} field.data.default - The default value of the field.
	 * @param {number} level - The level of the field.
	 *
	 * @return {void}
	 */
	addField({key, data}, level) {
		if(!this.hasOwnProperty(key)) {
			Object.defineProperty(this, key, {
				get () { return this[`_${key}`]},
				set (val) {
					let value = isNumber(val) ? Number(val) : val;
					switch (key) {
						case 'count':
							const max = this.calcMaxCount();
							value = parseInt(value);
							if(value > max) value = max;
							this.toggleAdditionalField('offset', value === 1)
							break
						case 'offset':
						case 'offsetEdge':
							let valueWithComma = String(value);
							if (valueWithComma.includes(',')) {
								valueWithComma = valueWithComma.replace(',', '.');
							}
							valueWithComma = Number(valueWithComma)
							const _v = Math.abs(parseFloat(valueWithComma));

							const maxVal = this.calcMaxOffset(key);
							value = !empty(_v) ? _v : data.default;
							if(value > maxVal && this.shouldUpdateProcessing) value = maxVal;
							break
						case "r":
							value = parseInt(value);
							break
					}

					if(value !== this.templateData?.[key]) {
						this.isLoad = false;
					}
					this[`_${key}`] = value;
					this.updateTemplateData({key, value})
					this.prepareFieldsList();
					switch (key) {
						case 'offset':
							const addParams = this?.count && this.count === 1 ? [
									{name: 'center', label: Languages.getTranslation('center-detail', true), callback: () => {
											this.setCenterOffset();
										}}
								] : []
							this.setFormField({name: key, value: value, additionalParam : addParams});
							break;
						case 'offsetEdge':
							const addParamsOffsetEdge =  [
									{name: 'center', label: Languages.getTranslation('center-detail', true), callback: () => {
											this.setCenterOffsetEdge();
										}}
								]
							this.setFormField({name: key, value: value, additionalParam : addParamsOffsetEdge});
							break;
						default:
							this.setFormField({name: key, value: value});
					}

					const fields = this.findFields(key);
					if(!empty(fields)) {
						this.genFields(fields, level + 1);
					}
					if(this.shouldUpdateProcessing) {
						if (this.generateTimeOut) {
							clearTimeout(this.generateTimeOut);
						}
						this.generateTimeOut = setTimeout(() => {
							this.genTemplateProcessing()
								.then(() => this.buildDetail())
								.then(() => this.renderDetail())
						}, 100)
						// }
					} else {
						this.buildDetail()
							.then(() => this.renderDetail())
					}

				},
				configurable: true
			})


			// if(key === 'edge') {
			// 	data.values = {}
			// 	data.values[null] = {
			// 		name: '',
			// 		fields: null,
			// 	}
			// 	this.detail.parent.edges.forEach(el => {
			// 		data.values[el.index] = {
			// 			name: edgeTitle(el),
			// 			fields: null,
			// 		}
			// 	})
			// 	// data.values = sortArray(data.values)
			// }
			let type = "text";
			if (key === 'r') {
				type = 'number';
			}
			const variables = [];
			if(!empty(data.values) && isObject(data.values)) {
				type = 'select';
				for(const [k, v] of Object.entries(data.values)) {
					if (this.getExpressionsForGenerateSelectOptions(data, k)) {
						variables.push({key: k, value: v.name});
					}
				}
			}

			this.setFormField({
				name: key,
				value: this[key],
				label: data.label,
				type: data.name === 'infoText' ? 'infoText' : type,
				variables: variables,
				visible: true
			})

			this.fields.push(key);
			this[key] = this.templateData?.[key] ?? data.default;
		}
	}

	/**
	 * Returns expressions for generating select options.
	 *
	 * @param {Object} data - The data object.
	 * @param {string|number} value - The value to be checked.
	 * @returns {boolean} - Returns true if the conditions are met, otherwise false.
	 */
	getExpressionsForGenerateSelectOptions(data, value) {
		if (this._template.name === 'Радіусний кут стільниці' && data.label === Languages.getTranslation('radius', true)) {
			return Number(value) < this.detail.l && Number(value) + 8 < this.detail.h
		}
		return true
	}

	/**
	 * Generate fields and add them to the specified level.
	 *
	 * @param {Object} fields - The fields object.
	 * @param {number} level - The level at which the fields should be added.
	 * @return {void}
	 */
	genFields(fields, level) {
		// if(this.isInit) return;
		for(const [key, value] of Object.entries(fields)) {
				this.addField({key, data: value}, level)
		}

	}


	/**
	 * Checks if the current object has valid conditions.
	 *
	 * @return {boolean} - True if the conditions are valid, false otherwise.
	 */
	get hasValidConditions() {
		const condition = this.templateId !== 'screed-tableTop';

		return condition
	};

	getProcessingValue(value, key, from0 = true, data={}) {
		let _value = value;
		if(isNumber(value)) {
			switch (key) {
				case 'x':
					if(this.hasOwnProperty('offset') && this.direction === 'hor') {
						_value = this.offset;
					} else {
						_value = _value < 0 && from0 ? this.detail.l + _value : Math.abs(_value);
					}
					break;
				case 'y':
					if(this.hasOwnProperty('offset') && this.direction === 'ver') {
						_value = this.offset;
					} else {
						_value = _value < 0  && from0 ? this.detail.h + _value : Math.abs(_value);
					}
					break;
				case 'z':
				case 'start':
					_value = _value < 0 && from0 ? this.detail.w + _value : Math.abs(_value);
					break
			}
			return _value;
		} else if(typeof value === "string"){
			if(value.indexOf('%') !== -1) {
				const _t = value.split('%');
				const _percent = Number(_t[0]);
				const _delta = Number(_t[1]) || 0;
				switch (key) {
					case 'x':
						const width = data?.width || 0
						_value = from0 || _percent > 0 ?
							this.detail.l * (_percent / 100) :
							this.detail.l - this.detail.l * (_percent / 100);
						if(_percent === 100 && width) _value -= width;
					break	;
					case 'width':
						_value = this.detail.l * (_percent / 100);
						break;
					case 'y':
						const height = data?.height || 0
						_value = from0 || _percent > 0? this.detail.h * (_percent / 100) : this.detail.h - this.detail.h * (_percent / 100);
						if(_percent === 100 && height) _value -= height;
						break;
					case 'height':
						_value = this.detail.h * (_percent / 100);
						break;
					case 'z':
					case 'depth':
					case 'start':
						_value = from0 || _percent > 0? this.detail.w * (_percent / 100) : this.detail.w - this.detail.w * (_percent / 100);
						break
				}
				return _value + _delta
			} else {
				const _t = value.split('_')
				const _delta = Number(_t[1]) || 0;
				let field = _t[0];
				let isFromOposit = false;
				if(_t[0].indexOf('-') === 0) {
					isFromOposit = true;
					field = _t[0].slice(1)
				}
				if(this.hasOwnProperty(field)) {
					_value = Number(this[field]) || 0
					if(!empty(_value)) {
						if(isFromOposit && from0) {
							switch (key) {
								case 'x':
									_value = this.detail.l - _value;
									break;
								case 'width':
									_value = this.detail.l - _value;
									break;
								case 'y':
									_value = this.detail.h - _value;
									break
								case 'height':
									_value = this.detail.h - _value;
									break;
								case 'z':
								case 'depth':
									_value = this.detail.w - _value;
									break
							}
						}
					}
					return _value + _delta
				} else {
					return value
				}
			}
		} else {
			return value
		}
	}

	sortProcessObject(obj) {
		const order = ['x', 'y', 'z','width', 'height'];
		const sortedObj = {};
		order.forEach(key => {
			if (obj.hasOwnProperty(key)) {
				sortedObj[key] = obj[key];
			}
		});
		Object.keys(obj).forEach(key => {
			if (!sortedObj.hasOwnProperty(key)) {
				sortedObj[key] = obj[key];
			}
		});
		return sortedObj
	}

	/**
	 * Generates a processing item based on given data, own data, and iteration.
	 *
	 * @param {Object} data - The data object to generate the processing item from.
	 * @param {Array} ownData - The own data array to include in the processing item.
	 * @param {number} iteration - The number of iterations.
	 * @returns {Object} The generated processing item.
	 */
	generateProcessingItem(data, ownData, iteration) {
		let processing = {
			id: this.genId(),
			dataForConstructor: {}
		};
		data.detailW = this.detailW;
		this.detectDirection();
		const usableKeys = ['x', 'y', 'z', 'side', 'subType', 'r','r1','r2','r3','r4', 'x_hor', 'y_ver', 'x_ver', 'y_hor',
			'diam', 'ext', 'width', 'height', 'depth', 'type', 'edgeSide', 'additionalSide', 'compactLockType', 'start'];
		for(const field in data) {
			if(usableKeys.includes(field)) {
				let value = this.hasOwnProperty(field) && !empty(this[field]) && empty(data[field]) ? this[field] : data[field];
				switch (field) {
					case 'depth':
						if(!isNumber(value) && value === '100%') {
							value = this.detail.w
						}
						break;
					case 'z':
						if(!isNumber(value) && value === 'center') {
							value =  this.detail.w / 2;
						} else {
							if(this.side === 'front') {
								value = value >= 0 ? value : this.detail.w + value;
							} else {
								value = Math.abs(value);
							}
						}
						break;

					case 'r1':
					case 'r2':
					case 'r3':
					case 'r4':
						processing.dataForConstructor[field] = Number(value)
						break;
					default:
						value = this.getProcessingValue(value, field)
						break
				}
				processing[field] = value
			}
		}

		if(!empty(ownData)) {
			ownData.forEach(el => {
				if(usableKeys.includes(el)) {
					processing[el] = this[el];
				}
			})
		}

		const isLast = !empty(this.count) ? iteration === this.count - 1 : true;
		const isFirst = iteration === 0;
		const isHalf = iteration >= this.count / 2;


		for(const [field, values] of Object.entries(data.values)) {
			if(this.hasOwnProperty(field) && !empty(values[this[field]])) {
				const sortedObject = this.sortProcessObject(values[this[field]])
				for(const [key, value] of Object.entries(sortedObject)) {
					// if(isNumber(value)) {
						let val;
						switch (key) {
							case 'y':
								if (['rabbet', 'cutOut'].includes(processing.subType)) {
									val = this.getProcessingValue(value, key, true, sortedObject);
								} else {
									val = this.getProcessingValue(value, key, false, sortedObject)
									if(isNumber(value)) {
										processing.dataForConstructor.y_axis = value >= 0 ? 'bottom' : 'top';
									} else {
										processing.dataForConstructor.y_axis = value.indexOf('-') !== 0 ? 'bottom' : 'top';
									}
								}
								processing[key] = val;
								break;
							case 'x':
								val = this.getProcessingValue(value, key);
								if (['rabbet', 'cutOut'].includes(processing.subType)) {
									val = this.getProcessingValue(value, key, true, sortedObject);
								} else {
									val = this.getProcessingValue(value, key, false, sortedObject)
									if(isNumber(value)) {
										processing.dataForConstructor.x_axis = value >= 0 ? 'left' : 'right';
									} else {
										processing.dataForConstructor.x_axis = value.indexOf('-') !== 0 ? 'left' : 'right';
									}

								}
								processing[key] = val;
								break;
							case 'z':
								processing[key] = this.getProcessingValue(value, key, this.side !== 'front', sortedObject);
								break;
							case 'y_hor':
								if(this.direction === 'hor') {
									processing.y += Number(value);
									processing[key] = Number(value);
								}
								break;
							case 'y_ver':
								if(this.direction === 'ver' && isFirst) {
									processing.y += Number(value);
									processing[key] = Number(value);
								} else {
									processing[key] = Number(value);
								}
								break;
							case 'x_hor':
								if(this.direction === 'hor' && isFirst) {
									processing.x += Number(value);
									processing[key] = Number(value);
								} else {
									processing[key] = Number(value);
								}
								break;
							case 'x_ver':
								if(this.direction === 'ver') {
									processing.x += Number(value);
									processing[key] = Number(value);
								}
								break;
							default:
								processing[key] = this.getProcessingValue(value, key, true, sortedObject);

						}
				}
			}
		}

		if(iteration > 0 && !empty(data.step)) {
			processing = {...processing, ...this.updateStepValues({
					...processing,
					step: data.step
				}, iteration)}
		}
		if(this.direction === 'hor' && !empty(processing.x_hor) && !isFirst) {
			// if(!isHalf) {
			// 	processing.x += processing.x_hor
			// }
			// else {
				processing.x += processing.x_hor
			// }

		}
		if(this.direction === 'ver' && !empty(processing.y_ver ) && !isFirst ) {
		// if(!isHalf) {
				processing.y += processing.y_ver
		// 	}
		// else {
		// 		processing.y -= processing.y_ver
		// 	}

		}

		// delete processing.step
		return processing
	}


	/**
	 * Update the step values for processing.
	 *
	 * @param {Object} processing - The processing object containing the relevant properties.
	 * @param {number} i - The current iteration index.
	 * @return {Object} - The updated x and y values.
	 */
	updateStepValues(processing, i) {
		const {
			x,
			y,
			step,
			subType,
			height = null,
			width = null,
			p_count = 1,
			y_ver,
			x_hor
		} = processing;

		const calcStep = (value, _step, delta) => {
			let step ;
			const len = this.direction === 'hor' ? this.detail.l : this.detail.h
			if(Number.isInteger(_step)) {
				step = _step - delta;
			} else {
				const cond0 = _step.split('%');
				const cond1 = _step.split('on');
				if(cond0.length > 1) {
					const _val = this.count || Number(cond0[0]);
					switch (cond0[1]) {
						case 'ofLength':
							step = (this.width * _val) / 100 + delta;
							break;
						case 'ofSide':
							step = this.direction === 'hor'? (this.detail.l * _val) / 100 + delta : (this.detail.h * _val) / 100 + delta
							break;
					}
				} else if(cond1.length > 1) {
					const _val = this.count - 1 || Number(cond1[0]) - 1;

					const _value = value + delta;
					switch (cond1[1]) {
						case 'Length':
							let l = this.width ;
							if(empty(l)) {
								l = len
							}
							if(subType === 'cutOut' && this.additionalSide === 'right' ) {
								if (['compactLock1', 'compactLock2', 'compactLock3'].includes(this.templateId)) {
									step = - ( l - (len - _value) * 2 ) / _val;
								} else {
									step = - ( l - (len - _value + width) * 2 ) / _val;
								}

							} else {
								step = ( l - _value * 2 ) / _val;
							}
							// if (['compactLock1', 'compactLock2', 'compactLock3'].includes(this.templateId) && ['top', 'bottom'].includes(this._templateData.edgeSide) && this.templateData.additionalSide === 'right') {
							//
							// } else {

							// }
							break;
						case 'Side':
							step =  (len - _value * 2) / _val;
							break;
					}
				}
			}
			return  value + step * i;
		}

		let delta = 0;
		let last = i + 1 === this.count;
		switch (this.direction) {
			case 'hor':
				if(!['hole'].includes(subType) && !empty(width)) {
					if(subType !== 'cutOut' || subType === 'cutOut' && !['compactLock1', 'compactLock2', 'compactLock3'].includes(this.templateId)) {
						delta = width / 2;
						if (!empty(x_hor) && !last) delta += x_hor
					}
				}
				if(last && x_hor) delta += x_hor
				return {x: calcStep(x, step.x, delta), y}
			case "ver":
				if(!['hole'].includes(subType) && !empty(height)) {
					if(subType !== 'cutOut' || subType === 'cutOut' && !['compactLock1', 'compactLock2', 'compactLock3'].includes(this.templateId)) {
						delta = height / 2;
						if(!empty(y_ver) && !last) delta += y_ver
					}

				}
				if(last && y_ver) delta += y_ver
				return {x, y: calcStep(y, step.y, delta)}
		}
	}

	/**
	 * Determines the direction based on the edge side.
	 * @returns {string} The direction, either 'ver' or 'hor'.
	 */
	detectDirection() {
		// if(this.getOwnPropertyDescriptor('direction').get) {
		// 	return this.direction;
		// } else {
			switch (this.edgeSide) {
				case "left":
				case "right":
					this.direction = 'ver';
					break;
				default:
					this.direction = 'hor';
			}
		// }

	}


	/**
	 * Retrieves the names of the own properties which have getter methods defined in the current object.
	 *
	 * @returns {Array} An array containing the names of the own properties with getter methods.
	 */
	get ownGeneratedValues() {
		const ownValuesObject = Object.getOwnPropertyDescriptors(this)
		return 	Object.keys(ownValuesObject).filter(key => ownValuesObject[key].get)
	}

	/**
	 * Returns the maximum side length of a shape.
	 *
	 * @returns {number} The maximum side length.
	 */
	getCurrentMaxSideLength(value) {
		if (value === 'offsetEdge') return ['left', 'right'].includes(this.edgeSide) ? this.detail.l : this.detail.h;
		return ['left', 'right'].includes(this.edgeSide) ? this.detail.h : this.detail.l;
	}

	/**
	 * Calculates the maximum count of items that can fit within the current maximum side length,
	 * taking into account an offset and a minimum distance between items.
	 *
	 * @returns {number} The maximum count of items that can fit.
	 */
	calcMaxCount() {
		const sideSize = this.getCurrentMaxSideLength();
		const offset = this.offset ?? 0;
		let usableDistance = Math.abs(sideSize - offset * 2);
		const minDistance = this.template?.minDistance || 64;
		return Math.floor(usableDistance / minDistance) || 1;
	}

	/**
	 * Calculates the maximum offset based on the current maximum side length.
	 * @returns {number} The maximum offset calculated.
	 */
	calcMaxOffset(value) {
		const sideSize = this.getCurrentMaxSideLength(value);
		return sideSize - 20;
	}


	/**
	 * Generates template processing items.
	 *
	 * @returns {Promise} - A promise that resolves when the template processing is complete.
	 * @throws {Error} - If an error occurs during the template processing.
	 */
	genTemplateProcessing() {
		// console.log(`genTemplateProcessing: isLoad=${this.isLoad}`)
		if(!this.isEdit) return Promise.resolve();
		// console.log('genTemplateProcessing: delete')
			return this.updateAdditionalTreatments('delete')
				.then(() => {
					for(const [key, data] of Object.entries(this.template.processing)) {
						data.forEach(el => {
							const count = this.count || el.count;
							for(let i = 0; i < count; i++ ) {
								const processing = this.generateProcessingItem(el, this.ownGeneratedValues, i);
								processing.isErrorText = '';
								processing.isTemplate = this.id;
								const procc = this.detail[this.getProcessingClass(processing.subType)](processing);

								this.addAdditionalTreats({type: procc.subType === 'tableProc' ? 'mill' : procc.subType, id: procc.id});
							}
						})
					}
					return Promise.resolve();
				})
				.catch(err => console.log(err))
	}

	/**
	 * Sorts the form fields based on the given sorting order.
	 *
	 * @return {Array} - The sorted form fields.
	 */
	get paramsSorting() {
		const sortingArr = [...this.fields, 'comment']
		return 	this.formFields.sort((a, b) => sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
	}

	/**
	 * Retrieves all treatments based on additional treatments IDs.
	 * Treatments are retrieved from the "holes", "grooves", "mills", and "corners" arrays in the "detail" object.
	 * @returns {Array} - An array containing all the treatments.
	 */
	getTreatments() {
		const treatments = [];
		if(empty(this.additionalTreats)) return [];
		for(const[key, value] of Object.entries(this.additionalTreats)) {
			if(empty(value) || !Array.isArray(value)) continue;
			value.forEach(id => treatments.push(this.detail[key].find(el => el.id === id)))
		}
		return treatments.filter(el => isset(el));
	}

	deleteTreatments(fromDb = false) {
		return new Promise((resolve, reject) => {
			const keys = Object.keys(this.additionalTreats);
			const deleteT = () => {
				const key = keys.pop()
				if(!empty(key)) {
					if(!empty(this.additionalTreats[key])) {
						this.detail[key] = this.detail[key].filter(item => !this.additionalTreats[key].includes(item.id))
							this.detailDb.deleteMultipleProcessing(this.getProcessingDbIndex(key), this.additionalTreats[key])
								.then(() => deleteT())
					} else {
						deleteT()
					}
				} else {
					resolve()
				}
			}
			deleteT()
		})
	}

	/**
	 * Updates additional treatments based on the given type.
	 * @param {string} type - The type of update. Possible values are 'delete', 'active', and 'select'.
	 * @param db
	 * @returns {Promise} - A Promise that resolves when the update is complete.
	 */
	updateAdditionalTreatments(type, db=false) {
		switch (type) {
			case 'delete':
				return this.deleteTreatments(db)
					.then(() => {
						this.additionalTreats = {};
						return Promise.resolve()
					})
			// }
			case 'active':
				this.getTreatments().forEach(el => el.isActive = this.isActive)
				return Promise.resolve()
			case 'select':
				this.getTreatments().forEach(el => el.isSelected = this.isSelected)
				return Promise.resolve()
		}
	}

	/**
	 * Returns an object containing data for saving.
	 *
	 * @return {Object} - An object containing the following properties:
	 *  - id: The ID of the data.
	 *  - name: The name of the data.
	 *  - subType: The subtype of the data.
	 *  - type: The type of the data.
	 *  - additionalTreats: Additional treats for the data.
	 *  - comment: The comment for the data.
	 *  - isErrorText: The error text for the data.
	 *  - additionalInfo: Additional information for the data.
	 *  - templateData: The template data for the data.
	 */
	dataForSave() {
		return {
			id: this.id,
			name: this.name,
			subType: this.subType,
			templateId: this.templateId,
			additionalTreats: this.additionalTreats,
			comment: this.comment,
			isErrorText: this.isErrorText,
			additionalInfo: this.additionalInfo,
			templateData: this.templateData,
			templateType: this.templateType
		}
	}

	updateTreatments() {
		// return new Promise((resolve, reject) => {
		// 	const treatments = this.getTreatments();
		// 	const update = () => {
		// 		const tr = treatments.pop();
		// 		if(!empty(tr)) {
		// 			tr.updateDb()
		// 				.then(() => update())
		// 		} else {
		// 			resolve()
		// 		}
		// 	}
		// 	update();
		// })
		if(this.shouldUpdateProcessing) {
			this.getTreatments().forEach(el => {
				return el.updateDb()
			})
		}
		return Promise.resolve()
		// return Promise.all(this.getTreatments().map(el => el.updateDb()))
	}

	/**
	 * Updates the detail database with the templates and treatments data.
	 * If the templates array is empty, it updates the templates detail with an empty array in the database.
	 * Otherwise, it updates the templates detail with the data for all templates in the array, using the dataForSave method for each template.
	 * After updating the templates detail, it updates the treatments detail for each treatment in the getTreatments array.
	 * Finally, it calls the renderDetail method.
	 *
	 * @returns {Promise} A promise that resolves after the detail database has been updated with the templates and treatments data.
	 */
	updateDb(remove = false) {
		if(this.isLoad && !remove) {
			// this.isLoad = false;
			return Promise.resolve(templates)
		}
		return this.detailDb.getProcessing('templates')
			.then(templates => {
				if(!templates) {
					templates = [];
				}

				const index = templates.findIndex(el => el.id === this.id);
				if(index === -1 ) {
					if(!remove) {
						templates.push(this.dataForSave())
					}
				} else {
					if(remove) {
						templates.splice(index, 1)
					} else {
						templates.splice(index, 1, this.dataForSave())
					}

				}
				return Promise.resolve(templates)
			})
			.then(templates => this.detailDb.updateDetail('templates', templates))
			.then(() => {
				if(remove) {
					return this.updateAdditionalTreatments('delete', true)
				} else {
					return this.updateTreatments()
				}
			})
			.then(() => {
				// return Promise.resolve()
				return this.buildDetail()
					.then(() => {
						this.renderDetail()
						 return Promise.resolve()
					})
				// return Promise.resolve()
			})
	}

	updateDataFromDb() {
		return this.detailDb.getProcessing('templates')
			.then(data => {
				const current = data.find(el => el.id === this.id);
				if(current) {
					for(const [key, value] of Object.entries(current)) {
						if(this[key] !== value && key !== 'additionalTreats') {
							if(key === 'templateData'){
								for(const [k, val] of Object.entries(value)){
									if(this[k] !== val){
										this[k] = val;
									}
								}
							}else{
								this[key] = value
							}
						}
					}
				}
				return Promise.all(this.getTreatments().map(el => el.updateDataFromDb()))
			})
			// .then(() => {
			// 	return
			// })

	}

	createTreatmentsCopy() {
		const trArr = [...this.getTreatments()];
		const newTreatments = {};
		return Promise.all(trArr.map(el => el.createCopy()))
			.then(data => {
				data.forEach(el => {
					if(empty(newTreatments[this.getProcessingArrayIndex(el.subType)])) {
						newTreatments[this.getProcessingArrayIndex(el.subType)] = [];
					}
					newTreatments[this.getProcessingArrayIndex(el.subType)].push(el.id);
				})
				return Promise.resolve(newTreatments);
			})
	}

	createCopy() {
		const template = this.dataForSave();
		template.id = this.genId();
		template.isNew = false;
		template.isLoad = true;

		return this.createTreatmentsCopy()
			.then(additionalTreats => {
				template.additionalTreats = additionalTreats;
				return this.detailDb.getProcessing('templates')
					.then(templates => {
						templates.push(template);
						return this.detailDb.updateDetail('templates', templates)
					})
					.then(() => {
						const _template = this.detail[this.getProcessingClass('template')](template)
						_template.isLoad = false;
						return Promise.resolve()
					})
			})
	}

	/**
	 * Removes the current instance.
	 *
	 * @returns {Promise} A Promise that resolves once the instance has been removed.
	 */
	remove() {
		return this.updateDb(true)
			.then(() => {
				const index = this.detail.templates.findIndex(el => el.id === this.id);
				if(index !== -1) {
					this.detail.templates.splice(index, 1);
				}
				return this.buildDetail()
			})
			.then(() => this.renderDetail())
	}

	/**
	 * Retrieves the additional treats for a given object.
	 *
	 * @returns {Array} - An array containing the additional treats.
	 */
	get additionalTreats() {
		return this._additionalTreats
	}

	/**
	 * Sets the additional treats for the object.
	 *
	 * @param {string[]} additionalTreats - An array of additional treats to be set.
	 */
	set additionalTreats(additionalTreats) {
		this._additionalTreats = additionalTreats
	}



	/**
	 * Adds an additional treat to the specified type.
	 *
	 * @param {Object} options - The options for adding additional treats.
	 * @param {string} options.type - The type of the treat.
	 * @param {string} options.id - The ID of the treat to add.
	 *
	 * @return {void}
	 */
	addAdditionalTreats({type, id}) {
		let treats = [id];
		const ind = this.getProcessingArrayIndex(type);
		if(!empty(this.additionalTreats[ind]) ) {
			treats = [...this.additionalTreats[ind], id]
		}
		this.additionalTreats = {...this.additionalTreats, [ind]: treats}
	}

	/**
	 * Returns the type of the object.
	 * @returns {string} The type of the object.
	 */
	get templateId() {
		return this._templateId
	}

	/**
	 * Set the type for the object.
	 *
	 * @param {string} type - The type to be set.
	 */
	set templateId(type) {
		this._templateId = type
	}

	/**
	 * Retrieves the value of the name property.
	 *
	 * @returns {string} The value of the name property.
	 */
	get name() {
		return this._name
	}

	/**
	 * Sets the name of the object.
	 *
	 * @param {string} name - The name to be set.
	 */
	set name(name) {
		this._name = name
	}

	/**
	 * Set the isActive status of the object.
	 *
	 * @param {boolean} active - The new isActive status to be set.
	 */
	set isActive(active) {
		this._isActive = active
		this.updateAdditionalTreatments('active')
			.then(() => this.renderDetail())
	}

	/**
	 * Gets the value of the isActive property.
	 *
	 * @return {boolean} The value of the isActive property.
	 */
	get isActive() {
		return this._isActive;
	}

	/**
	 * Retrieves the template data.
	 *
	 * @returns {any} The template data.
	 */
	get templateData() {
		return this._templateData;
	}

	/**
	 * Sets the template data for the instance.
	 *
	 * @param {object} data - The template data to be set.
	 */
	set templateData(data) {
		this._templateData = data;
	}

	// set detailW(detailW) {
	// 	this._detailW = detailW;
	// }
	//
	// get detailW() {
	// 	return this._detailW;
	// }

	/**
	 * Updates the template data by adding or updating a key-value pair.
	 *
	 * @param {string} key - The key of the data to be updated or added.
	 * @param {*} value - The value to be associated with the key.
	 *
	 * @return {void} - This method does not return anything.
	 */
	updateTemplateData({key, value}) {
		this.templateData = {...this.templateData, [key]: value}
	}

	validate() {
		return Promise.resolve();
	}

	/**
	 * Deletes all processing from the additional treats.
	 *
	 * @return {void} Returns nothing.
	 */
	clearAdditionalTreats() {
		this.additionalTreats = [];
	}

	/**
	 * Removes the template without any additional treats.
	 *
	 * @returns {Promise<void>} - A promise that resolves when the template is successfully removed.
	 */
	removeTemplateWithoutAdditionalTreats() {
		this.clearAdditionalTreats();
		return this.remove()
			.then(_ => Promise.resolve())
			.catch(e => console.error('Error removing template:', e));
	}

	rotateDetail(direction, l, h) {
		if(this.hasOwnProperty('edgeSide')) {
			let newOffset = this.offset;
			const newSide = this.getNextEdgeSideForRotate(direction)
			this.direction = this.direction === 'hor' ? 'ver' : 'hor';
			if(this.hasOwnProperty('count') && this.count === 1 && this.hasOwnProperty('offset')) {
				newOffset = this.offset;
				const x = {min: l, max: 0}, y = {min: h, max: 0};
				for (const [key, value] of Object.entries(this.template.processing)) {
					value.forEach(el => {
						if(el?.values?.edgeSide?.[newSide]) {
							let _xMin = this.offset, _xMax = this.offset, _yMin = this.offset, _yMax = this.offset;
							if(this.direction === 'hor') {
								_xMin = !el?.values?.edgeSide?.[newSide]?.x_hor ? this.offset : this.offset + el?.values?.edgeSide?.[newSide].x_hor;
								_xMax = !el?.values?.edgeSide?.[newSide]?.width ? this.offset : this.offset + el?.values?.edgeSide?.[newSide].width;
							} else {
								_yMin = !el?.values?.edgeSide?.[newSide]?.y_ver ? this.offset : this.offset + el?.values?.edgeSide?.[newSide].y_ver;
								_yMax = !el?.values?.edgeSide?.[newSide]?.height ? this.offset : this.offset + el?.values?.edgeSide?.[newSide]?.height;
							}

							x.min = Math.min(x.min, _xMin)
							x.max = Math.max(x.max, _xMax)
							y.min = Math.min(y.min, _yMin)
							y.max = Math.max(y.max, _yMax)
						}

					})
				}
				if(direction) {
					// console.log(this.edgeSide, newSide, (y.max - y.min))
					if(this.edgeSide === 'top') {
						newOffset = l - this.offset - (y.max - y.min)
					} else if(this.edgeSide === 'bottom') {
						newOffset = l - this.offset - (y.max - y.min)
					}
				} else {
					if(this.edgeSide === 'right') {
						newOffset = h - this.offset - (x.max - x.min)
					} else if(this.edgeSide === 'left') {
						newOffset = h + this.offset - (x.max - x.min)
					}
				}
			}
			this.shouldUpdateProcessing = false;
			if(newOffset !== this.offset) {
				this.offset = newOffset;
			}
			this.edgeSide = newSide;

			return this.updateDb()
				.then(() => {
					this.shouldUpdateProcessing = true;
					return Promise.resolve();
				})




			// return this.deleteTreatments(true)
			// 	.then(() => this.genTemplateProcessing())
			// 	.then(() => {
			// 		this.isEdit = false;
			// 		return Promise.resolve();
			// 	})
		} else {
			return Promise.reject('cant rotate template')
		}

	}
}