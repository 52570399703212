import {useEffect, useState} from "react";
import PrivateTemplate from "views/PrivateTemplate";
import Requests from "api/API";
import {useDispatch} from "react-redux";
import {modalsOperations} from "redux/modals";
import Project from "models/Project";
import {useNavigate} from "react-router-dom";

import Languages from "translation/Languages";
import Loader from "../../components/UI/Loader";
import {panelOperations} from "redux/panel";
import {errorMessageOperations} from '../../redux/errors'
import {toast} from 'react-toastify'
import SubmitModal from "../../components/UI/Modal/SubmitModal";
import {copyText, empty} from "../../helpers/helper";
import {useUpdateTranslations} from "../../hooks/useUpdateTranslations";
import {DownloadIcon} from "../../components/Icons/DownloadIcon";
import {DeleteIcon} from "../../components/Icons/DeleteIcon";
import {Pagination} from "../../components/UI/Pagination";

import "./styles.scss";
import CopyTextComponent from "../../components/UI/CopyTextComponent";

const DEFAULT_FOLDER = {
	id: null,
	name: Languages.getTranslation("new-projects", true),
	parent: null
}
const START_PAGE = 1;

const ProjectHeader = ({setActiveFolder, fetchProjects, setProjects, setMeta}) => {
	const [idValue, setIdValue] = useState("");
	const [orderNumber, setOrderNumber] = useState("");
	const [prevIdValue, setPrevIdValue] = useState(null);
	const [prevOrderNumber, setPrevOrderNumber] = useState(null);
	const dispatch = useDispatch();
	
	const getProjectById = async (id) => {
		const request = await Requests.Projects.getProjectById(id).catch(() =>
			dispatch(errorMessageOperations.switchStateError({
				message: Languages.getTranslation('project-not-found', true),
				type: 'warn',
				show: true
			})));
		
		if (!empty(request) && idValue !== "") {
			setActiveFolder(DEFAULT_FOLDER);
			setProjects([request]);
			setMeta(null)
		} else if (empty(idValue)) {
			fetchProjects()
		}
	};
	const getProjectByOrderNumber = async (order) => {
		const request = await Requests.Projects.getProjectByOrderNumber(order).catch(() =>
			dispatch(errorMessageOperations.switchStateError({
				message: Languages.getTranslation('project-not-found', true),
				type: 'warn',
				show: true
			})));
		
		if (!empty(request) && orderNumber !== "") {
			setActiveFolder(DEFAULT_FOLDER);
			setProjects([request]);
			setMeta(null)
		} else if (empty(orderNumber)) {
			fetchProjects()
		}
	};
	
	const changeHandler = (e) => {
		setIdValue(e.target.value);
	};
	const changeHandlerOrderNumber = (e) => {
		setOrderNumber(e.target.value);
	};
	
	const handleKeyDown = (e, type) => {
		if (e.key === "Enter") {
			if (type === 'id' && idValue !== prevIdValue) {
				setPrevIdValue(idValue);
				getProjectById(idValue);
			} else if (type !== 'id' && orderNumber !== prevOrderNumber) {
				setPrevOrderNumber(orderNumber);
				getProjectByOrderNumber(orderNumber);
			}
		}
	};
	
	return (<div className="projects-page__header project-type">
		<h2 className="project-type__title"> {Languages.getTranslation("my-projects", true)}</h2>
		<div className="project-actions">
			<div className="project-actions__search">
							<span className="project-actions__input-title">
									{`ID ${Languages.getTranslation("of-the-project", true)}`}
								</span>
				<input
					type="number"
					className="project-actions__input"
					value={idValue}
					onChange={(e) => changeHandler(e)}
					onKeyDown={(e) => handleKeyDown(e, 'id')}
				/>
			</div>
			<div className="project-actions__search">
								<span className="project-actions__input-title">
									{Languages.getTranslation("order-number-project", true)}
								</span>
				<input
					type="number"
					className="project-actions__input"
					value={orderNumber}
					onChange={(e) => changeHandlerOrderNumber(e)}
					onKeyDown={(e) => handleKeyDown(e, 'order')}
				/>
			</div>
		</div>
	</div>);
}

const ProjectTable = ({
												projects,
												meta,
												loadProject,
												removeHandler,
												canGo,
												onNextPage,
												onPrevPage,
												updateProjectsPage,
											}) => {
	return (<div className="projects-page__projects">
		{projects.length > 0 ? (
			<>
				<table className="projects-page__projects-table" border="0" cellSpacing="0" cellPadding="0">
					<thead>
					<tr>
						<th>#</th>
						<th>ID</th>
						<th>{Languages.getTranslation('order-numberR', true)}</th>
						<th>{Languages.getTranslation('name', true)}</th>
						<th>{`ID ${Languages.getTranslation('order', true)}`}</th>
						<th>{Languages.getTranslation('Date-of-creation', true)}</th>
						<th></th>
					</tr>
					</thead>
					<tbody>
					{projects.map((project, key) => (
						<tr className="projects-page__projects-tbody-tr" key={key}>
							<td className="align-center"># {key + 1}</td>
							<td className="align-center">№
								<CopyTextComponent textForCopy={project.id}/>
							</td>
							<td className="align-center">
								<CopyTextComponent textForCopy={project.order_number ? project.order_number : "-"}/>
							</td>
							<td>{project.name}</td>
							<td className="align-center">{project.order_id ? project.order_id : "-"}</td>
							<td className="align-center">
								{new Date(project.created_at).toLocaleString()}
							</td>
							<td
								style={{
									textAlign: "right",
									paddingRight: "10px",
								}}
							>
								<button className="projects__btn"
												onClick={() => loadProject(project.id)}
												title={Languages.getTranslation('load-project', true)}>
									<DownloadIcon/>
								</button>
								<button
									className="projects__btn"
									title={Languages.getTranslation('delete', true)}
									onClick={() => removeHandler(project.id)}
								>
									<DeleteIcon/>
								</button>
							</td>
						</tr>
					))
					}
					</tbody>
				</table>
				{meta && <Pagination
					canGo={canGo}
					meta={meta}
					onNextPage={onNextPage}
					onPrevPage={onPrevPage}
					onPageSelect={updateProjectsPage}
				/>}
			</>
		) : <div className='projects__center'>Немає проєктів...</div>}
	</div>)
}

const Folders = ({activeFolder, folders, selectFolder}) => {
	return <div className="projects-page__folders">
		<h3 className="projects-page__folders-title">{Languages.getTranslation("folders", true)}</h3>
		<div className="projects-page__folders-list">
			<div
				onClick={() => selectFolder(DEFAULT_FOLDER)}
				className={activeFolder.id === DEFAULT_FOLDER.id ? "active" : ""}
			>
				{DEFAULT_FOLDER.name}
			</div>
			{folders.map((folder, key) => (
				<div
					key={key}
					onClick={() => selectFolder(folder)}
					className={
						activeFolder.id === folder.id ? "active" : ""
					}
				>
					{folder.name}
				</div>
			))}
		</div>
	</div>
}

const Projects = () => {
	const [folders, setFolders] = useState([]);
	const [activeFolder, setActiveFolder] = useState(DEFAULT_FOLDER);
	const [projects, setProjects] = useState([]);
	const [meta, setMeta] = useState({});
	const navigate = useNavigate();
	const [isPageLoading, setIsPageLoading] = useState(false);
	const [isFoldersLoading, setIsFoldersLoading] = useState(false);
	const [isProjectsLoading, setIsProjectsLoading] = useState(false);
	const [projectId, setProjectId] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const dispatch = useDispatch();
	const [page, setPage] = useState(START_PAGE);
	const [canGo, setCanGo] = useState({
		back: false,
		next: true
	});
	useUpdateTranslations();
	
	const updateProjectsPage = (page) => {
		setPage(page);
	}
	const onNextPage = () => updateProjectsPage(page + 1);
	const onPrevPage = () => updateProjectsPage(page - 1);
	
	useEffect(() => {
		if (meta) {
			setCanGo({
				back: !(meta.current_page === 1),
				next: !(meta.last_page === meta.current_page)
			});
		}
	}, [meta]);
	
	useEffect(() => {
		toast.dismiss()
		dispatch(
			modalsOperations.switchStateForModal({
				show: false,
				type: "",
			})
		);
		dispatch(
			panelOperations.switchStateForPanel({
				show: false
			})
		)
	}, []);
	
	const fetchProjects = async () => {
		setIsProjectsLoading(true)
		Requests.Projects.getUserFoldersById({id: activeFolder.id, page}).then(({data: projects, meta}) => {
			setProjects(projects);
			setMeta(meta);
		}).catch((error) => {
			dispatch(errorMessageOperations.switchStateError(
				{message: error.message, type: 'warn', show: true}))
		}).finally(() => {
			setIsProjectsLoading(false)
		});
	}
	
	const loadProject = async (id) => {
		setIsPageLoading(true)
		Project.loadProject(id, dispatch)
			.then(() => Project.validateProjectDetails())
			.then(() => {
				dispatch(errorMessageOperations.removeAllErrors({message: '', drop: true}))
				setTimeout(() => {
					setIsPageLoading(false)
				}, 500)
				navigate("/details")
			})
			.catch(e => console.log(e));
	};
	
	const removeHandler = (id) => {
		setProjectId(id)
		setShowModal(true)
	}
	
	const remove = async (id) => {
		await Requests.Projects.deleteProject(id).then(() => {
			dispatch(errorMessageOperations.switchStateError(
				{message: Languages.getTranslation('project-delete', true), type: 'success', show: true}));
			fetchProjects();
		}).catch((error) => {
			dispatch(errorMessageOperations.switchStateError(
				{message: error.message, type: 'warn', show: true}))
		});
	};
	
	const selectFolder = (folder) => {
		setActiveFolder(folder);
		updateProjectsPage(START_PAGE);
	}
	
	let content;
	
	if (isFoldersLoading && isProjectsLoading) {
		content = <div className='projects__center'><Loader/></div>;
	} else {
		content = <div className="projects-page__wrapper">
			<Folders
				activeFolder={activeFolder}
				folders={folders}
				selectFolder={selectFolder}
			/>
			{isProjectsLoading ? <div className="projects-page__projects mt-20"><Loader/></div> : <ProjectTable
				projects={projects}
				meta={meta}
				loadProject={loadProject}
				removeHandler={removeHandler}
				canGo={canGo}
				updateProjectsPage={updateProjectsPage}
				onNextPage={onNextPage}
				onPrevPage={onPrevPage}
			/>}
		</div>
	}
	
	// getting folders
	useEffect(() => {
		const getListFolders = async () => {
			setIsFoldersLoading(true)
			Requests.Projects.getUserFolders().then((response) => {
				setFolders((prev) => ([...prev, ...response.data.filter((f) => f.parent === null)]))
			}).catch((error) => {
				dispatch(errorMessageOperations.switchStateError(
					{message: error.message, type: 'warn', show: true}))
			});
			setIsFoldersLoading(false)
		};
		
		getListFolders();
	}, []);
	
	useEffect(() => {
		fetchProjects();
	}, [page, activeFolder]);
	
	return (
		<PrivateTemplate>
			<main>
				{isPageLoading ? <div className="projects">
					<div className='projects__center'><Loader/></div>
				</div> : <div className="projects">
					<ProjectHeader
						setActiveFolder={setActiveFolder}
						setProjects={setProjects}
						setMeta={setMeta}
						fetchProjects={fetchProjects}/>
					{content}
				</div>}
			</main>
			<SubmitModal
				isOpen={showModal}
				setShowSubmitModal={setShowModal}
				submitModalText={Languages.getTranslation('want-to-delete-project', true)}
				submitAction={() => remove(projectId)}
				isRemoveProject={true}
			/>
		</PrivateTemplate>
	);
};

export default Projects;
