import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { modalsOperations } from "redux/modals";
import "./style.scss";
import Project from "models/Project";
import { projectSelectors } from "redux/project";
import { useSelector } from "react-redux";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";
import Loader from "../../Loader";
import {empty} from "../../../../helpers/helper";
import '../../../../components/CreateProject/Materials/style.scss'
import img from "../../../../media/material-add-img-test.svg";
import warning from "../../../../media/warning2.svg";
import download from "../../../../media/download.svg";
import {tempDetailOperations} from "redux/tempDetail";
import MaterialEdgeImg from "../../../CreateProject/Materials/MaterialEdgeImg/MaterialEdgeImg";
import {ModalFooter, ModalHeader} from "../index";
import {Tooltip} from "../../Tooltip";

const MapProject = () => {
  const dispatch = useDispatch();
  const [mapList, setMapList] = useState([]);
  const [messageCutting, setMessageCutting] = useState([]);
  const [messagePart, setMessagePart] = useState([]);
  const [mapListName, setMapListName] = useState([]);
  const [load, setLoad] = useState(true)
  const [isError, setIsError] = useState(null)
  const construction = useSelector(projectSelectors.getConstruction);
  const { tempProject } = useSelector(state => state.tempDetail.property)
  const { materials } = construction

  const getListMaterials = async () => {
    Project.getMapMaterials(dispatch, tempProject)
        .then((response) => {
          if (response && response.data.length) {
            setMapList(response.data.map(item => {
              // return { ...materials.find(el => el.article === item.match('\/([^\/]+)_')[1]), link: item}
              return { ...materials.find(el => el.article === item.match('\\/([^\\/]+)__')[0].replace('__', '').replace('/', '')), link: item}
            }));
            setLoad(false)
            setMessagePart(response.message_part)
            setMessageCutting(response.message_cutting)
            setMapListName(response.data.map(item => item.split('__')[1].split('.')[0]))
          }else{
            setLoad(false)

            setMessagePart(response.message_part)
            setMessageCutting(response.message_cutting)
          }
        })
        .catch(e => {
          setIsError(
              Languages.getTranslation(e.response.data.errors.text, true) === 'Відсутній переклад'
                  ? e.response.data.errors.text
                  : Languages.getTranslation(e.response.data.errors.text, true)
          )
          setLoad(false)
        })
  };

  useEffect(() => {
    getListMaterials();
  }, []);

  const close = () => {
    dispatch(tempDetailOperations.setTempProject({}))
    dispatch(
      modalsOperations.switchStateForModal({
        show: false,
        type: null,
      })
    );
  };
	
	let content;
	
	if (load) {
		content = <div className="mt-20"><Loader /></div>
	} else if (isError) {
		content = <h1 className='align-center'>{`${isError}.`}</h1>
	} else {
		content = <>
			{!empty(messagePart) && (
				<div className='warning-message'>
					<img src={warning} alt="warning" className="warning-icon"/>
					<span className="warning-message-text">{Languages.getAvaliableTranslation2(messagePart)}</span>
				</div>
			)}
			{!empty(messageCutting) && (
				<div className='warning-message'>
					<img src={warning} alt="warning" className="warning-icon"/>
					<span
						className="warning-message-text">{Languages.getAvaliableTranslation2(messageCutting)}</span>
				</div>
			)}
			{!empty(mapList) && (
				
				<div className="table">
					<div className="table__header">
						<div className="table__number-and-name table__number-and-name_edges">
							<div className="table__header-title table__number">
								{Languages.getTranslation("number", true)}
							</div>
							<div className="table__header-title table__name table__name_edges">
								{Languages.getTranslation('name', true)}
							</div>
						</div>
						<div className="table__data-container">
							<div className="table__header-title">{Languages.getTranslation('article', true)}</div>
							<div className="table__header-title">{Languages.getTranslation('height', true)}</div>
							<div className="table__header-title">{Languages.getTranslation('width', true)}</div>
							<div className="table__header-title">{Languages.getTranslation('thickness', true)}</div>
							<div className="table__header-title">{Languages.getTranslation('see', true)}</div>
						</div>
					</div>
					{mapList.length > 0 && mapList.map((item, index) => (
						<div className="table__body" >
							<div className="table__item" key={index}>
								<div className="table__number-and-name table__number-and-name_edges">
									<div className="table__number">{index + 1}</div>
									<MaterialEdgeImg item={item} />
									
									
									<div className="table__name table__name_edges">{mapListName[index]}</div>
								</div>
								<div className="table__data-container">
									<div className="table__data">
										{item.article}
									</div>
									<div className="table__data">
										{item.height}
									</div>
									<div className="table__data">
										{item.width}
									</div>
									<div className="table__data">
										{item.thickness}
									</div>
									<div className="table__data">
										<a href={item.link} target='_blank'>
											<img src={download} alt="download"/>
										</a>
									</div>
								</div>
								<div></div>
							</div>
						</div> ))}
				</div>
			)}
		
		
		
		</>
	}
	
	return (
		<>
			<div className="modal-map-project modal__wrapper">
				<ModalHeader>
          <div className='modal-map-project__header'>
            <h3 className="modal-map-project__title">{Languages.getTranslation('res-map-material', true)}</h3>
            <div className='detailsTab__icon-error pointer'
                 data-tooltip-id="modal-map-project__error">
              <svg viewBox="0 0 24 24" width="18px" height="18px"
                   fill={`var(--toastify-icon-color-success`}>
                <path
                  d="M11.983 0a12.206 12.206 0 00-8.51 3.653A11.8 11.8 0 000 12.207 11.779 11.779 0 0011.8 24h.214A12.111 12.111 0 0024 11.791 11.766 11.766 0 0011.983 0zM10.5 16.542a1.476 1.476 0 011.449-1.53h.027a1.527 1.527 0 011.523 1.47 1.475 1.475 0 01-1.449 1.53h-.027a1.529 1.529 0 01-1.523-1.47zM11 12.5v-6a1 1 0 012 0v6a1 1 0 11-2 0z"></path>
              </svg>
            </div>
            <Tooltip id="modal-map-project__error" clickable place="top">
              <div className='tooltip-error__body' style={{width: 800}}>
                    <div className='tooltip-error__item'>
                      {/*<div className='tooltip-error__item-img'>*/}
                      {/*  <svg viewBox="0 0 24 24" width="18px" height="18px"*/}
                      {/*       fill={`var(--toastify-icon-color-error)`}>*/}
                      {/*    <path*/}
                      {/*      d="M11.983 0a12.206 12.206 0 00-8.51 3.653A11.8 11.8 0 000 12.207 11.779 11.779 0 0011.8 24h.214A12.111 12.111 0 0024 11.791 11.766 11.766 0 0011.983 0zM10.5 16.542a1.476 1.476 0 011.449-1.53h.027a1.527 1.527 0 011.523 1.47 1.475 1.475 0 01-1.449 1.53h-.027a1.529 1.529 0 01-1.523-1.47zM11 12.5v-6a1 1 0 012 0v6a1 1 0 11-2 0z"></path>*/}
                      {/*  </svg>*/}
                      {/*</div>*/}
                      <div className='tooltip-error__item-text'>
                        {Languages.getTranslation('details-grouping-service-info', true)}
                      </div>
                      <br/>
                    </div>
              </div>
            </Tooltip>
          </div>
        </ModalHeader>
        <div className="modal-map-project__body">
          <div className="modal-map-project__form-control">
            <div className="materials-class">
              {content}
            </div>
          </div>
        </div>
				<ModalFooter className="modal-map-project__btns">
					<DefaultBtn title={Languages.getTranslation('close-btn', true)} handler={close}/>
				</ModalFooter>
      </div>
    </>
  );
};

export default MapProject;

