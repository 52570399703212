import saleNovember2024 from "media/sales/november-2024.svg";

const CONSTANTS = {
    // Цвета для кромок
    colors: [
        {color: "green", name: " 0.6 мм", sign: "<="},
        {color: "orange", name: " 0.8 мм", sign: "<="},
        {color: "lightblue", name: " 1.0 мм", sign: "<="},
        {color: "blue", name: " 2.0 мм", sign: "<"},
        {color: "purple", name: "2.0 мм", sign: "="},
    ],
    saleNovember2024Conditions: [

        {
            img: {
                src: saleNovember2024,
                width: 21,
                height: 35
            },
            titleKey: "sale-november-2024-condition-2-title",
            // descKey: "sale-november-2024-condition-2-desc"
        },

    ],
    emptyString: "",
    debounceTimeDetailInput: 300,
    keyCodes: {
        enter: 13,
        space: 32,
        top: 38,
        bottom: 40,
        left: 37,
        right: 39
    },
    cutGaps: {
        soft: 20,
        doubleSoft: function () {
            return this.soft * 2
        }
    },
    nodeQuerySelector: {
        tooltipContainer: "#tooltip-container"
    },
    detailsTableModes: {
        list: "list",
        edit: "edit"
    },
		defaultEdgeValueInDB: null,
		defaultEdgeValueSelect: "",
		edgeStep: 1,
    detailFieldNames: {
        NAME: "name",
        WIDTH: "l",
        HEIGHT: "h",
        COUNT: "count",
        MATERIAL: "material",
        TOP: "top",
        BOTTOM: "bottom",
        LEFT: "left",
        RIGHT: "right",
        PRODUCT_ID: "productId"
    },
    detailSelectInputClass: "detail-react-select-input",
    firstDetailIndex: 0,
    firstFieldDetailIndex: 0,
    defaultFocusedRow: function () {
        return {detailIndex: this.firstDetailIndex, fieldIndex: this.firstFieldDetailIndex}
    },
		reactSelectContainerClass: "react-select-container",
		lockedFieldsType: {
			millsGabarits: "mills-gabarits",
			millsEdges: "mills-edges",
			u1: "u1",
			u2: "u2",
			material: "material",
			onlyFirstGroup: "onlyFirstGroup",
			onlyEdge: "onlyEdge"
		},
		overscanRowCount: 10,
};

export default CONSTANTS;
