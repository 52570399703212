import {empty, interpreter, isDefined, isNumber, isset} from "../../helpers/helper";
import _Detail from "../../db/_Detail";
import _Details from "../../db/_Details";
import Helpers from "./Helpers";
import {v4 as uuidv4} from 'uuid';
import Languages from "../../translation/Languages";
import cloneDeep from "lodash.clonedeep";
import {toast} from "react-toastify";
import {toastError} from "../../helpers/toasts";
import isObject from "lodash.isobject";
import store from "redux/store";

const detailsDb = new _Details();
export default class Processing {
	_x;
	_y;
	_z;
	_r;
	_depth;
	_side;
	_detail;
	_comment;
	_x_axis = 'left';
	_y_axis = 'bottom';
	_formFields = [];
	_isErrorText = '';
	_subType;
	detailDb;
	_dataForConstructor = {};
	detailBuildTimeout;
	// detailRenderTimeout;
	multiplicity = 0;
	_id;
	_showArrow = false;
	additionalInfo = '';
	_isSelected = false;
	_isActive = false;
	_pointsAfterMove = null;
	isNew = false;
	// _isErrors = []
	isInit = false;
	shouldRender = true;
	_edge = null;
	requiredFields = {};
	errors = [];
	isEdit = false;
	buildPromises = [];
	_isTemplate = null;


	constructor(
	{x = 0, y = 0, z = 0, r = 0,

      side = 'front',
      detail,
			depth = detail.w,
      comment = '',
      x_axis = 'left',
      y_axis = 'bottom',
	  id = null,
	  isErrorText = '',
		additionalInfo = '',
		isInit = false,
		dataForConstructor = {},
		isTemplate = null
	}
	)	{
		this.detail = detail;
		this._x = dataForConstructor?.x || Number(x);
		this._y = dataForConstructor?.y || Number(y);
		this._z = Number(z);
		this._r = Number(r);
		this._depth = depth;
		this._side = side;
		this.isInit = isInit;
		this.comment = comment;
		this._x_axis = dataForConstructor?.x_axis || x_axis;
		this._y_axis = dataForConstructor?.y_axis || y_axis;
		this._dataForConstructor = dataForConstructor;
		this._isTemplate = isTemplate;
		if(empty(id)) {
			this.isNew = true;
			this.id = this.genId();
		} else {
			if(Number.isInteger(id)) {
				this.id = this.genId();
			} else {
				this.id = id;
			}
		}
		this.detailDb = new _Detail(detail.id);
		this.isErrorText = isErrorText;
		this.additionalInfo = additionalInfo;
	}

	initRequiredFields() {
		return;
	}

	genFormField({name, value, type, additionalParam, variables, visible, label, additionalProcessing}) {
		const data = {
			name,
			value,
			type,
			// This wen save data
			onChange: (setValue) => {
				this[name] = setValue
				// this.renderDetail()
				// this.buildDetail(this)
				// 	.then(() => this.renderDetail())
			},
			visible,
			label
		}
		if (!empty(additionalParam)) data.additionalParam = additionalParam
		if (!empty(variables)) data.variables = variables
		if (!empty(additionalProcessing)) data.additionalProcessing = additionalProcessing;
		return data
	}

	genId() {
		return uuidv4();
	}

	get id() {
		return this._id;
	}

	set id(id) {
		this._id = id;
	}

	get isSelected() {
		return this._isSelected;
	}

	set isSelected(selected) {
		this._isSelected = selected;
		this.renderDetail()
	}

	get isActive() {
		return this._isActive;
	}

	set isActive(active) {
		this._isActive = active;
		this.renderDetail()
	}

	dropFields() {
		this._formFields = [];
	}

	dropField(name) {
		this._formFields = [...this._formFields.filter(el => el.name !== name)]
	}

	setFormField(data) {

		const formField = this.formFields.find(el => el.name === data.name);
		//check if data.value !== formField.value, then checkIfTemplate
		if (!empty(formField)) {
			for (let field in data) {
				formField[field] = data[field];
			}
		} else {
			const newParam = {name: null, value: null, type: "text", additionalParam: [], visible: true, isError: null };
			this._formFields.push(this.genFormField({...newParam, ...data}))
		}
	}

	checkIfFieldShown(name) {
		const formField = this.formFields.find(el => el.name === name);
		if (empty(formField)) {
			return false
		}
		return formField?.visible || false
	}

	get x() {
		return this._x;
	}

	set x(x) {
		if(!this.checkRequiredField('x', Number(x))) {
			this._x = Number(x);
			this.fixMultiplicity();
			this.setFormField({name: 'x', value: this.x, label: 'x'})
			this.updateDataForConstructor({name: 'x', value: this.x})
			// this.buildDetail(this)
			// 	.then(() => this.renderDetail())
			this.renderDetail()
		} else {
			this.showErrors()
		}
	}

	set showArrow(showArrow) {
		this._showArrow = showArrow

	}

	get showArrow() {
		return this._showArrow;
	}

	get y() {
		return this._y;
	}

	set y(y) {
		if(!this.checkRequiredField('y', Number(y))) {
			this._y = Number(y);
			this.fixMultiplicity();
			this.setFormField({name: 'y', value: this.y, label: 'y'})
			this.updateDataForConstructor({name: 'y', value: this.y})
			this.renderDetail()
		} else {
			this.showErrors();
		}
	}

	get z() {
		return this._z;
	}

	set z(z) {
		if(!this.checkRequiredField('z', Number(z))) {
			this._z = Number(z);
			this.fixMultiplicity();
			this.setFormField({name: 'z', value: this.z, label: 'z'})
			// this.buildDetail(this)
			// 	.then(() => this.renderDetail())
			this.renderDetail()
		}else {
			this.showErrors();
		}
	}

	get r() {
		return this._r;
	}

	set r(r) {
		if(!this.checkRequiredField('r', Number(r))) {
			this._r = Number(r);
			this.fixMultiplicity();
			this.setFormField({name: 'r', value: this.r, label: Languages.getTranslation('radius', true)})
			this.renderDetail()
		} else {
			this.showErrors();
		}
	}

	get depth() {
		return this._depth;
	}

	set depth(depth) {
		if(!this.checkRequiredField('depth', Number(depth))) {
			this._depth = Number(depth);
			this.fixMultiplicity()
			this.setFormField({name: 'depth',value: this.depth, label: Languages.getTranslation('depth', true)})
			this.renderDetail()
		} else {
			this.showErrors()
		}
	}

	set diam(diam) {
		if(!this.checkRequiredField('diam', Number(diam))) {
			this._diam = Number(diam);
			this.fixMultiplicity()
			this.setFormField({name: 'diam', value: diam})
			this.renderDetail()
		} else {
			this.showErrors()
		}
	}

	get side() {
		return this._side;
	}

	set side(side) {
		if(!this.checkRequiredField('side', side)) {
			this._side = side;
			this.setFormField({name: 'side', value: this.side, label: Languages.getTranslation('side', true)})
			this.renderDetail()
		} else {
			this.showErrors()
		}
	}

	get detail() {
		return this._detail;
	}

	set detail(detail) {
		if (isDefined(detail)) {
			this._detail = detail;
		}
	}

	get comment() {
		return this._comment;
	}

	set comment(comment) {
		this._comment = comment;
		this.setFormField({name: 'comment', value: this.comment, label: Languages.getTranslation('coment', true)})
	}

	get x_axis() {
		return this._x_axis;
	}

	set x_axis(x_axis) {
		if(!this.checkRequiredField('x_axis', x_axis)) {
			this._x_axis = x_axis;
			this.setFormField({name: 'x_axis',value: this.x_axis, label: `${Languages.getTranslation('side-to-exist', true)} х`, type: 'select'})
			this.updateDataForConstructor({name: 'x_axis', value: this.x_axis})
			this.renderDetail()
		} else {
			this.showErrors()
		}
	}

	get y_axis() {
		return this._y_axis;
	}

	set y_axis(y_axis) {
		if(!this.checkRequiredField('y_axis', y_axis)) {
			this._y_axis = y_axis;
			this.setFormField({name: 'y_axis',value: this.y_axis, label: `${Languages.getTranslation('side-to-exist', true)} у`, type: 'select'})
			this.updateDataForConstructor({name: 'y_axis', value: this.y_axis})
			// this.buildDetail(this)
			// 	.then(() => this.renderDetail())
			this.renderDetail()
		} else {
			this.showErrors()
		}
	}

	get formFields() {
		return this._formFields;
	}

	get isErrorText() {
		return this._isErrorText;
	}

	set isErrorText(isErrorText) {
		this._isErrorText = isErrorText;
	}

	get pointsAfterMove() {
		return this._pointsAfterMove;
	}

	set pointsAfterMove(pointsAfterMove) {
		this._pointsAfterMove = pointsAfterMove;
	}

	remove() {
		const arr = this.getProcessingArray(this.subType);
		const index = this.detail[arr].findIndex(el => el.id === this.id);
		if(index !== -1) {
			this.detail.error.deleteErrorProcessing(this.detail[arr][index].isErrorText)
			this.detail[arr].splice(index, 1);
		}
		return this.updateDb(true)
			.then(() => this.buildDetail(this))
			.then(() => this.renderDetail())
	}

	updateDb(remove = false) {
		const dbProcessingName = this.getProcessingDbIndex(this.subType);
		let data = {};
		if(remove) {
			data.id = this.id;
			data.isDelete = true;
		} else {
			data = {...this.realData, dataForConstructor: {...this.getDataForConstructor()}}
		}
		return this.detail.updateProcessing(dbProcessingName, data)
			.then(() => {
				return Promise.resolve(this.id)
			})
	}

	buildDetail(process) {

		return new Promise((resolve, reject) => {
			clearTimeout(this.detailBuildTimeout);
			this.buildPromises.push(resolve)
			this.detailBuildTimeout = setTimeout(() => {
				if(window.location.pathname === '/processes'){
					this.detail
						.validate(process)
						.then((e) => {
							// if(!empty(e.errors)) {
							// 	 this.detail.error.addError(e.error.message, 'error')
							// }
							this.buildPromises.forEach(prom => prom())
							this.buildPromises = []
							resolve(e)
						})
						.catch(e => reject(e))
				} else {
					this.buildPromises.forEach(prom => prom())
					this.buildPromises = []
					resolve()
				}
			}, 50)
		})
	}

	renderDetail() {
		if(this.isInit) return;
		// if(!this.shouldRender) return;
		return this.detail.fullRender()
	}

	get subType() {
		return this._subType;
	}

	set subType(subType) {
		this._subType = subType;
	}

	updateDataForConstructor({name, value}) {
		const data = {...this._dataForConstructor, [name] : value}
		this._dataForConstructor = {...data};
	}

	getDataForConstructor() {
		return this._dataForConstructor;
	}

	validate() {
		return Promise.resolve()
	}

	saveError(){
		const saveErrors = []
		this.detail.grooves.forEach((item, index) => {
			if(item.isErrorText !== '') { saveErrors.push({index, errorText: item.isErrorText}) }})

		return saveErrors
	}

	addError(saveErrors) {
		saveErrors.forEach(item => { this.detail.grooves[item.index] = item.errorText })
	}

	updateDataFromDb() {
		return this.detailDb.getProcessing(this.getProcessingDbIndex(this.subType))
			.then(data => {
				const current = data.find(el => el.id === this.id);
				if(current) {
					if (['Sampling'].includes(current.type)) {
						const dataOfProcessing = {...current, ...current.dataForConstructor}
						delete dataOfProcessing.dataForConstructor
						for(const [key, value] of Object.entries(dataOfProcessing)) {
							const _val = current?.dataForConstructor?.[key] ?? value
							if(this.mainElement[key] !== _val) {
								this.mainElement[key] = _val
							}
						}
					} else {
						for(const [key, value] of Object.entries(current)) {
							const _val = current?.dataForConstructor?.[key] ?? value
							if (this.conditionForProcFromImport(current)) {
								this.updateDataFromDbForImportProc(key, _val, current)
							} else {
								if(this[key] !== _val) {
									this[key] = _val
								}
							}
						}
					}
				}
				// return this.updateDb()
				return Promise.resolve();
			})
	}

	_updateWidthForGroove(value, current) {
		let temp = current.direction === 'ver' ? current.height : value;
		if (this.width !== temp) {
			this.width = temp;
		}
	}

	_updateHeightForGroove(value, current) {
		let temp = current.direction === 'ver' ? current.width : value;
		if (this.height !== temp) {
			this.height = temp;
		}
	}

	updateDataFromDbForImportProc(key, value, current) {
		switch (current.type) {
			case "Groove":
				switch (key) {
					case "width":
						this._updateWidthForGroove(value, current)
						break
					case "height":
						this._updateHeightForGroove(value, current)
						break
					default :
						if(this[key] !== value) {
							this[key] = value
						}
				}
				break
		}
	}

	 conditionForProcFromImport(current) {
		return empty(current.dataForConstructor) && current.type === 'Groove'
	}

	get realData() {
		return null;
	}

	set isTemplate(isTemplate) {
		this._isTemplate = isTemplate;
		this.updateDb()
	}

	get isTemplate() {
		return this._isTemplate;
	}

	getRectData() {
		const data = this.realData
		if(empty(data) || ['UShape', 'Corner', 'Mill'].includes(data.type)) {
			return null
		}
		return {
			x1: data.x, y1: data.y + data.height,
			x2: data.x + data.width, y2: data.y + data.height,
			x3: data.x + data.width, y3: data.y,
			x4: data.x, y4: data.y,
		}
	}

	fixMultiplicity() {
		// const data = {_z: this.z ?? 0, _depth: this.depth, _multiplicity: 0};
		if(!['front', 'back'].includes(this.side)) {
			return ;
		}
		if(empty(this.detail.multiplicityClass)) {
			return;
		}
		const prData = this.getRectData()
		this._z = 0;
		this.multiplicity = 0;
		this._depth = (['circle', 'rectangle'].includes(this.subType)) ? this.detail.w : this.depth;
		this.detail.multiplicityClass?.getRealParts().forEach(part => {
			if(!empty(prData) && Helpers.rectanglesAreNested(
				prData, part
			)) {
				this._z = this.detail.multiplicityClass.w;
				this._depth = this.depth > this.detail.w - this.detail.multiplicityClass.w ? this.detail.w - this.detail.multiplicityClass.w :  this.depth;
				this.multiplicity = this.detail.multiplicityClass.w
			}
		})
	}

	getSidesValues() {
		const labels = {
			left: Languages.getTranslation('left-end', true),
			right: Languages.getTranslation('right-end', true),
			bottom: Languages.getTranslation('bottom-end', true),
			top: Languages.getTranslation('top-end', true)
		}
		const type = ['rabbet', 'groove', 'rectangle', 'circle'].includes(this.subType) ? 'rect' : this.subType;
		const checkIfSideEditable = side => {
			const sides = {right: 'left', left: 'right', bottom: 'top', top: 'bottom'};
			return !this.detail.checkProcessing(sides[side], type, this.id);
		}

		const sides = [];
		for (let side in this.detail.editableSides) {
			if(this.detail.editableSides[side][type] || !this.detail.editableSides[side][type] && checkIfSideEditable(side)) {
				sides.push({key: side, value: labels[side]})
			}
		}
		return sides;
	}

	getAnglesValue(type = null) {
		const { materials } = store.getState().project.project.construction;
		const detailMaterial = materials.find(el => el.index === this.detail.material)
		let labels = {
				left_top: Languages.getTranslation('left_top', true),
				right_top: Languages.getTranslation('right_top', true),
				left_bottom: Languages.getTranslation('left_bottom', true),
				right_bottom: Languages.getTranslation('right_bottom', true)
			},  _sides = [],
			_s = ['left', 'right'],
			__s = ['top', 'bottom'],
			sides = this.getSidesValues();
			// notEmptyCorner = []
		let sidesTemp = [], ind_left_top, ind_right_top, ind_left_bottom, ind_right_bottom;

		// ['corner', 'radiusEdge'].forEach(el => {
		// 	const temp = this.detail[this.getProcessingArray(el)]
		// 	if(!empty(temp))
		// 	temp.forEach(ang => {
		// 		notEmptyCorner.push(ang.angle)
		// 	})
		// })

		sides.forEach((el, i) => {
			if(_s.includes(el.key)) {
				_sides.push(el.key);
				// sides.splice(i, 1);
			}
		})

		sides.forEach(el => {
			if(__s.includes(el.key)) {
				_sides.forEach(_el => {
					const angleSide = `${_el}_${el.key}`
					// if(!notEmptyCorner.includes(angleSide)){
					sidesTemp.push({key: angleSide, value: labels[angleSide]})
					// }
				})
			}
		})
		function removeSide(sidesTemp, side) {
			const index = sidesTemp.findIndex(el => el.key === side);
			sidesTemp.splice(index, 1);
		}

		if(type === 'radius'){
			if(['Постформинг', 'Постформінг'].includes(detailMaterial.type)) {
				if (!detailMaterial.double_rounding && !this.detail.soft.top) {
					removeSide(sidesTemp, 'left_top');
					removeSide(sidesTemp, 'right_top');
				}
				if (detailMaterial.double_rounding && this.detail.soft.bottom && !this.detail.soft.top) {
					removeSide(sidesTemp, 'left_top');
					removeSide(sidesTemp, 'right_top');
				}
				if (detailMaterial.double_rounding && this.detail.soft.top && !this.detail.soft.bottom) {
					removeSide(sidesTemp, 'left_bottom');
					removeSide(sidesTemp, 'right_bottom');
				}
				if (detailMaterial.double_rounding && !this.detail.soft.top && !this.detail.soft.bottom) {
					removeSide(sidesTemp, 'left_top');
					removeSide(sidesTemp, 'right_top');
				}
			}
		}
		return sidesTemp
	}

	findMaterialOnDetail() {
		const { materials } = store.getState().project.project.construction;
		return materials.find(el => el.index === this.detail.material)
	}

	getSidesForCompactPlite() {
		const detailMaterial = this.findMaterialOnDetail()
		if(['Compact-плита'].includes(detailMaterial.type)) {
			this.setFormField({name:'side', value: this.side, label: Languages.getTranslation('side', true), type: 'select', variables: [
					{ key: "front", value: Languages.getTranslation('face', true) },
					{ key: "back", value: Languages.getTranslation('back', true) },
				], visible: true})
		}
	}

	getMinRByMaterial(defaultValue) {
		const detailMaterial = this.findMaterialOnDetail()
		if(['Compact-плита'].includes(detailMaterial.type)) {
			return 5
		}
		return defaultValue;
	}

	getMaterialDepth() {
		const detailMaterial = this.findMaterialOnDetail()
		return detailMaterial.thickness
	}

	getEdgeArrayIndex(index) {
		if(empty(index)) return null;
		return this.detail.parent.edges.findIndex(e => e.index === index)
	}

	getMeshColor() {
		if(this.isActive) {
			return Helpers.colorForActive
		}
		if(this.isSelected) {
			return Helpers.colorForSelected
		}
		if(!empty(this.isErrorText)) {
			return Helpers.colorForError
		}
		return null;
	}

	/**
	 * Checks if the template has additional treats based on the given subType and id.
	 * If the template has additional treats, it removes the template without additional treats.
	 *
	 * @returns {Promise<void>} - A promise that resolves when the check and removal (if necessary) is completed.
	 */
	async checkIfTemplate() {
		if (!empty(this.detail.templates)) {
			const value = this.detail.templates.find(template => {
				const idsOfTemplateAdditionalProc = template.additionalTreats[this.getProcessingArray(this.subType)]
				if (!empty(idsOfTemplateAdditionalProc) && idsOfTemplateAdditionalProc.some(el => el === this.id)) {
					return template;
				}
			})
			if (value) {
				return value.removeTemplateWithoutAdditionalTreats()
			}
			return Promise.resolve();
		}
		return Promise.resolve();
	}

	async compareDataFromDbToDeleteTemplate() {
		return this.detailDb.getProcessing(this.getProcessingDbIndex(this.subType))
			.then(async data => {
				const current = data.find(el => el.id === this.id);
				if (current) {
					const notNeededFields = ['dataForConstructor', 'comment', 'type', 'elements']
					if (['Sampling'].includes(current.type)) {
						const dataOfProcessing = {...current, ...current.dataForConstructor}
						delete dataOfProcessing.dataForConstructor
						for(const [key, value] of Object.entries(dataOfProcessing)) {
							const _val = current?.dataForConstructor?.[key] ?? value
							if(this.mainElement[key] !== _val && !notNeededFields.includes(key)) {
								await this.checkIfTemplate()
							}
						}
					} else {
						for (const [key, value] of Object.entries(current)) {
							const _val = current?.dataForConstructor?.[key] ?? value
							if (this[key] !== _val && !notNeededFields.includes(key)) {
								await this.checkIfTemplate()
							}
						}
					}
				}
				return Promise.resolve();
			})
	}

	getProcessingClass(type) {
		const cl = {
      corner: 'addCorner',
      circle: 'addCircle',
      rectangle: 'addRectangle',
      bevel: 'addBevel',
      rabbet: 'addRabbet',
      hole: 'addHole',
      groove: 'addGroove',
      mill: 'addMill',
      tableProc: 'addTableProc',
			template: 'addTemplates',
			cutOut: 'addCutOut'
		}
		return cl[type];
	}

	getProcessingDbIndex(type) {
		const cl = {
			corner: 'corners',
			corners: 'corners',
			circle: 'rects',
			rectangle: 'rects',
			rectangles: 'rects',
			bevel: 'bevels',
			bevels: 'bevels',
			rabbet: 'rects',
			rabbets: 'rects',
			hole: 'holes',
			holes: 'holes',
			groove: 'rects',
			grooves: 'rects',
			mill: 'mills',
			mills: 'mills',
			tableProc: 'mills',
			template: 'templates',
			cutOut: 'cutouts',
			cutouts: 'cutouts'
		}
		return cl[type];
	}

	getProcessingArray(type) {
		const cl = {
			corner: 'corners',
			circle: 'rectangles',
			rectangle: 'rectangles',
			bevel: 'bevels',
			rabbet: 'rabbets',
			hole: 'holes',
			groove: 'grooves',
			mill: 'mills',
			tableProc: 'mills',
			template: 'templates',
			cutOut: 'cutouts'
		}
		return cl[type];
	}

	/**
	 * Retrieves the processing detail array based on the given type.
	 *
	 * @param {string} type - The type of processing detail to retrieve.
	 *   Possible values are:
	 *   - 'corner' for corners
	 *   - 'circle' for addCircle
	 *   - 'rectangle' for rectangles
	 *   - 'bevel' for bevels
	 *   - 'rabbet' for rabbets
	 *   - 'hole' for holes
	 *   - 'groove' for grooves
	 *   - 'mill' for mills
	 *   - 'tableProc' for mills
	 * @return {string} The processing detail corresponding to the given type.
	 */
	getProcessingArrayIndex(type) {
		const cl = {
			corner: 'corners',
			circle: 'rectangles',
			rectangle: 'rectangles',
			bevel: 'bevels',
			rabbet: 'rabbets',
			hole: 'holes',
			groove: 'grooves',
			mill: 'mills',
			tableProc: 'mills',
			cutOut: 'cutouts'
		}

		return cl[type];
	}

	mirror(axis, data, detailH, detailL) {
		if(axis === 'ver') {
			data.x_axis = this.x_axis === 'left' ? 'right' : 'left'
		}

		if(axis === 'hor') {
			data.y_axis = this.y_axis === 'top' ? 'bottom' : 'top'
		}
	}

	clone({x = null, y = null, ver = false, hor = false}) {
		const data = this.realData
		data.id = this.genId();
		if(!empty(x)) {
			data.x = x;
		}
		if(!empty(y)) {
			data.y = y;
		}
		let axis;
		if(hor) {
			axis = 'hor';
		}
		if(ver) {
			axis = 'ver';
		}
		if(axis) {
			this.mirror(axis, data, this.detail.h, this.detail.l);
		}

		if (data.error) {
			return null
		}

		const clonedItem = this.detail[this.getProcessingClass(this.subType)](data);
		clonedItem.updateDb();
	}

	// mirrorWithReverse({x = null, y = null, ver = false, hor = false}) {
	// 	const data = this.realData
	// 	data.id = this.genId();
	// 	if(!empty(x)) {
	// 		data.x = x;
	// 	}
	// 	if(!empty(y)) {
	// 		data.y = y;
	// 	}
	// 	let axis;
	// 	if(hor) {
	// 		axis = 'hor';
	// 	}
	// 	if(ver) {
	// 		axis = 'ver';
	// 	}
	// 	if(axis) {
	// 		this.mirror(axis, data, this.detail.h, this.detail.l);
	// 	}
	//
	// 	if (data.error) {
	// 		return null
	// 	}
	// 	for (let prop in data) {
	// 		if (this.hasOwnProperty(`_${prop}`) &&  !['side', 'dataForConstructor', 'id'].includes(prop)) {
	// 			console.log(prop)
	// 			this[prop] = data[prop]
	// 		}
	// 	}
	// 	this.updateDb()
	// }

	groupMoving = ({direction, value }) => {
		const {x, y, x_axis, y_axis, diam} = this
		const {l, h} = this.detail
		const idx = this.detail.holes.indexOf(this)
		const message = `${Languages.getTranslation('hole', true)}[${idx + 1}] ${Languages.getTranslation('beyond-detail', true)}`

		switch (direction) {
			case "horizontal":
				if (['left', 'right'].includes(this.side)) {
					return
				}
				if (x_axis === 'left') {
					if (x + ((Number(diam)/2)  + Number(value)) >= l || x + ((Number(diam)/2)  + Number(value)) <= 0) {
					return this.detail.error.setError(message, 'error', true);
					}
				}
				if (x_axis === 'right') {
					if (x - (Number(diam) + Number(value)) <= 0 || x - (Number(diam) + Number(value)) >= l) {
					return this.detail.error.setError(message, 'error', true);
					}
				}
				this.x = (x_axis === 'right' ? x - Number(value) : x + Number(value))
				break
			case "vertical":
				if (['top', 'bottom'].includes(this.side)) {
					return
				}
				if (y_axis === 'top') {
					if (y - ((Number(diam)/2)  + Number(value)) <= 0 || y - ((Number(diam)/2)  + Number(value)) >= h) {
					return this.detail.error.setError(message, 'error', true);
					}
				}
				if (y_axis === 'bottom') {
					if (y + ((Number(diam)/2) + Number(value)) >= h || y + ((Number(diam)/2)  + Number(value)) <= 0) {
					return this.detail.error.setError(message, 'error', true);
					}
				}
				this.y = y_axis === 'top' ? y - Number(value) : y + Number(value)
				break
			// case "diagonal":
			// 	switch(modalState.directionSide){
			// 		case 'left-up':
			// 				x: x_axis === 'right' ? x + slide * i : x - slide * i,
			// 				y: y_axis === 'top' ? y - slide * i : y + slide * i,
			// 			break
			// 		case 'left-down':
			// 				x: x_axis === 'right' ? x + slide * i : x - slide * i,
			// 				y: y_axis === 'top' ? y + slide * i : y - slide * i,
			// 			break
			// 		case 'right-up':
			// 				x: x_axis === 'right' ? x - slide * i : x + slide * i,
			// 				y: y_axis === 'top' ? y - slide * i : y + slide * i,
			// 			break
			// 		case 'right-down':
			// 				x: x_axis === 'right' ? x - slide * i : x + slide * i,
			// 				y: y_axis === 'top' ? y + slide * i : y - slide * i,
			// 			break
			// 	}
			// 	break
		}
		this.updateDb();
	}

	createCopy() {
		// console.log(this.id)
		const processing = {...this.realData, dataForConstructor: {...this.getDataForConstructor()}};
		processing.id = this.genId();
		const createdProcessing = this.detail[this.getProcessingClass(this.subType)](processing);
		createdProcessing.updateDb();
		return Promise.resolve(createdProcessing)
	}

	groupMovingCopy = ({direction, value }) => {
		const {x, y, x_axis, y_axis, diam} = this
		const {l, h} = this.detail
		const idx = this.detail.holes.indexOf(this)
		const message = `${Languages.getTranslation('hole', true)}[${idx + 1}] ${Languages.getTranslation('beyond-detail', true)}`
		let newX, newY
		switch (direction) {
			case "horizontal":
				if (['left', 'right'].includes(this.side)) {
					return
				}
				if (x_axis === 'left') {
					if (x + ((Number(diam)/2)  + Number(value)) >= l || x + ((Number(diam)/2)  + Number(value)) <= 0) {
					return this.detail.error.setError(message, 'error', true);
					}
				}
				if (x_axis === 'right') {
					if (x - (Number(diam) + Number(value)) <= 0 || x - (Number(diam) + Number(value)) >= l) {
					return this.detail.error.setError(message, 'error', true);
					}
				}
				newX = (x_axis === 'right' ? x - Number(value) : x + Number(value))
				this.clone({x: newX})
				break
			case "vertical":
				if (['top', 'bottom'].includes(this.side)) {
					return
				}
				if (y_axis === 'top') {
					if (y - ((Number(diam)/2)  + Number(value)) <= 0 || y - ((Number(diam)/2)  + Number(value)) >= h) {
					return this.detail.error.setError(message, 'error', true);
					}
				}
				if (y_axis === 'bottom') {
					if (y + ((Number(diam)/2) + Number(value)) >= h || y + ((Number(diam)/2)  + Number(value)) <= 0) {
					return this.detail.error.setError(message, 'error', true);
					}
				}
				newY = y_axis === 'top' ? y - Number(value) : y + Number(value)
				this.clone({y: newY})
				break
		}

	}
	get edge() {
		return this._edge;
	}
	set edge(edge) {
		this._edge = edge;
		this.renderDetail();
	}
	get edgeIndex() {
		if(empty(this.edge)) return null;
		if(isNumber(this.edge)) return this.edge;
		if(isObject(this.edge) && !empty(this.edge?.index) && this.detail.parent.edges.find(el => el.index === this.edge.index)) return this.edge.index;
		return null;
	}

	setFeature(feature, modalState = null) {
		const foo = feature.split('_');
		switch (foo[0]) {
			case 'mirror':
				if (['gEdge', 'smile', 'radiusEdge', 'tableCorner', 'tableTop'].includes(this.type)) {
					toastError(Languages.getTranslation('g-edge-smile-mirror-error', true))
					return
				}
				if (modalState.type === 'mirror') {
					this.clone({hor: foo[1] === 'hor', ver: foo[1] === 'ver'});
				} else {
					this.mirrorWithReverse({hor: foo[1] === 'hor', ver: foo[1] === 'ver'})
					this.updateDb()
				}
			break
			case 'remove':
				this.remove()
			break
			case 'moving' :
				this.groupMoving(modalState)
				break
			case 'transferToReverseSide':
				this.reverseSide()
				break
			case 'moving-copy' :
				this.groupMovingCopy(modalState)
				break
		}
	}

	reverseSide() {
		switch (this.side) {
			case "front":
				this.side = 'back'
				break
			case "back":
				this.side = 'front'
				break
			case "right":
			case "left":
			case "top":
			case "bottom":
				if (this.z === this.detail.w) {
					return;
				} else {
					this.z = this.detail.w - this.z
				}
				break
			default :
				return;
		}
		this.updateDb()
	}

	formulaCalculationOnReSize (){
		const data = this._dataForConstructor
		for (let key in data) {
			if (key.indexOf('f_') !== -1 && !empty(data[key])) {
				this[key.split('_')[1]] = data[key]
			}
		}
		this.updateDb()
			.then(() => {})
			.catch(error => console.log(error))
	}

	formulaCalculation (formula){
		try{
			return eval(interpreter(cloneDeep(formula).replace(/,/g, '.')))
		} catch(error) {
			console.log(error)
			this.showToastError('formula-error')
		}
	}

	showToastError(message){
		toast.error(`${Languages.getTranslation(message, true)}`, {
			onClose: () => {},
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			toastId: `${message}`})
	}

	checkRequiredFields() {
		for (const[field, value] of Object.entries(this.requiredFields)) {
			if(!this.checkIfFieldShown(field)) {
				delete this.errors[field];
				continue
			}
			if(empty(this[field])) {
				this.errors[field] = 'empty';
			} else {
				if(!empty(value.min) && this[field] < value.min) {
					this.errors[field] = 'value_min';
					continue;
				}
				if(!empty(value.max) && this[field] > value.max) {
					this.errors[field] = 'value_max';
					continue;
				}
				delete this.errors[field];
			}
		}
	}

	checkRequiredField(field, value) {
		delete this.errors[field];
		this.setFormField({name: field, isError: null})
		if(!empty(this.requiredFields?.[field])) {
			if(empty(value)) {
				this.errors[field] = 'error_empty';
				return 'error_empty';
			} else {
				if(this.requiredFields[field].min && value < this.requiredFields[field].min) {
					this.errors[field] = 'error_value_min';
					return this.requiredFields[field].min;
				}
				if(this.requiredFields[field].max && value > this.requiredFields[field].max) {
					this.errors[field] = 'error_value_max';
					return this.requiredFields[field].max;
				}
				delete this.errors[field];
				return false;
			}
		}
		return false;
	}

	showErrors() {
		for (const[field, value] of Object.entries(this.errors)) {
			// if(this.checkIfFieldShown(field)) {
				this.setFormField({name: field, isError: value})
			// }
		}
	}

	getNextSideForRotate(direction) {
		const sides = ['left', 'top', 'right', 'bottom'];
		const currentSide = sides.findIndex(el => el === this.side);
		if(currentSide === -1) return this.side;
		if(direction) {
			return currentSide === 3 ? sides[0] : sides[currentSide + 1]
		}
		return currentSide === 0 ? sides[3] : sides[currentSide - 1]
	}

	getNextEdgeSideForRotate(direction) {
		const sides = ['left', 'top', 'right', 'bottom'];
		const currentSide = sides.findIndex(el => el === this.edgeSide);
		if(currentSide === -1) return this.edgeSide;
		if(direction) {
			return currentSide === 3 ? sides[0] : sides[currentSide + 1]
		}
		return currentSide === 0 ? sides[3] : sides[currentSide - 1]
	}

	rotateDetail(direction) {
		if(!empty(this.isTemplate)) return Promise.resolve();
		let x, y, x_axis, y_axis;

		switch (this.side) {
			case 'front':
			case 'back':
				[x , y] = [this.y, this.x];
				break;
			case 'left':
			case 'right':
				x = this.y;
				y = this.detail.h;
				break;
			case 'top':
			case 'bottom':
				y = this.x
				x = this.detail.l;
		}

		if(direction) {
			y_axis = (this.x_axis === 'right') ? 'bottom' : 'top';
			x_axis = (this.y_axis === 'bottom') ? 'left' : 'right';
		} else {
			y_axis = (this.x_axis === 'right') ? 'top' : 'bottom';
			x_axis = (this.y_axis === 'bottom') ? 'right' : 'left';
		}

		this.initRequiredFields()
		this.side = this.getNextSideForRotate(direction);
		this.y = y;
		this.x = x
		this.x_axis = x_axis;
		this.y_axis = y_axis;
		this.updateDataForConstructor({name: 'x', value: this.x})
		this.updateDataForConstructor({name: 'y', value: this.y})
		this.updateDataForConstructor({name: 'x_axis', value: this.x_axis})
		this.updateDataForConstructor({name: 'y_axis', value: this.y_axis})
		return this.updateDb();
	}

	checkIfAvailable() {
		const conf = this.detail.processConfig?.[this.detail.materialType]?.processing;
		if(!conf) {
			return false;
		}
		return isset(conf[this.subType])
	}

	checkIfAvailableType() {
		const conf = this.detail.processConfig?.[this.detail.materialType]?.processing?.[this.subType];
		if(!conf) {
			return false;
		}
		if(Array.isArray(conf) && isset(this.type)) {
			return conf.includes(this.type)
		}
		return true;
	}

	validateDepthCutoutWithMultiplicity(value){
		if (!empty(this.detail.multiplicity)) {
			return this.detail.w
		}
		return value
	}

	create3dDataForMills(elements) {
		return elements.map(el => ({
			...el,
			pId: this.id,
			meshType: `${this.subType}-${this.type}`,
			proc: this
		}))
	}

}