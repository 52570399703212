import {empty} from "../../../../helpers/helper";
import CONSTANTS from "../../../../config/constants";
import Languages from "../../../../translation/Languages";

export const atLeastOneHasErrors = (details) => details.some((detail) => detail?.error?.allErrorsMessage);

export function getArrErrors(arrErr) {
	let type = '';
	
	if (!empty(arrErr)) {
		const priority = {'error': 3, 'warning': 2, 'success': 1};
		let maxPriority = 0;
		
		arrErr.forEach(({type: errType}) => {
			let currentPriority = priority[errType] || 0;
			
			if (currentPriority > maxPriority) {
				type = errType;
				maxPriority = currentPriority;
			}
		});
		
		arrErr.sort((a, b) => {
			return priority[b.type] - priority[a.type];
		});
	}
	
	return {errorsByPriority: arrErr, type};
}

export const scrollToTop = () => {
	window.scrollTo(0, 0);
};

export const disableByMaterialType = (materialType) => ['Compact-плита', 'OSB', 'HDF', 'Фанера'].includes(materialType);

export const sortByPropertyDesc = (arr, property) => arr.sort((a, b) => b[property] - a[property]);

export const getFocusableFieldsByParentEl = (el) => el ? Array.from(el.querySelectorAll('[tabindex="0"]')) : [];
export const selectField = (nextField) => {
	if (nextField) {
		nextField.focus();
		
		setTimeout(() => {
			nextField.select()
		}, 0);
	}
}

export const acceptNumericOnly = (value) => {
	const pattern = /^$|^[1-9][0-9]*$/;
	return pattern.test(value);
};

export const selectFirstAvaliableDetailField = (detailWrapperRef, focusedRow) => {
	const detailWrapper = detailWrapperRef.querySelector(".ReactVirtualized__Grid__innerScrollContainer");
	const detailElems = detailWrapper.children;
	
	
	if (detailElems.length < focusedRow.detailIndex) {
		const {detailIndex, fieldIndex} = CONSTANTS.defaultFocusedRow();
		
		const detailNode = detailElems[detailIndex];
		const focusableFields = getFocusableFieldsByParentEl(detailNode);
		
		selectField(focusableFields[fieldIndex]);
	} else {
		const {detailIndex, fieldIndex} = focusedRow;
		
		const detailNode = detailElems[detailIndex];
		const focusableFields = getFocusableFieldsByParentEl(detailNode);
		
		selectField(focusableFields[fieldIndex]);
	}
}

export const formatEdgeValue = (edgeValue) => edgeValue === null || edgeValue === undefined ? CONSTANTS.defaultEdgeValueSelect : edgeValue + CONSTANTS.edgeStep;

export const checkDisabling = (params) => Object.values(params).some((v) => v);

export const getWarnings = (params) => {
	const WARNINGS = {
		[CONSTANTS.lockedFieldsType.millsEdges]: Languages.getTranslation("warning-mills-edges", true),
		[CONSTANTS.lockedFieldsType.millsGabarits]: Languages.getTranslation("warning-mills-gabarits", true),
		[CONSTANTS.lockedFieldsType.u1]: Languages.getTranslation("warning-edge-u1", true),
		[CONSTANTS.lockedFieldsType.u2]: Languages.getTranslation("warning-edge-u2", true),
		[CONSTANTS.lockedFieldsType.material]: Languages.getTranslation("warning-edge-by-material-type", true),
		// [CONSTANTS.lockedFieldsType.onlyFirstGroup]: "Изменения только по габаритам",
		// [CONSTANTS.lockedFieldsType.onlyEdge]: "Изменения только по крайками"
	}
	
	const onlyTrueConditions = Object.entries(params)
		.filter(([key, value]) => WARNINGS[key] && value);
	
	if (onlyTrueConditions.length === 1) {
		const [firstCondition] = onlyTrueConditions;
		const [name] = firstCondition;
		return WARNINGS[name];
	}
	
	if (onlyTrueConditions.length > 1) {
		return onlyTrueConditions.map(([key], index) => `${index + 1}: ${WARNINGS[key]}`).join("\n");
	}
	
	return undefined;
}