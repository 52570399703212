import  {useEffect, useState} from "react";
import AppRouter from "components/AppRouter";
import {useDispatch, useSelector} from "react-redux";
import Project from "models/Project";
import Loader from "../UI/Loader";
import {modalsSelectors} from "redux/modals";
import {userSelectors} from "redux/user";
import Db from "../../api/Db";
import {empty} from "../../helpers/helper";
import NewProjectOrLoad from "../UI/Modal/NewProjectOrLoad";
import QuitApp from "../QuitApp";
const db = new Db();

const App = () => {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [isEmpty, setIsEmpty] = useState([])
  const [projectLocked, setProjectLocked] = useState(false);
  const id = new URLSearchParams(window.location.search).get('id')
  const action = new URLSearchParams(window.location.search).get('action')
  const modalState = useSelector(modalsSelectors.getModalShowState);
  const user = useSelector(userSelectors.User);

  const TAB_KEY = 'project_window';
  const LIFETIME_KEY = 'lifetime';
  const CURRENT_TAB_ID = Date.now().toString();
  const channel = new BroadcastChannel('kronas');
  const lockTimeout = 5000; // Время жизни активной вкладки (5 сек)

  // Проверка, активна ли вкладка
  const isTabValid = () => {
    const activeTab = localStorage.getItem(TAB_KEY);
    const lastUpdate = localStorage.getItem(LIFETIME_KEY);
    const now = Date.now();

    if (!activeTab || !lastUpdate || now - parseInt(lastUpdate, 10) >= lockTimeout) {
      // Если вкладка "мертва" или устарела - очищаем всё
      localStorage.removeItem(TAB_KEY);
      localStorage.removeItem(LIFETIME_KEY);
      return false;
    }
    return true;
  };

  // Функция, которая делает текущую вкладку активной
  const becomeActiveTab = () => {
    localStorage.setItem(TAB_KEY, CURRENT_TAB_ID);
    localStorage.setItem(LIFETIME_KEY, Date.now().toString());
    startUpdatingLock();
  };

  // Запуск обновления блокировки
  const startUpdatingLock = () => {
    setInterval(() => {
      localStorage.setItem(LIFETIME_KEY, Date.now().toString());
    }, 3500);
  };

  // Проверка активности вкладки (на случай, если браузер завис и был закрыт)
  const verifyActiveTab = () => {
    if (!isTabValid()) {
      localStorage.removeItem(TAB_KEY);
      localStorage.removeItem(LIFETIME_KEY);
    }
  };

  // Удаляем активную вкладку при закрытии страницы
  const removeActiveTab = () => {
    if (localStorage.getItem(TAB_KEY) === CURRENT_TAB_ID) {
      localStorage.removeItem(TAB_KEY);
      localStorage.removeItem(LIFETIME_KEY);
      channel.postMessage({ type: 'tab_closed', tabId: CURRENT_TAB_ID });
    }
  };

  if (location.pathname !== "/cost") {
    useEffect(() => {
      if (!isTabValid()) {
        // Если вкладка мертва, очищаем и становимся активной
        becomeActiveTab();
      } else if (localStorage.getItem(TAB_KEY) !== CURRENT_TAB_ID) {
        // Если есть другая активная вкладка, блокируем текущую
        setProjectLocked(true);
      } else {
        // Если вкладка первая - делаем её активной
        becomeActiveTab();
      }
    }, []);

    channel.addEventListener('message', (event) => {
      if (event.data.type === 'duplicate_tab' && event.data.tabId !== CURRENT_TAB_ID) {
        // Здесь можно обработать дубликаты вкладок
      }
    });

    setInterval(verifyActiveTab, 5000);

    window.addEventListener('beforeunload', removeActiveTab);
    window.addEventListener('unload', removeActiveTab);
  }

  const checkLoadProject = async () => {
    const _id = id || null;
    const activeTab = localStorage.getItem(TAB_KEY);

    if (location.pathname === "/cost") {
      // console.log(" Пропускаем проверку");
      return Project.loadProject(_id, dispatch);
    }
    if (!isTabValid()) {
      // console.log("Вкладка зависла, очищаем");
      becomeActiveTab();
      return Project.loadProject(_id, dispatch);
    }

    if (activeTab && activeTab !== CURRENT_TAB_ID) {
      // console.log("Другая вкладка уже активна");
      return Promise.reject('error on load project');
    }

    // console.log("Загружаем проект");
    return Project.loadProject(_id, dispatch);
  };

  useEffect(() => {
    if (action === 'create') {
      db.checkIfData().then(res => {
        setIsEmpty(res)
        setLoaded(true)
      });
    } else {
      checkLoadProject()
        .then(data => {
          setLoaded(true);
          setLoad(data);
          return data;
        })
        .then((value) => {
          if (value) {
            Project.validateProjectDetails()
          }
        })
        .catch(err => console.log(err))
    }
  }, [])

  useEffect(() => {
    if (modalState) {
      document.body.classList.add('disable-scroll')
    } else {
      document.body.classList.remove('disable-scroll')
    }
  })

  const render = () => {
    if(loaded) {
      return (<>
        <AppRouter load={load} user={user} />
        {!empty(isEmpty) && action === 'create' && <NewProjectOrLoad setIsEmpty={setIsEmpty} />}
      </>)
    } else {
      return <Loader main={true}/>
    }
  }
  if (projectLocked) {
    return <QuitApp />;
  }

  return render();
};
export default App;