import {useEffect, useState} from "react";
import ParamItem from "./ParamItem";
import {empty} from "../../../helpers/helper";
import Languages from "../../../translation/Languages";
import {useDispatch, useSelector} from "react-redux";
import {projectOperations, projectSelectors} from "redux/project";
import FeaturesModal from "./FeaturesModal";
import {userSelectors} from "redux/user";

const ParamContainer = ({param, idx, createComment, detail, type, createProc, setCurrentDetail, setBackgroundOverFlow}) => {
	const permissions = useSelector(userSelectors.permissions);
	const dispatch = useDispatch()
	const [isAllSelected, setIsAllSelected] = useState(false);
	const [currentParams, setCurrentParams] = useState([])
	const [selectedItems, setSelectedItems] = useState([]);
	const [selectValue, setSelectValue] = useState('')
	const [modal, setModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('')
	const [modalType, setModalType] = useState('moving_group')
	const construction = useSelector(projectSelectors.getConstruction);
	const [modalState, setModalState] = useState({
		direction: 'vertical',
		value: ''
	})

	const modalSelectHandler = (e) => {
		const {name, value} = e.target
		switch (modalType) {
			case 'moving_group':
			case 'moving-copy_group':
				if (name === 'direction') {
					setModalState({
						[name]: value,
						value: ''
					})
					currentParams.filter(el => selectedItems.includes(el.id)).forEach(el => {
						el.pointsAfterMove = null
					})
					detail.create()
					return
				} else {
					const a = /,/gi
					const newValue = value.split('').includes(',') ? value.replace(a, '.') : value
					setModalState({
						...modalState,
						[name]: newValue
					})
				}
				currentParams.filter(el => selectedItems.includes(el.id)).forEach(el => {
					if (modalState.direction === 'vertical') {
						el.pointsAfterMove = {
							x: el.x,
							y: el.y_axis === 'top' ? el.y - Number(value) : el.y + Number(value)
						}
					} else {
						el.pointsAfterMove = {
							x: el.x_axis === 'right' ? el.x - Number(value) : el.x + Number(value),
							y: el.y
						}
					}
				})
				break
			case "mirror":
			case "mirrorWithMovement":
				setModalState({
					[name]: value,
					type: modalType
				})
				if (value === 'mirror_hor') {
					detail.dataForAnimations = {
						xMirrorLine: true,
						yMirrorLine: false,
					}
				} else if (value === 'mirror_ver') {
					detail.dataForAnimations = {
						xMirrorLine: false,
						yMirrorLine: true,
					}
				} else {
					detail.dataForAnimations = null
				}
				break
		}
		detail.create()
	}

	const applyModalState = () => {
		switch (modalType) {
			case 'moving_group':
				currentParams.filter(el => selectedItems.includes(el.id)).forEach((el) => {
					el.setFeature('moving_group', modalState)
				})
				break
			case 'moving-copy_group':
				currentParams.filter(el => selectedItems.includes(el.id)).forEach((el) => {
					el.setFeature('moving-copy_group', modalState)
				})
				break
			case 'mirror':
			case 'mirrorWithMovement':
				currentParams.filter(el => selectedItems.includes(el.id)).forEach((el) => {
					el.setFeature(modalState.direction, modalState)
				})
				break
		}
		detail.dataForAnimations = null
		setModalState({
			direction: 'vertical',
			value: ''
		})
		const realDetail = construction.details.find(el => el.id === detail.id)
		setCurrentDetail(realDetail)
		dispatch(projectOperations.setDetail({detail}));
		updateParams()
		closeModalHandler()
	}

	const updateParams = () => {
		if (param.items && param.items.length > 0) {
			if (empty(detail.contour) || !detail.contour.some(el => !empty(el.id)) || param.items.some(el => el.type === 'partial' && el.elements.some(_el => empty(_el.id)))) {
				if (type === 'mill') {
					setCurrentParams([...param.items].filter(el => ['gEdge', 'uShape', 'smile', 'tableTop', 'tableCorner', 'radiusEdge'].includes(el.type)))
					return
				}
			}
			if(type === 'hole'){
				setCurrentParams([...param.items].sort((a, b) => a.diam - b.diam));
				return;
			}
			setCurrentParams([...param.items])
		} else {
			setCurrentParams([])
		}
	}

	const selectItem = (uId) => {
		const selectedIndex = selectedItems.findIndex(el => el === uId);
		if (selectedIndex === -1) {
			currentParams.find(el => el.id === uId).isSelected = true;
			setSelectedItems([...selectedItems, uId]);
		} else {
			currentParams.find(el => el.id === uId).isSelected = false;
			setSelectedItems([...selectedItems.filter(el => el !== uId)]);
		}

	}

	const clearSelected = () => {
		selectedItems.forEach(uId => {
			currentParams.find(el => el.id === uId).isSelected = false
			currentParams.find(el => el.id === uId).pointsAfterMove = null
		})
		setSelectedItems([])
	}

	const applyFeature = (e) => {
		const {value} = e.target;
		if (['groupMoving', 'groupMovingCopy'].includes(value)) {
			setModalType(getModalType(value))
			setModalTitle(getModalTitle(value))
			setModalState({
				direction: 'vertical',
				value: ''
			})
			setSelectValue(value)
			setBackgroundOverFlow(true)
			setModal(true)
			currentParams.filter(el => selectedItems.includes(el.id)).forEach(el => {
				el.pointsAfterMove = {
					x: el.x,
					y: el.y
				}
			})
			detail.create()
			return
		}
		if (['mirror', 'mirrorWithMovement'].includes(value)) {
			setModalType(value)
			setModalTitle(getModalTitle(value))
			setModalState({
				direction: '',
			})
			setSelectValue(value)
			setBackgroundOverFlow(true)
			setModal(true)
			// currentParams.filter(el => selectedItems.includes(el.id)).forEach(el => {
			//
			// 	el.pointsAfterMove = {
			// 		x: el.x,
			// 		y: el.y
			// 	}
			// })
			detail.create()
			return
		}
		setSelectValue(value)
		currentParams.filter(el => selectedItems.includes(el.id)).forEach(el => {
			el.setFeature(value)
		})
		detail.validate(detail)
			.then(() => {
				clearSelected();
				createProc()
				updateParams();
				closeModalHandler()
			})
	}

	const getModalTitle = (type) => {
		const arr = {
			groupMoving: Languages.getTranslation('group-moving-workflows', true),
			groupMovingCopy: Languages.getTranslation('group-moving-withCopy-workflows', true),
			mirror: Languages.getTranslation('mirror', true),
			mirrorWithMovement: Languages.getTranslation('mirror-with-movement', true),
		}
		return arr[type]
	}

	const getModalType = (type) => {
		const arr = {
			groupMoving: 'moving_group',
			groupMovingCopy: 'moving-copy_group'
		}
		return arr[type]
	}

	const closeModalHandler = () => {
		detail.dataForAnimations = null
		setModalState({
			direction: 'vertical',
			value: ''
		})
		clearSelected()
		setBackgroundOverFlow(false)
		setModal(false)
		setSelectValue('')
	}

	const getSelectedOptions = () => {
		if (empty(selectedItems)) return null;
		if(!permissions.includes('KM_operationsForHandles')) return null;
		return <select onChange={applyFeature} className="details__select pointer" disabled={modal}
			value={selectValue} style={{cursor: modal ? 'not-allowed' : 'pointer', marginRight: 20}}>
			<option value='' disabled={true}>{Languages.getTranslation('choose', true)}</option>
			{['hole'].includes(type) ?
				<option value='groupMoving'>{Languages.getTranslation('group-moving-workflows', true)}</option> : ""}
			{['hole'].includes(type) ?
				<option
					value='groupMovingCopy'>{Languages.getTranslation('group-moving-withCopy-workflows', true)}</option> : ""}
			{['hole', 'rabbet', 'groove'].includes(type) ?
				<option
					value='transferToReverseSide'>{Languages.getTranslation('transfer-to-the-reverse-side', true)}</option> : ""}
			{!['corner', 'bevel', 'template', 'cutOut'].includes(type) ?
				<option value='mirror'>{Languages.getTranslation('mirror', true)}</option> : ""}
			{!['corner', 'bevel', 'template', 'cutOut'].includes(type) ?
				<option value='mirrorWithMovement'>{Languages.getTranslation('mirror-with-movement', true)}</option> : ""}
			<option value='remove'>{Languages.getTranslation('delete-chosen-proc', true)}</option>
		</select>
	}

	useEffect(() => {
		setIsAllSelected(currentParams.every(param=>param.isSelected));

		updateParams()
	}, [detail, param])

	const selectAll = () => {
		if (!isAllSelected) {
			let newSelectedItems = [];
			currentParams.forEach(el => {
				el.isSelected = true;
				newSelectedItems.push(el.id);
			});
			setSelectedItems(newSelectedItems);
		}
		else {
			currentParams.forEach(el => {
				el.isSelected = false;
			});
			setSelectedItems([]);
		}
		setIsAllSelected(!isAllSelected);

	}
	return currentParams && currentParams.length > 0 &&
		<>
			<div className="chosProcesses__item-container">
				<div className="chosProcesses__title" key={idx}>
					<i className="chosProcesses__title-icon"></i>
					<span className="chosProcesses__title-text">{param.name}</span>
					{getSelectedOptions()}
				</div>

				{currentParams.map((item, id) => <ParamItem
					isAllSelected={isAllSelected}
					selectAll={selectAll}
					param={item}
					idx={id}
					uId={item?.id}
					updateParams={() => updateParams()}
					detail={detail}
					createComment={createComment}
					select={selectItem} paramMain={param}
					createProc={createProc} key={id}
					setSelectedItems={setSelectedItems}
					applyModalState={applyModalState}
				/>)}
			</div>
			{
				modal ? <FeaturesModal modalType={modalType} modalState={modalState} modalTitle={modalTitle} modal={modal}
					closeModalHandler={closeModalHandler} modalSelectHandler={modalSelectHandler} applyModalState={applyModalState}/> : ""
			}
		</>
}

export default ParamContainer