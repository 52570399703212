import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import ACTIONS from "./actions";

const initialState = {
  object: null,
};

const hoveredObjectOn3D = createReducer(initialState, {
  [ACTIONS.HoveredObjectOn3DStateSwitch.type]: (state, val) => {
    return { ...state, object: val.payload.object};
  },
});

export default combineReducers({
  hoveredObjectOn3D,
});
