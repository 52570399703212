import './style.scss'
import {Divider} from "../../Divider";
import Languages from "../../../../translation/Languages";
import {useSelector} from "react-redux";
import {contourEdgeHelperSelectors} from "redux/contourEdgeHelper";
import {edgeTitle, empty} from "../../../../helpers/helper";
import MaterialEdgeImg from "../../../CreateProject/Materials/MaterialEdgeImg/MaterialEdgeImg";
import {projectSelectors} from "redux/project";
import {imgLink} from "../../../../helpers/helper";

const contourEdgeHelper = () => {
  const contourEdgeHelperEdge = useSelector(contourEdgeHelperSelectors.getContourEdgeHelperEdge)
  const contourEdgeHelperCursorPosition = useSelector(contourEdgeHelperSelectors.getContourEdgeHelperCursorPosition)

  if(empty(contourEdgeHelperEdge)) {
    return null;
  }
  const style = {
    top: contourEdgeHelperCursorPosition.y - 155,
    left: contourEdgeHelperCursorPosition.x - 303.5
  }

  return <div className='contourEdgeHelper' style={style}>
    <div className='contourEdgeHelper__header'>
      <div className='contourEdgeHelper__header-item contourEdgeHelper__sketch'>{Languages.getTranslation('Sketch', true)}</div>
      <div
        className='contourEdgeHelper__header-item contourEdgeHelper__name'>{Languages.getTranslation('name', true)}</div>
      <div
        className='contourEdgeHelper__header-item contourEdgeHelper__article'>{Languages.getTranslation('article', true)}</div>
      <div className='contourEdgeHelper__header-item '>{Languages.getTranslation('width', true)}</div>
      <div className='contourEdgeHelper__header-item '>{Languages.getTranslation('thickness', true)}</div>
    </div>
    <div className="contourEdgeHelper__body">
      <div className='contourEdgeHelper__body-item contourEdgeHelper__sketch'>
        <img
            src={
              Array.isArray(contourEdgeHelperEdge?.images) && contourEdgeHelperEdge?.images[0]?.original
                  ? contourEdgeHelperEdge?.images[0]?.original
                  : imgLink
            }
            alt="edge"
            height={40}
            width={41}
        /></div>
      <div className='contourEdgeHelper__body-item contourEdgeHelper__name'>{edgeTitle(contourEdgeHelperEdge)}</div>
      <div className='contourEdgeHelper__body-item contourEdgeHelper__article'>{contourEdgeHelperEdge.article}</div>
      <div className='contourEdgeHelper__body-item '>{contourEdgeHelperEdge.width}</div>
      <div className='contourEdgeHelper__body-item '>{contourEdgeHelperEdge.thickness}</div>
    </div>
    <Divider/>
  </div>
}

export default contourEdgeHelper