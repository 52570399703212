import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import Param from "components/UI/Params/Param";
import "./style.scss";
import Languages from "../../../../translation/Languages";
import CloseBtn from "components/UI/Buttons/CloseBtn";
import _Detail from "../../../../db/_Detail";
import {edgeTitle, empty, isset} from "helpers/helper";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";
import {panelSelectors, panelOperations} from "redux/panel";
import Contour from "../../SvgSketch";
import {ModalFooter, ModalHeader} from "../index";

const detailDb = new _Detail();

const Processing = ({ getTreatmentType, setSuccessAnimationOn }) => {
	const dispatch = useDispatch();
	const isEditPanel = useSelector(panelSelectors.getPanelIsEditPanel);
	const detail = useSelector(projectSelectors.getDetail);
	const treatment = useSelector(projectSelectors.getTreatment);
	const construction = useSelector(projectSelectors.getConstruction);
	detailDb.id = detail.id;
	const [additionalParams, setAdditionalParams] = useState([]);
	const treatmentType = treatment.subType || detail.type || null;
	const [saveButtonActive, setSaveButtonActive] = useState(true);
	const [runContour, setRunContour] = useState(false);
	const [initialEdges, setInitialEdges] = useState(detail.edges);

	async function updateEdges(sides, detail, initialEdges) {
		for (const side of sides) {
			await construction.updateDetailSideEdge(detail, side, initialEdges[side]);
		}
	}

	const close = (remove = false, update=true) => {
		const sides = ['top', 'bottom', 'left', 'right'];

		if (remove && !isEditPanel) {
			if(isset(treatment)) {
				treatment?.remove();
			}

		}
		if (isEditPanel) {
			treatment.updateDataFromDb()
				.then(() => {
					treatment.isActive = false;
					treatment.isEdit = false;
					dispatch(
						panelOperations.switchStateForPanel({
							show: 'chosProcesses',
						})
					);
				})
			updateEdges(sides, detail, initialEdges).then(r => Promise.resolve())


		} else {

			treatment.isActive = false
			if (update){
				updateEdges(sides, detail, initialEdges).then(() => {
					console.log('All edges updated');
				});
			}
			dispatch(
				panelOperations.switchStateForPanel({
					show: 'none',
				})
			);
		}
	};
    useEffect(() => {
		// treatment.paramsSorting.forEach(el => {
		// 	if(el.name === "angle" && el.value === undefined){
		// 		setSaveButtonActive(false)
		// 	}
		// })

        return () => {
            if(window.location.pathname.includes('/processes')) {

                close(true)

            }
        }
    }, [])

	useEffect(() => {
		if (treatmentType === 'template') {
			setAdditionalParams([...treatment.paramsSorting])
		}
	}, [treatment._formFields.length, treatment._template]);

    useEffect(() => {
			if (treatmentType === 'hole' && treatment.isEdit) {
				getTreatmentEditParams()
				return
			}
			setAdditionalParams([...treatment.paramsSorting])
    }, [getTreatmentType])

	const getTreatmentEditParams = () => {
		setAdditionalParams([...treatment.paramsSortingForEdit])
	}

    function saveData() {
			if(!saveButtonActive) return;
	    treatment.checkRequiredFields()
	    if(empty(Object.keys(treatment.errors))) {
				setSaveButtonActive(false);
		    treatment.validate()
					.then(() => {
						if (treatment.subType === 'mill') {
							treatment.calculateMillLength()
						}
						return Promise.resolve();
					})
			    .then(() => detail.validate(treatment))
			    .then(() => {
				    if(treatment.isNew) {
					    treatment.shouldRender = false;
					    return treatment.createCopy();
				    } else {
              if (treatmentType !== 'template') {
                return treatment.compareDataFromDbToDeleteTemplate()
                  .then(() => treatment.updateDb())
              } else {
                return treatment.updateDb()
              }
            }
			    })
			    .then(() => {
						setRunContour(true)
					setTimeout(() => {
						setRunContour(false)

					}, 200)
				    if(treatment.isNew) {

					    setSaveButtonActive(false);
				    } else {
					    close(false, false)
				    }
			    })
			    .catch((e) => console.log(e))
	    } else {
				treatment.showErrors()
	    }

	}


	function updateParams() {

		if (treatment) {
			setSaveButtonActive(true);
			treatment.shouldRender = true;
			if (treatmentType === 'hole' && treatment.isEdit) {
				getTreatmentEditParams()
				return
			}
			setAdditionalParams([...treatment.formFields]);
		}
	}

	const changeEdge = (e) => {
		let {value} = e.target;
		if (!empty(value)) {
			value = Number(value);
			const edge = construction.edges.find((item, i) => item.index === value);

			if (!empty(edge)) {
				treatment.edge = edge;
			}
		} else {
			treatment.edge = null;
		}
	};

	const changeTitle = () => {
		switch (treatmentType) {
			case "corner":
				return Languages.getTranslation('add_corner_title', true);
			case "groove":
				return Languages.getTranslation('add_groove_title', true);
			case "rabbet":
				return Languages.getTranslation('add_rabbet_title', true);
			case "circle":
				return Languages.getTranslation('circle-proc', true);
			case "rectangle":
				return Languages.getTranslation('rectangle-proc', true);
			case "uShape":
				return Languages.getTranslation('uShape-proc', true);
			case "hole":
				return Languages.getTranslation('hole', true);
			case 'mill':
				return Languages.getTranslation('add_mill_title', true);
			case 'bevel':
				return Languages.getTranslation('add_bevel_title', true)
			case 'template':
				return Languages.getTranslation('templates', true)
			case 'cutOut':
				return Languages.getTranslation('internal-milling', true)
			default:
				return "";
		}
	};

	return (
		<div className="processing">
			<div className="processing__container">
				<ModalHeader className="processing__header">
					<h3 className="processing__header-title">
            {changeTitle(detail.type)}
          </h3>
					<div className="processing__header-btn">
						<CloseBtn handler={() => close(true, true)}/>
					</div>
				</ModalHeader>
				<div className="processing__body">
					{["rabbet", "groove", "hole", "circle", "rectangle", "bevel", "template", 'cutOut'].indexOf(
						treatmentType
					) === -1 && treatment.type !== 'tableTop' ? (
						<div style={{width: "100%"}} className="param">
							<label className="param__label">
								{Languages.getTranslation("edge", true)}
							</label>
							<select
								defaultValue={
									!empty(treatment.edge)
										? typeof treatment.edge === "number"
											? treatment.edge
											: treatment.edge.index
										: null
								}
								onChange={changeEdge}
								className="param__default-select"
								style={{width: "100%"}}
							>
								<option value="">-</option>
								{!empty(construction.edges)
									? construction.edges.map((item, key) => {
										return (
											<option key={key} value={item.index}>
												{edgeTitle(item)}
											</option>
										);
									})
									: ""}
							</select>
						</div>
					) : (
						""
					)}
					{!empty(detail)
						? additionalParams.map((param, id) => {
							return (
								<Param
									updateParams={updateParams}
									key={`param_${id}`}
									param={param}
									treatmentType={treatmentType}
									treatment={treatment}
									detail={detail}
								/>
							);

						})
						: ""}
				</div>
			</div>
			{
				runContour && (
					<Contour
						key={detail.id}
						detail={detail}
						rects={detail.getCutoutsData()}
						holes={detail.holes}
					/>
				)
			}
			<ModalFooter className="processing__actions">
				<DefaultBtn
					title={Languages.getTranslation("save", true)}
					handler={saveData}
					disabled={!saveButtonActive}
				/>
			</ModalFooter>
		</div>
	);
};

export default Processing;
