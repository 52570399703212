import React, {useEffect, useRef, useState} from "react";
import "./index.scss";
import Languages, { LANGS } from "../../../../translation/Languages";
import {useDispatch, useSelector} from "react-redux";
import {projectOperations, projectSelectors} from "redux/project";
import { empty } from "../../../../helpers/helper";
import Requests from "../../../../api/API";
import Loader from "../../Loader";
import SubmitFurnitureModal from "../../Modal/SubmitFurnitureModal";
import EditFurnitureModal from "../../Modal/EditFurnitureModal";
import { langSelectors } from "redux/lang";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Errors from "../../../../models/Errors";
const _errors = new Errors([])
import HardwareTable from "../HardwareTable";
import HardwareRecommendedTable from "../HardwareRecommendedTable";
import Project from "../../../../models/Project";
import HardwareRecommendedCategory from "../HardwareRecommendedCategory";
import {errorMessageOperations} from "redux/errors";
import catalogImg from '../../../../media/catalog.svg'

const recommendedFurnitureByDiam = [
    {
        2: ["Samorizy", "Teleskopichni-kulkovi-napravlyayuchi", "Napryamni-prykhovanoho-montazhu"],
        4.5: ["Konfirmaty-ta-evrohvynty"],
        5: ["Minifiksy,-Maksifiksy", "Styazhky-Rafix-(Rafiks)-ta-TAB", "Polytsetrymachi-dlya-DSP", "Trymachi-dlya-skla"],
        7: ["Konfirmaty-ta-evrohvynty"],
        8: ["Minifiksy,-Maksifiksy", "Shkanty-meblevi-(chopyky)"],
        10: ["Opory-meblevi-rehulovani"],
        15: ["Minifiksy,-Maksifiksy"],
        18: ["Styazhky-Rafix-(Rafiks)-ta-TAB"],
        20: ["Styazhky-Rafix-(Rafiks)-ta-TAB"],
        26: ["Petli-meblevi-nakladni,-polunakladni,-vnutrishni", "Petli-meblevi-plavnoho-zakryvannya"],
        35: ["Petli-meblevi-nakladni,-polunakladni,-vnutrishni", "Petli-meblevi-plavnoho-zakryvannya"]
    }
]

const TableHardware = ({searchCatalog}) => {
    const currentFurniture = useSelector(projectSelectors.getFurniture)

    const dispatch = useDispatch();
    const construction = useSelector(projectSelectors.getConstruction);
    const { furnitures } = construction;
    const curLang = useSelector(langSelectors.getLangState);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadedHardware, setIsLoadedHardware] = useState(false);
    const [isLoadedRecommended, setIsLoadedRecommended] = useState(false);
    const [furnituresArr, setFurnituresArr] = useState([]);
    const [submitDelete, setSubmitDelete] = useState({ is: false, name: "", index: null });
    const [submitEdit, setSubmitEdit] = useState({ is: false, name: "", item: furnitures[0] });
    const [code, setCode] = useState("");
    const input = useRef(null);

    const [debouncedValue, setDebouncedValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [hardware, setHardware] = useState([])
    const [recommendFurniture, setRecommendFurniture] = useState([])
    const [recommendCategory, setRecommendCategory] = useState([])
    const [highlightedItems, setHighlightedItems] = useState([]);

    const dropdownRef = useRef(null);

    useEffect(() => {
        if (!empty(furnitures)) {
            setFurnituresArr(furnitures);

        }
    }, [furnitures]);


    useEffect(() => {
        const handleScroll = () => {
            const container = document.querySelector('.hardwareTab__list');
            if (!container) return;

            if (
                container.scrollHeight - container.scrollTop <= container.clientHeight + 10 &&
                !isFetching &&
                hasMore
            ) {
                search(currentPage + 1); // Загружаем данные для следующей страницы
            }
        };

        const container = document.querySelector('.hardwareTab__list');
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [currentPage, isFetching, hasMore, code]);
    useEffect(() => {
        const fetchRecommended =async () =>{
            setIsLoadedRecommended(true);
            try {
              const res =  await Project.getFurnitureRecommend();
              const resAlias =  await Project.getFurnitureRecommendCategory();
              if (!empty(res)){
                  const holesSide= res?.hole?.type_side
                  const holesBody= res?.hole?.type_body
                  // const holesSide= res[0]?.hole?.type_side?.map(hole => ({
                  //     ...hole, // Распаковываем существующий объект
                  //     type: "side" // Добавляем новое поле type со значением "side"
                  // }));
                  // const holesBody= res[0]?.hole?.type_body?.map(hole => ({
                  //     ...hole, // Распаковываем существующий объект
                  //     type: "body" // Добавляем новое поле type со значением "side"
                  // }));
                  const mergedHoles = [...holesBody, ...holesSide];
                  const groupedByDiam = mergedHoles.reduce((acc, current) => {
                      const found = acc.find(item => item.diam === current.diam);

                      if (found) {
                          // Если объект с таким diam уже есть, увеличиваем count
                          found.count += current.count;
                      } else {
                          // Если нет, добавляем объект в массив
                          acc.push({ ...current });
                      }

                      return acc;
                  }, []).sort((a, b) => parseFloat(a.diam) - parseFloat(b.diam));



                  const arr = groupedByDiam.map((entry) => {
                      const normalizedDiam = entry.diam.replace(',', '.'); // Преобразуем "4,5" -> "4.5"
                      const items = recommendedFurnitureByDiam[0][normalizedDiam] || []; // Проверяем наличие ключа

                      return {
                          ...entry,
                          items,
                      };
                  });

                  setRecommendFurniture(arr)
              }
              if(!empty(resAlias?.children)){

                  setRecommendCategory(resAlias.children)
                }

            } catch (error) {
                console.log('error', error)
            }finally {
                setIsLoadedRecommended(false);
            }
        }
        fetchRecommended()

    }, [])

    useEffect(() => {
        if (debouncedValue.length >= 3) {
            setHardware([]); // Очищаем массив
            setCurrentPage(1); // Сбрасываем текущую страницу на 1
            setHasMore(true); // Ставим флаг, что возможно будет больше данных
                search(1); // Запускаем поиск для первой страницы

        }else {
            setHardware([]);
            setCurrentPage(1);
        }
    }, [debouncedValue]);
    useEffect(() => {
        const delay = 500;
        const debounceTimer = setTimeout(() => {
            setDebouncedValue(code);
        }, delay);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [code]);

    const search = async (page = 1, perPage = 10) => {
        setIsLoadedHardware(true); // Отображаем загрузку
        setIsFetching(true); // Чтобы заблокировать дополнительные запросы

        try {
            const { data, meta } = await Requests.Materials.getHardware(code, page, perPage).catch((error) => {
                _errors.setError(error.message, 'error', true);
            });

            if (empty(data)) {
                if (page === 1) setHardware([]); // Если это первая страница, показываем "ничего не найдено"
                setHasMore(false); // Нет больше данных
            } else {
                setHardware((prev) =>
                    page === 1
                        ? data.map((item) => ({ ...item, count: 1, article: item.vendor_code })) // Если первая страница, очищаем массив
                        : [
                            ...prev,
                            ...data.map((item) => ({ ...item, count: 1, article: item.vendor_code })), // Подгружаем новые данные
                        ]
                );
                setHasMore(meta.current_page < meta.last_page); // Если текущая страница меньше последней, можно продолжать
            }

            setCurrentPage(meta.current_page); // Обновляем номер текущей страницы
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadedHardware(false);
            setIsFetching(false); // Разблокируем запросы
        }
    };




    const loadFurniture = async (article) => {
        Requests.Materials.getFurnitureAll(article)
            .then((data) => {
                if (!empty(data)) {
                    const tempData = [];
                    data.data.forEach((item) => {
                        const currentFur = furnitures.find((el) => (el.article ||  el.vendor_code) === item.vendor_code);
                        if (!empty(currentFur)) {
                            tempData.push({ ...item, link: item.link, count: currentFur.count, index: currentFur.index });
                        } else {
                            console.log("not find furnitures");
                        }
                    });
                    if(!empty(tempData)){
                        setFurnituresArr([...tempData]);
                        dispatch(projectOperations.updateFurniture(tempData));
                    }


                    setIsLoaded(false);
                }
                return Promise.resolve();
            })
            .catch((error) => console.log(error.message));
    };

    useEffect(() => {
        if (!empty(furnitures)) {
            setIsLoaded(true);
            let reqStr = "";
            furnitures.forEach((item, index) => {
                reqStr += `${index > 0 ? "&" : ""}vendorCodes[]=${item.article || item.vendor_code}`;
            });

            loadFurniture(reqStr)
                .then(() => {})
                .catch((error) => console.log(error));
        }
    }, []);



    const deleteOneHardware = (index) => {
        construction
            .deleteFurniture(index)
            .then(() => {
                setFurnituresArr((prev) => [...prev.filter((el) => el.index !== index)]);
                setSubmitDelete({ is: false, name: "", index: null });
            })
            .catch((error) => console.log(error));
    };



    const calcSum = () => {
        return furnituresArr
            .reduce((acc, el) => acc + el.count * el?.info?.price, 0)
            .toFixed(2);
    };

    const calcSumDiscount = () => {
        return furnituresArr
            .reduce((acc, { count, info }) => {
                const effectiveDiscount = info?.discount > info?.actionDiscount ? info?.discount : info?.actionDiscount;
                return effectiveDiscount > 0
                    ? acc + count * (info?.price * effectiveDiscount / 100)
                    : acc + 0;
            }, 0)
            .toFixed(2);
    };

    const closeSubmitDelete = () => {
        setSubmitDelete({ is: false, name: "", index: null });
    };
    const closeSubmitEdit = () => {
        setSubmitEdit({ is: false, name: "", item: null });
    };

    // Обработчик изменения значения count для одного объекта
    const handleCountChange = (index, newCount, item) => {
        setFurnituresArr((prevFurnituresArr) => {
            const updatedFurnitures = prevFurnituresArr.map((furniture, i) =>
                i === index
                    ? { ...furniture, count: newCount } // Сохраняем значение
                    : furniture
            );
            construction.updateFurniture(item.vendor_code, 'count', Number(newCount));

            return updatedFurnitures;
        });

        // Валидация поля
    };
    const submit = (selectedHardware) => {
        if(empty(selectedHardware)){
            _errors.setError(`${Languages.getTranslation("hardware-not-chos", true)}`, 'error', true)
            return;
        }
        const furnitureAlreadyExist = furnitures.some((el) => el?.vendor_code === selectedHardware[0]?.vendor_code)

        if(furnitureAlreadyExist){
            if (input.current) {
                input.current.value = "";
            }
            close();
            const mess = `${Languages.getTranslation("hardware", true)} ${Languages.getTranslation("articles", true)} ${selectedHardware[0]?.vendor_code} ${Languages.getTranslation("exist-in-project", true)}`

            setHardware([])
            dispatch(errorMessageOperations.switchStateError(
                { message: mess, type: 'error', show: true }))
            return
        }
        construction
            .setProjectFurnitures([...selectedHardware.map(el => ({...el, article: el.vendor_code}))])
            .then(() => {
                const arr =[
                    construction.furnitures[construction.furnitures.length - 1],
                    ...construction.furnitures.slice(0, -1),
                ]
                dispatch(
                    projectOperations.updateFurniture(arr)
                );
                setHardware([])
                dispatch(errorMessageOperations.switchStateError(
                    { message: Languages.getTranslation("added-furniture", true), type: 'success', show: true }))
                if (input.current) {
                    input.current.value = ""; // Сбрасываем значение инпута
                }
            });

        close();

    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            !empty(hardware)
        ) {
            setHardware([])
            if (input.current) {
                input.current.value = "";
            }
            close();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [hardware]);
    return (
        <div className="back">
            <div className="hardwareTables">
                <div className="hardwareTab">
                    <div className="hardwareTab_oneCol">
                        <div className="hardwareTab_oneCol__header">
                            <div className="hardwareTab_oneCol__header_wrap">
                                <div className="flex">
                                    <div className="hardwareTab_oneCol__header_wrap_nameCol">
                                        {Languages.getTranslation("added-product", true)}
                                    </div>
                                </div>
                                <div className="hardwareTab__add">


                                    <div className="hardwareTab__searchField">
                                        <i className="hardwareTab__input-icon"></i>
                                        <input
                                            className="hardwareTab__input"
                                            onChange={(e) => setCode(e.target.value)}
                                            ref={input}
                                            type="text"
                                            placeholder={Languages.getTranslation("enter-name-or-article", true)}
                                            autoComplete='off'
                                        />
                                    </div>

                                    {!empty(hardware) ? (
                                            <div className="hardwareTab__list" ref={dropdownRef}>
                                                {hardware.map((item, index) => {
                                                    if (item.active === "act" && (item.info.price > 0 || item.info.price_old > 0)) {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div className="hardwareTab__list-item" key={index}
                                                                     onClick={() => submit([item])}>

                                                                    <img
                                                                        className="hardwareTab__list-item-img"
                                                                        src={item.media.images[0].small}
                                                                        alt={Languages.getAvaliableTranslationBaseOnUk(item.translations)}/>
                                                                    <div className="hardwareTab__list-item-text"
                                                                         title={Languages.getAvaliableTranslationBaseOnUk(item.translations)}>
                                                                        {Languages.getAvaliableTranslationBaseOnUk(item.translations)}
                                                                    </div>
                                                                    <div className="hardwareTab__list-item-article">
                                                                        {`${item.vendor_code || item.article}`}
                                                                    </div>

                                                                    <div className="hardwareTab__list-item-price"
                                                                         style={{textAlign: 'center'}}>
                                                                        {Number(item.info.price).toFixed(2)} грн
                                                                    </div>
                                                                </div>
                                                                <div className="grey-divider"></div>

                                                            </React.Fragment>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        ) :
                                        <div>
                                        </div>
                                    }
                                    {isLoadedHardware &&  <div className="hardwareTab__list"><Loader /></div>}


                                    </div>
                                        <DefaultBtn
                                        title={Languages.getTranslation("search-in-catalog", true)}
                                        handler={searchCatalog}
                                        icon={catalogImg}
                                />
                            </div>
                            <div className="grey-divider"></div>


                            {isLoaded ? (
                                <div className="hardwareTab_oneCol__header_loader">
                                    <Loader/>
                                </div>
                            ) : !empty(furnituresArr) ? (
                                    <div className="hardwareTab_oneCol__table">
                                        <HardwareTable data={furnituresArr} handleCountChange={handleCountChange}
                                                       curLang={curLang}
                                                       setSubmitDelete={setSubmitDelete}
                                                       setSubmitEdit={setSubmitEdit}
                                        />
                                        <div className="hardwareTab_oneCol__table-sum"

                                        >
                                            <div className="hardwareTab_oneCol__table-sum-container">
                                                <div className="hardwareTab_oneCol__table-sum-name">
                                                    {`${Languages.getTranslation("total-sum", true)}: `}
                                                </div>
                                                <b className="hardwareTab_oneCol__table-sum-container-price">{calcSum() + " ₴"}</b>
                                            </div>

                                            <div className="hardwareTab_oneCol__table-sum-container">
                                                <div className="hardwareTab_oneCol__table-sum-name">
                                                    {`${Languages.getTranslation("total-sum-discont", true)}: `}
                                                </div>
                                                <b className="hardwareTab_oneCol__table-sum-container-price">{calcSumDiscount() + " ₴"}</b>
                                            </div>

                                            <div className="hardwareTab_oneCol__table-sum-container">
                                                <div className="hardwareTab_oneCol__table-sum-name">
                                                    {`${Languages.getTranslation("total-sum-for-payment", true)}: `}
                                                </div>
                                                <b className="hardwareTab_oneCol__table-sum-container-price">
                                                    {(calcSum() - calcSumDiscount()).toFixed(2) + " ₴"}
                                                </b>
                                            </div>
                                        </div>

                                    </div>
                                )

                                : (
                                    <div className="hardwareTab_oneCol__header_empty">
                                        {Languages.getTranslation("add-furnitures-in-list", true)}
                                    </div>
                                )}

                        </div>

                    </div>
                    {submitDelete.is && (
                        <SubmitFurnitureModal
                            isOpen={submitDelete.is}
                            index={submitDelete.index}
                            deleteOneHardware={deleteOneHardware}
                            close={closeSubmitDelete}
                            submitModalText={submitDelete.name}
                        />
                    )}
                    {submitEdit.is && (
                        <EditFurnitureModal
                            isOpen={submitEdit.is}
                            furnitures={furnitures}
                            setFurnituresArr={setFurnituresArr}
                            item={submitEdit.item}
                            curLang={curLang}
                            close={closeSubmitEdit}
                            submitModalText={submitEdit.name}
                        />
                    )}
                </div>
                <div className="vertical-line1"></div>

                <div className="hardwareRecommended">

                    <div className="hardwareRecommended-header">
                        {Languages.getTranslation("recommended-product", true)}

                    </div>
                    <div className="grey-divider"></div>
                    {isLoadedRecommended ? (
                        <Loader />
                    ) : (
                        <div className="hardwareRecommended-data">
                            {!empty(recommendFurniture) ? (
                            <div className="hardwareRecommended-data-furniture">

                                    <HardwareRecommendedTable data={recommendFurniture} curLang={curLang} onHoverItem={setHighlightedItems}/>
                            </div>
                                ) : (
                                    <div className="hardwareRecommended-data-empty">
                                        <span className="hardwareRecommended-data-empty-text">{Languages.getTranslation("empty-holes-project", true)}
                                       </span>
                                    </div>
                                )}
                                <div className="hardwareRecommended-category">
                            {!empty(recommendCategory) && (
                                <>
                                <div className="hardwareRecommended-category-header">
                                            {Languages.getTranslation("goods", true)}
                                        </div>
                                        <HardwareRecommendedCategory data={recommendCategory} loadFurniture={loadFurniture} highlightedItems={highlightedItems}/>
                                    </>
                                )}
                            </div>
                        </div>
                    )
                    }

                </div>


            </div>
        </div>
    );
};

export default TableHardware;