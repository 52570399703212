const DeleteProductTableItem = ({detail, index}) => {
  return <div className="deleteProduct__table-item">
    <div className="deleteProduct__table-number deleteProduct__table-itemStyle">{index + 1}</div>
    <div
      className="deleteProduct__table-name deleteProduct__table-itemStyle">
      {detail.name}
    </div>
    <div className="deleteProduct__table-data">
      <div className="deleteProduct__table-dataItem deleteProduct__table-itemStyle">{detail.l}</div>
      <div className="deleteProduct__table-dataItem deleteProduct__table-itemStyle">{detail.h}</div>
      <div className="deleteProduct__table-dataItem deleteProduct__table-itemStyle">{detail.count}</div>
    </div>
  </div>
}

export default DeleteProductTableItem;