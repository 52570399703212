import API from "config/api";
import Languages from "translation/Languages";
import {empty} from "../helpers/helper";

export default class Projects {
  constructor(axios, token, headers) {
    this.axios = axios;
    this.token = token;
    this.headers = headers;

  }

  deleteProject(id) {
    return this.axios.delete(`${API.ROUTE.delete_project()}/${id}`, this.headers).then(response => {
      if(response && response.data){
        return Promise.resolve(response.data)
      }else{
        return Promise.reject()
      }
    })
  }

  getProjectById(id) {
    return this.axios.get(API.ROUTE.get_project_list() + `/${id}`, this.headers).then(response => {
      if(response && response.data){
        return Promise.resolve(response.data)
      }else{
        return Promise.reject()
      }
    })
  }
  getProjectByOrderNumber(order) {
    return this.axios.get(API.ROUTE.get_project_list() + `/order-number/${order}`, this.headers).then(response => {
      if(response && response.data){
        return Promise.resolve(response.data)
      }else{
        return Promise.reject()
      }
    })
  }

  getUserFolders() {
    return this.axios.get(API.ROUTE.get_user_folders(), this.headers).then(response => {
      if(response && response.data){
        return Promise.resolve(response.data)
      }else{
        return Promise.reject()
      }
    })
  }

  getUserFoldersById({id, page}) {
		const params = {
			page,
			per_page: "15",
			constructor_type: "KMaster"
		}
		
		const searchParams = new URLSearchParams(params);
		
		if (id) {
			searchParams.append("project_folder_id", id);
		}
		
    return this.axios.get(`${API.ROUTE.get_file_folder()}?${searchParams.toString()}`, this.headers).then(response => {
      if(response && response.data){
        return Promise.resolve(response.data)
      }else{
        return Promise.reject()
      }
    })
  }

  async calcProject(data) {
    return this.axios.post(API.ROUTE.calc_project(), data, this.headers).then(response => {
      if(response && response.data){
        return Promise.resolve(response)
      }else{
        return Promise.reject()
      }
    })
  }
  async getDetailedReport(project) {

    const config = {
      ...this.headers,
      responseType: 'blob'
    };

    return this.axios.post(API.ROUTE.get_info_detailed_report(), project, config)
        .then(response => {
          if(response && response.data){
            const pdfBlob = new Blob([response.data], {type: 'application/pdf'});
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, '_blank');

            return Promise.resolve(response);
          } else {
            return Promise.reject();
          }
        })
  };
  async getFurnitureRecommend(json){
      this.headers = {
          headers: {
              ...this.headers.headers,
              app: "KM"
          }
      }

      return await this.axios
          .post(`${API.ROUTE.get_furniture_recommend()}`, json, this.headers)
          .then((response)=> {
              if(response && response.data)
                  return Promise.resolve(response.data)
              else
                  return Promise.reject()
          })
          .catch((error)=>{
              console.log(error.message)
          })
  }
  async getFurnitureRecommendCategory(alias, type) {
    return this.axios.get(`${API.ROUTE.search_catalog_alias_furniture()}/category-alias/${alias}/category-type/${type}`, this.headers)
        .then((response) => {
          if (response && response.data) {
            return Promise.resolve(response.data.data);
          }else{
            return Promise.reject()
          }
        })
  }
  async getProductsByAlias(alias, page, recommend = 1) {
    return this.axios.get(`${API.ROUTE.get_products_by_alias()}/${alias}?price=true&page=${page}&isRecommended=${recommend}`, this.headers)
        .then((response) => {
          if (response && response.data) {
            return Promise.resolve(response.data);
          }else{
            return Promise.reject()
          }
        })
  }
  async saveProject(name, folderName, file, images, constructor_type ="KMaster", provider_name, provider_version, provider_created_at) {
    const data = {
      name: name,
      file: file,
      project_folder_id: folderName,
      constructor_type,
      finalized: false,
      provider_name,
      provider_version: `${provider_version}`,
      provider_created_at
    };
    if (!empty(images)) {
      data.images = images;
    }
    return this.axios.post(API.ROUTE.save_project(), data, this.headers).then(response => {
      if(response && response.data){
        return Promise.resolve(response)
      }else{
        return Promise.reject()
      }
    })
  }
  async reportPdf(project) {

    const config = {
      ...this.headers,
      responseType: 'blob'
    };

    return this.axios.post(API.ROUTE.info_project(), project, config)
        .then(response => {
          if(response && response.data){
            const pdfBlob = new Blob([response.data], {type: 'application/pdf'});
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, '_blank');

            return Promise.resolve(response);
          } else {
            return Promise.reject();
          }
        })
  }

  async updateProject(id, name, file, images, constructor_type ="KMaster") {
    let data = {
      name: name,
      file: file,
      constructor_type,
      finalized: false,
    };

    if (!empty(images)) {
      data.images = images;
    }
    return this.axios.patch(`${API.ROUTE.update_project()}/${id}`, data, this.headers).then(response => {
      if(response && response.data){
        return Promise.resolve(response)
      } else{
        return Promise.reject()
      }
    })
  }

  getMapMaterials(data) {
    return this.axios.post(API.ROUTE.convert(), data, this.headers).then(response => {
      if(response && response.data){
        return Promise.resolve(response.data)
      }else{
        return Promise.reject()
      }
    })
  }

  editClientProject(name, owner, file, provider_name, provider_version, provider_created_at)  {
    const data = {
      name: name,
      owner: owner,
      file: file,
      provider_name,
      provider_version: `${provider_version}`,
      provider_created_at
    };

    return this.axios.post(API.ROUTE.edit_user_project(), data, this.headers).then(response => {
      if(response && response.data){
        return Promise.resolve(response)
      }else{
        return Promise.reject()
      }
    })
  }
  sendSupportMessage(data)  {
    
    const headers = {
      ...this.headers.headers,
      'Content-Type': 'multipart/form-data',
    };
      
    return this.axios.post(API.ROUTE.sendSupportMessage(), data, { headers } ).then(response => {
      if(response && response.data){
        return Promise.resolve(response)
      }else{
        return Promise.reject()
      }
    })
  }

  async importProject(data){
    const dataInfo = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: data
    }

    return this.axios.post(API.ROUTE.import_project(), dataInfo, this.headers)
  }
}
