import {
	BoxGeometry,
	BufferGeometry,
	CircleGeometry,
	EdgesGeometry,
	Line,
	LineBasicMaterial,
	LineDashedMaterial,
	LineSegments,
	Mesh,
	MeshBasicMaterial,
	MeshStandardMaterial,
	Shape,
	ShapeGeometry,
	Vector2,
	Vector3
} from "three";
import {empty} from "../../helpers/helper";
import {v4 as uuidv4} from "uuid";
import * as THREE from "three";
import Languages from "../../translation/Languages";

const materialColor = "#eeebed";
export default class Helpers {
	static ShapeGeometrySegments = 32;
	// static emptyEdgeColor = '#b6b5b5';
	static mMaterialColor = "#eeebed";
	static backSidemMaterialColor = "#c9c8c8";
	static cutMaterialColor = "#c4c3c3";
	static bevelColor = "#c2c0c1";
	static emptyEdgeColor = '#eeebed';
	static contourLinesColor = "#A04382";
	// static contourLinesColor = "red";
	static colorForActive = '#0020f3';
	static colorForSelected = '#049b45'
	static colorForError = '#ef0e0e'
	static sizesLineColor = '#000'
	static getArcGeometry(points, fromDepth, toDepth) {
		const vertices = [];
		const faces = [];

		for (let point of points) {
			vertices.push(new Vector3(point.x, point.y, fromDepth));
			vertices.push(new Vector3(point.x, point.y, toDepth));
		}

		let iteration = 0;
		for (let step = 0; step < vertices.length / 2; step++) {
			faces.push([iteration + 3, iteration + 1, iteration + 2]);
			iteration++;
			faces.push([iteration, iteration - 1, iteration + 1]);
			iteration++;
		}

		faces.pop();
		faces.pop();

		const geometry = new BufferGeometry();

		const pointsNew = [];

		for (let face of faces) {
			pointsNew.push(vertices[face[0]]);
			pointsNew.push(vertices[face[1]]);
			pointsNew.push(vertices[face[2]]);
		}

		geometry.setFromPoints(pointsNew);
		geometry.computeVertexNormals();
		return geometry;
	}

	static getLineMaterial(color = null) {
		return new LineBasicMaterial({
			color: color || this.contourLinesColor,
			linewidth: 1,
			linejoin: "miter",
			side: 2,
		})
	}

	static getLineDashedMaterial(color = null) {
		return new LineDashedMaterial({
			color: color || 0x0000,
			linewidth: 1,
			scale: 1,
			dashSize: 10,
			gapSize: 10,
		});
	}
	static getColorByThickEdge(thick) {
		if (thick <= 0.6) return "green";
		if (thick <= 0.8) return "orange";
		if (thick <= 1) return "lightblue";
		if (thick < 2) return "blue";
		if (thick === 2) return "purple";
	}

	static getHighColorByThickEdge(thick) {
		if (thick <= 0.6) return { r: 0.023529411764705882, g: 1, b: 0.023529411764705882 };
    if (thick <= 0.8) return { r: 1, g: 0.9647058823529412, b: 0 };
    if (thick <= 1) return { r: 0, g: 0.8156862745098039, b: 1 };
    if (thick < 2) return { r: 0, g: 0.5176470588235295, b: 1 };
    if (thick === 2) return { r: 0.8313725490196079, g: 0, b: 1 };
	}

	static getMaterial3D(color = null) {
		return new MeshStandardMaterial({
			color: color || materialColor,
			transparent: true,
			opacity: 0.8,
			// color: color || 'red',
			// transparent: false,
			// opacity: 1,
			side: 2,
		})
	}

	static getMaterialPreCutting(color = null) {
		return new MeshStandardMaterial({
			color: color || materialColor,
			transparent: false,
			opacity: 1,
			// color: color || 'red',
			// transparent: false,
			// opacity: 1,
			side: 2,
		})
	}

	static getMaterial3DTransparent() {
		return new MeshStandardMaterial({
			color: materialColor,
			transparent: true,
			opacity: 0,
			side: 2,
		})
	}
	static isCloseEnough(val1, val2, epsilon=0.000001) {
		return Math.abs(val1 - val2) < epsilon;
	}
	static findIntersection(segment1Start, segment1End, segment2Start, segment2End) {
		const x1 = segment1Start.x;
		const y1 = segment1Start.y;
		const x2 = segment1End.x;
		const y2 = segment1End.y;
		const x3 = segment2Start.x;
		const y3 = segment2Start.y;
		const x4 = segment2End.x;
		const y4 = segment2End.y;

		const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

		if (denominator === 0) {
			// Відрізки паралельні або збігаються
			return null;
		}

		let px = ((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) / denominator;
		let py = ((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) / denominator;

		if (
			!this.isCloseEnough(px, Math.min(x1, x2)) && px < Math.min(x1, x2) ||
			!this.isCloseEnough(px, Math.max(x1, x2)) && px > Math.max(x1, x2) ||
			!this.isCloseEnough(px, Math.min(x3, x4)) && px < Math.min(x3, x4) ||
			!this.isCloseEnough(px, Math.max(x3, x4)) && px > Math.max(x3, x4) ||
			!this.isCloseEnough(py, Math.min(y1, y2)) && py < Math.min(y1, y2) ||
			!this.isCloseEnough(py, Math.max(y1, y2)) && py > Math.max(y1, y2) ||
			!this.isCloseEnough(py, Math.min(y3, y4)) && py < Math.min(y3, y4) ||
			!this.isCloseEnough(py, Math.max(y3, y4)) && py > Math.max(y3, y4)
		) {
			// Точка перетину лежить поза межами відрізків
			return null;
		}


		return { x: px, y: py,};
	}

	static findIntersectionOfSegmentAndArc(_startPoint, _endPoint, _center, radius, startAngle, endAngle) {
		const startPoint = {x: parseFloat(_startPoint.x.toFixed(2)), y: parseFloat(_startPoint.y.toFixed(2))}
		const endPoint = {x: parseFloat(_endPoint.x.toFixed(2)), y: parseFloat(_endPoint.y.toFixed(2))}
		const center = {x: parseFloat(_center.x.toFixed(2)), y: parseFloat(_center.y.toFixed(2))}

		const dx = endPoint.x - startPoint.x;
		const dy = endPoint.y - startPoint.y;
		const d = { x: dx, y: dy };
		const f = { x: startPoint.x - center.x, y: startPoint.y - center.y };
		const a = dx * dx + dy * dy;
		const b = 2 * (f.x * dx + f.y * dy);
		const c = f.x * f.x + f.y * f.y - radius * radius;

		const discriminant = parseFloat((b * b - 4 * a * c).toFixed(2));



		if (discriminant < 0) {
			// Відрізок і дуга не перетинаються
			return null;
		}

		function isAngleBetween(angle, startAngle, endAngle) {
			// Если startAngle > endAngle, значит дуга пересекает линию x=0 (или y=0).
			// В этом случае, мы должны проверить две поддуги отдельно: от исходного угла до 2π и от 0 до конечного угла.
			if (startAngle > endAngle) {
				return (angle >= startAngle && angle <= Math.PI * 2) || (angle >= 0 && angle <= endAngle);
			} else {
				return angle >= startAngle && angle <= endAngle;
			}
		}

		// function isAngleBetween(angle, startAngle, endAngle) {
		// 	if (startAngle < endAngle) {
		// 		return angle >= startAngle && angle <= endAngle;
		// 	} else {
		// 		return angle >= startAngle || angle <= endAngle;
		// 	}
		// }
		const t1 = (-b + Math.sqrt(discriminant)) / (2 * a);
		const t2 = (-b - Math.sqrt(discriminant)) / (2 * a);


		if ((t1 >= 0 && t1 <= 1) || (t2 >= 0 && t2 <= 1)) {
			let t;
			if (t1 >= 0 && t1 <= 1) {
				t = t1;
			} else if (t2 <= 0) {
				t = t1;
			} else {
				t = t2;
			}
			const intersectionPoint = {
				x: startPoint.x + t  * d.x,
				y: startPoint.y + t * d.y,
			};

			// const intersectionPoint = {
			// 	x: startPoint.x + t1 * d.x,
			// 	y: startPoint.y + t1 * d.y
			// };

			let intersectionAngle = Math.atan2(intersectionPoint.y - center.y, intersectionPoint.x - center.x);


			if (isAngleBetween(intersectionAngle, startAngle, endAngle)) {
				return intersectionPoint;
			}
		}
		return null;
	}

	static calculateArcIntersections(center1, radius1, startAngle1, endAngle1, center2, radius2, startAngle2, endAngle2) {
		const intersections = [];

		// Знаходимо відрізок, що з'єднує центри дуг
		const dx = center2.x - center1.x;
		const dy = center2.y - center1.y;
		const segment = {
			startX: center1.x,
			startY: center1.y,
			endX: center1.x + dx,
			endY: center1.y + dy
		};

		// Перевіряємо перетин відрізка з першою дугою
		const segmentIntersections1 = this.findIntersectionOfSegmentAndArc({x: segment.startX, y: segment.startY}, {x: segment.endX, y: segment.endY}, center1, radius1, startAngle1, endAngle1);
		if(!empty(segmentIntersections1)) {
			intersections.push(segmentIntersections1);
		}


		// Перевіряємо перетин відрізка з другою дугою
		const segmentIntersections2 = this.findIntersectionOfSegmentAndArc({x: segment.startX, y: segment.startY}, {x: segment.endX, y: segment.endY}, center2, radius2, startAngle2, endAngle2);
		if(!empty(segmentIntersections2)) {
			intersections.push(segmentIntersections2);
		}

		return intersections;
	}

	static circleIntersection(center1, radius1, center2, radius2) {
		const d = Math.sqrt((center2.x - center1.x) ** 2 + (center2.y - center1.y) ** 2);
		if (d > radius1 + radius2) { return []; } // circles are too far apart, no solution
		if (d < Math.abs(radius1 - radius2)) { return []; } // one circle contains the other, no solution
		if (d === 0 && radius1 === radius2) { return []; } // circles coincide, no solution

		const a = (radius1 * radius1 - radius2 * radius2 + d * d) / (2 * d);
		const h = Math.sqrt(radius1 * radius1 - a * a);

		const cx2 = center1.x + a * (center2.x - center1.x) / d;
		const cy2 = center1.y + a * (center2.y - center1.y) / d;

		return [
			{x: cx2 + h * (center2.y - center1.y) / d, y: cy2 - h * (center2.x - center1.x) / d},
			{x: cx2 - h * (center2.y - center1.y) / d, y: cy2 + h * (center2.x - center1.x) / d}
		];
	}
	static isAngleInArc(angle, arcStart, arcEnd) {
		if (arcStart < arcEnd) {
			return angle >= arcStart && angle <= arcEnd;
		} else {
			return angle >= arcStart || angle <= arcEnd;
		}
	}

	static findIntersectionOfTwoArcs(center1, radius1, startAngle1, endAngle1, center2, radius2, startAngle2, endAngle2) {
		const circleIntersections = this.circleIntersection(center1, radius1, center2, radius2);
		const intersections = [];

		for (const point of circleIntersections) {
			const angle1 = Math.atan2(point.y - center1.y, point.x - center1.x);
			const angle2 = Math.atan2(point.y - center2.y, point.x - center2.x);

			if (this.isAngleInArc(angle1, startAngle1, endAngle1) && this.isAngleInArc(angle2, startAngle2, endAngle2)) {
				intersections.push(point);
			}
		}
		return intersections.length > 0 ? intersections : null;
	}

	static createMillFromSmiles (detail) {
		if (!empty(detail.smiles)) {
			detail.smiles.forEach(smile => {
				const newSmile = {
					type: 'smile',
					subType: 'mill',
					width: Number(smile.width),
					edgeSide: ['left', 'right', 'top', 'bottom',].find(el => smile.side.split('_').includes(el)),
					additionalInfo: `From Smiles\n ${JSON.stringify(smile)}`,
					edge: !empty(smile.edge) ? Number(smile.edge) : null,
					dataForConstructor: {
						width: Number(smile.width),
						x: Number(smile.x),
						y: Number(smile.y),
					}
				}
				newSmile.offset = ['top', 'bottom'].includes(smile.side.split('_')[1]) ?
					Number(smile.x) :
					Number(smile.y)
				detail.mills.push(newSmile)
			})
			detail.smiles = []
		}
	}

	static updateContour(l, h, contour = [], edges = []) {
		let newContour = [];

		if(empty(contour) || contour.some(el => !empty(el.id))) {
			const sides = ['left', 'top', 'right', 'bottom'];
			let line;
			sides.forEach(side => {
				switch (side) {
					case 'left':
						line = this.createLine(0, 0, 0, h, edges.left);
						break
					case 'top':
						line = this.createLine(0, h, l, h, edges.top);
						break
					case 'right':
						line = this.createLine(l, h, l, 0, edges.right);
						break
					case 'bottom':
						line = this.createLine(l, 0, 0, 0, edges.bottom);
						break
				}
				line.elementSide = side;
				line.elementType = 'contour';
				newContour.push(line);
			})
		}
		else {
			const leftY = [];
			contour.forEach(el => {
				el.holes = [];
				if(el.type === 'arc') {
					const angle = this.calculateAngle(
						{x: el.xc, y: el.yc},
						{x: el.x1, y: el.y1},
						{x: el.x2, y: el.y2}
					)
					el.angle = angle.angle;
					el.startAngle = angle.angleStart * (180 / Math.PI);
					el.endAngle = angle.angleEnd * (180 / Math.PI);
					el.startAngleRad = angle.angleStart;
					el.endAngleRad = angle.angleEnd;
					el.length = this.calculateArcLength(el.r, angle.angle);
				} else {
					el.length = this.calculateDistance(el.x1, el.y1, el.x2, el.y2);
				}
				if(el.x1 === 0) {
					leftY.push(el.y1);
				}
			})
			leftY.sort((a, b) => a - b);
			const start = contour.findIndex(el => el.x1 === 0 && el.y1 === leftY[0]);
			newContour = [...contour.slice(start), ...contour.slice(0, start)];
		}
		return newContour
	}

	static calcLength(center=false, startPoint, endPoint, clockwise= false) {
		if(empty(center)) {

		} else {

		}
	}

	static calculateAngle(center, startPoint, endPoint, clockwise= false, from='') {
		const dx = endPoint.x < startPoint.x ? startPoint.x - endPoint.x : endPoint.x - startPoint.x;
		const dy = endPoint.y < startPoint.y ? startPoint.y - endPoint.y : endPoint.y - startPoint.y;
		let angleStart = Math.atan2(startPoint.y - center.y, startPoint.x - center.x);
		let angleEnd = Math.atan2(endPoint.y - center.y, endPoint.x - center.x);
		let angle = Math.abs(angleEnd) - Math.abs(angleStart);
		if(clockwise) {
			angleStart = Math.atan2(endPoint.y - center.y, endPoint.x - center.x);
			angleEnd = Math.atan2(startPoint.y - center.y, startPoint.x - center.x);
			angle = Math.abs(angleStart) - Math.abs(angleEnd)
		}
		if (angle < 0) {
			angle = Math.PI + angle;
		}
		// if (angle >= 0 && !clockwise) {
		// 	angle = 2 * Math.PI - angle;
		// }

		return {angle, angleStart, angleEnd};
	}

	static calculateIntersection(segmentStartX, segmentStartY, segmentEndX, segmentEndY, centerX, centerY, radius, arcStartX, arcStartY, arcEndX, arcEndY, clockwise=false) {
		const intersectionPoints = [];

		// Step 1: Calculate the arc angle
		const angle = Math.atan2(centerY - arcStartY, centerX - arcStartX);

		// Step 2: Calculate the sweep angle
		const endAngle = Math.atan2(centerY - arcEndY, centerX - arcEndX);
		let sweepAngle = endAngle - angle;

		// Adjust sweep angle based on the clockwise parameter
		if (!clockwise) {
			if (sweepAngle > 0) {
				sweepAngle -= 2 * Math.PI;
			} else {
				sweepAngle += 2 * Math.PI;
			}
		}

		function isPointOnSegment(x, y, startX, startY, endX, endY) {
			const minX = Math.min(startX, endX);
			const maxX = Math.max(startX, endX);
			const minY = Math.min(startY, endY);
			const maxY = Math.max(startY, endY);

			return x >= minX && x <= maxX && y >= minY && y <= maxY;
		}
		// Step 3: Calculate the parameterized representation of the arc and check for intersection
		for (let t = 0; t <= 1; t += 0.1) {
			const arcX = centerX + radius * Math.cos(angle + t * sweepAngle);
			const arcY = centerY + radius * Math.sin(angle + t * sweepAngle);

			// Step 4: Check if the point lies on the line segment
			if (isPointOnSegment(arcX, arcY, segmentStartX, segmentStartY, segmentEndX, segmentEndY)) {
				intersectionPoints.push({ x: arcX, y: arcY });
			}
		}

		return intersectionPoints;
	}

	static calculateDistance(x1, y1, x2, y2) {
		const deltaX = x2 - x1;
		const deltaY = y2 - y1;
		return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
	}

	static calculateArcLength(r, startAngleRad, endAngleRad) {
		const maxAngle = Math.max(...[startAngleRad, endAngleRad])
		const minAngle = Math.min(...[startAngleRad, endAngleRad])
		let neededAngle = Math.abs(maxAngle) - Math.abs(minAngle)
		if(neededAngle === 0) {
			neededAngle = Math.abs(startAngleRad) + Math.abs(endAngleRad)
		}
		return Math.abs(r * neededAngle);
	}

	// static calculateArcLength(cx, cy, x1, y1, x2, y2, r) {
	// 	const angle1 = Math.atan2(y1 - cy, x1 - cx);
	// 	const angle2 = Math.atan2(y2 - cy, x2 - cx);
	// 	const dAngle = angle2 - angle1;
	//
	// 	// Нормалізуємо кут до відрізку [0, 2π], якщо він перебільшує
	// 	// if (dAngle < 0) {
	// 	// 	dAngle += 2 * Math.PI;
	// 	// } else if (dAngle > 2 * Math.PI) {
	// 	// 	dAngle -= 2 * Math.PI;
	// 	// }
	// 	const arcLength = r * Math.abs(dAngle);
	// 	return arcLength.toFixed(1);
	// }

	static createLine = (x1, y1, x2, y2, edge= null, color = null, id=null) => {
		return {
			"type": "line",
			"x1": x1,
			"y1": y1,
			"x2": x2,
			"y2": y2,
			"holes": [],
			"color": color,
			"edge" : edge,
			"id": id,
			"length": this.calculateDistance(x1, y1, x2,y2)
		}
	}

	static createArc = (x1, y1, x2, y2, r, xc, yc, dir= true, edge = null, color=null, id=null) => {
		const angle = this.calculateAngle(
			{x: xc, y: yc},
			{x: x1, y: y1},
			{x: x2, y: y2},
		)
		return {
			"type": "arc",
			"x1": x1,
			"y1": y1,
			"x2": x2,
			"y2": y2,
			"xc": xc,
			"yc": yc,
			"r": r,
			"id": id,
			"dir": dir,
			"edge": edge,
			"color": color,
			"holes": [],
			"angle": angle.angle,
			"startAngle": angle.angleStart * (180 / Math.PI),
			"endAngle": angle.angleEnd * (180 / Math.PI),
			"startAngleRad": angle.angleStart,
			"endAngleRad": angle.angleEnd,
			"length": this.calculateArcLength(r, angle.angleStart, angle.angleEnd)
		}
	}
	static addContourSides(contour, l, h) {
		let leftY = 0;
		let rightY = 0;
		let minRightY = 10000
		contour.forEach(el => {
			if (!el) {
				return
			}
			if(el.x1 === 0) {
				leftY = Math.max(leftY,  el.y1);
			}
			if(el.x1 >= l - 1) {
				rightY = Math.max(rightY,  el.y1);
				minRightY = Math.min(minRightY,  el.y1);
			}
		})

		const startTop = contour.findIndex(el => el?.x1 === 0 && el?.y1 === leftY)
		const startRight = contour.findIndex(el => el?.x1 >= l - 1 && el?.y1 === rightY)
		const startBottom = contour.findIndex(el => el?.x1 >= l - 1 && el?.y1 === minRightY)

		return contour.map((el, i) => {
			let side;
			if(i <= startTop) {
				side = 'left'
			} else if(i <= startRight) {
				side = 'top'
			} else if(i <= startBottom) {
				side = 'right'
			} else {
				side = 'bottom'
			}
			return {...el, detailSide: side}
		})
	}
	static 	generateElementsByEdge(proc, elements, angle, edgesInProject) {
		const last = elements.length - 1;
		switch (proc.type) {
			case 'gEdge':
				return elements.map((el, i) => {
					const neededEdge = edgesInProject.find(_edge => _edge.index === el.edge)
					if (!empty(el.edge)) {
						switch (angle) {
							case 'left_top':
								switch (elements.length) {
									case 3:
										if (i === 0) {
											el.y1 -= neededEdge.thickness
											el.y2 -= neededEdge.thickness
										} else if (i === 1) {
											el.x2 += neededEdge.thickness
											el.y1 -= neededEdge.thickness
											el.r += neededEdge.thickness
										} else {
											el.x1 += neededEdge.thickness
											el.x2 += neededEdge.thickness
										}
										break;
									case 2:
										if (elements[0].type === 'arc') {
											if (i === 0) {
												el.y1 -= neededEdge.thickness
												el.x2 += neededEdge.thickness
												el.r += neededEdge.thickness
											} else if (i === 1) {
												el.x1 += neededEdge.thickness
												el.x2 += neededEdge.thickness
											}
										} else {
											if (i === 0) {
												el.y1 -= neededEdge.thickness
												el.y2 -= neededEdge.thickness
											} else if (i === 1) {
												el.y1 -= neededEdge.thickness
												el.r += neededEdge.thickness
												el.x2 += neededEdge.thickness
											}
										}
										break;
									case 1:
										el.y1 -= neededEdge.thickness
										el.x2 += neededEdge.thickness
										el.r += neededEdge.thickness
										break;
								}
								break;
							case 'right_top':
								switch (elements.length) {
									case 3:
										if (i === 0) {
											el.x1 -= neededEdge.thickness
											el.x2 -= neededEdge.thickness
										} else if (i === 1) {
											el.x1 -= neededEdge.thickness
											el.y2 -= neededEdge.thickness
											el.r += neededEdge.thickness
										} else {
											el.y1 -= neededEdge.thickness
											el.y2 -= neededEdge.thickness
										}
										break;
									case 2:
										if (elements[0].type === 'arc') {
											if (i === 0) {
												el.x1 -= neededEdge.thickness
												el.x2 -= neededEdge.thickness
												el.y2 -= neededEdge.thickness
												el.r += neededEdge.thickness
											} else if (i === 1) {
												el.y1 -= neededEdge.thickness
												el.y2 -= neededEdge.thickness
											}
										} else {
											if (i === 0) {
												el.x1 -= neededEdge.thickness
												el.x2 -= neededEdge.thickness
											} else if (i === 1) {
												el.x1 -= neededEdge.thickness
												el.y2 -= neededEdge.thickness
												el.r += neededEdge.thickness
											}
										}
										break;
									case 1:
										el.x1 -= neededEdge.thickness
										el.y2 -= neededEdge.thickness
										el.r += neededEdge.thickness
										break;
								}
								break;
							case 'right_bottom':
								switch (elements.length) {
									case 3:
										if (i === 0) {
											el.y1 += neededEdge.thickness
											el.y2 += neededEdge.thickness
										} else if (i === 1) {
											el.y1 += neededEdge.thickness
											el.x2 -= neededEdge.thickness
											el.r += neededEdge.thickness
										} else {
											el.x1 -= neededEdge.thickness
											el.x2 -= neededEdge.thickness
										}
										break;
									case 2:
										if (elements[0].type === 'arc') {
											if (i === 0) {
												el.y1 += neededEdge.thickness
												el.x2 -= neededEdge.thickness
												el.r += neededEdge.thickness
											} else if (i === 1) {
												el.x1 -= neededEdge.thickness
												el.x2 -= neededEdge.thickness
											}
										} else {
											if (i === 0) {
												el.y1 += neededEdge.thickness
												el.y2 += neededEdge.thickness
											} else if (i === 1) {
												el.y1 += neededEdge.thickness
												el.x2 -= neededEdge.thickness
												el.r += neededEdge.thickness
											}
										}
										break;
									case 1:
										el.y1 += neededEdge.thickness
										el.x2 -= neededEdge.thickness
										el.r += neededEdge.thickness
										break;
								}
								break;
							case 'left_bottom':
								switch (elements.length) {
									case 3:
										if (i === 0) {
											el.x1 += neededEdge.thickness
											el.x2 += neededEdge.thickness
										} else if (i === 1) {
											el.x1 += neededEdge.thickness
											el.y2 += neededEdge.thickness
											el.r += neededEdge.thickness
										} else {
											el.y1 += neededEdge.thickness
											el.y2 += neededEdge.thickness
										}
										break;
									case 2:
										if (elements[0].type === 'arc') {
											if (i === 0) {
												el.x1 += neededEdge.thickness
												el.y2 += neededEdge.thickness
												el.r += neededEdge.thickness
											} else if (i === 1) {
												el.y1 += neededEdge.thickness
												el.y2 += neededEdge.thickness
											}
										} else {
											if (i === 0) {
												el.x1 += neededEdge.thickness
												el.x2 += neededEdge.thickness
											} else if (i === 1) {
												el.x1 += neededEdge.thickness
												el.y2 += neededEdge.thickness
												el.r += neededEdge.thickness
											}
										}
										break;
									case 1:
										el.x1 += neededEdge.thickness
										el.y2 += neededEdge.thickness
										el.r += neededEdge.thickness
										break;
								}
								break;
						}
						if(el.type === 'arc'){
							el.length = this.calculateArcLength(el.r, el.startAngleRad, el.endAngleRad)
						} else {
							el.length  = this.calculateDistance(el.x1, el.y1, el.x2, el.y2)
						}
					}
					if(el.type === 'arc'){
						el.length = this.calculateArcLength(el.r, el.startAngleRad, el.endAngleRad)
					} else {
						el.length  = this.calculateDistance(el.x1, el.y1, el.x2, el.y2)
					}
					return el
				})
			case 'radiusEdge':
				return elements.map((el, i) => {
					const neededEdge = edgesInProject.find(_edge => _edge.index === el.edge)
					if (!empty(el.edge)) {
						switch (angle) {
							case 'left_bottom':
								el.x1 += neededEdge.thickness
								el.x2 += neededEdge.thickness
								el.y1 += neededEdge.thickness
								el.y2 += neededEdge.thickness
								if(el.type === 'arc'){
									el.r += neededEdge.thickness
								}
								break;
							case 'left_top':
								el.x1 += neededEdge.thickness
								el.x2 += neededEdge.thickness
								el.y1 -= neededEdge.thickness
								el.y2 -= neededEdge.thickness
								if(el.type === 'arc'){
									el.r += neededEdge.thickness
								}
								break;
							case 'right_top':
								el.x1 -= neededEdge.thickness
								el.x2 -= neededEdge.thickness
								el.y1 -= neededEdge.thickness
								el.y2 -= neededEdge.thickness
								if(el.type === 'arc'){
									el.r += neededEdge.thickness
								}
								break;
							case 'right_bottom':
								el.x1 -= neededEdge.thickness
								el.x2 -= neededEdge.thickness
								el.y1 += neededEdge.thickness
								el.y2 += neededEdge.thickness
								if(el.type === 'arc'){
									el.r += neededEdge.thickness
								}
								break;
						}
						if(el.type === 'arc'){
							el.length = this.calculateArcLength(el.r, el.startAngleRad, el.endAngleRad)
						} else {
							el.length  = this.calculateDistance(el.x1, el.y1, el.x2, el.y2)
						}
					}
					if(el.type === 'arc'){
						el.length = this.calculateArcLength(el.r, el.startAngleRad, el.endAngleRad)
					} else {
						el.length  = this.calculateDistance(el.x1, el.y1, el.x2, el.y2)
					}
					return el
				})
			case 'uShape':
				return elements.map((el, i) => {
					const neededEdge = edgesInProject.find(_edge => _edge.index === el.edge)
					if (!empty(el.edge)) {

						switch (proc.edgeSide) {
							case 'top':
								if(i === 0) {
									el.x1 -= neededEdge.thickness
									el.x2 -= neededEdge.thickness
								} else if(i === last) {
									el.x1 += neededEdge.thickness
									el.x2 += neededEdge.thickness
								} else {
									if(el.type === 'arc'){
										el.r += neededEdge.thickness
										if(i === 1) {
											el.x1 -= neededEdge.thickness
											el.y2 -= neededEdge.thickness
										} else {
											el.x2 += neededEdge.thickness
											el.y1 -= neededEdge.thickness
										}
									} else {
										el.y1 -= neededEdge.thickness
										el.y2 -= neededEdge.thickness
									}
								}
								break;
							case 'bottom':
								if(i === 0) {
									el.x1 += neededEdge.thickness
									el.x2 += neededEdge.thickness
								} else if(i === last) {
									el.x1 -= neededEdge.thickness
									el.x2 -= neededEdge.thickness
								} else {

									if(el.type === 'arc'){
										el.r += neededEdge.thickness
										if(i === 1) {
											el.x1 += neededEdge.thickness
											el.y2 += neededEdge.thickness
										} else {
											el.x2 -= neededEdge.thickness
											el.y1 += neededEdge.thickness
										}
									} else {
										el.y1 += neededEdge.thickness
										el.y2 += neededEdge.thickness
									}
								}
								break;
							case 'left':
								if(i === 0) {
									el.y1 -= neededEdge.thickness
									el.y2 -= neededEdge.thickness
								} else if(i === last) {
									el.y1 += neededEdge.thickness
									el.y2 += neededEdge.thickness
								} else {

									if(el.type === 'arc'){
										el.r += neededEdge.thickness
										if(i === 1) {
											el.y1 -= neededEdge.thickness
											el.x2 += neededEdge.thickness
										} else {
											el.y2 += neededEdge.thickness
											el.x1 += neededEdge.thickness
										}
									} else {
										el.x1 += neededEdge.thickness
										el.x2 += neededEdge.thickness
									}
								}
								break;
							case 'right':
								if(i === 0) {
									el.y1 += neededEdge.thickness
									el.y2 += neededEdge.thickness
								} else if(i === last) {
									el.y1 -= neededEdge.thickness
									el.y2 -= neededEdge.thickness
								} else {
									if(el.type === 'arc'){
										el.r += neededEdge.thickness
										if(i === 1) {
											el.y1 += neededEdge.thickness
											el.x2 -= neededEdge.thickness
										} else {
											el.y2 -= neededEdge.thickness
											el.x1 -= neededEdge.thickness
										}
									} else {
										el.x1 -= neededEdge.thickness
										el.x2 -= neededEdge.thickness
									}
								}
								break;
						}

					}
					if(el.type === 'arc'){
						el.length = this.calculateArcLength(el.r, el.startAngleRad, el.endAngleRad)
					} else {
						el.length  = this.calculateDistance(el.x1, el.y1, el.x2, el.y2)
					}
					return el
				})
			case 'smile':
				return elements.map((el, i) => {
					const neededEdge = edgesInProject.find(_edge => _edge.index === el.edge)
					if (!empty(el.edge)) {
						switch (proc.edgeSide) {
							case 'top':
								if (el.type === 'arc') {
									if (i % 2 !== 0) {
										i === 1 || i === 7 ? el.x2 -= neededEdge.thickness : el.x1 -= neededEdge.thickness
										el.r -= neededEdge.thickness
									} else {
										i === 2 || i === 8 ? el.x1 -= neededEdge.thickness : el.x2 -= neededEdge.thickness
										el.r += neededEdge.thickness
									}
									el.y1 -= neededEdge.thickness
									el.y2 -= neededEdge.thickness
								} else {
									el.y1 -= neededEdge.thickness
									el.y2 -= neededEdge.thickness
								}
								break;
							// case 'bottom':
							// 	if (el.type === 'arc') {
							// 		if (i % 2 !== 0) {
							// 			i === 1 || i === 7 ? el.x2 += neededEdge.thickness : el.x1 += neededEdge.thickness
							// 			el.r += neededEdge.thickness
							// 		} else {
							// 			i === 2 || i === 8 ? el.x1 += neededEdge.thickness : el.x2 += neededEdge.thickness
							// 			el.r -= neededEdge.thickness
							// 		}
							// 		el.y1 += neededEdge.thickness
							// 		el.y2 += neededEdge.thickness
							// 	} else {
							// 		el.y1 += neededEdge.thickness
							// 		el.y2 += neededEdge.thickness
							// 	}
							// 	break;
							// case 'left':
							// 	if (el.type === 'arc') {
							// 		if (i % 2 !== 0) {
							// 			i === 1 || i === 7 ? el.y2 -= neededEdge.thickness : el.y1 -= neededEdge.thickness
							// 			el.r -= neededEdge.thickness
							// 		} else {
							// 			i === 2 || i === 8 ? el.y1 -= neededEdge.thickness : el.y2 -= neededEdge.thickness
							// 			el.r += neededEdge.thickness
							// 		}
							// 		el.x1 += neededEdge.thickness
							// 		el.x2 += neededEdge.thickness
							// 	} else {
							// 		el.x1 += neededEdge.thickness
							// 		el.x2 += neededEdge.thickness
							// 	}
							// 	break;
							case 'right':
								if (el.type === 'arc') {
									if (i % 2 !== 0) {
										i === 1 || i === 7 ? el.y2 += neededEdge.thickness : el.y1 += neededEdge.thickness
										el.r -= neededEdge.thickness
									} else {
										i === 2 || i === 8 ? el.y1 += neededEdge.thickness : el.y2 += neededEdge.thickness
										el.r += neededEdge.thickness
									}
									el.x1 -= neededEdge.thickness
									el.x2 -= neededEdge.thickness
								} else {
									el.x1 -= neededEdge.thickness
									el.x2 -= neededEdge.thickness
								}
								break;
						}

					}
					if(el.type === 'arc') {
						el.length = this.calculateArcLength(el.r, el.startAngleRad, el.endAngleRad)
					} else {
						el.length  = this.calculateDistance(el.x1, el.y1, el.x2, el.y2)
					}
					return el
				})
			case 'tableCorner':
				return elements.map((el, i) => {
					if (!empty(el.edge)) {
						const neededEdge = edgesInProject.find(_edge => _edge.index === el.edge)
						switch (angle) {
							// case 'left_top':
							// 	el.x1 += neededEdge.thickness
							// 	el.x2 += neededEdge.thickness
							// 	break
							// case 'left_top':
							// 	if (i === 0) {
							// 		el.r += neededEdge.thickness
							// 	}
							// 	if (i === 1) {
							// 		el.r -= neededEdge.thickness
							// 	}
							// 	el.x1 += neededEdge.thickness
							// 	el.x2 += neededEdge.thickness
							// 	break
							case 'right_top':
								if (i === 0) {
									el.r += neededEdge.thickness
								}
								if (i === 1) {
									el.r -= neededEdge.thickness
								}
								el.x1 -= neededEdge.thickness
								el.x2 -= neededEdge.thickness
								break
						}
						if (el.type === 'arc') {
							el.length = this.calculateArcLength(el.r, el.startAngleRad, el.endAngleRad)
						} else {
							el.length = this.calculateDistance(el.x1, el.y1, el.x2, el.y2)
						}
						return el
					} else {
						return el
					}
				})
			default:
				return elements
		}

	}


	static getLengthForMills(mill, edgesInProject = null) {
		let result = 0
		const edges = edgesInProject ? edgesInProject : mill.detail.parent.edges
		let elements = this.generateElementsByEdge(mill, mill.elements, mill.angle, edges)
		for (const argumentsKey in elements) {
			result += mill.elements[argumentsKey].length
		}
		return result
	}

	static isPointOnSegment(pointX, pointY, segmentStartX, segmentStartY, segmentEndX, segmentEndY) {
		const minX = Math.min(Number(segmentStartX.toFixed(1)), Number(segmentEndX.toFixed(1)));
		const maxX = Math.max(Number(segmentStartX.toFixed(1)), Number(segmentEndX.toFixed(1)));
		const minY = Math.min(Number(segmentStartY.toFixed(1)), Number(segmentEndY.toFixed(1)));
		const maxY = Math.max(Number(segmentStartY.toFixed(1)), Number(segmentEndY.toFixed(1)));

		return (pointX >= minX && pointX <= maxX && pointY >= minY && pointY <= maxY);
	}

	static isPointOnArc(pointX, pointY, el) {
		const {xc, yc, startAngleRad, endAngleRad, r} = el;
		const dx = pointX - xc;
		const dy = pointY - yc;
		const distance = Math.sqrt(dx * dx + dy * dy);

		// If distance is not equal to the radius, then point is definitely not on the arc
		if (Math.abs(distance - r) > 0.03) { // Tolerance might be adjusted depending on your use case
			return false;
		}

		// Calculate the angle from the circle center to the point
		const pointAngle = Math.atan2(dy, dx);

		// Check if the angle is between the start and end angles of the arc. Here we assume that angles are measured counter-clockwise from the positive x-axis
		return ((pointAngle >= startAngleRad && pointAngle <= endAngleRad) ||
			(pointAngle + 2 * Math.PI >= startAngleRad && pointAngle + 2 * Math.PI <= endAngleRad) ||
			(pointAngle - 2 * Math.PI >= startAngleRad && pointAngle - 2 * Math.PI <= endAngleRad));
	}

	static fixUShapePoints(uShape, start= null, end = null, originalIndex = null, target = 'elements') {
		const shapes = uShape[target].map((el, i) => {
			switch (el.type) {
				case 'line':
					const line =  this.createLine(
						el.x1 + uShape.x,
						el.y1 + uShape.y,
						el.x2 + uShape.x,
						el.y2 + uShape.y,
						!empty(uShape.edge) ? uShape.edge : null,
							el.color
					)
					if(!empty(originalIndex)) {
						line.parentIndex = originalIndex;
						line.originalIndex = i;
					}
					line.holes = el.holes ?? [];
					// line.color = uShape.color ?? null;
					if (uShape.subType === 'cutOut') {
						return {...line, cutOutType: uShape.type}
					}
					return line;
				case 'arc':
					const arc = this.createArc(
						start ? start.x : el.x1 + uShape.x,
						start ? start.y : el.y1 + uShape.y,
						end ? end.x : el.x2 + uShape.x,
						end ? end.y : el.y2 + uShape.y,
						el.r,
						el.xc + uShape.x,
						el.yc + uShape.y,
						el.dir,
						!empty(uShape.edge) ? uShape.edge : null,
						el.color
					)
					if(!empty(originalIndex)) {
						arc.parentIndex = originalIndex;
						arc.originalIndex = i;

					}
					arc.holes = el.holes ?? []
					// arc.color = uShape.color ?? null
					if (uShape.subType === 'cutOut') {
						return {...arc, cutOutType: uShape.type}
					}
					return arc;
			}
		})
		return [...shapes];
	}

	static getLineMesh({x1, y1, x2, y2, holes, w}, color = null, side = null, lines = false) {
		const edgeLength = Math.hypot((x2 - x1), (y2 - y1));
		const squareShape = new Shape();
		squareShape.moveTo(0, 0)
		squareShape.lineTo(0, edgeLength);
		squareShape.lineTo(w, edgeLength);
		squareShape.lineTo(w, 0);
		if(!empty(holes) && !empty(side)) {
			holes.map((obj) =>{
				if(obj.hasOwnProperty('proc')) {
					delete obj.proc;
				}
				return obj;
			}).forEach(hole => {
				if(!empty(hole.intersections) && hole.intersections.length === 2) {
					let start, stop;
					switch (side) {
						case 'left':
							start = hole.intersections[0].y - y1
							stop = hole.intersections[1].y - y1
							break
						case 'top':
							start = hole.intersections[0].x - x1
							stop = hole.intersections[1].x - x1
							break
						case 'right':
							start = edgeLength - hole.intersections[1].y + y2
							stop = edgeLength - hole.intersections[0].y + y2
							break
						case 'bottom':
							start = edgeLength - hole.intersections[1].x + x2
							stop = edgeLength - hole.intersections[0].x + x2
					}
					const posZ = hole.side === 'front' ? 0 : w - hole.depth;
					const holeShape = new Shape();
					holeShape.moveTo(posZ, start);
					holeShape.lineTo(posZ, stop);
					holeShape.lineTo(hole.side === 'front' ? hole.depth : w, stop);
					holeShape.lineTo(hole.side === 'front' ? hole.depth : w, start);
					holeShape.lineTo(posZ, start);
					squareShape.holes.push(holeShape)
				}
				if(!empty(hole.type) && hole.type === 'circle') {
					const cx = hole.z;
					let cy;
					switch (side) {
						case 'left':
							cy = hole.y - y1;
							break
						case 'top':
							cy = hole.x - x1;
							break
						case 'right':
							cy = edgeLength - hole.y + y2;
							break
						case 'bottom':
							cy = edgeLength - hole.x + x2;
					}
					const holeShape = new Shape();
					holeShape.moveTo(cx, cy)
					holeShape.absellipse(cx, cy, hole.r, hole.r, 0, Math.PI * 2, false, 0);
					squareShape.holes.push(holeShape)
				}
				if(!empty(hole.type) && hole.type === 'bevel') {
					const holeShape = new Shape();
					holeShape.moveTo(hole.elements[0].x1, hole.elements[0].y1);
					hole.elements.forEach(el => {
						holeShape.lineTo(el.x2, el.y2);
					})
					squareShape.holes.push(holeShape)
				}
				if(!empty(hole.type) && hole.type === 'contour') {
					const x = hole.z;
					let y, rotation;
					switch (side) {
						case 'left':
							y = hole.y;
							break
						case 'top':
							y = hole.x;
							break
						case 'right':
							y = edgeLength - (edgeLength - hole.y);
							break
						case 'bottom':
							y = edgeLength - hole.x;
							break
					}
					let contour = this.fixUShapePoints({...hole})
					const holeShape = new Shape();
					contour.forEach((el, i) => {
						let fixedEl = {...el};
						switch (el.type) {
							case 'line':
								if(i === 0) holeShape.moveTo(fixedEl.x1, fixedEl.y1);
								holeShape.lineTo(fixedEl.x2, fixedEl.y2);
								break
							case 'arc':
								if(i === 0) holeShape.moveTo(el.xc, el.yc);
								holeShape.absarc(el.xc, el.yc, el.r, el.startAngleRad, el.endAngleRad, el.dir);
								break
						}
					})
					// holeShape.rotate
					squareShape.holes.push(holeShape)
				}
			})
		}
		let squareShapeGeometry = new ShapeGeometry(squareShape, 4);
		squareShapeGeometry.rotateY(Math.PI / 2);
		const mesh = new Mesh(squareShapeGeometry, this.getMaterial3D(color));
		if(lines) {
			mesh.add(this.addContourLines(mesh.geometry, color))
		}
		return mesh;
	}

	static arcCheckOnRadiusEdge({x1, y1, x2, y2, r}){
		const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

		return distance > 2 * r
	}

	static calculateArcCenter(x1, y1, x2, y2, r) {
		const xm = (x1 + x2) / 2;
		const ym = (y1 + y2) / 2;

		const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

		if (distance > 2 * r) {
			return {xC1: NaN, yC1: NaN, xC2: NaN, yC2: NaN};
		}

		const d = Math.sqrt(Math.pow(r, 2) - Math.pow(distance / 2, 2));

		const dTimesDiffYOverDistance = d * (y2 - y1) / distance;
		const dTimesDiffXOverDistance = d * (x2 - x1) / distance;

		const xC1 = xm + dTimesDiffYOverDistance;
		const yC1 = ym - dTimesDiffXOverDistance;
		const xC2 = xm - dTimesDiffYOverDistance;
		const yC2 = ym + dTimesDiffXOverDistance;

		return {xC1, yC1, xC2, yC2}
	}

	static isArcOutsideDetail(detailWidth, detailHeight, arcObj) {
		const startAngleRad = arcObj.startAngleRad;
		const endAngleRad = arcObj.endAngleRad;

		/* Проверяем, переходит ли арка через нижнюю, верхнюю, левую или правую границу с помощью Sin и Cos функций  */
		const checkAngles = [startAngleRad, endAngleRad, 0, Math.PI / 2, Math.PI, 3 * Math.PI / 2];

		const xCoordinates = checkAngles.map(angle => arcObj.xc + arcObj.r * Math.cos(angle));
		const yCoordinates = checkAngles.map(angle => arcObj.yc + arcObj.r * Math.sin(angle));

		/* Обрезаем массивы до значений в пределах стартового и конечного углов в предположении, что они находятся внутри диапазона 0 до 2π */
		const validXCoordinates = xCoordinates.filter((_, idx) => (checkAngles[idx] >= startAngleRad && checkAngles[idx] <= endAngleRad) || checkAngles[idx] === startAngleRad || checkAngles[idx] === endAngleRad);
		const validYCoordinates = yCoordinates.filter((_, idx) => (checkAngles[idx] >= startAngleRad && checkAngles[idx] <= endAngleRad) || checkAngles[idx] === startAngleRad || checkAngles[idx] === endAngleRad);

		const minX = Math.min(...validXCoordinates);
		const maxX = Math.max(...validXCoordinates);
		const minY = Math.min(...validYCoordinates);
		const maxY = Math.max(...validYCoordinates);

		if (maxX > detailWidth || maxY > detailHeight || minX < 0 || minY < 0) {
			return true; // Арка выходит за пределы детали
		} else {
			return false; // Арка находится в пределах детали
		}
	}

	static getPointsForRadiusEdgeAngle(l, h, angle, x, y, r) {
		const data = {
			left_bottom: {x1: x, y1: 0, x2: 0, y2: y, r: r},
			left_top: {x1: 0, y1: h - y, x2: x, y2: h, r: r,},
			right_bottom: {x1: l, y1: y, x2: l - x, y2: 0, r: r,},
			right_top: {x1: l - x, y1: h, x2: l, y2: h - y, r: r,},
		}
		return data[angle]
	}

	static getArcMesh({x1, y1, x2, y2, xc, yc, r, dir, w}, color = null, side, lines = false) {
		const shape = new Shape()
		shape.moveTo(0, 0)
		let radStart = Math.atan2(yc - y1, xc - x1) - Math.PI;
		let radEnd = Math.atan2(yc - y2, xc - x2) - Math.PI;
		shape.absarc(xc, yc, r, radStart, radEnd, dir);
		const points = shape.getPoints(this.ShapeGeometrySegments);
		const mesh = new Mesh(this.getArcGeometry(points, w, 0), this.getMaterial3D(color));
		if(lines) {
			mesh.add(this.addContourLines(mesh.geometry, color))
		}
		return mesh;
	}

	static addContourLines(geometry, color = null) {
		const edgesGeometry = new EdgesGeometry(geometry);
		return new LineSegments(edgesGeometry, this.getLineMaterial(color));
	}

	static getContourEnd(contour = [], start = 0, end = 0) {
		const len = contour.length;
		if(start < end) {
			return contour.slice(start, end)
		} else {
			return [...contour.slice(start), ...contour.slice(0, end)]
		}
	}

	static rectanglesAreNested(rect1, rect2) {
		// rect1 та rect2 повинні бути об'єктами з властивостями x1, y1, x2, y2, x3, y3, x4, y4
		// де (x1, y1) - координати верхнього лівого кута, (x2, y2) - координати верхнього правого кута
		// (x3, y3) - координати нижнього лівого кута, (x4, y4) - координати нижнього правого кута

		return (
			rect1.x1 >= rect2.x1 &&
			rect1.y1 <= rect2.y1 &&
			rect1.x2 <= rect2.x2 &&
			rect1.y2 <= rect2.y2 &&
			rect1.x3 <= rect2.x3 &&
			rect1.y3 >= rect2.y3 &&
			rect1.x4 >= rect2.x4 &&
			rect1.y4 >= rect2.y4
		);
	}

	static rectanglesAreNestedLB(rect1, rect2) {
		// rect1 та rect2 повинні бути об'єктами з властивостями x1, y1, x2, y2, x3, y3, x4, y4
		// де (x1, y1) - координати верхнього лівого кута, (x2, y2) - координати верхнього правого кута
		// (x3, y3) - координати нижнього лівого кута, (x4, y4) - координати нижнього правого кута

		return (
			rect1.x1 >= rect2.x1 &&
			rect1.y1 >= rect2.y1 &&
			rect1.x2 >= rect2.x2 &&
			rect1.y2 <= rect2.y2 &&
			rect1.x3 <= rect2.x3 &&
			rect1.y3 <= rect2.y3 &&
			rect1.x4 <= rect2.x4 &&
			rect1.y4 >= rect2.y4
		);
	}

	static getLine(size, direction = 'hor', side = 'front') {
		const points = [];
		const end = direction === 'ver' ? [0 , size] : [size, 0];
		points.push( new Vector2( 0, 0 ) );
		points.push( new Vector2( ...end ) );
		const geometry = new BufferGeometry().setFromPoints( points );
		const material = side === 'front' ? Helpers.getLineMaterial() : Helpers.getLineDashedMaterial()
		const line = new Line( geometry, material);
		if (side === 'back') {
			line.computeLineDistances();
		}
		return line
		// return  new Line( geometry, Helpers.getLineMaterial('red') );
	}

	static getCircle(l, h, side = "front") {
		let shape = new THREE.Shape();
		shape.absarc(0, 0, (l + h) / 20, 0, Math.PI * 2, false);

		let geometry = new THREE.ShapeGeometry(shape);

		let material = new THREE.MeshBasicMaterial({
			color: "#00000",
			opacity: 0.5,
			transparent: true
		});

		let mesh = new THREE.Mesh(geometry, material);

		let path = new THREE.Path(shape.getPoints());
		let points = path.getPoints();

		let lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
		let lineMaterial = side !== "back" ? Helpers.getLineMaterial('#504f51') : Helpers.getLineDashedMaterial()

		let border = new THREE.Line(lineGeometry, lineMaterial);
		if (side === 'back') {
			border.computeLineDistances();
		}

		mesh.add(border)

		return mesh
	}

	static getTextureMesh(l, h, depth, side = 'front', isTexture = true) {
		if (isTexture) {
			const mesh = new Mesh();
			const line1 = this.getLine(l / 4, "hor", side);
			const line2 = this.getLine(l / 4, "hor", side);
			const line3 = this.getLine(l / 4, "hor", side);
			line2.position.y = 30;
			line3.position.y = 60;
			mesh.add(line1);
			mesh.add(line2);
			mesh.add(line3);
			mesh.position.x = -l / 8;
			mesh.position.y = -30;
			mesh.position.z = side === 'front' ? depth / 2 : -depth / 2;
			return mesh;
		} else {
			const mesh = new Mesh();
			const line1 = this.getLine(l / 4, "hor", side);
			const line2 = this.getLine(l / 4, "hor", side);
			const line3 = this.getLine(l / 4, "hor", side);
			const points = [];
			points.push(new THREE.Vector3(150, 70, 0));
			points.push(new THREE.Vector3(0, -10, 0));

			const geometry = new THREE.BufferGeometry().setFromPoints(points);
			const material = side === 'front' ? Helpers.getLineMaterial() : Helpers.getLineMaterial('#000');

			const line = new THREE.Line(geometry, material);
			mesh.add(line);

			line2.position.y = 30;
			line3.position.y = 60;
			mesh.add(line1);
			mesh.add(line2);
			mesh.add(line3);

			mesh.position.x = -l / 8;
			mesh.position.y = -30;
			mesh.position.z = side === 'front' ? depth / 2 : -depth / 2;
			return mesh
		}
	}

	static getHoleRectangle(cx, cy, r) {
		return {x1: cx - r, y1: cy - r, x2: cx - r, y2: cy + r, x3: cx + r, y3: cy + r, x4: cx + r, y4: cy - r}
	}

	static getStrangeArrow(size= 50, color = null) {
		const shape = new Shape()
		shape.moveTo(size / 6, 0);
		shape.lineTo(size / 6, size / 4);
		shape.lineTo(0, size / 4);
		shape.lineTo(size / 4, size);
		shape.lineTo(size / 2, size / 4);
		shape.lineTo(size / 3, size / 4);
		shape.lineTo(size / 3, 0);

		return new Mesh(new ShapeGeometry(shape), this.getLineMaterial(color))

	}

	static getHelpersFontSize({l, h}) {
		const size = Math.max(l / 80, h / 80);
		return Math.max(l, h) > 200 ? size : 5
	}

	static calcMillHeight(elements) {
		const maxY = Math.max(...elements.map(el => el.y2), ...elements.map(el => el.y1));
		const minY = Math.min(...elements.map(el => el.y2), ...elements.map(el => el.y1));
		return maxY - minY;
	}

	static calcMillWidth(elements) {
		const maxX = Math.max(...elements.map(el => el.x2), ...elements.map(el => el.x1));
		const minX = Math.min(...elements.map(el => el.x2), ...elements.map(el => el.x1));
		return maxX - minX;
	}

	static convertRectToMill(rect, detailL, detailH) {
		const mill = {...rect};
		if(empty(mill.r) || mill.r < 3) {
			mill.r = 3;
		}
		this.detectMillSide(mill, detailL, detailH);
		if(!empty(mill.angle)) {
			mill.type = 'gEdge';
			mill.elements = this.createGedgeElements(mill, detailH, detailL)
		} else {
			mill.type = 'uShape';
			mill.elements = this.createUshapeElements(mill, detailL, detailH)
		}
		let length = 0;
		mill.elements.forEach(el => {
			length += el.length;
		})
		mill.dataForConstructor = {
			x: rect.x,
			y: rect.y,
			width: rect.width,
			height: rect.height,
			r: rect.r
		}
		mill.quant = mill.elements.length
		mill.length = length;
		return mill;
	}

	static detectMillSide(mill, detailL, detailH) {
		if(!empty(mill.x) && !empty(mill.y) && !empty(mill.width) && !empty(mill.height)) {
			let side1, side2;
			if(mill.x === 0) {
				side1 = 'left';
			}	else if(mill.x === detailL - mill.width) {
				side1 = 'right';
			}
			if (mill.y === 0) {
				side2 = 'bottom';
			} else if(mill.y === detailH - mill.height){
				side2 = 'top';
			}
			if(!empty(side1) && !empty(side2)) {
				mill.angle = `${side1}_${side2}`;
			} else {
				mill.edgeSide = side1 || side2;
			}
		} else if(!empty(mill.elements)){
			const start = mill.elements[0];
			const end = mill.elements[mill.elements.length - 1];
			let side, endSide;
			if(start.x1 >= 0 && start.x1 <= 2) {
				side = 'left';
			} else if(start.x1 <= detailL && start.x1 >= detailL - 2) {
				side = 'right';
			} else if(start.y1 >= 0 && start.y1 <= 2) {
				side = 'bottom';
			} else if(start.y1 <= detailH && start.y1 >= detailH - 2) {
				side = 'top'
			}
			if(end.x2 >= 0 && end.x2 <= 2) {
				endSide = 'left';
			} else if(end.x2 <= detailL && end.x2 >= detailL - 2) {
				endSide = 'right';
			} else if(end.y2 >= 0 && end.y2 <= 2) {
				endSide = 'bottom';
			} else if(end.y2 <= detailH && end.y2 >= detailH - 2) {
				endSide = 'top';
			}
			if(side === endSide) {
				mill.edgeSide = side;
			} else {
				switch (side) {
					case 'left':
					case "right":
						mill.angle = ['top', 'bottom'].includes(endSide) ? `${side}_${endSide}` : null;
						break;
					case 'top':
					case 'bottom':
						mill.angle = ['left', 'right'].includes(endSide) ? `${endSide}_${side}` : null;
						break
				}
			}
		}
	}

	static createGedgeElements(mill, detailH, detailL, color = null) {
		const elements = []
		switch (mill.angle) {
			case 'left_bottom' :
				elements.push(this.createLine(mill.width, 0, mill.width, mill.height - mill.r, mill.edge, color))
				if(mill.r > 0) {
					elements.push(this.createArc(mill.width, mill.height - mill.r, mill.width - mill.r, mill.height, mill.r, mill.width - mill.r, mill.height - mill.r, false, mill.edge, color))
				}
				elements.push(this.createLine(mill.width - mill.r, mill.height, 0, mill.height, mill.edge, color))
				break
			case 'left_top' :
				elements.push(this.createLine(0, detailH - mill.height, mill.width - mill.r, detailH - mill.height, mill.edge, color))
				if(mill.r > 0) {
					elements.push(this.createArc(mill.width - mill.r, detailH - mill.height, mill.width, detailH - mill.height + mill.r, mill.r, mill.width - mill.r, detailH - mill.height + mill.r, false, mill.edge, color))
				}
				elements.push(this.createLine(mill.width, detailH - mill.height + mill.r, mill.width, detailH, mill.edge, color))
				break
			case 'right_top' :
				elements.push(this.createLine(detailL - mill.width, detailH, detailL - mill.width, detailH - mill.height + mill.r, mill.edge, color))
				if(mill.r > 0) {
					elements.push(this.createArc(detailL - mill.width, detailH - mill.height + mill.r, detailL - mill.width + mill.r, detailH - mill.height, mill.r, detailL - mill.width + mill.r, detailH - mill.height + mill.r, false, mill.edge, color))
				}
				elements.push(this.createLine(detailL - mill.width + mill.r, detailH - mill.height, detailL, detailH - mill.height, mill.edge, color))
				break
			case 'right_bottom' :
				elements.push(this.createLine(detailL, mill.height, detailL - mill.width + mill.r, mill.height, mill.edge, color))
				if(mill.r > 0) {
					elements.push(this.createArc(detailL - mill.width + mill.r, mill.height, detailL - mill.width, mill.height - mill.r, mill.r, detailL - mill.width + mill.r, mill.height - mill.r, false, mill.edge, color))
				}
				elements.push(this.createLine(detailL - mill.width, mill.height - mill.r, detailL - mill.width, 0, mill.edge, color))
				break
		}
		return elements;
	}

	static createUshapeElements(mill, detailH, detailL) {
		const elements = []
		switch (mill.edgeSide) {
			case 'left':
				elements.push(this.createLine(0, 0, mill.width - mill.r, 0, mill.edge))
				if(mill.r > 0) {
					elements.push(this.createArc(mill.width - mill.r, 0, mill.width, mill.r, mill.r, mill.width - mill.r, mill.r, false, mill.edge));
				}
				elements.push(this.createLine(mill.width, mill.r, mill.width, mill.height - mill.r, mill.edge))
				if(mill.r > 0) {
					elements.push(this.createArc(mill.width, mill.height - mill.r, mill.width - mill.r, mill.height, mill.r, mill.width - mill.r, mill.height - mill.r, false, mill.edge));
				}
				elements.push(this.createLine(mill.width - mill.r, mill.height, 0, mill.height, mill.edge))
				break
			case 'top':
				elements.push(this.createLine(0, mill.height, 0, mill.r, mill.edge))
				if(mill.r > 0) {
					elements.push(this.createArc(0, mill.r, mill.r, 0, mill.r, mill.r, mill.r, false, mill.edge));
				}
				elements.push(this.createLine(mill.r, 0, mill.width - mill.r, 0, mill.edge))
				if(mill.r > 0) {
					elements.push(this.createArc(mill.width - mill.r, 0, mill.width, mill.r, mill.r, mill.width - mill.r, mill.r, false, mill.edge));
				}
				elements.push(this.createLine(mill.width, mill.r, mill.width, mill.height, mill.edge))
				break
			case 'right':
				elements.push(this.createLine(mill.width, mill.height, mill.r, mill.height, mill.edge))
				if(mill.r > 0) {
					elements.push(this.createArc(mill.r, mill.height, 0, mill.height - mill.r, mill.r, mill.r, mill.height - mill.r, false, mill.edge));
				}
				elements.push(this.createLine(0, mill.height - mill.r, 0, mill.r, mill.edge))
				if(mill.r > 0) {
					elements.push(this.createArc(0, mill.r, mill.r, 0, mill.r, mill.r, mill.r, false, mill.edge));
				}
				elements.push(this.createLine(mill.r, 0, mill.width, 0, mill.edge))
				break
			case 'bottom':
				elements.push(this.createLine(mill.width, 0, mill.width, mill.height - mill.r, mill.edge))
				if(mill.r > 0) {
					elements.push(this.createArc(mill.width, mill.height - mill.r, mill.width - mill.r, mill.height, mill.r, mill.width - mill.r, mill.height - mill.r, false, mill.edge));
				}
				elements.push(this.createLine(mill.width - mill.r, mill.height, mill.r, mill.height, mill.edge))
				if(mill.r > 0) {
					elements.push(this.createArc(mill.r, mill.height, 0, mill.height - mill.r, mill.r, mill.r, mill.height - mill.r, false, mill.edge));
				}
				elements.push(this.createLine(0, mill.height - mill.r, 0, 0, mill.edge))
				break
		}
		return this.getRealCords(elements, mill.x, mill.y);
	}

	static getRealCords(tmp_elements, x, y) {
		const elements = [];
		tmp_elements.forEach(el => {
			if(el.type === 'line') {
				elements.push(
					{...el,
						x1: el.x1 + x,
						x2: el.x2 + x,
						y2: el.y2 + y,
						y1: el.y1 + y
					})
			} else if(el.type === 'arc') {
				elements.push(
					{...el,
						x1: el.x1 + x,
						x2: el.x2 + x,
						y2: el.y2 + y,
						y1: el.y1 + y,
						xc: el.xc + x,
						yc: el.y2 + y
					})
			}
		})
		return elements;
	}
	static detectTwoProcOnSide(arr, value, side, id) {
		return arr.some(process => {
			if (process.id === id) {
				return false
			}
			if (process[value] === side) {
				return true
			}
		})
	}

	static getCubeMesh(h, l, w) {
		return new Mesh( new BoxGeometry(l, h, w), this.getMaterialPreCutting(this.cutMaterialColor))
	}

	static calcDetailPreCutting(detail, add = false) {
		let left = 0, right = 0, top = 0, bottom = 0;
		if(!empty(detail?.preCutting?.left)) {
			left = add ? detail.preCutting.left : -detail.preCutting.left;
			// detail.l += left;
			detail.l = Number((Number(detail.l) + left).toFixed(1));
			if(!empty(detail.rects)) detail.rects.forEach(el => el.x += left)
			if(!empty(detail.holes)) detail.holes.forEach(el => el.x += left);
			if(!empty(detail.cutouts)) {
				detail.cutouts.forEach(el => el.x += left)
			}
			if(!empty(detail.mills)) {
				detail.mills.forEach(mill => {
					mill.elements.forEach(el => {
						el.x1 += left;
						el.x2 += left;
						if (el.type === 'arc') {
							el.xc += left;
						}
					})
				})
			}
		}
		if(!empty(detail?.preCutting?.bottom)) {
			bottom = add ?  detail.preCutting.bottom : -detail.preCutting.bottom;
			// detail.h += bottom;
			detail.h = Number((Number(detail.h) + bottom).toFixed(1))
			if(!empty(detail.rects)) detail.rects.forEach(el => el.y += bottom)
			if(!empty(detail.holes)) detail.holes.forEach(el => el.y += bottom)
			if(!empty(detail.cutouts)) detail.cutouts.forEach(el => el.y += bottom);
			if(!empty(detail.mills)){
				detail.mills.forEach(mill => {
					mill.elements.forEach(el => {
						el.y1 += bottom;
						el.y2 += bottom;
						if(el.type === 'arc') {
							el.yc += bottom;
						}
					})
				})
			}

		}
		if(!empty(detail?.preCutting?.top)) {
			top = add ? detail.preCutting.top : -detail.preCutting.top;
			// detail.h += top;
			detail.h = Number((Number(detail.h) + top).toFixed(1))
		}
		if(!empty(detail?.preCutting?.right)) {
			right = add ? detail.preCutting.right : -detail.preCutting.right;
			// detail.l += right;
			detail.l = Number((Number(detail.l) + right).toFixed(1))
		}

	}

	static prepareDetails(_detail) {
		const detail = {..._detail};
		if (!empty(detail.rects)) {
			let newRects = []
			detail.rects.forEach(rect => {
				if (empty(rect.id) || Number.isInteger(rect.id)) {
					rect.id = uuidv4()
				}
				if (rect.type === 'UShape') {
					detail.mills.push(this.convertRectToMill(rect, detail.l, detail.h))
				} else {
					if(rect.type === 'Groove') {
						rect.direction = rect.height >= rect.width ? 'ver' : 'hor';
					}
					if(rect.type === 'Rabbet' && ['left', 'right', 'top', 'bottom'].includes(rect.side)) {
						rect = {
							...rect,
							depth: rect.height,
							height: rect.depth,
							side: rect.z === 0 ? 'back' : 'front',
							isErrorText: Languages.getTranslation('check-processing-parameters', true),
							z: 0,
							additionalInfo: JSON.stringify(rect)
						}
					}
					newRects.push(rect)
				}
			})
			detail.rects = newRects
		}

		this.createMillFromSmiles(detail);
		if (!empty(detail.mills)) {
			detail.mills.forEach(mill => {
				if(!['partial', 'closed'].includes(mill.type)) {
					mill.elements = []
				}
				if (empty(mill.id) || Number.isInteger(mill.id)) {
					mill.id = uuidv4()
				}
				if (mill.r < 3) {
					mill.r = 3
				}
				if(mill.type === 'uShape') {
					mill.angle = null;
					this.detectMillSide(mill, detail.l, detail.h);
					if(!empty(mill.angle)) {
						mill.type = 'gEdge';
						mill.elements = this.createGedgeElements(mill, detail.h, detail.l);
					} else if(empty(mill.elements)) {
						mill.elements = this.createUshapeElements(mill);
					}
				}
				if(mill.type === 'gEdge') {
					switch (mill.angle) {
						case 'left_bottom':
							if(mill.x !== 0 && mill.y !== 0) {
								[mill.width, mill.x, mill.height, mill.y] = [mill.x, 0, mill.y, 0]
							}
							break;
						case 'left_top':
							if(mill.x !== 0 && mill.y !== detail.h - mill.height) {
								[mill.width, mill.x, mill.height, mill.y] = [mill.x, 0, mill.y, detail.h - mill.height]
							}
							break;
						case 'right_top':
							if(mill.x !== detail.l - mill.width && mill.y !== detail.h - mill.height) {
								[mill.width, mill.x, mill.height, mill.y] = [mill.x, detail.l - mill.width, mill.y, detail.h - mill.height]
							}
							break;
						case 'right_bottom':
							if(mill.x !== detail.l - mill.width && mill.y !== 0) {
								[mill.width, mill.x, mill.height, mill.y] = [mill.x, detail.l - mill.width, mill.y, 0]
							}
							break;
					}
					if(empty(mill.elements)) {
						mill.elements = this.createGedgeElements(mill, detail.h, detail.l);
					}
				}

			})
		}
		detail.holes.forEach(hole => {
			if (empty(hole.id) || Number.isInteger(hole.id)) {
				hole.id = uuidv4()
			}
		})
		if (!empty(detail.bevels)) {
			detail.bevels.forEach(bevel => {
				if (empty(bevel.id) || Number.isInteger(bevel.id)) {
					bevel.id = uuidv4()
				}
			})
		}
		detail.corners.forEach(corner => {
			if (empty(corner.id) || Number.isInteger(corner.id)) {
				corner.id = uuidv4()
			}
		})
		if(!empty(detail.cutouts)){
			detail.cutouts.forEach(cutout => {
				if (empty(cutout.id) || Number.isInteger(cutout.id)) {
					cutout.id = uuidv4()
				}
			})
		}
		this.calcDetailPreCutting(detail);
		return detail;
	}

	static degToPi(deg) {
		return deg * (Math.PI / 180)
	}

	static getStartAndEndPointsForProc(proc, l, h) {
		let startX, startY, endX, endY

		 startX = proc.x_axis === 'left' ? -l / 2 + proc.x : l / 2 - proc.x
		 startY = proc.y_axis === 'bottom' ? -h / 2 + proc.y : h / 2 - proc.y
		 endX = proc.x_axis === 'left' ? -l / 2 + proc.pointsAfterMove.x : l / 2 - proc.pointsAfterMove.x
		 endY = proc.y_axis === 'bottom' ? -h / 2 + proc.pointsAfterMove.y :h / 2 - proc.pointsAfterMove.y

		return {
			startX,
			startY,
			endX,
			endY
		}
	}

	static rotateElementsArr(elements, l, h, direction = true, isMill = false) {
		const px = l / 2, py = h / 2;
		const theta = Math.PI / 2;
		const newElements = elements.map(el => {
			let x1, x2,y1,y2,xc,yc, dx,dy;
			switch (el.type) {
				case 'line':
					if(!direction) {
						x1 = px + Math.cos(theta) * (el.x1 - px) - Math.sin(theta) * (el.y1 - py);
						y1 = py + Math.sin(theta) * (el.x1 - px) + Math.cos(theta) * (el.y1 - py);
						x2 = px + Math.cos(theta) * (el.x2 - px) - Math.sin(theta) * (el.y2 - py);
						y2 = py + Math.sin(theta) * (el.x2 - px) + Math.cos(theta) * (el.y2 - py);
					} else {
						x1 = px + Math.cos(theta) * (el.x1 - px) + Math.sin(theta) * (el.y1 - py);
						y1 = py - Math.sin(theta) * (el.x1 - px) + Math.cos(theta) * (el.y1 - py);
						x2 = px + Math.cos(theta) * (el.x2 - px) + Math.sin(theta) * (el.y2 - py);
						y2 = py - Math.sin(theta) * (el.x2 - px) + Math.cos(theta) * (el.y2 - py);
					}
					dx = isMill ? 0 : py - px;
					dy = isMill ? 0 : px - py;
					// dx = 0;
					// dy = 0;
					return {...el,
						x1: parseFloat((x1 + dx).toFixed(3)),
						x2: parseFloat((x2 + dx).toFixed(3)),
						y1: parseFloat((y1 + dy).toFixed(3)),
						y2: parseFloat((y2 + dy).toFixed(3))
					}
				case 'arc':
					if(!direction) {
						x1 = px + Math.cos(theta) * (el.x1 - px) - Math.sin(theta) * (el.y1 - py);
						y1 = py + Math.sin(theta) * (el.x1 - px) + Math.cos(theta) * (el.y1 - py);
						x2 = px + Math.cos(theta) * (el.x2 - px) - Math.sin(theta) * (el.y2 - py);
						y2 = py + Math.sin(theta) * (el.x2 - px) + Math.cos(theta) * (el.y2 - py);
						xc = px + Math.cos(theta) * (el.xc - px) - Math.sin(theta) * (el.yc - py);
						yc = py + Math.sin(theta) * (el.xc - px) + Math.cos(theta) * (el.yc - py);
					} else {
						x1 = px + Math.cos(theta) * (el.x1 - px) + Math.sin(theta) * (el.y1 - py);
						y1 = py - Math.sin(theta) * (el.x1 - px) + Math.cos(theta) * (el.y1 - py);
						x2 = px + Math.cos(theta) * (el.x2 - px) + Math.sin(theta) * (el.y2 - py);
						y2 = py - Math.sin(theta) * (el.x2 - px) + Math.cos(theta) * (el.y2 - py);
						xc = px + Math.cos(theta) * (el.xc - px) + Math.sin(theta) * (el.yc - py);
						yc = py - Math.sin(theta) * (el.xc - px) + Math.cos(theta) * (el.yc - py);
					}
					dx = isMill ? 0 : py - px;
					dy = isMill ? 0 : px - py;
					// dx = 0;
					// dy = 0;
					const res = {
						x1: parseFloat((x1 + dx).toFixed(3)),
						x2: parseFloat((x2 + dx).toFixed(3)),
						y1: parseFloat((y1 + dy).toFixed(3)),
						y2: parseFloat((y2 + dy).toFixed(3)),
						xc: parseFloat((xc + dx).toFixed(3)),
						yc: parseFloat((yc + dy).toFixed(3))
					}
					const angle = this.calculateAngle(
					{x: res.xc, y: res.yc},
					{x: res.x1, y: res.y1},
					{x: res.x2, y: res.y2},
						res.dir
				)
					return {...el, ...res,
						"angle": angle.angle,
						"startAngle": angle.angleStart * (180 / Math.PI),
						"endAngle": angle.angleEnd * (180 / Math.PI),
						"startAngleRad": angle.angleStart,
						"endAngleRad": angle.angleEnd
					}
			}
		})
		return newElements
	}
}

