import Helpers from "../3D/Helpers";

import {empty} from "../../helpers/helper";
import Languages from "../../translation/Languages";
import _Details from "../../db/_Details";
const detailsDb = new _Details();
export default class Smile  {
  _elements;
  parent;
  _detailH;
  _detailL;
  constructor({
    parent,
    elements,
    detailL,
    detailH,
  }) {
    this.parent = parent;
    this._detailH = detailH
    this._detailL = detailL
    if (empty(this.parent.edgeSide)) {
      this.parent._edgeSide = "bottom";
    }
    // this._elements = elements;

    // if(!empty(this.parent._dataForConstructor.edgeSide)) {
    //   this.parent._edgeSide = this.parent._dataForConstructor.edgeSide;
    // }
    //
    // if(!empty(this.parent._dataForConstructor.offset)) {
    //   this.parent._offset = this.parent._dataForConstructor.offset;
    // }

    this.addParams();
    this.initRequiredFields();
    this.parent.touchedSides = [this.parent.edgeSide];
    // this.elements = this.generateElements();
  }
  initRequiredFields() {
    this.parent.requiredFields = {
      offset: {
        min: 0,
        max: ['left', 'right'].includes(this.parent.edgeSide) ? this.parent.detail.h - this.parent.width - 2 : this.parent.detail.l - this.parent.width - 2
      },
    }
  }

  addParams() {
    let f_value = this.parent._dataForConstructor.hasOwnProperty('f_offset') ? this.parent._dataForConstructor['f_offset'] : '';
    this.parent.setFormField({name: 'typeR', value: this.parent.typeR, visible: false })
    this.parent.setFormField({name: 'offset', label: `${Languages.getTranslation('displacement', true)} (po)`, value: this.parent.offset, type: 'number', visible: true, additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value},
        {name: 'center_x', label: `${Languages.getTranslation('center-on-axis', true)} X`, callback: () => {
            this.parent.setCenterForSmile();
          }}
      ]});
    this.parent.setFormField({name: 'width', value: this.parent.width, type: 'number', label: Languages.getTranslation('height', true), visible: true,});
    this.parent.setFormField({name: 'doubleSmiles', value: this.parent.doubleSmiles, type: 'checkbox-orig', label: Languages.getTranslation('double-smiles', true), visible: true,});
    this.parent.setFormField({name:'edgeSide', type: 'select', label: Languages.getTranslation('only-end', true), value: this.parent.edgeSide, visible: true, variables: [
        ...this.parent.getSidesValues()
      ]})

    this.parent.setFormField({name: 'x', visible: false})
    this.parent.setFormField({name: 'height', visible: false})
    this.parent.setFormField({name: 'y', visible: false})
    this.parent.setFormField({name: 'angle', visible: false})
    this.parent.setFormField({name: 'r', visible: false})
  }

  get paramsSorting() {
    const sortingArr = ['type', 'edgeSide', 'width', 'offset', 'doubleSmiles', 'comment']

    return 	this.parent.formFields.sort((a, b)=> sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
  }

  // removeRadius() {
  //   this.parent.r = 0;
  //   this.parent.setFormField({name: 'r', value: 0})
  //   this.parent.buildDetail()
  //     .then(() => this.parent.renderDetail());
  // }

  get elements() {
    return this.generateElements()
  }

  // set elements(element) {
  //   this._elements = element
  // }

  get shape() {
    return this.parent.realData
  }

  get realData() {
    return {
      side: 'front',
      r: this.parent?.r1 || null,
      x: this.xCalc,
      y: this.yCalc,
      height: this.parent.height,
      width: this.parent.width,
      ext: false,
      touchedSides: [this.parent.edgeSide],
      type: 'smile',
      doubleSmiles: this.parent.doubleSmiles
    };
  }

  get cutOut() {
    const width = this.parent.widthCalc;
    const height = this.parent.heightCalc;
    const x = this.xCalc;
    const y = this.yCalc;
    // this.generateElements()
    const cutout = {
      x: x,
      y: y,
      side: 'front',
      type: 'smile',
      subType: 'mill',
      depth: this.parent.detail.w,
      edge: this.parent.edge,
      place: this.parent.edgeSide,
      width: width,
      touchedSides: [this.parent.edgeSide],
      sidesCount: 1,
      color: this.parent.isActive ? Helpers.colorForActive : null,
      height: height,
      elements: this.parent.create3dDataForMills(this.elements)
    }
    return cutout;
  }

  get startPoint() {
    switch (this.parent.edgeSide) {
      case 'top' :
      case 'left' :
        return this.parent.offset;
      case 'bottom' :
        return this.detailL - this.parent.offset;
      case 'right' :
        return this.detailH - this.parent.offset;
    }
  }

  generateElements() {
    this.detailH = this.parent.detail.h
    this.detailL = this.parent.detail.l
    const elements = []
    let start = this.startPoint
    let startOfCenterLine
    let endOfCenterLine
    switch (this.parent.edgeSide) {
      case 'left':
        if(this.parent.doubleSmiles) {
          elements.push(Helpers.createLine(0, 0, 0, start, this.parent.edge, this.getActiveElement()))
          this.getArc(0, start).elementsDoubleArc.forEach(el => elements.push(el))
          startOfCenterLine = (this.detailH /2) - this.getArc(0,start).y
          endOfCenterLine = this.getArc(0, start).y + (startOfCenterLine*2)
          elements.push(Helpers.createLine(0, this.getArc(0, start).y, 0, endOfCenterLine, this.parent.edge, this.getActiveElement()))
          this.getArc(0,endOfCenterLine).elementsDoubleArc.forEach(el => elements.push(el))
          elements.push(Helpers.createLine(0,  this.getArc(0, endOfCenterLine).y, 0, this.detailH, this.parent.edge, this.getActiveElement()))
        } else {
          elements.push(Helpers.createLine(0, 0, 0, start, this.parent.edge, this.getActiveElement()))
          this.getArc(0, start).elementsDoubleArc.forEach(el => elements.push(el))
          elements.push(Helpers.createLine(0, this.getArc(0, start).y, 0, this.detailH, this.parent.edge, this.getActiveElement()))
        }
        break
      case 'top':
        if(this.parent.doubleSmiles) {
	        elements.push(Helpers.createLine(0, this.detailH, start, this.detailH, this.parent.edge, this.getActiveElement()))
            this.getArc(start, this.detailH).elementsDoubleArc.forEach(el => elements.push(el))
            startOfCenterLine = (this.detailL /2) - this.getArc(start, this.detailH).x
            endOfCenterLine = this.getArc(start, this.detailH).x + (startOfCenterLine*2)
            elements.push(Helpers.createLine(this.getArc(start, this.detailH).x, this.detailH, endOfCenterLine, this.detailH, this.parent.edge, this.getActiveElement()))
            this.getArc(endOfCenterLine, this.detailH).elementsDoubleArc.forEach(el => elements.push(el))
            elements.push(Helpers.createLine( this.getArc(endOfCenterLine, this.detailH).x, this.detailH, this.detailL, this.detailH, this.parent.edge, this.getActiveElement()))
        } else {
            elements.push(Helpers.createLine(0, this.detailH, start, this.detailH, this.parent.edge, this.getActiveElement()))
            this.getArc(start, this.detailH).elementsDoubleArc.forEach(el => elements.push(el))
            elements.push(Helpers.createLine(this.getArc(start, this.detailH).x, this.detailH, this.detailL, this.detailH, this.parent.edge, this.getActiveElement()))
        }
        break
      case 'right':
        if(this.parent.doubleSmiles) {
          elements.push(Helpers.createLine(this.detailL, this.detailH, this.detailL, start, this.parent.edge, this.getActiveElement()))
          this.getArc(this.detailL, start).elementsDoubleArc.forEach(el => elements.push(el))
          startOfCenterLine = (this.detailH /2) - this.getArc(this.detailL, start).y
          endOfCenterLine = this.getArc(this.detailL, start).y + (startOfCenterLine*2)
          elements.push(Helpers.createLine(this.detailL, this.getArc(this.detailL, start).y, this.detailL, endOfCenterLine, this.parent.edge, this.getActiveElement()))
          this.getArc(this.detailL,endOfCenterLine).elementsDoubleArc.forEach(el => elements.push(el))
          elements.push(Helpers.createLine(this.detailL, this.getArc(this.detailL, endOfCenterLine).y, this.detailL, 0, this.parent.edge, this.getActiveElement()))
        } else {
          elements.push(Helpers.createLine(this.detailL, this.detailH, this.detailL, start, this.parent.edge, this.getActiveElement()))
          this.getArc(this.detailL, start).elementsDoubleArc.forEach(el => elements.push(el))
          elements.push(Helpers.createLine(this.detailL, this.getArc(this.detailL, start).y, this.detailL, 0, this.parent.edge, this.getActiveElement()))
        }
        break
      case 'bottom':
        if(this.parent.doubleSmiles) {
          elements.push(Helpers.createLine(this.detailL, 0, start, 0, this.parent.edge, this.getActiveElement()))
          this.getArc(start, 0).elementsDoubleArc.forEach(el => elements.push(el))
          startOfCenterLine = (this.detailL /2) - this.getArc(start, 0).x
          endOfCenterLine = this.getArc(start, 0).x + (startOfCenterLine*2)
          elements.push(Helpers.createLine(this.getArc(start, 0).x, 0, endOfCenterLine, 0, this.parent.edge, this.getActiveElement()))
          this.getArc(endOfCenterLine,0).elementsDoubleArc.forEach(el => elements.push(el))
          elements.push(Helpers.createLine(this.getArc(endOfCenterLine, 0).x, 0, 0, 0, this.parent.edge, this.getActiveElement()))
        } else {
          elements.push(Helpers.createLine(this.detailL, 0, start, 0, this.parent.edge, this.getActiveElement()))
          this.getArc(start, 0).elementsDoubleArc.forEach(el => elements.push(el))
          elements.push(Helpers.createLine(this.getArc(start, 0).x, 0, 0, 0, this.parent.edge, this.getActiveElement()))
        }
        break
    }
    return elements
  }

  getArc(startPointX, startPointY) {
    let points
    let x2CenterLine
    let y2CenterLine
    let elementsDoubleArc = []
    switch (this.parent.edgeSide) {
      case 'left':
        points = this.getArcCoords({x1: startPointX, y1: startPointY, r: 90, angle: Math.PI-3.1377081210273374, angle2: 2.610563564686055})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, true, this.parent.edge, this.getActiveElement()))
        points = this.getArcCoords({x1: points.x2, y1: points.y2, r: 120, angle:  Math.PI-0.5310118266811059, angle2: -0.004706860659603824})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, false, this.parent.edge, this.getActiveElement()))

        y2CenterLine = points.y2 + this.calcCenterLineX2(startPointY, this.parent.width, points.y2, this.parent.edgeSide)
        elementsDoubleArc.push(Helpers.createLine(points.x2, points.y2, points.x2, y2CenterLine, this.parent.edge, this.getActiveElement()))

        points = this.getArcCoords({x1: points.x2, y1: y2CenterLine, r: 120, angle: Math.PI-0.004714158009958709, angle2: 0.5310118266811057,})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, false,this.parent.edge, this.getActiveElement()))
        points = this.getArcCoords({x1: points.x2, y1: points.y2, r: 90, angle: Math.PI-2.610563564686055, angle2: 3.137695750029572})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, true,this.parent.edge, this.getActiveElement()))
        return {x: points.x2, y: points.y2, elementsDoubleArc}
      case 'top':
        points = this.getArcCoords({x1: startPointX, y1: startPointY, r: 90, angle: -1.574680811302159, angle2: 1.0397672378911584})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, true, this.parent.edge, this.getActiveElement()))
        points = this.getArcCoords({x1: points.x2, y1: points.y2, r: 120, angle: Math.PI - 2.1018081534760027, angle2: -1.5755032218026173})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, false, this.parent.edge, this.getActiveElement()))

        x2CenterLine = points.x2 + this.calcCenterLineX2(startPointX, this.parent.width, points.x2, this.parent.edgeSide)
        elementsDoubleArc.push(Helpers.createLine(points.x2, points.y2, x2CenterLine, points.y2, this.parent.edge, this.getActiveElement()))

        points = this.getArcCoords({x1: x2CenterLine, y1: points.y2, r: 120, angle: Math.PI -1.5660821343835707, angle2: -1.0397845001137909,})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, false,this.parent.edge, this.getActiveElement()))
        points = this.getArcCoords({x1: points.x2, y1: points.y2, r: 90, angle: 2.1018254156986345 - Math.PI, angle2: 1.566899471442906})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, true,this.parent.edge, this.getActiveElement()))

        return {x: points.x2, y: points.y2, elementsDoubleArc,}

      case 'right':
        points = this.getArcCoords({x1: startPointX, y1: startPointY, r: 90, angle: Math.PI - 0.003896855351990649, angle2: -0.5310290889037379})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, true, this.parent.edge, this.getActiveElement()))
        points = this.getArcCoords({x1: points.x2, y1: points.y2, r: 120, angle: Math.PI + 2.610580826908687, angle2: 3.1368784611784672})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, false, this.parent.edge, this.getActiveElement()))

        y2CenterLine = points.y2 + this.calcCenterLineX2(startPointY, this.parent.width, points.y2, this.parent.edgeSide)
        elementsDoubleArc.push(Helpers.createLine(points.x2, points.y2, points.x2, y2CenterLine, this.parent.edge, this.getActiveElement()))

        points = this.getArcCoords({x1: points.x2, y1: y2CenterLine, r: 120, angle: Math.PI-3.1368857585820726, angle2: -2.610580826908687,})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, false,this.parent.edge, this.getActiveElement()))
        points = this.getArcCoords({x1: points.x2, y1: points.y2, r: 90, angle: Math.PI+0.5310290889037382, angle2: 0.0038844845072624912})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, true,this.parent.edge, this.getActiveElement()))
        return {x: points.x2, y: points.y2, elementsDoubleArc}

      case 'bottom':
        points = this.getArcCoords({x1: startPointX, y1: startPointY, r: 90, angle: Math.PI-1.5668994232346751, angle2: -2.1018254156986345})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, true,this.parent.edge, this.getActiveElement()))
        points = this.getArcCoords({x1: points.x2, y1: points.y2, r: 120, angle: 1.0397845001137909 - Math.PI, angle2: 1.566082168784938})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, false,this.parent.edge, this.getActiveElement()))

        x2CenterLine = points.x2 + this.calcCenterLineX2(startPointX, this.parent.width, points.x2, this.parent.edgeSide)
        elementsDoubleArc.push(Helpers.createLine(points.x2, points.y2, x2CenterLine, points.y2, this.parent.edge, this.getActiveElement()))

        points = this.getArcCoords({x1: x2CenterLine, y1: points.y2, r: 120, angle: - 1.5755031874545005, angle2: 2.1018081534760027,})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, false,this.parent.edge, this.getActiveElement()))
        points = this.getArcCoords({x1: points.x2, y1: points.y2, r: 90, angle: Math.PI -1.0397672378911584, angle2: -1.5746808593573525,})
        elementsDoubleArc.push(Helpers.createArc(points.x1, points.y1, points.x2, points.y2, points.r, points.xc, points.yc, true,this.parent.edge, this.getActiveElement()))
        return {x: points.x2, y: points.y2, elementsDoubleArc}
    }
  }

  getActiveElement() {
    return this.parent.isActive ? Helpers.colorForActive : null
  }

  calcCenterLineX2(startPoint, detailSideLength, pointLine, side) {
    switch (side) {
      case 'bottom' :
      case 'right' :
        return (startPoint - (detailSideLength / 2) - pointLine) * 2
      case "top" :
      case 'left':
        return (startPoint + (detailSideLength / 2) - pointLine) * 2
    }
  }

  getArcCoords({x1, y1, r, angle, angle2}) {
    const xc = x1 + r * Math.cos(angle);
    const yc = y1 + r * Math.sin(angle);
    const x2 = xc + r * Math.cos(angle2);
    const y2 = yc + r * Math.sin(angle2);
    return {x1, y1, xc, yc, x2, y2, r}
  }

  addSizesArrows() {
    return null
  }

  get dataForValidate() {
    const shape = this.shape;
    const objectEdge = !empty(this.parent.edge) ? this.parent.detail.parent.edges.find((el) => el.index === this.parent.edge) : this.parent.edge
    return {
      side: 'front',
      x: shape.x,
      y: shape.y,
      width: shape.height,
      height: shape.width,
      depth: shape.depth,
      ext: false,
      fullDepth: true,
      r: shape.r,
      edge: this.parent.getEdgeArrayIndex(this.parent.edge),
      subtype: "ushape",
      subside: this.parent.edgeSide,
      id: this.parent.id
    };
  }

  get detailH() {
    return this._detailH;
  }

  set detailH(detailH) {
    this._detailH = detailH;
  }

  get detailL() {
    return this._detailL;
  }

  set detailL(detailL) {
    this._detailL = detailL;
  }


  async remove() {
    this.parent.detail.uShapes.splice(this.parent.detail.uShapes.indexOf(this), 1);
    // return this.parent.buildDetail()
    //   .then(() => this.parent.updateDb())
    //   .then(() => this.parent.renderDetail());
  }

  get xCalc() {
    switch (this.parent.edgeSide) {
      case 'top':
      case 'bottom':
        // this.parent.x = this.parent.offset;
        return this.parent.offset;
      case 'left':
        // this.parent.x = 0
        return 0;
      case 'right':
        // this.parent.x = this.detailL - this.parent.widthCalc;
        return this.detailL - this.parent.widthCalc

    }
  }

  get yCalc() {
    switch (this.parent.edgeSide) {
      case 'left':
      case 'right':
        // this.parent.y = this.parent.offset;
        return this.parent.offset;
      case 'top':
        // this.parent.y = this.detailH - this.parent.heightCalc;
        return this.detailH - this.parent.heightCalc;
      case 'bottom':
        // this.parent.y = 0;
        return 0;
    }
  }

  get widthCalc() {
    if (["top", "bottom"].includes(this.parent.edgeSide)) {
      return this.parent.width;
    } else {
      return this.parent.height;
    }
  }

  get heightCalc() {
    if (["top", "bottom"].includes(this.parent.edgeSide)) {
      return this.parent.height;
    } else {
      return this.parent.width;
    }
  }

  get detailSide() {
    if (["top", "bottom"].includes(this.parent.edgeSide)){
      return this.detailL
    } else {
      return this.detailH
    }
  }

  async validate() {
    const details = await detailsDb.getDetails()
    const neededDetail = await details.find((detail) => detail.id === this.parent.detail.id)
    const neededMills = neededDetail.mills.filter(mill => mill.type === 'smile')
    if (this.parent.width < 220) {
      this.parent.detail.error.setError(`${Languages.getTranslation('error-min-width', true)} ${220}`, 'error', true);
      return Promise.reject('error-min-width')
    }
    if (Helpers.detectTwoProcOnSide(neededMills, 'edgeSide', this.parent.edgeSide, this.parent.id)) {
      this.parent.detail.error.setError(`${Languages.getTranslation('smiles-error-change-edgeSide', true)}`, 'error', true);
      return Promise.reject('smiles-error-change-edgeSide')
    }
    if(this.parent.doubleSmiles) {
      if (this.parent.width + this.parent.offset >= this.detailSide / 2 || this.parent.offset < 0) {
        this.parent.detail.error.setError(`${Languages.getTranslation('smiles-error-change-offset', true)}`, 'error', true);
        return Promise.reject('smiles-error-change-offset')
      }
    }
    if (!this.parent.doubleSmiles) {
      if (this.parent.offset < 0 || this.parent.offset > this.detailSide) {
        this.parent.detail.error.setError(`${Languages.getTranslation('smiles-error-change-offset', true)}`, 'error', true);
        return Promise.reject('smiles-error-change-offset')
      }
    }
    return Promise.resolve()
  }

  rotateDetail(direction) {
    const currentOffset = this.parent.offset;
    let offset = this.parent.offset;
    // const oldEdgeSide = this.parent.edgeSide;
    this.parent.edgeSide = this.parent.getNextEdgeSideForRotate(direction);
    // switch (oldEdgeSide) {
    //   case 'top':
    //     offset = direction ? this.parent.detail.h - currentOffset - this.parent.width : currentOffset;
    //     break;
    //   case 'right':
    //     offset = direction ? currentOffset : this.parent.detail.l - currentOffset - this.parent.width;
    //     break;
    //   case 'left':
    //     offset = direction ? currentOffset : this.parent.detail.l - currentOffset - this.parent.width;
    //     break;
    //   case 'bottom':
    //     offset = direction ? this.parent.detail.h - currentOffset - this.parent.width : currentOffset;
    //     break;
    // }
    this.initRequiredFields();
    this.parent.offset = offset;

    return Promise.resolve()
  }
}
