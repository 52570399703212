import { createAction } from "@reduxjs/toolkit";
import actionType from "./actionType";

const ConstructionProject = createAction(actionType.Construction);
const setDetail = createAction(actionType.Detail);
const setFurniture = createAction(actionType.Furniture);
const setIsArrowMaterial = createAction(actionType.IsArrowMaterial);
const setIsArrowEdge = createAction(actionType.IsArrowEdge);
const setTreatment = createAction(actionType.Treatment);
const setsceneModel = createAction(actionType.sceneModel);
const errorProject = createAction(actionType.ErrorProject);
const updateDetailsTableMode = createAction(actionType.UpdateDetailsTableMode);
const updateDetailsTableLimits = createAction(actionType.UpdateDetailsTableLimits);
const updateDetailsTableFocusedRow = createAction(actionType.UpdateDetailsTableFocusedRow);
const setDefaultDetailsTableFocusedRow = createAction(actionType.SetDefaultDetailsTableFocusedRow);
const setDefaultDetailsTableLimits = createAction(actionType.SetDefaultDetailsTableLimits);
const updateProductsInConstruction = createAction(actionType.UpdateProductsInConstruction);
const updateMaterialsInConstruction = createAction(actionType.UpdateMaterialsInConstruction);
const updateDetailInConstruction = createAction(actionType.UpdateDetailInConstruction);
const setIdDetailFromScene = createAction(actionType.SetIdDetailFromScene);
const updateFurnitureInConstruction = createAction(actionType.UpdateFurnitureInConstruction);

export default {
  ConstructionProject,
  setDetail,
  setsceneModel,
  setTreatment,
  errorProject,
  setIsArrowMaterial,
  setIsArrowEdge,
  updateDetailsTableMode,
  updateDetailsTableLimits,
  updateDetailsTableFocusedRow,
  setDefaultDetailsTableFocusedRow,
  updateProductsInConstruction,
  updateMaterialsInConstruction,
  updateDetailInConstruction,
	setDefaultDetailsTableLimits,
  setIdDetailFromScene,
  setFurniture,
  updateFurnitureInConstruction
};
