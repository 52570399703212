import ACTIONS from "./actions";

const switchStateForHoveredObjectOn3D = ({ object}) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.HoveredObjectOn3DStateSwitch({ object: object}));
    } catch (e) {
      console.log(e);
    }
  };
};

export default {
  switchStateForHoveredObjectOn3D,
};
