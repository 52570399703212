const getContourEdgeHelperShowState = ({ contourEdgeHelper }) => {
  return contourEdgeHelper.contourEdgeHelper.show;
};

const getContourEdgeHelperEdge = ({contourEdgeHelper}) => {
  return contourEdgeHelper.contourEdgeHelper.edge || false
}

const getContourEdgeHelperCursorPosition = ({contourEdgeHelper}) => {
  return contourEdgeHelper.contourEdgeHelper.cursorPosition || {
    x: 0,
    y: 0,
  }
}

export default {
  getContourEdgeHelperShowState,
  getContourEdgeHelperEdge,
  getContourEdgeHelperCursorPosition
};
