import Languages from "../../../../../translation/Languages";
import {materialTitle} from "../../../../../helpers/helper";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import {memo} from "react";
import imgMaterial from "../../../../../media/material-test.png";
import {Tooltip} from "../../../Tooltip";
import img from "../../../../../media/material-add-img-test.svg";
import {
	createImgForMaterialFace,
	createTextForMaterialFace
} from "../../../../CreateProject/Materials/MaterialItem/MaterialItem";

const Material = ({detailMaterial, detailId}) => {
    const construction = useSelector(projectSelectors.getConstruction);
    const currentMaterials = useSelector(projectSelectors.getMaterials);

    const materialTitleChange = (detailMaterial) => {
        const material = currentMaterials.find((el) => el.index === detailMaterial)
        return materialTitle(material)
    };
		const materialFaceImg = () => {
			const material = currentMaterials.find((el) => el.index === detailMaterial)
			return createImgForMaterialFace(material)
		}

		const materialFaceText = () => {
			const material = currentMaterials.find((el) => el.index === detailMaterial)
			return createTextForMaterialFace(material)
		}
    return (
        <>
					<div className="pointer detailMaterial_Face" data-tooltip-id={`material-tooltip-${detailId}`}>
						{construction._materials.findIndex(
							(el) => el.index === detailMaterial
						) + 1}
						<img
							src={materialFaceImg()}
							alt="face_back"
							width={18}
							height={18}
						/>
					</div>
					<Tooltip id={`material-tooltip-${detailId}`}>
						<div
							className="itemHelper__material-container detailsTab__materials-tooltip">
										<div className="itemHelper__material-header">
												<div
														className="itemHelper__text itemHelper__material-title">{Languages.getTranslation('material', true)}:
												</div>
												<span
														className="itemHelper__material-title itemHelper__text ">№{detailMaterial.index + 1 || detailMaterial + 1}</span>
										</div>
										<div className="itemHelper__material-name">
												<img src={imgMaterial} alt="material"/>
											<div className='itemHelper__block-container'>
												<span className="itemHelper__text2">{`Матеріал: ${materialTitleChange(detailMaterial)}`}</span>
												<span className="itemHelper__text2">{`${materialFaceText(detailMaterial)}`}</span>
											</div>

										</div>
						</div>
					</Tooltip>
				</>
		)
}

export default memo(Material);