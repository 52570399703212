import {empty, isset, sortByType, isNumber} from '../../helpers/helper'
import Helpers from './Helpers'
import store from "../../redux/store";
import Construction from '../Construction'


//this.contour - контур детали
//this.shapes - обработки

export default class Contour {
	_baseContour;
	contour;
	corners = [];
	shapes;
	edges = {left: null, top: null, right: null, bottom: null}
	_h;
	_l;
	_detail;

	constructor({h, l, contour = [], corners = [], shapes = [], cutouts = [], edges = [], updateContour, detail}) {
		this._h = h;
		this._l = l;
		if (!empty(edges)) this.edges = edges;
		this.contour = Helpers.updateContour(this.l, this.h, contour, {...this.edges});
		this._baseContour = [...this.contour];
		this.corners = corners;
		this.shapes = shapes;
		this._detail = detail;
		
		this.addCorners();
		this.addMills()
		
		if (typeof updateContour === 'function') {
			updateContour(this.contour);
		}

	}

	addCorners() {
		if (empty(this.corners)) return;
		this.corners.forEach(corner => {
			this.addMill(corner.cutOut);
		})
		this.addCornerEdgesToSides();
	}
	
	getPerimeterPosition = (x, y) => {
		if (x === 0) {
			// ліва сторона (знизу вверх)
			return y;
		} else if (y === this._detail.h) {
			// верхня сторона (зліва направо)
			return this._detail.h + x;
		} else if (x === this._detail.l) {
			// права сторона (зверху вниз)
			return this._detail.h + this._detail.l + (this._detail.h - y);
		} else if (y === 0) {
			// нижня сторона (справа наліво)
			return this._detail.h + this._detail.l + this._detail.h + (this._detail.l - x);
		}
		
		// якщо точка не на периметрі
		return -1;
	};
	addMills() {
		const sortingArr = ['closed', 'partial', 'smile', 'tableTop', 'tableCorner', "radiusEdge", "gEdge", "uShape"]
		const closedMills = this.shapes.filter(shape => shape.type === 'closed')
		const notClosedMills = this.shapes.filter(shape => shape.type !== 'closed')
		// const array = [...notClosedMills, ...closedMills]
		closedMills.forEach(shape => {
			this.addMillClosed(shape.cutOut)
		})
		notClosedMills.forEach(shape => {
			shape.cutOutElements = !empty(shape?.overallElements) ? shape.overallElements : shape.cutOut.elements;
		})


		notClosedMills.sort((a, b) => {
			if (!a.cutOutElements?.[0] || !b.cutOutElements?.[0]) return 0;
			
			const posA = this.getPerimeterPosition(a.cutOutElements[0].x1, a.cutOutElements[0].y1);
			const posB = this.getPerimeterPosition(b.cutOutElements[0].x1, b.cutOutElements[0].y1);
			
			return posA - posB;
		});
		
		
		notClosedMills.forEach(shape => {
			const cutout = shape.cutOut;
			// if (cutout?.subType ==='mill') {
			this.addMill(cutout)
						// this.deleteLinesThatArePoint()
	            // } else if (cutout?.subType === 'tableProc') {
			// 	switch (cutout.type) {
			// 		case 'tableTop':
			// 			this.addMill(cutout)
			// 			this.deleteLinesThatArePoint()
						// break;
					// case 'tableCorner':
					// 	this.addMill(cutout)
					// 	this.deleteLinesThatArePoint()
						// break;
				// }
			// }
		})
	}

	// oldContourMethod() {
  //
	// }
  //
	// addMillPartial(cutout) {
	// 	const contour = [...this.contour];
	// 	const fixedCutIutElements = cutout.elements
	// 	console.log(cutout.place)
	// 	this.contour.forEach((cont, i) => {
	// 		let line, line2, startIndex, endIndex;
	// 		this.getUShapePoints(fixedCutIutElements).forEach((point, j) => {
	// 			if(!empty(cont)) {
	// 				if (Helpers.isPointOnSegment(point.x, point.y, cont.x1, cont.y1, cont.x2, cont.y2)) {
	// 					if (j === 0) {
	// 						line = {...cont};
	// 						startIndex = i;
	// 						line.x2 = point.x;
	// 						line.y2 = point.y;
	// 					} else {
	// 						endIndex = i;
	// 						line2 = {...cont};
	// 						line2.x1 = point.x;
	// 						line2.y1 = point.y;
	// 					}
	// 				}
	// 			}
	// 		})
  //
	// 		switch (cutout.sidesCount) {
	// 			case 1:
	// 				if (!empty(startIndex) && !empty(endIndex) && startIndex === endIndex) {
	// 					contour.splice(i, 1, line, ...fixedCutIutElements, line2)
	// 				}
	// 				break;
	// 			case 2:
	// 				if(!empty(startIndex)){
	// 					contour.splice(startIndex, 2, line, ...fixedCutIutElements, line2)
	// 				}
	// 				break;
	// 			case 3:
	// 				if(!empty(line) && !empty(line2)) {
	// 					const start = startIndex === contour.length - 1 ? 0 : startIndex
	// 					contour.splice(start, 4, ...fixedCutIutElements, {...line, x1: line2.x1, y1: line2.y1} )
	// 				}
  //
	// 				break;
	// 		}
  //
	// 	})
	// 	this.contour = [...contour]
	// }
	getLinesIntersection(line1, line2) {
		const { x1, y1, x2, y2 } = line1;
		const { x1: x3, y1: y3, x2: x4, y2: y4 } = line2;
		const x = [], y = [];
		const aPoints = [];
		// Calculating denominator to detect parallel or collinear conditions
		const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
		
		if (denominator === 0) {
			// Check if the lines are collinear
			const crossProduct = (x3 - x1) * (y2 - y1) - (y3 - y1) * (x2 - x1);
			if (crossProduct !== 0) {
				// Lines are parallel but not collinear
				// return { type: "parallel" };
			}
			
			// Lines are collinear, check if they overlap
			if (x1 === x2 && x3 === x4) {
				// Both lines are vertical (same x-axis)
				if (x1 !== x3) {
					return { type: "none" }; // No overlap, different x
				}
				
				// Find overlap by comparing y ranges
				const overlapStart = Math.max(Math.min(y1, y2), Math.min(y3, y4));
				const overlapEnd = Math.min(Math.max(y1, y2), Math.max(y3, y4));
				
				if (overlapStart <= overlapEnd) {
					aPoints.push({ x: x1, y: overlapStart })
					aPoints.push({ x: x1, y: overlapEnd })
					return {
						type: "overlap",
						start: { x: x1, y: overlapStart },
						end: { x: x1, y: overlapEnd },
						aPoints
					};
				}
				
				return { type: "none" }; // No overlap
			} else if (y1 === y2 && y3 === y4) {
				// Both lines are horizontal (same y-axis)
				if (y1 !== y3) {
					return { type: "none" }; // No overlap, different y
				}
				
				// Find overlap by comparing x ranges
				const overlapStart = Math.max(Math.min(x1, x2), Math.min(x3, x4));
				const overlapEnd = Math.min(Math.max(x1, x2), Math.max(x3, x4));
				
				if (overlapStart <= overlapEnd) {
					aPoints.push({ x: overlapStart, y: y1 })
					aPoints.push({ x: overlapEnd, y: y1 })
					return {
						type: "overlap",
						start: { x: overlapStart, y: y1 },
						end: { x: overlapEnd, y: y1 },
						aPoints
					};
				}
				
				return { type: "none" }; // No overlap
			}
			
			// Lines are collinear but not strictly vertical or horizontal.
			// Find intersection range by projecting both lines onto their axis.
			const xOverlapStart = Math.max(Math.min(x1, x2), Math.min(x3, x4));
			const xOverlapEnd = Math.min(Math.max(x1, x2), Math.max(x3, x4));
			const yOverlapStart = Math.max(Math.min(y1, y2), Math.min(y3, y4));
			const yOverlapEnd = Math.min(Math.max(y1, y2), Math.max(y3, y4));
			
			if (xOverlapStart <= xOverlapEnd && yOverlapStart <= yOverlapEnd) {
				aPoints.push({ x: xOverlapStart, y: yOverlapStart })
				aPoints.push({ x: xOverlapEnd, y: yOverlapStart })
				return {
					type: "overlap",
					start: { x: xOverlapStart, y: yOverlapStart },
					end: { x: xOverlapEnd, y: yOverlapEnd },
					aPoints
				};
			}
			
			return { type: "none" };
		}
		
		// Lines are not parallel; calculate the intersection point
		const t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / denominator;
		const u = -((x1 - x2) * (y1 - y3) - (y1 - y2) * (x1 - x3)) / denominator;
		
		// Check if the intersection point lies on both line segments
		if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
			const intersectX = x1 + t * (x2 - x1);
			const intersectY = y1 + t * (y2 - y1);
			aPoints.push({ x: intersectX, y: intersectY })
			return {
				type: "point",
				x: intersectX,
				y: intersectY,  aPoints
			};
		}
		
		return { type: "none" }; // No intersection within the segments
	}
	
	getLineArcIntersection = (line, arc) => {
		const EPSILON = 1e-8;
		const x = [], y = [];
		const aPoints = [];
		
		// Деструктуризація переданих об'єктів
		const { x1: lx1, y1: ly1, x2: lx2, y2: ly2 } = line;
		const { xc: cx, yc: cy, x1: ax1, y1: ay1, x2: ax2, y2: ay2, dir: clockwise } = arc;
		
		// Розрахунок радіуса (через початкову точку дуги і її центр)
		const r = Math.hypot(ax1 - cx, ay1 - cy);
		
		// Параметрична форма рівняння лінії
		const dx = lx2 - lx1;
		const dy = ly2 - ly1;
		
		// Складові квадратного рівняння для визначення перетину
		const A = dx * dx + dy * dy;
		const B = 2 * (dx * (lx1 - cx) + dy * (ly1 - cy));
		const C = (lx1 - cx) ** 2 + (ly1 - cy) ** 2 - r * r;
		
		const discriminant = B * B - 4 * A * C;
		
		// Якщо немає точок перетину прямої з колом
		if (discriminant < -EPSILON) {
			return { type: "none" };
		}
		
		// Визначаємо t з допустимою похибкою
		const sqrtDisc = discriminant < 0 ? 0 : Math.sqrt(discriminant);
		const t1 = (-B + sqrtDisc) / (2 * A);
		const t2 = (-B - sqrtDisc) / (2 * A);
		
		const intersectionPoints = [];
		
		// Функція перевірки кута, з epsilon і циклічністю
		const angleInArc = (angle, startAngle, endAngle, clockwise) => {
			const mod = (val) => (val + 2 * Math.PI) % (2 * Math.PI);
			angle = mod(angle);
			startAngle = mod(startAngle);
			endAngle = mod(endAngle);
			
			if (clockwise) {
				if (startAngle >= endAngle) {
					return angle - EPSILON <= startAngle && angle + EPSILON >= endAngle;
				} else {
					return angle - EPSILON <= startAngle || angle + EPSILON >= endAngle;
				}
			} else {
				if (endAngle >= startAngle) {
					return angle + EPSILON >= startAngle && angle - EPSILON <= endAngle;
				} else {
					return angle + EPSILON >= startAngle || angle - EPSILON <= endAngle;
				}
			}
		};
		
		// Функція для отримання кута точки
		const pointAngle = (px, py) => Math.atan2(py - cy, px - cx);
		
		// Кути початку та кінця дуги
		const angleStart = pointAngle(ax1, ay1);
		const angleEnd = pointAngle(ax2, ay2);
		
		[t1, t2].forEach((t) => {
			if (t >= -EPSILON && t <= 1 + EPSILON) {
				const ix = lx1 + t * dx;
				const iy = ly1 + t * dy;
				
				const angle = pointAngle(ix, iy);
				
				if (angleInArc(angle, angleStart, angleEnd, clockwise)) {
					intersectionPoints.push({ x: ix, y: iy });
					aPoints.push({ x: ix, y: iy });
				}
			}
		});
		
		if (intersectionPoints.length === 0) {
			return { type: "none" };
		}
		
		return { type: "points", points: intersectionPoints, aPoints};
	};
	
	getArcArcIntersection(arc1, arc2) {
		const x = [], y = [];
		const aPoints = [];
		// Допоміжна функція для отримання точок перетину двох кіл
		const getCircleIntersections = (cx1, cy1, r1, cx2, cy2, r2) => {
			const dx = cx2 - cx1;
			const dy = cy2 - cy1;
			const d = Math.hypot(dx, dy);
			
			
			// Якщо нема перетину
			if (d > r1 + r2 || d < Math.abs(r1 - r2) || d === 0) {
				return [];
			}
			
			const a = (r1**2 - r2**2 + d**2) / (2 * d);
			const h = Math.sqrt(Math.max(0, r1**2 - a**2));
			
			const xm = cx1 + (a * dx) / d;
			const ym = cy1 + (a * dy) / d;
			
			const rx = -(dy * (h/d));
			const ry = dx * (h/d);
			
			return [
				{ x: xm + rx, y: ym + ry },
				{ x: xm - rx, y: ym - ry }
			];
		};
		
		// Допоміжна функція для перевірки чи лежить точка на заданій дузі
		const isPointOnArc = (arc, point) => {
			const centerAngle = (x, y) => (Math.atan2(y - arc.yc, x - arc.xc) + 2 * Math.PI) % (2 * Math.PI);
			
			const startAngle = centerAngle(arc.x1, arc.y1);
			const endAngle = centerAngle(arc.x2, arc.y2);
			const pointAngle = centerAngle(point.x, point.y);
			
			if (arc.dir) { // Якщо за годинниковою
				return startAngle >= endAngle
					? (pointAngle <= startAngle && pointAngle >= endAngle)
					: (pointAngle <= startAngle || pointAngle >= endAngle);
			} else { // Якщо проти годинникової
				return endAngle >= startAngle
					? (pointAngle >= startAngle && pointAngle <= endAngle)
					: (pointAngle >= startAngle || pointAngle <= endAngle);
			}
		};
		
		// Отримуємо точки перетину батьківських кіл дуг
		const intersectionPoints = getCircleIntersections(
			arc1.xc, arc1.yc, arc1.r,
			arc2.xc, arc2.yc, arc2.r
		);
		
		// Перевіряємо чи належать ці точки обом дугам
		const validPoints = intersectionPoints.filter(pt =>
		{
			if(isPointOnArc(arc1, pt) && isPointOnArc(arc2, pt)) {
				aPoints.push({ x: pt.x, y: pt.y });
				return true;
			}
		}
		);
		
		return validPoints.length
			? { type: 'points', points: validPoints,  aPoints }
			: { type: 'none' };
	}
	
	addMillClosed(cutout) {
		if (!empty(cutout.elements)) {
			this.contour = cutout.elements
		}
	}

	checkIntersections(mill) {
		const intersections = [];
		let point;
		this.contour.forEach((cont, i) => {
			mill.forEach((mill_el, j) => {
				switch (cont.type) {
					case 'line':
						if(mill_el.type === 'line') {
							point = this.getLinesIntersection(cont, mill_el);
							if(point.type !== 'none') {
								intersections.push({...point, cIndex: i, mIndex: j})
							}
							
						} else if(mill_el.type === 'arc'){
							point = this.getLineArcIntersection(cont, mill_el)
							if(point.type !== 'none') {
								intersections.push({...point, cIndex: i, mIndex: j})
							}
						}
						break;
					case 'arc':
						if(mill_el.type === 'line') {
							point = this.getLineArcIntersection(mill_el, cont);
							if(point.type !== 'none') {
								intersections.push({...point, cIndex: i, mIndex: j})
							}
						} else if(mill_el.type === 'arc'){
							point = this.getArcArcIntersection(mill_el, cont);
							if(point.type !== 'none') {
								intersections.push({...point, cIndex: i, mIndex: j})
							}
						}
				}
			})
		})
		const points = [];
		intersections.forEach(point => {
			point.aPoints.forEach(aPoint => {
				points.push({...aPoint, cIndex: point.cIndex, mIndex: point.mIndex})
			})
		})
		return points.sort((a, b) => {
			const posA = this.getPerimeterPosition(a.x, a.y);
			const posB = this.getPerimeterPosition(b.x, b.y);
			// Спочатку сортуємо за позицією на периметрі
			return posA - posB;
		});
	}
	
	addMill(cutout) {
		if (empty(cutout.elements)) return false
		const isBottomLeft = ['left', 'bottom'].every(element => cutout.touchedSides.includes(element))
		const isBottomRight = ['right', 'bottom'].every(element => cutout.touchedSides.includes(element))
		const isBottom = cutout.touchedSides.includes('bottom') && cutout.touchedSides.length === 1
		const isRight = cutout.touchedSides.includes('right') && cutout.touchedSides.length === 1
		function roundPoint(num1, num2) {
			let distance = Math.abs(num1 - num2);
			if (distance <= 0.050) {
				return parseFloat(num2.toFixed(3));
			} else {
				return parseFloat(num1.toFixed(3));
			}
		}

		let contour = [...this.contour];
		const fixedCutIutElements = cutout.elements

		let startMainIndex, endMainIndex, line, line2, lastIntersection;
		const intersections = this.checkIntersections(fixedCutIutElements).sort((a, b) => a.mIndex - b.mIndex);
		// const linePoints = intersections.filter(point => point.type === 'point').sort((a, b) => a.mIndex - b.mIndex);
		// const lineOverlaps = intersections.filter(point => point.type === 'overlap' && (point.start.x !== point.end.x || point.start.y !== point.end.y) ).sort((a, b) => a.mIndex - b.mIndex);
		// const arcPoints = intersections.filter(point => point.type === 'points').sort((a, b) => a.mIndex - b.mIndex);
		// const singlePoints = [];
		if(!empty(intersections)) {
			if(intersections.length > 1) {
				const lastInArr = intersections[intersections.length - 1];
				lastIntersection = intersections.filter(el => el.x === lastInArr.x && el.y === lastInArr.y).sort((a, b) => a.cIndex - b.cIndex)[0];
				const startArr = (intersections[0].x === 0 && intersections[0].y === 0) ? intersections[0] : intersections.filter(el => el.x === intersections[0].x && el.y === intersections[0].y).sort((a, b) => b.cIndex - a.cIndex)[0];
				// if(intersections[0].x === lastIntersection.x && intersections[0].y === lastIntersection.y) {
				// 	lastIntersection = intersections[intersections.length - 2];
				// }
				
				startMainIndex = startArr.cIndex;
				line = {...contour[startArr.cIndex], x2: startArr.x, y2: startArr.y};
				endMainIndex = lastIntersection.cIndex;
				fixedCutIutElements[startArr.mIndex].x1 = startArr.x;
				fixedCutIutElements[startArr.mIndex].y1 = startArr.y;
				fixedCutIutElements[lastIntersection.mIndex].x2 = lastIntersection.x;
				fixedCutIutElements[lastIntersection.mIndex].y2 = lastIntersection.y;
				line2 = {...contour[lastIntersection.cIndex], x1: lastIntersection.x, y1: lastIntersection.y};
				if(line.type === 'arc') {
					const angle = Helpers.calculateAngle(
									{x: line.xc, y: line.yc},
									{x: line.x1, y: line.y1},
									{x: line.x2, y: line.y2},
									!line.dir
								)
					line = {...line,
						"angle": angle.angle,
						"startAngle": angle.angleStart * (180 / Math.PI),
						"endAngle": angle.angleEnd * (180 / Math.PI),
						"startAngleRad": angle.angleStart,
						"endAngleRad": angle.angleEnd
					}
				}
				if(line2.type === 'arc') {
					const angle = Helpers.calculateAngle(
									{x: line2.xc, y: line2.yc},
									{x: line2.x1, y: line2.y1},
									{x: line2.x2, y: line2.y2},
									!line2.dir
								)
					line2 = {...line2,
						"angle": angle.angle,
						"startAngle": angle.angleStart * (180 / Math.PI),
						"endAngle": angle.angleEnd * (180 / Math.PI),
						"startAngleRad": angle.angleStart,
						"endAngleRad": angle.angleEnd
					}
				}
			}
			// else {
			// 	singlePoints.push({
			// 		...lineOverlaps[0],
			// 		x: lineOverlaps[0].start.x,
			// 		y: lineOverlaps[0].start.y
			// 	});
			// }
		}
		// if(!empty(linePoints) && (!startMainIndex || !endMainIndex)) {
		// 	if(linePoints.length > 1) {
		// 		lastIntersection = linePoints[linePoints.length - 1];
		// 		if(linePoints[0].x === lastIntersection.x && linePoints[0].y === lastIntersection.y) {
		// 			lastIntersection = linePoints[linePoints.length - 2];
		// 		}
		// 		startMainIndex = linePoints[0].cIndex;
		// 		endMainIndex = lastIntersection.cIndex;
		// 		if(isBottomLeft) {
		// 			line = {...contour[linePoints[0].cIndex], x2: linePoints[0].x, y2: linePoints[0].y};
		// 			line2 = {...contour[lastIntersection.cIndex], x1: lastIntersection.x, y1: lastIntersection.y};
		// 		} else {
		// 			line = {...contour[linePoints[0].cIndex], x2: linePoints[0].x, y2: linePoints[0].y};
		// 			fixedCutIutElements[linePoints[0].mIndex].x1 = linePoints[0].x;
		// 			fixedCutIutElements[linePoints[0].mIndex].y1 = linePoints[0].y;
		// 			fixedCutIutElements[lastIntersection.mIndex].x2 = lastIntersection.x;
		// 			fixedCutIutElements[lastIntersection.mIndex].y2 = lastIntersection.y;
		// 			line2 = {...contour[lastIntersection.cIndex], x1: lastIntersection.x, y1: lastIntersection.y};
		// 		}
		// 	} else {
		// 		singlePoints.push({
		// 			...linePoints[0],
		// 			x: linePoints[0].x,
		// 			y: linePoints[0].y
		// 		});
		// 	}
		// }
		// if(!empty(arcPoints) && (!startMainIndex || !endMainIndex)) {
		// 	if(arcPoints.length > 1) {
		// 		lastIntersection = arcPoints[arcPoints.length - 1];
		// 		startMainIndex = arcPoints[0].cIndex;
		// 		endMainIndex = lastIntersection.cIndex;
		// 		if(isBottomRight || isBottom || isRight) {
		// 			fixedCutIutElements[arcPoints[0].mIndex].x1 = arcPoints[0].points[0].x;
		// 			fixedCutIutElements[arcPoints[0].mIndex].y1 = arcPoints[0].points[0].y;
		// 			fixedCutIutElements[lastIntersection.mIndex].x2 = lastIntersection.points[0].x;
		// 			fixedCutIutElements[lastIntersection.mIndex].y2 = lastIntersection.points[0].y;
		// 			if(arcPoints[0].mIndex !== 0) {
		// 				fixedCutIutElements.splice(0, arcPoints[0].mIndex)
		// 			}
		// 			if(lastIntersection.mIndex !== fixedCutIutElements.length - 1) {
		// 				fixedCutIutElements.splice(lastIntersection.mIndex)
		// 			}
		// 			line = {...contour[startMainIndex], x2: arcPoints[0].points[arcPoints[0].points.length - 1].x, y2: arcPoints[0].points[arcPoints[0].points.length - 1].y};
		// 			line2 = {...contour[endMainIndex], x1: lastIntersection.points[0].x, y1: lastIntersection.points[0].y};
		// 		} else {
		// 			fixedCutIutElements[arcPoints[0].mIndex].x1 = arcPoints[0].points[0].x;
		// 			fixedCutIutElements[arcPoints[0].mIndex].y1 = arcPoints[0].points[0].y;
		// 			fixedCutIutElements[lastIntersection.mIndex].x2 = lastIntersection.points[0].x;
		// 			fixedCutIutElements[lastIntersection.mIndex].y2 = lastIntersection.points[0].y;
		// 			line = {...contour[startMainIndex], x2: arcPoints[0].points[0].x, y2: arcPoints[0].points[0].y};
		// 			line2 = {...contour[endMainIndex], x1: lastIntersection.points[0].x, y1: lastIntersection.points[0].y};
		// 		}
		//
		// 		const angle = Helpers.calculateAngle(
		// 						{x: line.xc, y: line.yc},
		// 						{x: line.x1, y: line.y1},
		// 						{x: line.x2, y: line.y2},
		// 						!line.dir
		// 					)
		// 		const angle2 = Helpers.calculateAngle(
		// 			{x: line2.xc, y: line2.yc},
		// 			{x: line2.x1, y: line2.y1},
		// 			{x: line2.x2, y: line2.y2},
		// 			!line2.dir
		// 		)
		// 		line = {...line,
		// 			"angle": angle.angle,
		// 			"startAngle": angle.angleStart * (180 / Math.PI),
		// 			"endAngle": angle.angleEnd * (180 / Math.PI),
		// 			"startAngleRad": angle.angleStart,
		// 			"endAngleRad": angle.angleEnd
		// 		}
		// 		line2 = {...line2,
		// 			"angle": angle2.angle,
		// 			"startAngle": angle2.angleStart * (180 / Math.PI),
		// 			"endAngle": angle2.angleEnd * (180 / Math.PI),
		// 			"startAngleRad": angle2.angleStart,
		// 			"endAngleRad": angle2.angleEnd
		// 		}
		// 	} else {
		// 		singlePoints.push({
		// 			...arcPoints[0],
		// 			x: arcPoints[0].points[0].x,
		// 			y: arcPoints[0].points[0].y
		// 		});
		// 	}
		// }
		// if(!empty(singlePoints) && (!startMainIndex || !endMainIndex)) {
		// 	singlePoints.sort((a, b) => {
		// 		const posA = this.getPerimeterPosition(a.x, a.y);
		// 		const posB = this.getPerimeterPosition(b.x, b.y);
		// 		return posA - posB;
		// 	});
		// 	lastIntersection = singlePoints[singlePoints.length - 1];
		// 	startMainIndex = singlePoints[0].cIndex;
		// 	if(fixedCutIutElements[singlePoints[0].mIndex] && fixedCutIutElements[lastIntersection.mIndex]) {
		// 		fixedCutIutElements[singlePoints[0].mIndex].x1 = singlePoints[0].x;
		// 		fixedCutIutElements[singlePoints[0].mIndex].y1 = singlePoints[0].y;
		// 		fixedCutIutElements[lastIntersection.mIndex].x2 = lastIntersection.x;
		// 		fixedCutIutElements[lastIntersection.mIndex].y2 = lastIntersection.y;
		// 	}
		// 	line = {...contour[singlePoints[0].cIndex], x2: singlePoints[0].x, y2: singlePoints[0].y};
		// 	endMainIndex = lastIntersection.cIndex;
		// 	line2 = {...contour[lastIntersection.cIndex], x1: lastIntersection.x, y1: lastIntersection.y};
		// }
		console.log(intersections)
		if(empty(startMainIndex) || empty(endMainIndex)) {
			return
		}
		let  countToDelete;
		if(endMainIndex >= startMainIndex) {
			let delta = 0;
			// if(this.checkElementIfDot(line) && startMainIndex !== 0) {
			// 	delta = 1;
			// }
			// if(this.checkElementIfDot(line2)) {
			// 	delta += 1;
			// }
			countToDelete = endMainIndex - startMainIndex - delta;
		} else if(endMainIndex === 0){
			countToDelete = contour.length - (startMainIndex + 1)
		} else if(startMainIndex + 1 === contour.length){
			countToDelete = endMainIndex;
		} else {
			countToDelete = (contour.length - endMainIndex) - startMainIndex + 1;
		}
		// if(countToDelete === 0) {
		// 	let element;
		// 	if(cutout.sidesCount !== 1) {
		// 		if(contour[startMainIndex].type === 'arc') {
		// 			element = {
		// 				...contour[startMainIndex],
		// 				x1: line.x1,
		// 				y1: line.y1,
		// 				x2: line.x2,
		// 				y2: line.y2
		// 			}
		// 			const angle = Helpers.calculateAngle(
		// 				{x: element.xc, y: element.yc},
		// 				{x: element.x1, y: element.y1},
		// 				{x: element.x2, y: element.y2},
		// 				element.dir
		// 			)
		//
		// 			element = {...element,
		// 				"angle": angle.angle,
		// 				"startAngle": angle.angleStart * (180 / Math.PI),
		// 				"endAngle": angle.angleEnd * (180 / Math.PI),
		// 				"startAngleRad": angle.angleStart,
		// 				"endAngleRad": angle.angleEnd
		// 			}
		// 		} else {
		// 			element = line
		// 		}
		// 		contour = [
		// 			...fixedCutIutElements,
		// 			{
		// 				...element
		// 			}
		// 		]
		// 	} else {
		// 		element = []
		// 		if(!this.checkElementIfDot(line)) {
		// 			element.push(line)
		// 		}
		// 		element = [...element, ...fixedCutIutElements];
		// 		if(!this.checkElementIfDot(line2)) {
		// 			element.push(line2)
		// 		}
 		// 		contour.splice(startMainIndex, 1, ...element)
		// 	}
		// } else {
			let element = []
			if(!this.checkElementIfDot(line)) {
				element.push(line)
			}
			element = [...element, ...fixedCutIutElements];
			if(!this.checkElementIfDot(line2)) {
				element.push(line2)
			}
			if(startMainIndex + 1 === contour.length && endMainIndex !== startMainIndex) {
				contour[contour.length - 1] = line;
				const start = {...contour[0], x1: line2.x1, y1: line2.y1}
				if(!this.checkElementIfDot(start)) {
					contour[0] = start
				}
				contour.splice(0, endMainIndex === 0 ? 0 : countToDelete + 1, ...element)

			} else {
				contour.splice(startMainIndex, countToDelete + 1, ...element)
				// if(endMainIndex === 0 && startMainIndex !== 0 || endMainIndex < startMainIndex) {
				// 	contour.splice(0, endMainIndex)
				// 	contour[0] = {
				// 		...contour[0],
				// 		x1: line2.x2,
				// 		y1: line2.y2
				// 	}
				// }
			}

		// }

		this.contour = [...contour].map((el) => ({...el, x1: Number(el.x1), y1: Number(el.y1), x2: Number(el.x2), y2: Number(el.y2)}))
	}

	deleteLinesThatArePoint() {
    this.contour.forEach((cont, i) => {
			if (cont.type === 'line') {
				if (cont.x1 === cont.x2 && cont.y1 === cont.y2) {
					this.contour.splice(i, 1)
				}
			}
    })
	}

	checkElementIfDot(el) {
		return el.x1 === el.x2 && el.y1 === el.y2
	}

	

	getPoints(elements) {
		const lastIndex = elements.length - 1;
		return [
			{
				x: (elements[0].x2),
				y: (elements[0].y2)

			},
			{
				x: (elements[lastIndex].x2),
				y: (elements[lastIndex].y2)
			}
		]
	}

	getUShapePoints (elements) {
		const lastIndex = elements.length - 1;
		return [
			{
				x: (elements[0].x1),
				y: (elements[0].y1)

			},
			{
				x: (elements[lastIndex].x2),
				y: (elements[lastIndex].y2)
			}
		]
	}


	addGEdge(cutout) {
		const { y,x, height,width,  angle } = cutout

		switch (cutout.angle){
			case "left_bottom":
				this.updateBevelsHoles(0, y , angle.split("_")[0])
				this.updateBevelsHoles(0, x , angle.split("_")[1])
                break;
			case "right_bottom":
				this.updateBevelsHoles(x, x + width , angle.split("_")[1])
				this.updateBevelsHoles(height, y, angle.split("_")[0])
				break;
			case "left_top":
				this.updateBevelsHoles(x, x + width, angle.split("_")[1])
				this.updateBevelsHoles(y, y + height, angle.split("_")[0])
				break;
			case "right_top":
				this.updateBevelsHoles(x, x + width, angle.split("_")[1]);
				this.updateBevelsHoles(y + height, y, angle.split("_")[0])
				break;
		}

		const contour = [...this.contour];
		const newElements = [...cutout.elements]
		let line, line2, startIndex, endIndex;
		this.contour.forEach((cont, i) => {
			this.getUShapePoints(newElements).forEach((point, j) => {
				if (Helpers.isPointOnSegment(point.x, point.y, cont.x1, cont.y1, cont.x2, cont.y2)) {
					if (cutout.angle === 'left_bottom') {
						if (j === 0) {
							line2 = {...cont};
							startIndex = i;
							line2.x2 = point.x;
							line2.y2 = point.y;
						} else {
							endIndex = i;
							line = {...cont};
							line.x1 = point.x;
							line.y1 = point.y;
						}
					} else {
						if (j === 0) {
							line = {...cont};
							startIndex = i;
							line.x2 = point.x;
							line.y2 = point.y;
						} else {
							endIndex = i;
							line2 = {...cont};
							line2.x1 = point.x;
							line2.y1 = point.y;
						}
					}
				}
			})
		})
		if (cutout.angle === 'left_bottom') {
			contour.splice(0, 1, line)
			contour.splice(contour.length - 1, 1, line2, ...newElements)
		} else {
			if(!empty(startIndex)){
				contour.splice(startIndex, 2, line, ...newElements, line2)
			}
		}
		this.contour = [...contour]
	}

	addRadiusEdge(cutout) {
		const contour = [...this.contour];
		const newElements = [...cutout.elements]
		let line, line2, startIndex, endIndex;
		this.contour.forEach((cont, i) => {
			this.getUShapePoints(newElements).forEach((point, j) => {
				if (Helpers.isPointOnSegment(point.x, point.y, cont.x1, cont.y1, cont.x2, cont.y2)) {
					if (cutout.angle === 'left_bottom') {
						if (j === 0) {
							line2 = {...cont};
							startIndex = i;
							line2.x2 = point.x;
							line2.y2 = point.y;
						} else {
							endIndex = i;
							line = {...cont};
							line.x1 = point.x;
							line.y1 = point.y;
						}
					} else {
						if (j === 0) {
							line = {...cont};
							startIndex = i;
							line.x2 = point.x;
							line.y2 = point.y;
						} else {
							endIndex = i;
							line2 = {...cont};
							line2.x1 = point.x;
							line2.y1 = point.y;
						}
					}
				}
			})
		})
		if (cutout.angle === 'left_bottom') {
			contour.splice(0, 1, line)
			contour.splice(contour.length - 1, 1, line2, ...newElements)
		} else {
			contour.splice(startIndex, 2, line, ...newElements, line2)
		}
		this.contour = [...contour]
	}

	chooseSideForUpdate = (y,x, height, place, width) => {
		switch (place) {
			case "left":
			case "right":

				this.updateBevelsHoles(y, y + height, place);
				break;

			case "top":
			case "bottom":

				this.updateBevelsHoles(x, x + width, place);
				break;

		}
   }
	addUShapes(cutout) {
			//TODO убрать условия потом
                const { y,x, height, place, width } = cutout
		  this.chooseSideForUpdate( y,x, height, place, width)

				const fixedCutIutElements = cutout.elements


				const contour = [...this.contour];
				this.contour.forEach((cont, i) => {
					let line, line2, arc1, arc2, startIndex, endIndex;
					switch (cont.type) {
						case 'line':
							this.getUShapePoints(fixedCutIutElements).forEach((point, j) => {
								if (Helpers.isPointOnSegment(point.x, point.y, cont.x1, cont.y1, cont.x2, cont.y2)) {
									if (j === 0) {
										line = {...cont};
										startIndex = i;
										line.x2 = point.x;
										line.y2 = point.y;
									} else {
										endIndex = i;
										line2 = {...cont};
										line2.x1 = point.x;
										line2.y1 = point.y;
									}

								}
							})
							if (!empty(startIndex) && !empty(endIndex) && startIndex === endIndex) {
								contour.splice(i, 1, line, ...fixedCutIutElements, line2)
							}
							break
						case 'arc':
							fixedCutIutElements.forEach((cut, j) => {
								switch (cut.type) {
									case 'line':
										const intersection = Helpers.findIntersectionOfSegmentAndArc(
											{x: cut.x1, y: cut.y1},
											{x: cut.x2, y: cut.y2},
											{x: cont.xc, y: cont.yc},
											cont.r,
											cont.startAngle,
											cont.endAngle
										)
										if (!empty(intersection)) {
											if (!empty(line) && empty(line2) || empty(line) && !empty(line2) || !empty(arc1)) {
												const angle = Helpers.calculateAngle(
													{x: cont.xc, y: cont.yc},
													{x: intersection.x, y: intersection.y},
													{x: cont.x2, y: cont.y2}
												)
												arc2 = {
													...cont,
													x2: intersection.x,
													y2: intersection.y,
													startAngle: angle.angleStart * (180 / Math.PI),
													endAngle: angle.angleEnd * (180 / Math.PI),
													startAngleRad: angle.angleStart,
													endAngleRad: angle.angleEnd
												};
												endIndex = i;
											} else if (empty(line) && empty(line2)) {
												const angle = Helpers.calculateAngle(
													{x: cont.xc, y: cont.yc},
													{x: cont.x1, y: cont.y1},
													{x: intersection.x, y: intersection.y}
												)
												arc1 = {
													...cont,
													x2: intersection.x,
													y2: intersection.y,
													startAngle: angle.angleStart * (180 / Math.PI),
													endAngle: angle.angleEnd * (180 / Math.PI),
													startAngleRad: angle.angleStart,
													endAngleRad: angle.angleEnd
												};
												startIndex = i;
											}
										}
								}
							})

							if (startIndex && endIndex) {
								if (startIndex === endIndex) { // both sides of uShape are intersect one arc

								} else {

								}
							}
							break
					}
				})
				this.contour = [...contour];
	}

	addCorner(corner) {
		switch (corner.angle){
			case "left_bottom":
				this.updateBevelsHoles(0, corner.y , corner.angle.split("_")[0])
				this.updateBevelsHoles(0, corner.x , corner.angle.split("_")[1])
				break;
			case "left_top":
				this.updateBevelsHoles(this.h, this.h - corner.y , corner.angle.split("_")[0])
				this.updateBevelsHoles(this.l, this.l - corner.x , corner.angle.split("_")[1])
				break;
		}
		const h = this.h;
		const l = this.l;
		let intersections = [];
		let p3, p4, xc, yc, startAngle, endAngle;
		const inverce = corner.angle === 'left_bottom';
		function getCornerAngle(corner) {
			switch (corner) {
				case 'left_bottom':
					return [-90, 180]
				case 'left_top':
					return [180, 90]
				case 'right_top':
					return [0, -90]
				case 'right_bottom':
					return [-90, 0]
			}
		}

		const angle = getCornerAngle(corner.angle);

		function addCorner(startPoints, endPoints) {
			switch (corner.type) {
				case "radius":
					const x1 = inverce ? endPoints.x : startPoints.x;
					const y1 = inverce ? endPoints.y : startPoints.y;
					const x2 = inverce ? startPoints.x : endPoints.x;
					const y2 = inverce ? startPoints.y : endPoints.y;
					const cont = Helpers.createArc(x1, y1, x2, y2, corner.r, xc, yc, true)
					cont.isCorner = corner.angle;
					cont.edge = corner.edge ? corner.edge.index : null;
					cont.color = corner.isActive ? Helpers.colorForActive : null
					return cont
				case "line":
					const contLine = Helpers.createLine(startPoints.x, startPoints.y, endPoints.x, endPoints.y);
					contLine.isCorner = corner.angle;
					contLine.color = corner.isActive ? Helpers.colorForActive : null;
					contLine.edge = corner.edge ? corner.edge.index : null
					return contLine;
			}
		}

		switch (corner.angle) {
			case 'left_bottom':
				if (corner.type === "radius") {
					xc = corner.r;
					yc = corner.r;
					startAngle = angle[0];
					endAngle = angle[1];
				} else {
					p3 = {x: corner.x, y: 0};
					p4 = {x: 0, y: corner.y};
				}
				break
			case 'left_top':
				if (corner.type === "radius") {
					xc = corner.r;
					yc = h - corner.r;
					startAngle = angle[0];
					endAngle = angle[1];
				} else {
					p3 = {x: 0, y: h - corner.y}
					p4 = {x: corner.x, y: h}
				}
				break
			case 'right_top':
				if (corner.type === "radius") {
					xc = l - corner.r;
					yc = h - corner.r;
					startAngle = angle[0];
					endAngle = angle[1];
				} else {
					p3 = {x: l - corner.x, y: h}
					p4 = {x: l, y: h - corner.y}
				}
				break
			case 'right_bottom':
				if (corner.type === "radius") {
					xc = l - corner.r;
					yc = corner.r;
					startAngle = angle[0];
					endAngle = angle[1];
				} else {
					p3 = {x: l, y: corner.y}
					p4 = {x: l - corner.x, y: 0}
				}
				break
		}

		switch (corner.type) {
			case "line":
				this.contour.forEach((el, i) => {
					let isIntersections = [];
					switch (el.type) {
						case 'line':
							isIntersections = Helpers.findIntersection(
								{x: el.x1, y: el.y1},
								{x: el.x2, y: el.y2},
								p3,
								p4
							)
							if (!empty(isIntersections)) {
								intersections.push({[i]: isIntersections})
							}
							break
						case 'arc':
							isIntersections = Helpers.findIntersectionOfSegmentAndArc(
								p3,
								p4,
								{x: el.xc, y: el.yc},
								el.r,
								el.startAngle,
								el.endAngle
							)
							if(!empty(isIntersections)) {
								intersections.push({[i]: isIntersections})
							}
							break
					}
				})
				break
			default:
				this.contour.forEach((el, i) => {
					let isIntersections = [];
					switch (el.type) {
						case 'line':
							isIntersections = Helpers.findIntersectionOfSegmentAndArc(
								{x: el.x1, y: el.y1},
								{x: el.x2, y: el.y2},
								{x: xc, y: yc},
								corner.r,
								startAngle,
								endAngle
							)
							if (!empty(isIntersections)) {
								intersections.push({[i]: isIntersections})
							}
							break
						case 'arc':

							isIntersections = Helpers.findIntersectionOfTwoArcs(
								{x: el.xc, y: el.yc},
								el.r,
								el.startAngle,
								el.endAngle,
								{x: xc, y: yc},
								corner.r,
								startAngle,
								endAngle
							)
							if (!empty(isIntersections)) {
								isIntersections.map(coordinate => intersections.push({[i]: coordinate}))
							}
							break
					}
				})
				break
		}
		if (!empty(intersections)) {
			let startIndex, endIndex, startPoints, endPoints, contour1, contour2, countsToDelete = 0;
			if(intersections.length > 2 &&  corner.type === "radius" && corner.angle === 'left_top'){

					intersections.splice( 2)
			}
			if (intersections.length > 2 && corner.type === "radius" && corner.angle === 'right_top') {
				if(corner.r === l && corner.r === h ){
					intersections.pop()
					intersections.shift()
				}else if( corner.r === h){
					//needs fix in the future
					if(l < h *2){
						intersections.shift()
						intersections.pop()

					}else if(l ===  h * 2){
						const left_top = this.corners.find(({ angle }) => angle === 'left_top')
                        if(left_top?.r < h){
							intersections.splice(3)

						}else{
							intersections.splice(2)

						}
					}
					else {

						intersections.splice(3)

					}

				}
				else if( corner.r === l){

					intersections.shift()
				}
				else if(corner.r === l/2){

					intersections.splice(0,1)
				}

				else{
					//needs fix in the future
					// intersections.splice(2)
					intersections.shift()
				}
			}
			if (intersections.length > 2 && corner.type === "line" && (corner.angle === 'left_top')) {
				//needs fix in the future
				intersections.splice(2)
                // 	intersections.pop();

			}

			if (intersections.length > 2 && corner.type === "line" &&  corner.angle === 'right_bottom') {
				intersections.shift();
			}
			if (intersections.length > 2 && corner.type === "radius" &&  corner.angle === 'right_bottom') {
				intersections.splice(0,1)

				// intersections.splice(2)
			}
			if (intersections.length < 2) {
				return false;
			}
			intersections.forEach((el, i) => {
				if (i === 0) {
					startIndex = Number(Object.keys(el)[0]);
					contour1 = {...this.contour[startIndex]};
					startPoints = el[startIndex];
					if (corner.angle === 'left_bottom') {
						contour1.x1 = el[startIndex].x
						contour1.y1 = el[startIndex].y;
					} else {
						contour1.x2 = el[startIndex].x;
						contour1.y2 = el[startIndex].y;
					}
				} else if (i === intersections.length - 1) {
					endIndex = Number(Object.keys(el)[0]);
					contour2 = {...this.contour[endIndex]};
					endPoints = el[endIndex];
					if (corner.angle === 'left_bottom') {
						contour2.x2 = el[endIndex].x;
						contour2.y2 = el[endIndex].y;
					} else {
						contour2.x1 = el[endIndex].x;
						contour2.y1 = el[endIndex].y;
					}
				}
			})

			let _corner = addCorner(startPoints, endPoints)
			_corner = {
				..._corner,
				pId: corner.id,
				meshType: `${corner.subType}-${corner.type}`,
				proc: corner
			}
			if (corner.angle === 'left_bottom') {
				this.contour.splice(0, 1, contour1)
				this.contour.splice(endIndex, 1, contour2, _corner)
			} else {
				if (this.contour.length > 3) {
					this.contour.splice(startIndex, endIndex - startIndex + 1, contour1, _corner, contour2)
				} else {
					switch (corner.angle) {
						case 'right_bottom':
							this.contour.splice(endIndex + 1, 1, _corner);
							break;
						case 'right_top':
							this.contour.splice(startIndex + 1, 1, _corner);
							break;
						case 'left_top':
							this.contour.splice(startIndex, 1, _corner);
							break;
					}
				}

			}

		}
	}

	addCornerEdgesToSides() {
		const {corners} = this._detail;
		const detail = this._detail;
		const edges = this.edges;
		const construction = this._detail.parent;
		const len = this.contour.length
		const nextIndex = i => {
			if (!empty(this.contour[i + 1])) {
				return i + 1
			} else {
				return 0
			}
		}
		const prewIndex = i => {
			if (i === 0) {
				return len - 1
			}
			return i - 1
		}
		const removeEdgeSideForWidthOrHeightCorner = () => {
			if(!empty(edges)){

			corners.map(corner => {
				if(corner.angle === "left_top"){
					if(corner.y === detail.h){
						edges.left = null
					}else if(corner.x === detail.l){
						edges.top = null
					}
				}else if(corner.angle === "right_top"){
					if(corner.y === detail.h){
						edges.right = null
					}else if(corner.x === detail.l){
						edges.top = null
					}
				}else if(corner.angle === "left_bottom"){
					if(corner.y === detail.h){
						edges.left = null
					}else if(corner.x === detail.l){
						edges.bottom = null
					}
				}else if(corner.angle === "right_bottom"){
					if(corner.y === detail.h){
						edges.right = null
					}else if(corner.x === detail.l){
						edges.bottom = null
					}
				}

			})
			}
		}

		const _corners = [];
		const usedSides = [];
		// removeEdgeSideForWidthOrHeightCorner()

		corners.map(item => item).reverse().forEach(el => {
			const sides = el.angle.split('_')
			if (el.type === "radius" && el.type !== "line") {
				const pres = _corners.find(_el => {
					return usedSides.includes(sides[0]) || usedSides.includes(sides[1])
				})
				if (!pres) {
					_corners.push({
						edge: el.edge?.index ?? null,
						corner: el.angle
					})
				}
				usedSides.push(...el.angle.split('_'));
			}
		})

		const addCornersToDb = (sides, edge) => {
			return new Promise((resolve, reject) => {
				function addToDB (side){
					if(['left', 'right', 'top', 'bottom'].includes(side) && !empty(side)){
						construction.updateDetailSideEdge(detail, side, edge)
							.then(() => {
								addToDB(sides.shift())
								// if(corners.length === 1){
								// 	let newSide = ''
								// 	if(side === 'left' && !edges[side]){ newSide = 'right' }
								// 	if(side === 'right' && !edges[side]){ newSide = 'left' }
								// 	if(side === 'top' && !edges[side]){ newSide = 'bottom' }
								// 	if(side === 'bottom' && !edges[side]){ newSide = 'top' }
								// 	if(['left', 'right', 'top', 'bottom'].includes(newSide)){
								// 		construction.updateDetailSideEdge(detail, newSide, null)
								// 			.then(() => addToDB(sides.shift()))
								// 			.catch(error => reject(error))
								// 	}else{
								// 		addToDB(sides.shift())
								// 	}
								// }else{
								// 	addToDB(sides.shift())
								// }
							})
							.catch(error => reject(error))
					} else {
						return resolve()
					}
				}
				addToDB(sides.shift())
			})
		}

		const addCornerEdges = (index, edge, currentContour) => {
			if (!empty(this.contour[index])) {
				this.contour[index].edge = edge
				// if (this.contour[index].isCorner) {
				// 	addCornersToDb(index, edge)
				// }
				if (!empty(nextIndex(index)) && nextIndex(index) !== currentContour) {
					if (!(this.contour[index].type === 'line' && this.contour[nextIndex(index)].type === 'line')) {
						addCornerEdges(nextIndex(index), edge, currentContour)
					}
				}
			}
		}

		const addCornerEdges2 = (index, edge, currentContour) => {
			if (!empty(this.contour[index])) {
				this.contour[index].edge = edge
				if (!empty(prewIndex(index)) && prewIndex(index) !== currentContour) {
					if (!(this.contour[index].type === 'line' && this.contour[prewIndex(index)].type === 'line')) {
						addCornerEdges2(prewIndex(index), edge, currentContour)
					}
				}
			}
		}

		_corners.reverse().forEach(corner => {
			const currentContour = this.contour.findIndex(el => el.isCorner === corner.corner);
			addCornerEdges(currentContour, corner.edge, currentContour);
			addCornerEdges2(currentContour, corner.edge, currentContour);
		})
		const sides = [];
		let edge = null;
		corners.forEach(el => {
			if(el.type === 'radius'){
				const tempAngle = el.angle.split('_');
				if(!empty(el?.edge?.index)){
					edge = el.edge.index;
				} else if(!empty(el?.edge) && isNumber(el.edge)) {
					edge = el.edge
				}
				if(!sides.includes(tempAngle[0])){ sides.push(tempAngle[0]) }
				if(!sides.includes(tempAngle[1])){ sides.push(tempAngle[1]) }
			}
		})

		addCornersToDb(sides, edge)
			.then(() => {})
			.catch(error => console.log(error));
	}
	updateBevelsHoles(start, end, side) {
		if(empty(this._detail.bevels)) return;
		const bevel = this._detail.bevels.find(el => el.edgeSide === side);
		if(!empty(bevel)) {
			bevel.ownHoles.push({start, end})
		}
	}
	get h() {
		return this._h
	}

	get l() {
		return this._l
	}
}