import { useState, useEffect, useRef } from "react";

import store from "redux/store";
import "./style.scss";
import { useDispatch } from "react-redux";
import { modalsOperations } from "redux/modals";
import { useSelector } from "react-redux";
import {projectOperations, projectSelectors} from "redux/project";
import Languages from "../../../translation/Languages";
import {checkDetailsWithEdges, edgeTitle, empty} from "../../../helpers/helper";
import { errorMessageOperations, errorMessageSelectors } from '../../../redux/errors';
import { ReactComponent as Arrow } from '../../../media/angle-down-purpur.svg';
import MaterialItem from "./MaterialItem/MaterialItem";
import { userSelectors } from "redux/user";
import {tempDetailOperations} from "redux/tempDetail";
import { toastWarning} from "../../../helpers/toasts";
import MaterialEdgeImg from "./MaterialEdgeImg/MaterialEdgeImg";
import {AppstoreAddOutlined} from "../../Icons/AppstoreAddIcon";

const optionsSelectGlue = [
	{ value: "glue-not-selected", glue: null},
	{ value: "select-glue-PUR-transparent", glue: {id: 0, name: 'PUR', type: 'transparent'}},
	{ value: "select-glue-PUR-white", glue: {id: 1, name: 'PUR', type: 'white'}},
	{ value: "select-glue-EVA-transparent", glue: {id: 2, name: 'EVA', type: 'transparent'}},
	{ value: "select-glue-EVA-white", glue: {id: 3, name: 'EVA', type: 'white'}},
];

const getIconClass = (state) => state
	? "btn__icon-angle-up"
	: "btn__icon-angle-down";

const Materials = ({ materials, setMaterials }) => {
	const dispatch = useDispatch();
	const showState = store.getState().modals.modals.show;
	const typeState = store.getState().modals.modals.type;
	const materialsConstruction = useSelector(projectSelectors.getMaterials);
	const edgesConstruction = useSelector(projectSelectors.getEdges);
	const project = useSelector(projectSelectors.getConstruction);
	const construction = useSelector(projectSelectors.getConstruction);
	const isArrowMaterial = useSelector(projectSelectors.getIsArrowMaterial);
	const isArrowEdge = useSelector(projectSelectors.getIsArrowEdge);

	const errorMessage = useSelector(errorMessageSelectors.getErrorMessageState)
	const [selectGlue, setSelectGlue] = useState(
		!empty(project?.projectData?.glue) ?
			optionsSelectGlue[project?.projectData?.glue?.id + 1]?.value :
			optionsSelectGlue[0]?.value
	)
	const permissions = useSelector(userSelectors.permissions);
	const [isOpen, setIsOpen] = useState(false)
	const [currentEdges, setCurrentEdges] = useState([])
	const clickRef = useRef(null)

	const deleteMaterial = (material) => {
		const detailsWithMaterialAndMultiplicity = construction.details.filter(
			(detail) => detail.material === material.index || detail.multiplicity?.material === material.index
		);

		if (!empty(detailsWithMaterialAndMultiplicity)) {
			dispatch(
				modalsOperations.switchStateForModal({
					show: true,
					type: "LINK_MATERIAL",
					isEdit: material,
				})
			);
		} else {
			if (!empty(material.parts)) {
        async function deleteReservingMaterialsForKronasElements(materials, construction) {
          for (const part of materials.parts) {
            if (part.typeParts === 'kronasElement') {
              await construction.deleteReservingMaterials(construction.projectData.id, part.id);
            }
          }
        }

        try {
          deleteReservingMaterialsForKronasElements(material, construction);
        } catch (error) {
         toastWarning(Languages.getTranslation('defaultError', true))
        }
			}
			construction.deleteMaterial(material.index)
				.then(() => {
					setMaterials([...construction.materials]);
				})
				.catch((err) => console.error(err));
		}
	}
    const openCuttingParams = (material) => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "CUTTING_PARAMS",
				isEdit: material,

			})
		);
	}
	const deleteEdges = (edge) => {
		const detailsWithEdges = checkDetailsWithEdges(construction.details, edge.index)

		if (!empty(detailsWithEdges)) {
			dispatch(
				modalsOperations.switchStateForModal({
					show: true,
					type: "REMOVE_EDGES",
					isEdit: edge,
				})
			);
		} else {
			construction.deleteEdge(edge.index)
				.then(() => {
					setCurrentEdges([...construction?.edges]);
				})
				.catch((err) => console.error(err));
		}
	};

	const addMaterials = () => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "ADD_MATERIAL",
			})
		);

		dispatch(projectOperations.setIsArrowMaterial( true));
	};

	const addEdjes = () => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "ADD_EDJES",
			})
		);
		dispatch(projectOperations.setIsArrowEdge(true));
	};

	const openKronasMaterials = () => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "KRONAS_MATERIALS",
			})
		);
	};

	const openMaterialsResiduesModal = () => {
		if (!project.projectData.id) {
			return toastWarning(Languages.getTranslation('first-save-project', true))
		}
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "MATERIALS_RESIDUES",
			})
		);
	};

	const openCustomerWarehouse = () => {
		if (permissions.includes('KM_openCustomerWarehouse')) {
			dispatch(
				modalsOperations.switchStateForModal({
					show: true,
					type: "CHOOSE_OWNER",
				})
			);
			return
		}
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "CUSTOMER_WAREHOUSE",
			})
		);
	};

	const openReplaceMaterialProject = () => {
		if (!construction._materials.length) {
			dispatch(errorMessageOperations.switchStateError({ message: Languages.getTranslation('dont-have-materials-project', true), type: 'warning', show: true }))
			return
		}
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "REPLACE_MATERIAL_PROJECT",
				isReplacedMaterialForProject: true,
			})
		);
	};

	const openReplaceEdgesProject = () => {
		if (!construction._edges.length) {
			dispatch(errorMessageOperations.switchStateError({ message: Languages.getTranslation('edges-none-project', true), type: 'warning' }))
			return
		}
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "REPLACE_EDGES_PROJECT",
			})
		);
	};

	const handleClickOut = event => {
		if (clickRef.current && !clickRef.current.contains(event.target) && isOpen) {
			setIsOpen(prevState => !prevState);
		}
	};

	useEffect(() => {
		if (typeState === "ADD_MATERIAL") {
			dispatch(
				modalsOperations.switchStateForModal({
					show: true,
					type: "ADD_MATERIAL",
				})
			);
		}
		if (construction){
			setMaterials(construction._materials);
		}
	}, [showState, materialsConstruction, typeState, ]);

	useEffect(() => {
		if (construction){
			setCurrentEdges(construction?._edges);
		}
	}, [edgesConstruction]);
	useEffect(() => {
		if (isOpen) {
			document.addEventListener('click', handleClickOut);
		}
		return () => {
			document.removeEventListener('click', handleClickOut);
		};
	}, [isOpen])

	const handleChangeGlue = (item, event) => {
		event.stopPropagation()
		setIsOpen(prevState => !prevState)
		setSelectGlue(item.value)
		const { id } = project._projectData
		construction.updateDetailGlue(id, item.glue)
	}

	const mapListMaterial = (material) => {
		const material_cutting = construction.materials.findIndex(el => el.article === material.article)
		if (empty(construction.details)) {
			dispatch(errorMessageOperations.switchStateError({
				message: Languages.getTranslation('map-project-error', true),
				type: 'error', show: true
			}))
			return
		}
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "MAP_PROJECT",
			})
		);
		dispatch(tempDetailOperations.setTempProject({material_cutting}))
	}

	const addMaterialButton = () => {
		if(!permissions.includes('KM_addMaterial')) return null;
		return (
			<div className="materials__content-container">
				<button onClick={addMaterials} className="btn">
					<i className="btn__icon-add"></i>
				</button>
				<div className="name">{Languages.getTranslation('materials', true)} KRONAS</div>
			</div>
		)
	}

	const addClientsMaterialButton = () => {
		if(!permissions.includes('KM_addClientsMaterial')) return null;
		return (
			<div className="materials__content-container">
				<button onClick={openKronasMaterials} className="btn">
					<i className="btn__icon-add"></i>
				</button>
				<div className="name">{`${Languages.getTranslation(
					"materials",
					true
				)} ${Languages.getTranslation("owner", false)}`}</div>
			</div>
		)
	}

	const addCustomerWarehouseMaterialButton = () => {
		if(!permissions.includes('KM_openCustomerWarehouseButton')) return null;
		return (
			<div className="materials__content-container">
				<button onClick={openCustomerWarehouse} className="btn">
					<i className="btn__icon-add"></i>
				</button>
				<div className="name">{Languages.getTranslation('material-from-warehouse', true)}</div>
			</div>
		)
	}

	const addReplaceMaterialButton = () => {
		if(!permissions.includes('KM_changeProjectMaterial')) return null;
		return (
			<div className="materials__content-container">
				<button onClick={openReplaceMaterialProject} title={Languages.getTranslation("replace-material", true)}
					className="btn_replace-material"><AppstoreAddOutlined/></button>
				<div className="name">{Languages.getTranslation('replace-material-project', true)}</div>
			</div>
		)
	}
	const addMaterialsResiduesButton = () => {
		if(!permissions.includes('KM_MaterialsResidues')) return null;
		return (
			<div className="materials__content-container">
				<button onClick={openMaterialsResiduesModal} className="btn">
					<i className="btn__icon-add"></i>
				</button>
				<div className="name">{Languages.getTranslation('MaterialsResidues', true)}</div>
			</div>
		)
	}

	const addEdgeButton = () => {
		if (!permissions.includes('KM_addEdge')) return null;
		return (
			<div className="edges__buttons-add">
				<button onClick={addEdjes} className="btn">
					<i className="btn__icon-add"></i>
				</button>
				<div className="edges__buttons-name">{Languages.getTranslation('edges', true)}</div>
			</div>
		)
	}

	const addReplaceEdgeButton = () => {
		if(!permissions.includes('KM_changeProjectEdge')) return null;
		return (
			<div className="materials__content-container">
				<button onClick={openReplaceEdgesProject} title={Languages.getTranslation('replace-edge-project', true)}
					className="btn_replace-material">
					<AppstoreAddOutlined/></button>
				<div className="name">{Languages.getTranslation('replace-edge-project', true)}</div>
			</div>
		)
	}

	const addSawdustHeader = () => {
		if(!permissions.includes('KM_sawdust')) return null;
		return (
			<div className="table__header-title"> {Languages.getTranslation('sawdust', true)}</div>
		)
	}

	const getMaterialsList = () => {
		if(!permissions.includes('KM_showMaterials')) return null;
		return (
			<div className="materials">
				<div className="options">
					<div className="materials__content">
						{addMaterialButton()}
						{addClientsMaterialButton()}
						{addCustomerWarehouseMaterialButton()}
						{addReplaceMaterialButton()}
						{addMaterialsResiduesButton()}
					</div>
					<button
						className="btn"
						style={{marginLeft: "auto"}}
						onClick={() => dispatch(projectOperations.setIsArrowMaterial(!isArrowMaterial))}					>
						<i className={getIconClass(isArrowMaterial)}></i>
					</button>
				</div>
				{materials.length ? (
					<div
						className={`materials-class ${isArrowMaterial ? "materials-class--opened" : ""}`}
					>
						<div className="materials-class__wrapper">
						<div className="table">
							<div className="table__header">
								<div className="table__number-and-name">
									<div className="table__header-title table__number">
										{Languages.getTranslation('number', true)}
									</div>
									<div className="table__header-title table__name">
										{Languages.getTranslation('name', true)}
									</div>
								</div>
								<div className="materialItem__data-and-btn" style={{paddingRight: 26}}>
									<div className="table__data-container">
										<div className="table__header-title"> {Languages.getTranslation('article', true)}</div>
										<div className="table__header-title"> {Languages.getTranslation('height', true)}</div>
										<div className="table__header-title"> {Languages.getTranslation('width', true)}</div>
										<div className="table__header-title"> {Languages.getTranslation('thickness', true)}</div>
										{addSawdustHeader()}
										<div className="table__header-title"> {Languages.getTranslation('amount', true)}</div>
										<div className="table__header-title"> {Languages.getTranslation('face_back', true)}</div>
										<div className="table__header-title"> </div>
									</div>
								</div>
							</div>
							<div className="table__body">
								{materials.map((material, index) => (
									<MaterialItem key={material.index} material={material} index={index}
										mapListMaterial={mapListMaterial}
										deleteMaterial={deleteMaterial}
										openCuttingParams={openCuttingParams}
										permissions={permissions}/>
								))}
							</div>
						</div>
						</div>
					</div>
				) : (
					<div></div>
				)}
			</div>
		)
	}
	const getEdgesList = () => {
		if(!permissions.includes('KM_showEdges')) return null;
		return (
			<div className="materials" style={{paddingTop: 0, paddingBottom: 0}}>
				<div className="options">
					<div className="edges__buttons">
						{addEdgeButton()}
						<div ref={clickRef}
							className={`materials__glue ${errorMessage.find(er => er.message === Languages.getTranslation('glue-not-selected', true)) ? 'errors' : ''}`}
							onClick={() => setIsOpen(prevState => !prevState)}>
							<div className={`materials__glue_text`}>{Languages.getTranslation(selectGlue, true)}</div>
							<Arrow className={isOpen ? 'materials__glue_arrow-rotate' : 'materials__glue_arrow-down'}/>
							{isOpen &&
								<div className='materials__select'>
									{optionsSelectGlue.map((item, index) => (
										index > 0 &&
										<div
											onClick={(event) => handleChangeGlue(item, event)}
											className='materials__select_text' key={index}>
											{Languages.getTranslation(item.value, true)}
										</div>
									))}
								</div>}
						</div>
						<div style={{width: "200px"}}></div>
						{addReplaceEdgeButton()}
					</div>

					<button
						className="btn"
						style={{marginLeft: "auto"}}
						onClick={() => dispatch(projectOperations.setIsArrowEdge(!isArrowEdge))}
					>
						<i className={getIconClass(isArrowEdge)}></i>
					</button>
				</div>
				{!empty(currentEdges) ? (
					<div className={`materials-class ${isArrowEdge ? "materials-class--opened" : ""}`}>
						<div className="materials-class__wrapper">
							<div className="table">
								<div className="table__header">
									<div className="table__number-and-name table__number-and-name_edges">
										<div className="table__header-title table__number">
											{Languages.getTranslation('number', true)}
										</div>
										<div className="table__header-title table__name table__name_edges">
											{Languages.getTranslation('name', true)}
										</div>
									</div>
									<div className="table__data-container">
										<div className="table__header-title">{Languages.getTranslation('article', true)}</div>
										<div className="table__header-title">{Languages.getTranslation('width', true)}</div>
										<div className="table__header-title">{Languages.getTranslation('thickness', true)}</div>
									</div>
								</div>
								<div className="table__body">
									{currentEdges.map((edge, index) => (
										<div className="table__item" key={index}>
											<div className="table__number-and-name table__number-and-name_edges">
												<div className="table__number">{index + 1}</div>
												{/*<img src={edge.images[0]?.small || img} alt="image"*/}
												{/*	 className="material__img"/>*/}
												<MaterialEdgeImg item={edge} isMaterial={false}/>
												<div className="table__name table__name_edges">
													{edgeTitle(edge)}
												</div>
											</div>
											<div className="table__data-container">
												<div className="table__data">
													{edge.article}
												</div>
												<div className="table__data">
													{edge.width}
												</div>
												<div className="table__data">
													{edge.thickness}
												</div>
											</div>
											{permissions.includes('KM_editEdge') &&
												<div className="edges__deleteBtnContainer">
													<button
														className="btn"
														onClick={() => deleteEdges(edge)}
													>
														<i className="btn__icon-delete"></i>
													</button>
												</div>
											}
										</div>
									))}
								</div>
							</div>
						</div>
						</div>
						) : (
						""
						)}
					</div>
				)
				}
				
				return (
				<>
					{getMaterialsList()}
					{getEdgesList()}
				</>
				);
				};
				
				export default Materials;
