import {useState, useCallback, useMemo, useEffect} from "react";
import CONSTANTS from "../../../../../config/constants";
import {DeleteBtn, Checkbox, DetailWrapper, Texture} from "../common";
import Material from "./Material";
import Product from "./Product";
import CustomInput from "./CustomInput";
import {acceptNumericOnly, checkDisabling, disableByMaterialType, formatEdgeValue, getWarnings} from "../helper";
import {edgeTitle, empty, imgLink, materialTitle} from "../../../../../helpers/helper";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import img from "../../../../../media/material-test.png";
import Languages from "../../../../../translation/Languages";
import {Tooltip} from "../../../Tooltip";

const Detail = ({
									style,
									detail,
									index,
									editLimits,
									warningState = {},
									remove,
									changeInputType,
									updateSizeWarning,
									changeSelectType,
									isSelected,
									isFocused,
									construction,
									selectDetailHandler
								}) => {
	const [inputValues, setInputValues] = useState({
		[CONSTANTS.detailFieldNames.NAME]: detail.name,
		[CONSTANTS.detailFieldNames.WIDTH]: detail.l,
		[CONSTANTS.detailFieldNames.HEIGHT]: detail.h,
		[CONSTANTS.detailFieldNames.COUNT]: detail.count,
		[CONSTANTS.detailFieldNames.TOP]: formatEdgeValue(detail.edges.top),
		[CONSTANTS.detailFieldNames.BOTTOM]: formatEdgeValue(detail.edges.bottom),
		[CONSTANTS.detailFieldNames.LEFT]: formatEdgeValue(detail.edges.left),
		[CONSTANTS.detailFieldNames.RIGHT]: formatEdgeValue(detail.edges.right),
	});
	const [edgeAnchorSelect, setEdgeAnchorSelect] = useState(false);
	const edges = useSelector(projectSelectors.getEdges);
	const hasDetailMills = useMemo(() => detail.mills.some(el => ['partial', 'closed'].includes(el.type)), [detail.mills])
	
	const detailMaterial = useMemo(() => construction.materials.find(el => detail.material === el.index), [detail.material, construction.materials]);
	
	const selectDetail = useCallback(() => {
		selectDetailHandler(detail);
	}, [selectDetailHandler, detail])
	
	const updateInputValues = useCallback((name, value) => {
		setInputValues((prev) => ({
			...prev,
			[name]: value,
		}));
	}, [])
	
	const changeName = useCallback(({name, value}) => {
		updateInputValues(name, value);
		
		changeInputType(detail, name, value);
	}, [detail, changeInputType, updateInputValues]);
	
	const changeCount = useCallback(({name, value}) => {
		if (!acceptNumericOnly(value)) {
			return;
		}
		
		updateInputValues(name, value);
		
		changeInputType(detail, name, value);
	}, [detail, changeInputType, updateInputValues]);
	
	const changeWidth = useCallback(({name, value}) => {
		if (!acceptNumericOnly(value)) {
			return;
		}
		
		updateInputValues(name, value);
		
		if (value) {
			updateSizeWarning(index, "width", false);
		}
		
		changeInputType(detail, name, value);
	}, [detail, index, changeInputType, updateSizeWarning, updateInputValues]);
	
	const changeHeight = useCallback(({name, value}) => {
		if (!acceptNumericOnly(value)) {
			return;
		}
		
		updateInputValues(name, value);
		
		if (value) {
			updateSizeWarning(index, "height", false);
		}
		changeInputType(detail, name, value)
	}, [detail, index, changeInputType, updateInputValues, updateSizeWarning]);
	
	const changeEdge = useCallback(({name, value}) => {
		const edgesIndexes = edges.map((edge) => edge.index + 1);
		
		if (edgesIndexes.includes(+value) || value === CONSTANTS.emptyString) {
			updateInputValues(name, value);
			
			const formattedValue = value === CONSTANTS.emptyString ? CONSTANTS.defaultEdgeValueInDB : +value - CONSTANTS.edgeStep;
			changeInputType(detail, name, formattedValue)
		}
	}, [detail, changeInputType, edges, updateInputValues]);
	
	const isTopEdgeDisabled = useMemo(() => {
		const detailMaterialType = detailMaterial.type;
		const uniquePFConditionU1 = ['Постформінг', 'Постформинг'].includes(detailMaterialType) && !detail.soft.top;
		return {
			[CONSTANTS.lockedFieldsType.millsEdges]: hasDetailMills,
			[CONSTANTS.lockedFieldsType.u1]: uniquePFConditionU1,
			[CONSTANTS.lockedFieldsType.material]: disableByMaterialType(detailMaterialType),
			[CONSTANTS.lockedFieldsType.onlyFirstGroup]: editLimits.onlyFirstGroup,
		}
	}, [detailMaterial, detail.soft, editLimits.onlyFirstGroup, hasDetailMills]);
	
	const isBottomEdgeDisabled = useMemo(() => {
		const detailMaterialType = detailMaterial.type;
		const uniquePFConditionU2 = ['Постформінг', 'Постформинг'].includes(detailMaterialType) && detailMaterial.double_rounding && !detail.soft.bottom;
		
		return {
			[CONSTANTS.lockedFieldsType.millsEdges]: hasDetailMills,
			[CONSTANTS.lockedFieldsType.u2]: uniquePFConditionU2,
			[CONSTANTS.lockedFieldsType.material]: disableByMaterialType(detailMaterialType),
			[CONSTANTS.lockedFieldsType.onlyFirstGroup]: editLimits.onlyFirstGroup,
		}
	}, [detailMaterial, detail.soft, editLimits.onlyFirstGroup, hasDetailMills]);
	
	const isLeftAndRightEdgesDisabled = useMemo(() => {
		const detailMaterialType = detailMaterial.type;
		
		return {
			[CONSTANTS.lockedFieldsType.millsEdges]: hasDetailMills,
			[CONSTANTS.lockedFieldsType.material]: disableByMaterialType(detailMaterialType),
			[CONSTANTS.lockedFieldsType.onlyFirstGroup]: editLimits.onlyFirstGroup,
		}
	}, [detailMaterial, editLimits.onlyFirstGroup, hasDetailMills]);
	
	const isNameDisabled = useMemo(() => ({
		[CONSTANTS.lockedFieldsType.onlyEdge]: editLimits.onlyEdge,
		[CONSTANTS.lockedFieldsType.onlyFirstGroup]: editLimits.onlyFirstGroup
	}), [editLimits.onlyEdge || editLimits.onlyFirstGroup]);
	
	const isSizeDisabled = useMemo(() => ({
		[CONSTANTS.lockedFieldsType.onlyEdge]: editLimits.onlyEdge,
		[CONSTANTS.lockedFieldsType.millsGabarits]: hasDetailMills
	}), [editLimits.onlyEdge || hasDetailMills]);
	
	const optionsMaterial = useMemo(() => construction._materials.map((item, key) => ({
		value: item.index, label: materialTitle(item), article: item.article, index: key + 1
	})), [construction._materials]);
	
	const optionsProduct = useMemo(() => construction._products.map((p, key) => ({
		value: p.id,
		label: `${key + 1}: ${p.name}`
	})), [construction._products]);
	
	const showEdgeTooltip = useCallback(() => {
		setEdgeAnchorSelect(true);
	}, [setEdgeAnchorSelect]);
	
	const hideEdgeTooltip = useCallback(() => {
		setEdgeAnchorSelect(false);
	}, [setEdgeAnchorSelect]);
	
	useEffect(() => {
		setInputValues((prev) => ({
			...prev,
			[CONSTANTS.detailFieldNames.TOP]: formatEdgeValue(detail.edges.top),
			[CONSTANTS.detailFieldNames.BOTTOM]: formatEdgeValue(detail.edges.bottom),
			[CONSTANTS.detailFieldNames.LEFT]: formatEdgeValue(detail.edges.left),
			[CONSTANTS.detailFieldNames.RIGHT]: formatEdgeValue(detail.edges.right),
		}))
	}, [detail.edges]);
	const detailW =  ()=> {
		if(!empty(detail.multiplicity)){
			return detail.w + detail.getMaterialThickness(detail.multiplicity.material);
		}
		return detail.w
	}
	return (
		<>
			<DetailWrapper
				style={style}
				data-index={index}
				className={`${isFocused ? "detailsTab__body-item--selected" : ""}`}
				detailId={detail.id}>
				<Checkbox tabIndex={-1} onChange={selectDetail} isSelected={isSelected}/>
				<span className="detailsTab__number">{index + 1}</span>
				<CustomInput
					isDisabled={checkDisabling(isNameDisabled)}
					name={CONSTANTS.detailFieldNames.NAME}
					className="detailsTab__data-input_name"
					value={inputValues[CONSTANTS.detailFieldNames.NAME]}
					onChange={changeName}
				/>
				<CustomInput
					isDisabled={checkDisabling(isSizeDisabled)}
					name={CONSTANTS.detailFieldNames.WIDTH}
					className={`text-center detailsTab__data-input ${warningState[CONSTANTS.detailFieldNames.WIDTH] ? ' border_warning' : ''} ${!!detail?.[`error_${CONSTANTS.detailFieldNames.WIDTH}`] ? ' border_error' : ''} `}
					value={inputValues[CONSTANTS.detailFieldNames.WIDTH]}
					title={getWarnings(isSizeDisabled)}
					onChange={changeWidth}
				/>
				<CustomInput
					isDisabled={checkDisabling(isSizeDisabled)}
					name={CONSTANTS.detailFieldNames.HEIGHT}
					className={`text-center detailsTab__data-input ${warningState[CONSTANTS.detailFieldNames.HEIGHT] ? 'border_warning' : ''} ${!!detail?.[`error_${CONSTANTS.detailFieldNames.HEIGHT}`] ? 'border_error' : ''}`}
					value={inputValues[CONSTANTS.detailFieldNames.HEIGHT]}
					title={getWarnings(isSizeDisabled)}
					onChange={changeHeight}
				/>
				<CustomInput
					isDisabled={checkDisabling({[CONSTANTS.lockedFieldsType.onlyEdge]: editLimits.onlyEdge})}
					name={CONSTANTS.detailFieldNames.COUNT}
					className="text-center detailsTab__data-input"
					value={inputValues[CONSTANTS.detailFieldNames.COUNT]}
					onChange={changeCount}
				/>
				<Material
					detail={detail}
					optionsMaterial={optionsMaterial}
					selectedMaterial={optionsMaterial.find((option) => option.value === detail.material)}
					isDisabled={checkDisabling(isNameDisabled)}
					changeSelectType={changeSelectType}
				/>
				<div className="detailsTab__data-item">{detailW()}</div>
				<div className="detailsTab__edges-group" data-tooltip-id={`detail-${detail.id}-edge-tooltip`}>
					<CustomInput
						isDisabled={checkDisabling(isTopEdgeDisabled)}
						name={CONSTANTS.detailFieldNames.TOP}
						className="text-center detailsTab__data-input detailsTab__data-input--edge"
						value={inputValues[CONSTANTS.detailFieldNames.TOP]}
						onChange={changeEdge}
						title={getWarnings(isTopEdgeDisabled)}
						onFocus={showEdgeTooltip}
						onBlur={hideEdgeTooltip}
					/>
					<CustomInput
						isDisabled={checkDisabling(isBottomEdgeDisabled)}
						name={CONSTANTS.detailFieldNames.BOTTOM}
						className="text-center detailsTab__data-input detailsTab__data-input--edge"
						value={inputValues[CONSTANTS.detailFieldNames.BOTTOM]}
						onChange={changeEdge}
						title={getWarnings(isBottomEdgeDisabled)}
						onFocus={showEdgeTooltip}
						onBlur={hideEdgeTooltip}
					/>
					<CustomInput
						isDisabled={checkDisabling(isLeftAndRightEdgesDisabled)}
						name={CONSTANTS.detailFieldNames.LEFT}
						className="text-center detailsTab__data-input detailsTab__data-input--edge"
						value={inputValues[CONSTANTS.detailFieldNames.LEFT]}
						onChange={changeEdge}
						title={getWarnings(isLeftAndRightEdgesDisabled)}
						onFocus={showEdgeTooltip}
						onBlur={hideEdgeTooltip}
					/>
					<CustomInput
						isDisabled={checkDisabling(isLeftAndRightEdgesDisabled)}
						name={CONSTANTS.detailFieldNames.RIGHT}
						className="text-center detailsTab__data-input detailsTab__data-input--edge"
						value={inputValues[CONSTANTS.detailFieldNames.RIGHT]}
						onChange={changeEdge}
						title={getWarnings(isLeftAndRightEdgesDisabled)}
						onFocus={showEdgeTooltip}
						onBlur={hideEdgeTooltip}
					/>
				</div>
				<div className="detailsTab__texture-edit">
					<Texture
						disabled={checkDisabling(isNameDisabled)}
						detailMaterial={detailMaterial}
						isRotateTexture={detail.isRotateTexture}
						id={detail.id}/>
				</div>
				<Product
					optionsProduct={optionsProduct}
					selectedProduct={optionsProduct.find((option) => option.value === detail.productId)}
					isDisabled={checkDisabling(isNameDisabled)}
					detail={detail}
					changeSelectType={changeSelectType}
				/>
				<div className="detailsTab__data-item detailsTab__number">
					<DeleteBtn detail={detail} remove={remove}/>
				</div>
			</DetailWrapper>
			{edgeAnchorSelect &&
				<Tooltip
					className="detailsTab__edges-tooltip"
					id={`detail-${detail.id}-edge-tooltip`}
					isOpen={edgeAnchorSelect}
					place="bottom"
					positionStrategy="fixed"
					clickable
				>
					<div className="detailsTab__edges-tooltip-wrapper">
						{edges.length > 0 ? edges.map((edge) => <div
							className="quickInputTable__edges-helper-item" key={edge.index}>
							<div className="edgesHelper__number">{edge.index + 1}</div>
							<img
								src={edge?.images?.length > 0 && edge.images[0].hasOwnProperty('small') ? edge.images[0].small : imgLink}
								alt="edge2"
								className="material__img"
							/>
							<div className="edgesHelper__item-name">{edgeTitle(edge)}</div>
						</div>) : <p>{Languages.getTranslation("edges-not-find-variant", true)}</p>}
					</div>
				</Tooltip>}
		</>
	)
};

export default Detail;