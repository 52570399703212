import * as translations from './translations.json';

export const LANGS = {
  UK: "uk",
  UA: "ua",
  RU: "ru",
  EN: "en",
  SK: "sk",
  PL: "pl",
}

export default class Languages {
  static _locale = localStorage.getItem('locale') || LANGS.UK;
  static _avaliableLocales = [LANGS.UK, LANGS.RU, LANGS.EN, LANGS.SK, LANGS.PL];

  static get lang() {
    return this._locale;
  }

  static get langs() {
    return this._avaliableLocales;
  }

  static checkUkLang(lang) {
      return lang === LANGS.UK ? LANGS.UA : lang;
  }

  static getTranslationFromObj = (languages, property = "name", lang) => {
    return languages?.[lang]?.[property] || languages?.[LANGS.RU]?.[property];
  }

  static getAvaliableTranslationBaseOnUk = (languages, property = "name") => {
    return this.getTranslationFromObj(languages, property, this.lang);
  }


  static getAvaliableTranslation(languages, property = "name") {
    const lang = languages?.hasOwnProperty(LANGS.UK) ? this.lang : this.checkUkLang(this.lang) ;
    return languages?.[lang]?.[property]  || languages?.[LANGS.RU]?.[property];
  }
  static getAvaliableTranslation2(languages, property = "name") {
    const lang = this.checkUkLang(this.lang);
    return languages?.[lang] || languages?.[LANGS.UK] || languages?.[LANGS.RU];
  }

  static getTranslation(name, capitalise = false) {
    if (typeof name === 'string') {
      let str = translations?.[name]?.[this._locale];
      if (capitalise && str) {
        str = str.charAt(0).toUpperCase() + str.slice(1)
      }
      return str || 'Відсутній переклад';
    }
  }
}