import './style.scss';
import Languages, {LANGS} from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";
import {Modal, ModalFooter, ModalHeader} from "../index";
import React, {useState} from "react";
import {empty, validationInput} from "../../../../helpers/helper";
import Requests from "../../../../api/API";
import Errors from "../../../../models/Errors";
import {errorMessageOperations} from "redux/errors";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../Loader";
import {projectOperations, projectSelectors} from "redux/project";
import CloseBtn from "../../Buttons/CloseBtn";
const _errors = new Errors([])

const EditFurnitureModal = ({isOpen, submitModalText, curLang, setFurnituresArr,close, item, furnitures }) => {
	const [furnitureForChange, setFurnitureForChange] = useState({});
	const [isLoaded, setIsLoaded] = useState(false); // Используем флаг, чтобы отследить состояние загрузки
	const [prevFurniture, setPrevFurniture] = useState({});
	const [countOld, setCountOld] = useState(null);
	const dispatch = useDispatch();
	const construction = useSelector(projectSelectors.getConstruction);

	const search = async (code) => {
		if (code.length >= 5) {
			setIsLoaded(true);
			try {
				const request = await Requests.Materials.getHardware(code);
				if (!empty(request.data)) {
					request.data[0].count = item.count;
					request.data[0].article = request.data[0].vendor_code;

					const updatedFur = request.data[0];
					setFurnitureForChange(updatedFur);

					const prevFur = construction.furnitures.find(({vendor_code}) => vendor_code ===updatedFur.vendor_code)
					setCountOld(prevFur?.count || 0)
					setPrevFurniture(prevFur || null);
				} else {
					dispatch(errorMessageOperations.switchStateError({
						message: Languages.getTranslation("not-found-furnitures", true),
						type: 'error',
						show: true
					}));
				}
			} catch (error) {
				_errors.setError(error.message, 'error', true);
			} finally {
				setIsLoaded(false);
			}
		}

	};
	const editFurniture = () => {
          if(!empty(furnitureForChange)){
			  furnitureForChange.index = item.index

			  construction.setProjectFurnitures([furnitureForChange])
				  .then(() => {
					  construction.deleteFurniture(item.index)
					  setTimeout(() =>{
						  const arr = [
							  ...construction.furnitures,
						  ]

						  if(!empty(prevFurniture)){
							  const updatedArray = arr.map(el => {
								  if (el.vendor_code === furnitureForChange.vendor_code) {
									  if (el.count !== furnitureForChange.count + countOld) {
										  el.count = furnitureForChange.count + countOld;
									  }
								  }
								  return el;
							  });
							  setFurnituresArr(updatedArray);
							  dispatch(projectOperations.updateFurniture(updatedArray));

						  }else{
						  setFurnituresArr(arr);
						  dispatch(projectOperations.updateFurniture(arr));
					  }
					  }, 100)
					  close();
				  })
				  .catch((error) => console.error('Error in async chain:', error));
			}

	}
	const handleIncrement = () => {
		if (furnitureForChange) {
			const newCount = (furnitureForChange?.count || 1) + 1;
			if (newCount <= 99999) {
				setFurnitureForChange({
					...furnitureForChange,
					count: newCount,
				});
			}
		}
	};
	const handleDecrement = () => {
		if (furnitureForChange) {
			setFurnitureForChange({
				...furnitureForChange,
				count: furnitureForChange?.count > 1 ? furnitureForChange?.count - 1 : 1,
			});
		}
	};
	const handleInputChange = (e) => {
		const rawValue = e.target.value;
		const numericValue = rawValue.replace(/\D/g, ''); // Only numbers
		const validatedValue = numericValue === '0' || numericValue === '' ? '1' : numericValue;

		if (furnitureForChange) {
			setFurnitureForChange({
				...furnitureForChange,
				count: Number(validatedValue),
			});
		}
	};
	return <Modal isOpen={isOpen}>
		<div className="modal">
		<div className='editFurnitureModal modal__wrapper'>
			<ModalHeader className="replaceMaterialProject__header">
				<h3 className="editFurnitureModal__title">{Languages.getTranslation('replacing-fittings', true)}</h3>
				<CloseBtn handler={close}/>

			</ModalHeader>
			<div className="editFurnitureModal__body">
				<table className="hardware-table">
					<thead>
					<tr className="hardware-table-row-header">
						<th className="hardware-table-header-item no-border-left">№</th>
						<th className="hardware-table-header-item"></th>
						<th className="hardware-table-header-name">{Languages.getTranslation('name', true)}</th>
						<th className="hardware-table-header-item">{Languages.getTranslation('article', true)}</th>
						<th className="hardware-table-header-item">{Languages.getTranslation('amount', true)}</th>
						<th className="hardware-table-header-item">{Languages.getTranslation('price', true)}</th>
						<th className="hardware-table-header-item">{Languages.getTranslation('total', true)}</th>
					</tr>
					</thead>
					<tbody>
					<>
						<tr key={item.index} className="hardware-table-row-item">
							<td className="hardware-table-item hardware-table-item-first-item">{1}</td>
							<td className="hardware-table-item">
								<img
									className="hardware-table-item_img"
									src={item?.media?.images[0]?.small}
									alt={item.article}
								/>
							</td>
							<td className="hardware-table-item__name">
								<div className="hardware-table-item__subText">
									<a
										className="hardware-table-item__subText_link"
										href={
											curLang === LANGS.UA
												? `https://kronas.com.ua/ua/api/v2/product/link/${item.vendor_code}`
												: `https://kronas.com.ua/api/v2/product/link/${item.vendor_code}`
										}
										target="_blank"
										rel="noreferrer"
									>
										{Languages.getAvaliableTranslation(item.translations) ||
											item.name}
									</a>
								</div>
							</td>
							<td className="hardware-table-item">{item?.vendor_code}</td>
							<td className="hardware-table-item">
								<div>
									{/* Кнопка уменьшения значения */}
									<input
										className="hardware-table-item__default-input-small"
										type="text"
										value={item.count}
										pattern="^\d*$"
										maxLength="5"
										disabled

									/>

								</div>
							</td>
							<td className="hardware-table-item">{item?.info?.price.toFixed(2)}</td>
							<td className="hardware-table-item">{(item?.count * item?.info?.price.toFixed(2)).toFixed(2)}</td>


						</tr>
					</>
					</tbody>
				</table>

				<div className="editFurnitureModal__body-searchField">
					<h4>{Languages.getTranslation('for-change', true)}</h4>

					<div className="addMaterial__header-searchField">
						<i className="addMaterial__input-icon"></i>
						<input
							className="addMaterial__input"
							type="text"
							onInput={(e) => validationInput(e)}
							onKeyUp={(e) => search(e.target.value)}

							placeholder={Languages.getTranslation("add-article-material", true)}

						/>
					</div>
				</div>

					{!empty(furnitureForChange) && (
						<table className="hardware-table">
							<thead>
							<tr className="hardware-table-row-header">
								<th className="hardware-table-header-item no-border-left">№</th>
								<th className="hardware-table-header-item"></th>
								<th className="hardware-table-header-name">{Languages.getTranslation('name', true)}</th>
								<th className="hardware-table-header-item">{Languages.getTranslation('article', true)}</th>
								<th className="hardware-table-header-item">{Languages.getTranslation('amount', true)}</th>
								<th className="hardware-table-header-item">{Languages.getTranslation('price', true)}</th>
								<th className="hardware-table-header-item">{Languages.getTranslation('total', true)}</th>
							</tr>
							</thead>
							<tbody>
							<>
								<tr key={furnitureForChange.index} className="hardware-table-row-item">
									<td className="hardware-table-item hardware-table-item-first-item">{1}</td>
									<td className="hardware-table-item">
										<img
											className="hardware-table-item_img"
											src={furnitureForChange?.media?.images[0]?.small}
											alt={furnitureForChange.article}
										/>
									</td>
									<td className="hardware-table-item__name">
										<div className="hardware-table-item__subText">
											<a
												className="hardware-table-item__subText_link"
												href={
													curLang === LANGS.UA
														? `https://kronas.com.ua/ua/api/v2/product/link/${furnitureForChange.vendor_code}`
														: `https://kronas.com.ua/api/v2/product/link/${furnitureForChange.vendor_code}`
												}
												target="_blank"
												rel="noreferrer"
											>
												{Languages.getAvaliableTranslation(furnitureForChange.translations) ||
													furnitureForChange.name}
											</a>
										</div>
									</td>
									<td className="hardware-table-item">{furnitureForChange?.vendor_code}</td>
									<td className="hardware-table-item">
										<div>
											{/* Кнопка уменьшения значения */}
											<button
												className="hardware-table-item__button"
												style={{marginRight: '8px'}}
												onClick={handleDecrement}

											>
												-
											</button>
											{/* Поле ввода */}
											<input
												className="hardware-table-item__default-input-small"
												type="text"
												value={furnitureForChange?.count}
												pattern="^\d*$"
												maxLength="5"
												onChange={handleInputChange}

											/>
											{/* Кнопка увеличения значения */}
											<button
												className="hardware-table-item__button"
												style={{marginLeft: '8px'}}
												onClick={handleIncrement}
											>
												+
											</button>
										</div>
									</td>
									<td className="hardware-table-item">{furnitureForChange?.info?.price.toFixed(2)}</td>
									<td className="hardware-table-item">{(furnitureForChange?.count * furnitureForChange?.info?.price.toFixed(2)).toFixed(2)}</td>

								</tr>
							</>
							</tbody>
						</table>

					)}
				{isLoaded && <Loader />}
			</div>


			<ModalFooter className="editFurnitureModal__actions">

				<DefaultBtn
					title={Languages.getTranslation("submit", true)}
					handler={editFurniture}
				/>
			</ModalFooter>
		</div>
		</div>
	</Modal>

}

export default EditFurnitureModal