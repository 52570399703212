import Processing from "./3D/Processing";
import TableTop from "./Mills/TableTop";
import Helpers from "./3D/Helpers";
import {empty} from "../helpers/helper";
import TableCorner from "./Mills/TableCorner";
import Languages from "../translation/Languages";

export default class TableProc extends Processing {
	_width
	_edgeSide
	_elements
	_additionalSide
	_mill
	_isActive = false;
	_type;
	_isErrorText = "";
	_subType = 'tableProc'
	_edge
	_angle
	_r
	touchedSides
	constructor({
					edge = null,
					angle = 'left_top',
          detail,
          comment,
          additionalSide = 'left',
          mill,
		      dataForConstructor = {},
		      quant = 0,
		      length = 0,
          elements,
          width = 600,
          edgeSide = 'left',
          type = "tableTop",
          subType = 'tableProc',
					id,
          isErrorText = '',
		      additionalInfo = '',
		      isInit = false,
					r = 300
	            }) {
		super({detail, id, comment, edgeSide, isErrorText, additionalInfo, isInit});
		this._mill = mill
		this._type = type
		this._subType = subType
		this._angle = angle
		this._edge = edge
		this._additionalSide = additionalSide
		this._dataForConstructor = dataForConstructor;
		this._side = 'front';
		this._width = width
		this.quant = quant;
		this.length = length;
		this._elements = elements
		this._edgeSide = edgeSide
		this.touchedSides = this.angle ? angle.split("_") : [edgeSide]
		this._r = r
		if(empty(this.getDataForConstructor().edgeSide)) {
			this.updateDataForConstructor({name: 'edgeSide', value: this.edgeSide})
		}
		this.switchMill()
		this.addParams()
		if(this?._mill) {
			this._mill?.initRequiredFields()
		}
	}

	remove() {
		const index = this.detail.mills.findIndex(el => el.id === this.id);
		if(index !== -1) {
			this.detail.error.deleteErrorProcessing(this.detail.mills[index].isErrorText)
			this.detail.mills.splice(index, 1);
			if (this.type === 'smile') {
				this.detail.edges[this.edgeSide] = null
			}
		}
		return this.updateDb(true)
			.then(() => this.detailDb.updateDetail('edges', this.detail.edges))
			.then(() => this.buildDetail())
			.then(() => this.renderDetail())
	}

	switchMill() {
		switch (this.type) {
			case 'tableTop':
				this.mill = new TableTop({
					parent: this,
					detailL: this.detail.l,
					detailH: this.detail.h,
					// elements: this.elements,
				})
				this.mill.paramsSorting
				break
			case 'tableCorner':
				this.mill = new TableCorner({
					parent: this,
					detailL: this.detail.l,
					detailH: this.detail.h,
					// elements: this.elements,
				})
				this.mill.paramsSorting
				break
		}
		this.mill.initRequiredFields()
	}

	get additionalSide() {
		return this._additionalSide
	}

	set additionalSide(additionalSide) {
		this._additionalSide = additionalSide;
		this.setFormField({name: 'additionalSide', value: this.additionalSide})
		this.renderDetail()
	}

	get mill() {
		return this._mill
	}

	set mill(mill) {
		this._mill = mill
	}

	set isActive(active) {
		this._isActive = active
		this.renderDetail()
	}

	get isActive() {
		return this._isActive;
	}

	get edge() {
		return this._edge;
	}

	set edge(edge) {
		let newEdge
		typeof edge === 'object' && edge !== null ? newEdge = edge.index : newEdge = edge
		this._edge = newEdge;
		if(this.type === 'tableCorner') {
			if (this.angle==='left_top') {
				this.detail.edges = {...this.detail.edges, left: this.edge}
			} else {
				this.detail.edges = {...this.detail.edges, right: this.edge}
			}
		}
		this.renderDetail()
	}

	get angle() {
		return this._angle
	}

	set angle(angle) {
		this._angle = angle;
		this.setFormField({name: 'angle', value: this.angle});
	}

	get realData() {
		this.elements = this?.mill?.elements || [];
		const data = {
			type: this.type,
			edge: this.edge,
			depth: this.detail.realW,
			angle: this.angle,
			fullDepth: true,
			touchedSides: this.angle ? this.angle.split("_") : [this.edgeSide],
			id: this.id,
			edgeSide: this.edgeSide,
			subside: this.angle ? this.angle.split("_") : null,
			elements: this.elements,
			...this?.mill?.realData,
			quant: this.elements.length,
		}
		let result = 0
		for (const argumentsKey in this.elements) {
			result += this.elements[argumentsKey].length
		}
		data.length = result
		return data
	}

	get type() {
		return this._type
	}

	set type(type) {
		this._type = type
		this.setFormField({name: 'type', value: this.type, label: Languages.getTranslation('type-of-proces', true)})
		this._dataForConstructor = {}
		this.switchMill()
	}

	get r() {
		return this._r
	}

	set r(r) {
		this._r = r
		this.setFormField({name: 'r', value: this.r, label: 'Радіус'})
		this.initRequiredFields()
	}

	get subType() {
		return this._subType
	}

	get width() {
		return this._width
	}

	addSizesArrows() {
		return this.mill.addSizesArrows()
	}

	initRequiredFields() {
		this?.mill?.initRequiredFields()
	}

	set width(width) {
		this._width = Number(width);
		this.setFormField({name: 'width', value: this.width})
		this.renderDetail()
	}

	get elements() {
		return this._elements
	}

	set elements(elements) {
		this._elements = elements
	}

	get edgeSide() {
		return this._edgeSide
	}

	get cutOut() {
		return this.mill.cutOut
	}

	set edgeSide(edgeSide) {
		this._edgeSide = edgeSide
		if (['top', 'bottom'].includes(this.edgeSide)) {
			this.setFormField({name: 'width', visible: true})
			this.setFormField({name: 'additionalSide', visible: true})
		}
		if (['right', 'left'].includes(this.edgeSide)) {
			this.setFormField({name: 'additionalSide', visible: false})
			this.setFormField({name: 'width', visible: false})
		}
		this.updateDataForConstructor({name: 'edgeSide', value: this.edgeSide});
		this.setFormField({name: 'edgeSide', value: this.edgeSide})
		this.renderDetail()
	}

	get paramsSorting() {
		return this.mill.paramsSorting
	}

	addParams() {
		return this.mill.addParams()
	}
}