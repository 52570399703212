import ContourEdgeHelper from "../UI/3dUI/ContourEdgeHelper";
import {useSelector} from "react-redux";
import {contourEdgeHelperSelectors} from "redux/contourEdgeHelper";

const InterFace3D = () => {
  const contourEdgeHelperShow = useSelector(contourEdgeHelperSelectors.getContourEdgeHelperShowState)

  return <>
    {contourEdgeHelperShow && <ContourEdgeHelper />}
  </>
}

export default InterFace3D;