import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import ACTIONS from "./actions";

const initialState = {
  show: false,
  edge: null,
  cursorPosition: {
    x: 0,
    y: 0,
  }
};

const contourEdgeHelper = createReducer(initialState, {
  [ACTIONS.ContourEdgeHelperStateSwitch.type]: (state, val) => {
    return { ...state, show: val.payload.show, edge: val.payload.edge || null, cursorPosition: val.payload.cursorPosition };
  },
});

export default combineReducers({
  contourEdgeHelper,
});
