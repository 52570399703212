import {List} from "react-virtualized";
import {Detail} from "./Detail";
import {forwardRef, useCallback, useState, useEffect} from "react";
import {useProjectTableContext} from "../../../../../hooks/useContexts";
import {useDispatch, useSelector} from "react-redux";
import {updateDetailOrderInDB} from "../../../../../helpers/helper";
import Languages from "../../../../../translation/Languages";
import {projectOperations, projectSelectors} from "redux/project";
import CONSTANTS from "../../../../../config/constants";

const Table = forwardRef(({isDraggable, selectDetail, selectedDetails, remove, details, setDetails, height, hasErrors, width}, ref) => {
    const {tableRef, rerenderTable} = useProjectTableContext();
    const dispatch = useDispatch();
		const construction = useSelector(projectSelectors.getConstruction);
		const detailIdFromScene = useSelector(projectSelectors.getIdDetailFromScene);
		const [scrolledDetail, setScrolledDetail] = useState(typeof detailIdFromScene === 'number' ? details.findIndex((d) => d._id === detailIdFromScene) : false);
  
		const handleDragStart = useCallback((e, detail) => {
				setTimeout(() => e.target.classList.add("dragging"), 0);
        e.dataTransfer.setData('order', detail.order);
    }, []);
	
	const dragEndHandler = useCallback((e) => {
		e.target.classList.remove("dragging");
	}, []);

    const handleDrop = useCallback(async (e, detail) => {
        e.preventDefault();
        const draggingOrderId = e.dataTransfer.getData('order');

        const draggingItem = details.find((d) => d.order === Number(draggingOrderId));
        const targetItem = details.find((d) => d.order === detail.order);

        const draggingIndex = details.indexOf(draggingItem);
        const targetIndex = details.indexOf(targetItem);
			
				if (draggingIndex !== -1 && targetIndex !== -1) {
					const newDetails = [...details];
					
					const [removed] = newDetails.splice(draggingIndex, 1);
					newDetails.splice(targetIndex, 0, removed);
					
					newDetails.forEach((item, index) => {
						item.order = index + 1;
					});
					
					setDetails(newDetails);
					rerenderTable();
					
					await Promise.all(newDetails.map((item, index) => updateDetailOrderInDB(item, index + 1)));
				}
    }, [details, setDetails, rerenderTable]);

    const dragOverHandler = useCallback((e) => {
        e.preventDefault()
    }, []);

    const rowRenderer = ({index, style}) => {
			if (details.length === 0) {
				return (
					<div key="empty-project" style={style} className="details__empty-project">{Languages.getTranslation("empty-project", true)}</div>
				);
			}
			
			const detail = details[index];
			const isHighlighted = typeof scrolledDetail === "number" ? scrolledDetail === index : false;

        return (
            <Detail
                key={detail.id}
                style={{...style, backgroundColor: isHighlighted ? "#f1e5ef" : ""}}
                selectDetailHandler={selectDetail}
                detail={detail}
                index={index}
                hasErrors={hasErrors}
                remove={remove}
								isDraggable={isDraggable}
                isSelected={selectedDetails.includes(detail)}
                dragEndHandler={dragEndHandler}
                dragOverHandler={dragOverHandler}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
								construction={construction}
            />
        )
    };
	
	useEffect(() => {
		setTimeout(() => {
			setScrolledDetail(false);
      dispatch(
        projectOperations.setIdDetailFromScene(null)
      );
		}, 5000);
	}, []);

    return (
        <div ref={ref} className="table-wrapper">
            <List
								{...(typeof scrolledDetail === "number" && {scrollToIndex: scrolledDetail})}
                ref={tableRef}
                className="default-details-table"
                height={height}
                overscanRowCount={CONSTANTS.overscanRowCount}
								scrollToAlignment="center"
                rowCount={details.length === 0 ? 1 : details.length}
                rowHeight={70}
                rowRenderer={rowRenderer}
                width={width}
            />
        </div>
    )
});

export default Table;