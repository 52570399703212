import React, { useEffect, useRef, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {projectOperations, projectSelectors} from "redux/project";

import CloseBtn from "components/UI/Buttons/CloseBtn";
import Languages from "translation/Languages";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";

import "./style.scss";
import Loader from "../../Loader";
import Errors from "../../../../models/Errors";
import { formatPrice } from "../../Price/Price";
import Project from "../../../../models/Project";
import {errorMessageOperations} from "redux/errors";

const _errors = new Errors([]);
const AddCatalogProductModal = ({ close, catalogList, productId, loadFurniture }) => {
    const dispatch = useDispatch();

    const construction = useSelector(projectSelectors.getConstruction);
    const containerRef = useRef(null);
    const [products, setProducts] = useState(catalogList?.data || []);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(catalogList?.meta.current_page || 1);
    const lastPage = catalogList?.meta?.last_page || 1;


    const loadMore = async () => {
        if (loading || page >= lastPage) {

            return;
        }

        setLoading(true);

        try {

            const response = await Project.getProductsByAlias(productId, page + 1);

            if (response?.data?.length) {
                setProducts((prevProducts) => [...prevProducts, ...response.data]);
                setPage((prevPage) => prevPage + 1);
            }
        } catch (error) {
            console.error("Ошибка во время загрузки данных:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = () => {
        const container = containerRef.current;
        if (!container) return;

        const { scrollTop, scrollHeight, clientHeight } = container;

        if (scrollHeight - clientHeight - scrollTop < 50 && !loading && page < lastPage) {
            loadMore();
        }
    };


    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [page, loading]);


    const submit = (selectedHardware) => {
        if (!selectedHardware || selectedHardware.length === 0) {
            _errors.setError(
                `${Languages.getTranslation("hardware-not-chos", true)}`,
                "error",
                true
            );
            return;
        }


        const furnitureAlreadyExist = construction.furnitures.some((el) => el?.vendor_code === selectedHardware[0]?.vendor_code)
        if(furnitureAlreadyExist) {
            close();
            const mess = `${Languages.getTranslation("hardware", true)} ${Languages.getTranslation("articles", true)} ${selectedHardware[0]?.vendor_code} ${Languages.getTranslation("exist-in-project", true)}`
            dispatch(errorMessageOperations.switchStateError(
                { message: mess, type: 'error', show: true }))
        }else {
        construction
            .setProjectFurnitures([
                ...selectedHardware.map((el) => ({
                    ...el,
                    article: el.vendor_code,
                    count: 1,
                })),
            ])
            .then(() => {
                const arr= [
                    construction.furnitures[construction.furnitures.length - 1],
                    ...construction.furnitures.slice(0, -1),
                ];
                dispatch(
                    projectOperations.updateFurniture(arr)
                );

                dispatch(errorMessageOperations.switchStateError(
                    { message: Languages.getTranslation("added-furniture", true), type: 'success', show: true }))
                close();
            });
        }
    };

    return (
        <div className="modal">
            <div className="addCatalogProducts modal__wrapper"
                 ref={containerRef}
            >
                <div className="addCatalogProducts__header">
                    <div className="addCatalogProducts__header-title">
                        <h3 className="addCatalogProducts__header-title-text">
                            {Languages.getTranslation("recommended-product", true)}
                        </h3>
                    </div>
                    <CloseBtn handler={close} />
                </div>

                <div className="addCatalogProducts-grey-divider"></div>
                <div
                    className="addCatalogProducts__main"
                >
                    {products.map((item, index) => (
                        <div
                            key={index}
                            className="addCatalogProducts__main_item"
                        >

                            <div className="addCatalogProducts__main_item_imgContainer">
                                <img
                                    src={item.media.images[0]?.original || ""}
                                    className="addCatalogProducts__main_item_img"
                                    alt={item?.alias}
                                />
                            </div>
                            <div className="addCatalogProducts__main_item_article">
                                {Languages.getTranslation("article-product", true)}:{" "}
                                <span className="addCatalogProducts__main_item_article_num">
                                    {item.vendor_code}
                                </span>
                            </div>
                            <div className="addCatalogProducts__main_item_text">
                                {Languages.getAvaliableTranslationBaseOnUk(item.translations)}
                            </div>
                            <div className="addCatalogProducts__main_item_blockAdd">
                                <div className="addCatalogProducts__main_item_blockAdd_price">
                                    {formatPrice(item.info.price)} {Languages.getTranslation("uah1", true)}
                                </div>
                                <div>
                                    <DefaultBtn
                                        btnStyle={{ width: "5px" }}
                                        title={Languages.getTranslation("add", true)}
                                        handler={() => submit([item])}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {loading && <Loader />}

        </div>
    );
};

export default AddCatalogProductModal;